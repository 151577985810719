import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { KalkulatorService } from '../../../services/kalkulator.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mfo-tarifalas',
  templateUrl: './mfo-tarifalas.component.html',
  styleUrls: ['./mfo-tarifalas.component.scss']
})
export class MfoTarifalasComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private kalkulatorService: KalkulatorService,
    private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      // Ha van értéke
      if (params['azonosito']) {
        this.azonosito = parseInt(params['azonosito']);
      }
    });
  }

  mfoTarifalasFormGroup: FormGroup;
  azonosito: number = 0;

  ngOnInit(): void {

    this.mfoTarifalasFormGroup = this.formBuilder.group({
      f_azonosito: new FormControl(this.azonosito),
      alapAdatokFormGroup: this.formBuilder.group({
        f_azonosito: new FormControl(this.azonosito),
        f_kockazatviseles_kezdete: new FormControl(null, Validators.required),
        f_biztositas_tipusa: new FormControl(null, Validators.required),
        f_biztositando_ingatlan_tipusa: new FormControl(null, Validators.required),
        f_tulajdonviszony: new FormControl(null, Validators.required),
        f_ingatlan_allandoan_lakott: new FormControl(false),
        f_szerzodo_maganszemely: new FormControl(false),
        f_ingatlan_epites_alatt_all: new FormControl(false),
        f_ingatlan_kulteruleten_helyezkedik_el: new FormControl(false),
        f_szerzodo_szuletesi_datuma: new FormControl(null, Validators.required),
        f_iranyitoszam: new FormControl(null, Validators.required),
        f_telepules: new FormControl(null, Validators.required),
        f_foepulet_epitesi_eve: new FormControl(null, Validators.required),
        f_biztositando_mellekepuletek_szama: new FormControl(null, Validators.required),
        f_eletvitelszeruen_ott_lakok_szama: new FormControl(null, Validators.required)
      }),
      epuletAdataiFormGroup: this.formBuilder.group({
        f_azonosito: new FormControl(this.azonosito),
        f_foepulet_alapterulete: new FormControl(null, Validators.required),
        f_mellekepulet_alapterulete_1: new FormControl(null),
        f_mellekepulet_alapterulete_2: new FormControl(null),
        f_mellekepulet_alapterulete_3: new FormControl(null),
        f_mellekepulet_alapterulete_4: new FormControl(null),
        f_mellekepulet_alapterulete_5: new FormControl(null),
        f_foepulet_falazata: new FormControl(null, Validators.required),
        f_mellekepulet_falazata_1: new FormControl(null),
        f_mellekepulet_falazata_2: new FormControl(null),
        f_mellekepulet_falazata_3: new FormControl(null),
        f_mellekepulet_falazata_4: new FormControl(null),
        f_mellekepulet_falazata_5: new FormControl(null),
        f_foepulet_tetozete: new FormControl(null, Validators.required),
        f_mellekepulet_tetozete_1: new FormControl(null),
        f_mellekepulet_tetozete_2: new FormControl(null),
        f_mellekepulet_tetozete_3: new FormControl(null),
        f_mellekepulet_tetozete_4: new FormControl(null),
        f_mellekepulet_tetozete_5: new FormControl(null)
      }),
      ajanlottBiztositasiOsszegekFormGroup: this.formBuilder.group({
        f_azonosito: new FormControl(this.azonosito),
        f_ajanlott_ingatlan_biztositasi_osszeg: new FormControl(null),
        f_ajanlott_ingosag_biztositasi_osszeg: new FormControl(null),
        f_foepulet_ingatlan_biztositasi_osszeg: new FormControl(null),
        f_foepulet_ingosag_biztositasi_osszeg: new FormControl(null),
        f_mellekepulet_ingatlan_biztositasi_osszeg_1: new FormControl(null),
        f_mellekepulet_ingosag_biztositasi_osszeg_1: new FormControl(null),
        f_mellekepulet_ingatlan_biztositasi_osszeg_2: new FormControl(null),
        f_mellekepulet_ingosag_biztositasi_osszeg_2: new FormControl(null),
        f_mellekepulet_ingatlan_biztositasi_osszeg_3: new FormControl(null),
        f_mellekepulet_ingosag_biztositasi_osszeg_3: new FormControl(null),
        f_mellekepulet_ingatlan_biztositasi_osszeg_4: new FormControl(null),
        f_mellekepulet_ingosag_biztositasi_osszeg_4: new FormControl(null),
        f_mellekepulet_ingatlan_biztositasi_osszeg_5: new FormControl(null),
        f_mellekepulet_ingosag_biztositasi_osszeg_5: new FormControl(null),
        f_ingatlan_dij: new FormControl(null),
        f_ingosag_dij: new FormControl(null),
        f_szeretnem_modositastani_ingatlan_biztositasi_osszeget: new FormControl(false),
        f_szeretnem_modositastani_ingosag_biztositasi_osszeget: new FormControl(false),
      })
    });

    if (this.azonosito > 0) {
      this.formGroupFeltolteseAdatokkal();
    }
  }

  // FromGroup feltöltése adatokkal aznosító alapján
  formGroupFeltolteseAdatokkal() {
    forkJoin(this.kalkulatorService.mfoAlapAdatokLekerese(this.azonosito), this.kalkulatorService.mfoEpuletAdataiLekerese(this.azonosito)).subscribe(
      data => {
        console.log(data);
      },
      errorResult => {
        console.log(errorResult);
      }
    );
  }

  // Alap adatok tovább esemény
  onAlapAdatokTovabbClick() {
    this.kalkulatorService.mfoAlapAdatokMentes(this.mfoTarifalasFormGroup.controls['alapAdatokFormGroup'].value).subscribe(
      data => {
        this.mfoTarifalasFormGroup.controls['f_azonosito'].setValue(data);
      },
      errorResult => {
        console.log(errorResult);
      }
    );
  }

  // Épület adati tovább esemény
  onEpuletAdataiTovabbClick() {
    this.kalkulatorService.mfoEpuletAdataiMentes(this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'].value).subscribe(
      () => {
      },
      errorResult => {
        console.log(errorResult);
      }
    );
  }

  // Ajánlott biztositási összegek tovább esemmény
  onAjanlottBiztositasiOsszegekTovabbClick() {
    console.log(this.mfoTarifalasFormGroup);
  }
}
