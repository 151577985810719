<div class="contain-lg">

  <form [formGroup]="karbejelentoBejelentoForm" class="form">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">

          <div [class.disableDiv]="karbejelentoBejelentoForm.get('f_modozat').value == 'CMR'">

            <div class="row mt-3">

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Bejelentő típusa:  *</mat-label>
                  <mat-select matInput formControlName="f_bejelento_tipusa" [disabled]="karbejelentoBejelentoForm.get('f_ugyfelportal').value">
                    <mat-option value="0">Kérem válasszon..!</mat-option>
                    <mat-option value="E" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value != 'CMR'">Károsult</mat-option>
                    <mat-option value="e" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value != 'CMR'">Károkozó</mat-option>
                    <mat-option value="J" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'KGFB' || karbejelentoBejelentoForm.get('f_modozat').value == 'CASCO'">Javító</mat-option>
                    <mat-option value="c" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'OTTHON' || karbejelentoBejelentoForm.get('f_modozat').value == 'TRAFIK'">Bejelentő</mat-option>
                    <mat-option value="t" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'CMR'">Szerződő / Biztosított</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_bejelento_tipusa').errors?.keremValasszon">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>{{bejelentoNevCimke}}</mat-label>
                  <input matInput formControlName="f_nev">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_nev').errors?.required">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>
              <div class="col-lg-2" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value != 'CMR'">
                <mat-form-field appearance="fill">
                  <mat-label>Születési dátum:</mat-label>
                  <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_szul_datum" [max]="szulDatumMaxDate">
                  <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                  <mat-datepicker #szulDatumPicker></mat-datepicker>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_szul_datum').errors?.required">
                    Hibás dátum!
                  </mat-error>
                </div>
              </div>
              <div class="col-lg-2" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value != 'CMR'">
                <mat-form-field appearance="fill">
                  <mat-label>Születési hely:</mat-label>
                  <mat-select matInput formControlName="f_szul_hely">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let telepules of szulTelepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-label class="control-label">{{bejelentoLakcimCimke}}</mat-label>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-select matInput formControlName="f_cim_mo" (selectionChange)="orszagTipusValtozas()">
                    <mat-option value="N">Külföld</mat-option>
                    <mat-option value="I">Magyarország</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="karbejelentoBejelentoForm.get('f_cim_mo').value && karbejelentoBejelentoForm.get('f_cim_mo').value == 'I'">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Irányítószám:  *</mat-label>
                    <input type="text" matInput formControlName="f_irsz" [textMask]="{mask: irszamMask}">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_irsz').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_irsz').errors?.irszamHossz">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Település:  *</mat-label>
                    <mat-select matInput formControlName="f_telepules">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev
                  }}</mat-option>
                  </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telepules').errors?.keremValasszon">
                      Hiányzó adat!
                    </mat-error>
                  </div>
</div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Utcanév:  *</mat-label>
                    <mat-select matInput formControlName="f_utcanev">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_utcanev').errors?.keremValasszon">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Úttípus:  *</mat-label>
                    <mat-select matInput formControlName="f_uttipus" [disabled]="!karbejelentoBejelentoForm.get('f_uttipus_van').value">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_uttipus').errors?.keremValasszon">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Házszám  *</mat-label>
                    <input matInput formControlName="f_hazszam" maxlength="5">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_hazszam').errors?.fuggosegiKotelezettseg">
                      Házszám vagy Helyrajzi szám kötelező!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Emelet:</mat-label>
                    <input matInput formControlName="f_emelet">
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Ajtó:</mat-label>
                    <input matInput formControlName="f_ajto">
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Hrsz:</mat-label>
                    <input matInput formControlName="f_hrsz">
                  </mat-form-field>
                </div>

              </div>


            </div>

            <div *ngIf="karbejelentoBejelentoForm.get('f_cim_mo').value && karbejelentoBejelentoForm.get('f_cim_mo').value == 'N'">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Ország:  *</mat-label>
                    <mat-select matInput formControlName="f_orszag" (selectionChange)="orszagValtozas($event)">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Irányítószám:  *</mat-label>
                    <input matInput formControlName="f_kulf_irsz">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kulf_irsz').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Cím:  *</mat-label>
                    <input matInput formControlName="f_kulf_cim">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kulf_cim').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Telefon típusa:</mat-label>
                <mat-select matInput formControlName="f_telefon_tipus">
                  <mat-option value="M">Mobil</mat-option>
                  <mat-option value="V">Vezetékes</mat-option>
                  <mat-option value="K">Külföldi</mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'CMR'" matTooltip="A kárügyintézésben eljáró fél elérhetősége.">info</mat-icon>
              </mat-form-field>
            </div>

            <div class="col-lg-2" *ngIf="karbejelentoBejelentoForm.get('f_telefon_tipus').value != 'K'">
              <mat-form-field appearance="fill">
                <mat-label>Előhívó:  *</mat-label>
                <mat-select matInput formControlName="f_telefon_elohivo" (selectionChange)="eloHivoValtozas()">
                  <mat-option value="0">Kérem válasszon..</mat-option>
                  <mat-option *ngFor="let eloHivo of eloHivoLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>

            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Telefonszám:  *</mat-label>
                <input type="text" matInput formControlName="f_telefon_szam" [textMask]="karbejelentoBejelentoForm.get('f_telefon_tipus').value != 'K' ? {mask: phoneMask} : {mask: false}" [placeholder]="phonePlaceholder">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam').errors?.telefonszamUres">
                  Hiányzó adat!
                </mat-error>
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam').errors?.telefonszam">
                  Hibás adat!
                </mat-error>                
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam').errors?.telefonszamKulfHossz">
                  Hibás adat! (Minimum 6 és maximum 20 karakterből állhat)
                </mat-error>
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam').errors?.telefonszamKulf">
                  Hibás adat! (Megengedett karakterek: 0-9, /, +, -)
                </mat-error>
              </div>
            </div>

          </div>

          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>E-mail cím:</mat-label><mat-label *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'CMR'">  *</mat-label>
                <input type="text" matInput formControlName="f_email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_email').errors?.pattern">
                  Hibás adat!
                </mat-error>
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_email').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>

            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Fax:</mat-label>
                <input type="text" matInput formControlName="f_fax" [textMask]="{mask: faxMask}" placeholder="+36(12) 123-1234">
              </mat-form-field>
              <mat-error *ngIf="karbejelentoBejelentoForm.get('f_fax').errors?.fax">
                Hibás adat!
              </mat-error>
            </div>
          </div>

          <div class="row mt-3">

            <div class="col-lg-12 mb-2">
              <mat-label class="control-label font-bold">Szerződő/ Biztosított kapcsolattartója</mat-label>
            </div>
          </div>

          <div class="row mt-3" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'CMR'">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Név:  *</mat-label>
                <input matInput formControlName="f_nev_kapcsolattarto">
              </mat-form-field>
            </div>
          </div>

          <div class="row mt-3" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'CMR'">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Telefon típusa:  *</mat-label>
                <mat-select matInput formControlName="f_telefon_tipus_kapcsolattarto">
                  <mat-option value="M">Mobil</mat-option>
                  <mat-option value="V">Vezetékes</mat-option>
                  <mat-option value="K">Külföldi</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-2" *ngIf="karbejelentoBejelentoForm.get('f_telefon_tipus_kapcsolattarto').value != 'K'">
              <mat-form-field appearance="fill">
                <mat-label>Előhívó:  *</mat-label>
                <mat-select matInput formControlName="f_telefon_elohivo_kapcsolattarto" (selectionChange)="eloHivoValtozasKapcsolattarto()">
                  <mat-option value="0">Kérem válasszon..</mat-option>
                  <mat-option *ngFor="let eloHivo of kapcsolattartoEloHivoLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Telefonszám:  *</mat-label>
                <input type="text" matInput formControlName="f_telefon_szam_kapcsolattarto" [textMask]="karbejelentoBejelentoForm.get('f_telefon_tipus_kapcsolattarto').value != 'K' ? {mask: phoneMaskKapcsolattarto} : {mask: false}" [placeholder]="phonePlaceholderKapcsolattarto">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam_kapcsolattarto').errors?.telefonszam">
                  Hibás adat!
                </mat-error>
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam_kapcsolattarto').errors?.telefonszamKulfHossz">
                  Hibás adat! (Minimum 6 és maximum 20 karakterből állhat)
                </mat-error>
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_telefon_szam_kapcsolattarto').errors?.telefonszamKulf">
                  Hibás adat! (Megengedett karakterek: 0-9, /, +, -)
                </mat-error>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>E-mail cím:</mat-label>
                <input type="text" matInput formControlName="f_email_kapcsolattarto" [textMask]="{mask: emailMaskKapcsolattarto}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoBejelentoForm.get('f_email_kapcsolattarto').errors?.pattern">
                  Hibás adat!
                </mat-error>
              </div>
            </div>

            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Fax:</mat-label>
                <input type="text" matInput formControlName="f_fax_kapcsolattarto" [textMask]="{mask: faxMaskKapcsolattarto}" placeholder="+36(12) 123-1234">
              </mat-form-field>
              <mat-error *ngIf="karbejelentoBejelentoForm.get('f_fax').errors?.fax">
                Hibás adat!
              </mat-error>
            </div>
          </div>

          <div class="mt-3" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value && (karbejelentoBejelentoForm.get('f_modozat').value == 'OTTHON' || karbejelentoBejelentoForm.get('f_modozat').value == 'TRAFIK')">
            <div class="row">
              <div class="col-lg-12 mb-2">
                <mat-label class="control-label font-bold">Kártérítés kedvezményezett adatai</mat-label>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Tipus:  *</mat-label>
                  <mat-select matInput formControlName="f_kedv_adat_tipus">
                    <mat-option value="0">Kérem válasszon..!</mat-option>
                    <mat-option value="szla">Számlaszám</mat-option>
                    <mat-option value="tel">Másodlagos banki azonosító - Telefonszám</mat-option>
                    <mat-option value="mail">Másodlagos banki azonosító - E-mail cím</mat-option>
                    <mat-option value="ado">Másodlagos banki azonosító - Adóazonosítójel</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat_tipus').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2" *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat_tipus').value != 'tel' && karbejelentoBejelentoForm.get('f_kedv_adat_tipus').value != '0'">
                <mat-form-field appearance="fill">
                  <mat-label>{{kedvTipAdatLabel}}</mat-label>
                  <input type="text" matInput formControlName="f_kedv_adat" [textMask]="{mask: kedvAdatMask}" [placeholder]="kedvAdatPlaceholder">
                </mat-form-field>
                <mat-label class="info-text">{{szlaBank}}</mat-label>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat').errors?.szlaHiba">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-10" *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat_tipus').value == 'tel'">
                <div class="row">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Telefon típusa:</mat-label>
                      <mat-select matInput formControlName="f_kedv_telefon_tipus">
                        <mat-option value="M">Mobil</mat-option>
                        <mat-option value="V">Vezetékes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Előhívó:  *</mat-label>
                      <mat-select matInput formControlName="f_kedv_telefon_elohivo" (selectionChange)="kedvEloHivoValtozas()">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let eloHivo of kedvEloHivoLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Telefonszám:  *</mat-label>
                      <input type="text" matInput formControlName="f_kedv_telefon_szam" [textMask]="{mask: kedvPhoneMask}" [placeholder]="kedvPhonePlaceholder">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_telefon_szam').errors?.telefonszamUres">
                        Hiányzó adat!
                      </mat-error>
                      <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_telefon_szam').errors?.telefonszam">
                        Hibás adat!
                      </mat-error>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-label class="control-label">Kedvezményezett adat újra:  *</mat-label>
              </div>

              <div class="col-lg-2" *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat_tipus').value != 'tel' && karbejelentoBejelentoForm.get('f_kedv_adat_tipus').value != '0'">
                <mat-form-field appearance="fill">
                  <mat-label>{{kedvTipAdatLabel}}</mat-label>
                  <input type="text" matInput formControlName="f_kedv_adat_ujra" [textMask]="{mask: kedvAdatMask}" [placeholder]="kedvAdatPlaceholder" onCopy="return false" onPaste="return false">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat_ujra').errors?.fuggosegiKotelezettseg">
                    Nem egyeznek a kedvezményezett adatok!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-10" *ngIf="karbejelentoBejelentoForm.get('f_kedv_adat_tipus').value == 'tel'">
                <div class="row">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Telefon típusa:</mat-label>
                      <mat-select matInput formControlName="f_kedv_telefon_tipus_ujra">
                        <mat-option value="M">Mobil</mat-option>
                        <mat-option value="V">Vezetékes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Előhívó:  *</mat-label>
                      <mat-select matInput formControlName="f_kedv_telefon_elohivo_ujra" (selectionChange)="kedvEloHivoUjraValtozas()">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let eloHivo of kedvEloHivoUjraLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Telefonszám:  *</mat-label>
                      <input type="text" matInput formControlName="f_kedv_telefon_szam_ujra" [textMask]="{mask: kedvPhoneUjraMask}" [placeholder]="kedvPhoneUjraPlaceholder" onCopy="return false" onPaste="return false">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoBejelentoForm.get('f_kedv_telefon_szam_ujra').errors?.kedvTelefonUjra">
                        Nem egyeznek a kedvezményezett adatok!
                      </mat-error>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="row mt-2">
            <div class="col-lg-12">
              <div class="row" *ngIf="karbejelentoBejelentoForm.get('f_modozat').value == 'KGFB' && karbejelentoBejelentoForm.get('f_bejelento_tipusa').value == 'e'">
                <div class="col-lg-4 mb-2">
                  <mat-label class="control-label">KGFB okozói adatkezelési nyilatkozat</mat-label>
                  <div class="mt-2">
                    <mat-checkbox matCheckbox class="mr-2" formControlName="f_adatkezelesi_okozoi"></mat-checkbox>
                    <span>* <a href="{{weboldalDokumentumok}}/kgfb_adatkez_tajek_karesemeny.pdf" target="_blank">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítás biztosítottjának gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></span>
                    <div class="col-lg-12 pt-2">
                      <mat-error *ngIf="karbejelentoBejelentoForm.get('f_adatkezelesi_okozoi').errors?.required">
                        Az online kárbejelelentés funkció használatának feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>

</div>
