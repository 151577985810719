import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';

import { AppRoutingModule } from './app-routing.module';
import { CommonLibraryModule } from '@granit/common-library';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { HomeComponent } from './components/home/home.component';

import { KarbejelentoComponent, KarbejelentoDuplaDialog } from './components/karbejelento/karbejelento.component';
import { KarbejelentoAlapadatokComponent } from './components/karbejelento/aloldalak/karbejelento-alapadatok/karbejelento-alapadatok.component';
import { KarbejelentoKaresemenyComponent } from './components/karbejelento/aloldalak/karbejelento-karesemeny/karbejelento-karesemeny.component';
import { KarbejelentoBejelentoComponent } from './components/karbejelento/aloldalak/karbejelento-bejelento/karbejelento-bejelento.component';
import { KarbejelentoSzemleComponent } from './components/karbejelento/aloldalak/karbejelento-szemle/karbejelento-szemle.component';
import { KarbejelentoGepjarmuSzemelyComponent } from './components/karbejelento/aloldalak/karbejelento-gepjarmu-szemely/karbejelento-gepjarmu-szemely.component';
import { KarbejelentoKarosultComponent } from './components/karbejelento/aloldalak/karbejelento-karosult/karbejelento-karosult.component';
import { KarbejelentoKarosultOtthonComponent } from './components/karbejelento/aloldalak/karbejelento-karosult-otthon/karbejelento-karosult-otthon.component';
import { KarbejelentoVagyontargyComponent } from './components/karbejelento/aloldalak/karbejelento-vagyontargy/karbejelento-vagyontargy.component';
import { KarbejelentoFajlfeltoltesComponent } from './components/karbejelento/aloldalak/karbejelento-fajlfeltoltes/karbejelento-fajlfeltoltes.component';
import { KarbejelentoEredmenyComponent } from './components/karbejelento/aloldalak/karbejelento-eredmeny/karbejelento-eredmeny.component';
import { BefizetesComponent } from './components/befizetes/befizetes.component';

import { TextMaskModule } from 'angular2-text-mask';
import { MfoTarifalasComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-tarifalas.component';
import { MfoAlapAdatokComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-alap-adatok/mfo-alap-adatok.component';
import { MfoEpuletAdataiComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-epulet-adatai/mfo-epulet-adatai.component';
import { MfoAjanlottBiztositasiOsszegekComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-ajanlott-biztositasi-osszegek/mfo-ajanlott-biztositasi-osszegek.component';
import { MfoOsszesgzesEsKiegeszitoBiztositasokComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-osszesgzes-es-kiegeszito-biztositasok/mfo-osszesgzes-es-kiegeszito-biztositasok.component';
import { MfoBiztositasiOsszegekModositasaComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-biztositasi-osszegek-modositasa/mfo-biztositasi-osszegek-modositasa.component';
import { IdopontUsziComponent } from './components/idopont-uszi/idopont-uszi.component';
import { KarbejelentoHibaOldalComponent } from './components/karbejelento/karbejelento-hiba-oldal/karbejelento-hiba-oldal.component';
import { PortalLibraryModule } from '@granit/portal-library';
import { RendezvenyRegisztracioComponent, RendezvenyRegisztracioAdatkezelesiDialog } from './components/rendezveny-regisztracio/rendezveny-regisztracio.component';
import { DokumentumIgenylesComponent } from './components/dokumentum-igenyles/dokumentum-igenyles.component';
import { DokumentumLetoltesComponent } from './components/dokumentum-letoltes/dokumentum-letoltes.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FairPaySuccessComponent } from './components/fairpay/fairpay-success.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    HomeComponent,
    KarbejelentoComponent,
    KarbejelentoDuplaDialog,
    KarbejelentoAlapadatokComponent,
    KarbejelentoKaresemenyComponent,
    KarbejelentoBejelentoComponent,
    KarbejelentoSzemleComponent,
    KarbejelentoGepjarmuSzemelyComponent,
    KarbejelentoKarosultComponent,
    KarbejelentoKarosultOtthonComponent,
    KarbejelentoVagyontargyComponent,
    KarbejelentoFajlfeltoltesComponent,
    KarbejelentoEredmenyComponent,
    BefizetesComponent,
    MfoTarifalasComponent,
    MfoAlapAdatokComponent,
    MfoEpuletAdataiComponent,
    MfoAjanlottBiztositasiOsszegekComponent,
    MfoBiztositasiOsszegekModositasaComponent,
    MfoOsszesgzesEsKiegeszitoBiztositasokComponent,
    IdopontUsziComponent,
    KarbejelentoHibaOldalComponent,
    RendezvenyRegisztracioComponent,
    DokumentumIgenylesComponent,
    DokumentumLetoltesComponent,
    FairPaySuccessComponent
  ],
  entryComponents: [
    KarbejelentoDuplaDialog,
    RendezvenyRegisztracioAdatkezelesiDialog
  ],
  imports: [
    AppRoutingModule,
    MatRadioModule,
    TooltipModule,
    CommonLibraryModule.forRoot(),
    PortalLibraryModule.forRoot(),
    TextMaskModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
