<form [formGroup]="ajanlottBiztositasiOsszegekFormGroup" class="form-group">
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Ajánlott ingatlan biztosítási összeg</mat-label>
        <input matInput formControlName="f_ajanlott_ingatlan_biztositasi_osszeg">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Ajánlott ingóság biztosítási összeg</mat-label>
        <input matInput formControlName="f_ajanlott_ingosag_biztositasi_osszeg">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Főépület ingatlan biztosítási összeg</mat-label>
        <input matInput formControlName="f_foepulet_ingatlan_biztositasi_osszeg">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Főépület ingóság biztosítási összeg</mat-label>
        <input matInput formControlName="f_foepulet_ingosag_biztositasi_osszeg">
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngFor="let i of biztositandoMellekepuletekSzamaArray">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field appearance="outline">
          <mat-label>{{i}}. Melléképület ingatlan biztosítási összeg</mat-label>
          <input matInput formControlName="f_mellekepulet_ingatlan_biztositasi_osszeg_1">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field appearance="outline">
          <mat-label>{{i}}. Melléképület ingóság biztosítási összeg</mat-label>
          <input matInput formControlName="{{'f_mellekepulet_ingosag_biztositasi_osszeg_' + i}}">
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-lg-12">
      <mat-checkbox formControlName="f_szeretnem_modositastani_ingatlan_biztositasi_osszeget">Szeretném módosítani az ingatlan biztosítási összeget</mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-checkbox formControlName="f_szeretnem_modositastani_ingosag_biztositasi_osszeget">Szeretném módosítani az ingóság biztosítási összeget</mat-checkbox>
    </div>
  </div>
</form>
