<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="mfoTarifalasFormGroup.controls['alapAdatokFormGroup']">
          <ng-template matStepLabel>Alap adatok</ng-template>
          <mfo-alap-adatok [formGroup]="mfoTarifalasFormGroup"></mfo-alap-adatok>
          <button mat-raised-button matStepperNext color="accent" (click)="onAlapAdatokTovabbClick()" [disabled]="!mfoTarifalasFormGroup.controls['alapAdatokFormGroup'].valid">Tovább</button>
        </mat-step>
        <mat-step [stepControl]="mfoTarifalasFormGroup.controls['epuletAdataiFormGroup']">
          <ng-template matStepLabel>Épület adatai</ng-template>
          <mfo-epulet-adatai [formGroup]="mfoTarifalasFormGroup"></mfo-epulet-adatai>
          <button mat-raised-button matStepperPrevious color="accent">Vissza</button>
          <button mat-raised-button matStepperNext color="accent" (click)="onEpuletAdataiTovabbClick()" [disabled]="!mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'].valid">Tovább</button>
        </mat-step>
        <mat-step [stepControl]="mfoTarifalasFormGroup.controls['ajanlottBiztositasiOsszegekFormGroup']">
          <ng-template matStepLabel>Ajánlott biztosítási összegek</ng-template>
          <mfo-ajanlott-biztositasi-osszegek [formGroup]="mfoTarifalasFormGroup"></mfo-ajanlott-biztositasi-osszegek>
          <button mat-raised-button matStepperPrevious color="accent">Vissza</button>
          <button mat-raised-button matStepperNext color="accent" (click)="onAjanlottBiztositasiOsszegekTovabbClick()" [disabled]="!mfoTarifalasFormGroup.controls['ajanlottBiztositasiOsszegekFormGroup'].valid">Tovább</button>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Biztosítási összegek módosítása</ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Összegzés és kiegésztő biztosítások</ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>




