<div class="contain-lg">
  <form [formGroup]="karbejelentoFajlFeltoltesForm" class="form">
  <ng-container *ngIf="toltoKereses">
    <div class="row">
      <div class="col-lg-12 bg-white">
        <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
        </ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!toltoKereses">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">
          <div class="row mt-1">
            <mat-icon class="ml-3 info-icon" [matTooltip]="karbejelentoFajlFeltoltesForm.get('f_modozat').value != 'CMR' ? 'A káreseménnyel kapcsolatban rendelkezésre álló fájlokat (fotókat, egyéb dokumentumokat) kérjük feltölteni. Biztonsági okok miatt, csak jpg, jpeg, bmp, tif, png, pdf, doc, docx, odt kiterjesztés file-kat tud kárbejelentéséhez csatolni. Kérjük szíves megértését.' : 'Kérjük, a fájl választása funkció alkalmazásával a káreseménnyel kapcsolatban rendelkezésre feltölteni szíveskedjen. Amennyiben a kárbejelentő fél a károkozó vagy a károsult fél meghatalmazottja, abban az esetben a meghatalmazást kérjük, feltölteni szíveskedjen. Biztonsági okok miatt, csak jpg, jpeg, bmp, tif, png, pdf, doc, docx, odt kiterjesztés file-kat tud kárbejelentéséhez csatolni. Kérjük szíves megértését.'">info</mat-icon>
          </div>
          <div class="row mt-5">
            <div class="col-lg-4">
              <granit-file-upload #fileUpload ngDefaultControl [formGroup]="karbejelentoFajlFeltoltesForm" [formControlName]="'f_dokumentum'" [labelText]="''"
                                  [buttonText]="'Fájl kiválasztás'" allowMultipleFiles="true" [acceptedFileExtensions]="fajlKiterjesztesek" (changeClick)="fajlEllenorzes($event)"></granit-file-upload>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4">
              <mat-label>A kárbejelentéshez legfeljebb 15MB méretig lehet fájlokat csatolni. Amennyiben a feltölteni kívánt fájlok összeadott mérete meghaladja a 15MB-ot, kéjük csökkentse a képek méretét.</mat-label>
            </div>
          </div>

          <mat-card class="form-content-2 mt-5 mb-3 pt-2 pb-3 col-lg-4" *ngIf="hibak.length > 0">
            <div>
              <mat-card-header>
                <mat-card-title>Hibák</mat-card-title>
              </mat-card-header>
            </div>
            <mat-card-content>
              <div class="col-lg-12">
                <div *ngFor="let item of hibak">
                  {{item.azonosito}} : {{item.hibaSzoveg}}
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <div class="row mt-3">
            <div class="col-lg-4">
              <mat-label>Feltöltött fájlok össz mérete: {{this.karbejelentoFajlFeltoltesForm.controls["f_fajlok_merete"].value}} MB</mat-label>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4">

              <mat-table [dataSource]="feltoltottFajlokTabla" class="w100 mr-5 table_border">
                <ng-container *ngFor="let column of feltoltottFajlokOszlopok" [matColumnDef]="column.columnDef">
                  <mat-header-cell *matHeaderCellDef class="mat-header-cell cell_border" [class]=column.class>{{ column.header }}</mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index;" [formGroup]="element"
                            [class]=column.class>
                    <span *ngIf="column.columnDef != 'f_egyeb_muvelet_gomb'" [innerHTML]="element.get([column.columnDef]).value | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus | highlight: filterString"></span>
                    <button mat-icon-button *ngIf="column.columnDef == 'f_egyeb_muvelet_gomb'" (click)="feltoltottFajlTorlese(element);" [matTooltip]="column.buttonText">
                      <mat-icon class="material-icons-outlined">{{column.buttonIcon}}</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>
                <!-- Fejléc -->
                <mat-header-row *matHeaderRowDef="getDisplayedFeltoltottFajlokColumns()"></mat-header-row>
                <!-- Sorok -->
                <mat-row *matRowDef="let element; let row; let i = index; columns: getDisplayedFeltoltottFajlokColumns();"></mat-row>
              </mat-table>


            </div>
          </div>

          <div class="row mt-3" *ngIf="karbejelentoFajlFeltoltesForm.get('f_szemely_serultes').value && karbejelentoFajlFeltoltesForm.get('f_fajl_db').value > 0">
            <div class="col-lg-4 mb-4">
              <mat-label class="control-label">Egészségügyi adatkezelés</mat-label>
              <div class="mt-2">
                <mat-checkbox matCheckbox class="mr-2" formControlName="f_egeszsegugyi_adatkezeles"></mat-checkbox>
                <span>* Az <a href="{{weboldalDokumentumok}}/nyilatkozat_egeszsegugyi_adatok_kezelesehez.pdf" target="_blank">egészségügyi adatkezelés nyilatkozat</a> tartalmát megismertem, az abban foglaltakat tudomásul vettem.</span>
                <div class="col-lg-12 pt-2">
                  <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_egeszsegugyi_adatkezeles').errors?.fuggosegiKotelezettseg">
                    Az online kárbejelelentés fájlfeltöltési funkciójának feltétele személyi sérüléses kár esetében az egészségügyi adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                  </mat-error>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5" *ngIf="karbejelentoFajlFeltoltesForm.get('f_modozat').value == 'KGFB' && karbejelentoFajlFeltoltesForm.get('f_bejelento').value == 'e'">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Megítélése szerint a káreseményért felelős:  *</mat-label>
                <mat-select matInput formControlName="f_okozo_felelos">
                  <mat-option value="-1">Kérem válasszon..</mat-option>
                  <mat-option value="0">Egészben saját magam</mat-option>
                  <mat-option value="1">Részben saját magam</mat-option>
                  <mat-option value="2">Egészében a másik fél</mat-option>
                  <mat-option value="3">Egyéb</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_okozo_felelos').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>
            <div class="col-lg-2" *ngIf="karbejelentoFajlFeltoltesForm.get('f_okozo_felelos').value == '3'">
              <mat-form-field appearance="fill">
                <input type="text" matInput formControlName="f_okozo_felelos_egyeb" [disabled]="karbejelentoFajlFeltoltesForm.get('f_okozo_felelos').value != 3">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_okozo_felelos_egyeb').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Milyen minőségben vezette a gépjárművet :  *</mat-label>
                <mat-select matInput formControlName="f_okozo_vezeto">
                  <mat-option value="-1">Kérem válasszon..</mat-option>
                  <mat-option value="0">Tulajdonos</mat-option>
                  <mat-option value="1">Alkalmazott (céges)</mat-option>
                  <mat-option value="2">Egyéb</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_okozo_vezeto').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>
            <div class="col-lg-2" *ngIf="karbejelentoFajlFeltoltesForm.get('f_okozo_vezeto').value == '2'">
              <mat-form-field appearance="fill">
                <input type="text" matInput formControlName="f_okozo_vezeto_egyeb" [disabled]="karbejelentoFajlFeltoltesForm.get('f_okozo_vezeto').value != 2">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_okozo_vezeto_egyeb').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>
          </div>

          <div *ngIf="karbejelentoFajlFeltoltesForm.get('f_modozat').value == 'OTTHON' || karbejelentoFajlFeltoltesForm.get('f_modozat').value == 'TRAFIK'">
            <div class="row mt-5">
              <div class="col-lg-4">
                <mat-label>Az adott káreseménnyel kapcsolatban más biztosítónál tett-e kárbejlentést?</mat-label>
              </div>
            </div>
            <div class="row mt-2" *ngIf="karbejelentoFajlFeltoltesForm.get('f_modozat').value == 'OTTHON' || karbejelentoFajlFeltoltesForm.get('f_modozat').value == 'TRAFIK'">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Kárbejlentés történt?  *</mat-label>
                  <mat-select matInput formControlName="f_mas_biztositonal_bejelentes" (selectionChange)="karbejelentesTortentValtozas()">
                    <mat-option value="-1">Kérem válasszon..</mat-option>
                    <mat-option value="N">Nem</mat-option>
                    <mat-option value="I">Igen</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_mas_biztositonal_bejelentes').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Biztosító neve:  *</mat-label>
                  <mat-select matInput formControlName="f_mas_biztosito_kod" [disabled]="karbejelentoFajlFeltoltesForm.get('f_mas_biztositonal_bejelentes').value != 'I'" (selectionChange)="biztositoValtozas($event)">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let biztosito of biztositoLista" [value]="biztosito.f_bizt_kod">{{biztosito.f_biztosito}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoFajlFeltoltesForm.get('f_mas_biztosito_kod').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
  </form>
</div>
