<div class="contain-lg">
    <form [formGroup]="karbejelentoEredmenyForm" class="form">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="mt-4">    
                    <div class="row mt-5 mb-5" [hidden]="karbejelentoEredmenyForm.get('f_uzenet').value != ''">
                        <div class="col-lg-4">
                            <p>Eddig sikeresen rögzítette kárbejelentését. Mielőtt véglegesítené a kárbejelentését és beküldi biztosító társaságunknak az Ön által kitöltött adatokat ellenőrizni szíveskedjen. Amennyiben az Ön által rögzített adatokat helyesnek ítéli meg, akkor legyen szíves a beküldés gombra kattintani.<br><br>A beküldés gomra történő kattintás után visszaigazolást küldünk Önnek, mely tartalmazza a bejelentés nyílvántartási számát és a kárigénybejelentő pdf formátumú dokumentumát, mellyel igazolni tudja, hogy a kárbejelentés az Ön részéről megtörtént. Estelegesen a későbbiekben felmerülő kérdések érdekében az általunk küldött elektronikus igazolást elmenteni szíveskedjen.<br><br>Tisztelettel:<br>Gránit Biztosító Zrt</p>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-4">                                
                            <div [innerHtml]="karbejelentoEredmenyForm.get('f_uzenet').value"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
