import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonValidator, HibaService, UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RendezvenyService } from '../../services/rendezveny.service';
import { DomSanitizer } from '@angular/platform-browser'
import { forkJoin } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { WebAppsService } from '../../services/webApps.service';


const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];

@Component({
  selector: 'rendezveny-regisztracio',
  templateUrl: './rendezveny-regisztracio.component.html',
  styleUrls: ['./rendezveny-regisztracio.component.scss']
})
export class RendezvenyRegisztracioComponent implements OnInit {

  rendezvenyFormGroup: FormGroup;
  externalHtml: any;
  resztvevokLista: any[];
  regisztracioVege: string = "";
  aktualisDatum: any = new Date();

  constructor(private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private rendezvenyService: RendezvenyService,
    public hibaService: HibaService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private httpClient: HttpClient,
    private sanitized: DomSanitizer,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private webApps: WebAppsService) { }

  config: CountdownConfig = {
    leftTime: 0,
    format: 'DD HH:mm:ss',
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);

      return CountdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
            // When days is empty
            if (name === 'D' && v <= 0) {
              return '';
            }
            return v.toString().padStart(match.length, '0');
          });
        }
        return current;
      }, formatStr);
    },
  };

  @ViewChild('cd', { static: false }) private cd: CountdownComponent;

  ngOnInit(): void {

    this.webApps.changeMessage("Rendezvény regisztráció");

    this.rendezvenyFormGroupInit();

    this.rendezvenyFormGroup.controls['email1'].valueChanges.subscribe(value => {
      if (this.rendezvenyFormGroup.controls['email1'].value && this.rendezvenyFormGroup.controls['email1'].errors == null) {
        if (this.resztvevokLista.filter(x => x.f_resztvevo_email == this.rendezvenyFormGroup.controls['email1'].value).length > 0) {
          this.rendezvenyFormGroup.controls['email1'].setErrors({ regisztraltEmail: true });
        }
      }
    });

    this.rendezvenyFormGroup.controls['email2'].valueChanges.subscribe(value => {
      if (this.rendezvenyFormGroup.controls['email2'].value && this.rendezvenyFormGroup.controls['email2'].errors == null) {
        if (this.resztvevokLista.filter(x => x.f_resztvevo_email == this.rendezvenyFormGroup.controls['email2'].value).length > 0) {
          this.rendezvenyFormGroup.controls['email2'].setErrors({ regisztraltEmail: true });
        }
      }
    });

  } 

  rendezvenyFormGroupInit() {
    this.rendezvenyFormGroup = this.formBuilder.group({
      sorszam: new FormControl(null),
      megnevezes: new FormControl(null),
      maxFo: new FormControl(null),
      hatarido: new FormControl(null),
      meghivo: new FormControl(null),
      meghivo_szoveg: new FormControl(null),
      regisztracio: new FormControl(null),
      regisztracio_szoveg: new FormControl(null),
      hatarido_szoveg: new FormControl(null),
      maxfo_szoveg: new FormControl(null),
      adatkezelesi_szoveg: new FormControl(null),
      idopont: new FormControl(""),
      helyszin: new FormControl(""),
      cegnev: new FormControl(null, Validators.required),
      nev1: new FormControl(null),
      email1: new FormControl(null, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")),
      nev2: new FormControl(null, ),
      email2: new FormControl(null, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")),
      gepjarmu: new FormControl(""),
      adatkezelesi: new FormControl(false, CommonValidator.defaultEllenorzo(false))      
    }, {
      validators: [AdatValidator.resztvevoEllenor('nev1', 'email1', 'nev2', 'email2')]});

    this.rendezvenyLekeres();
  }

  rendezvenyLekeres() {

    this.recaptchaV3Service.execute('webapps_rendezveny_alapadatok').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {


          this.rendezvenyService.rendezvenyAlapAdatok().subscribe(
            data => {
              if (data) {
                this.rendezvenyFormGroup.controls["sorszam"].setValue(data["f_sorszam"]);
                this.rendezvenyFormGroup.controls["megnevezes"].setValue(data["f_megnevezes"]);
                this.rendezvenyFormGroup.controls["maxFo"].setValue(data["f_max_fo"]);
                this.rendezvenyFormGroup.controls["hatarido"].setValue(data["f_hatarido"]);
                this.rendezvenyFormGroup.controls["meghivo"].setValue(data["f_meghivo_megnevezes"]);
                this.rendezvenyFormGroup.controls["meghivo_szoveg"].setValue(data["f_meghivo_szoveg"]);
                this.rendezvenyFormGroup.controls["regisztracio"].setValue(data["f_regisztracio_megneveztes"]);
                this.rendezvenyFormGroup.controls["regisztracio_szoveg"].setValue(data["f_regisztracio_szoveg"]);
                this.rendezvenyFormGroup.controls["hatarido_szoveg"].setValue(data["f_hatarido_szoveg"]);
                this.rendezvenyFormGroup.controls["maxfo_szoveg"].setValue(data["f_maxfo_szoveg"]);
                this.rendezvenyFormGroup.controls["adatkezelesi_szoveg"].setValue(data["f_adatkezelesi_szoveg"]);
                this.rendezvenyFormGroup.controls["idopont"].setValue(data["f_datum_szoveg"]);
                this.rendezvenyFormGroup.controls["helyszin"].setValue(data["f_helyszin"]);

                this.rendezvenyResztvevok();
              }
              else {
                this.regisztracioVege = "nincs";
              }

            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a rendezvény adatok lekérdezése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  rendezvenyResztvevok() {

    this.resztvevokLista = [];

    this.recaptchaV3Service.execute('webapps_rendezveny_resztvevok').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            sorszam: this.rendezvenyFormGroup.controls["sorszam"].value
          }

          this.rendezvenyService.rendezvenyResztvevok(model).subscribe(
            data => {
              this.resztvevokLista = data;

              this.regisztracioVege = "";
              let datum: any = moment(new Date()).format('YYYY.MM.DD HH:mm');
              let hatarido: any = moment(this.rendezvenyFormGroup.controls["hatarido"].value).format('YYYY.MM.DD HH:mm');

              if (datum > hatarido) {
                this.regisztracioVege = "hatarido";
              }
              else if (this.resztvevokLista.length >= this.rendezvenyFormGroup.controls["maxFo"].value) {
                this.regisztracioVege = "maxFo";
              }

              if (this.regisztracioVege == "") {
                var vegDatum: any = new Date(this.rendezvenyFormGroup.controls["hatarido"].value);
                var datumKulonbseg: any = (Date.UTC(vegDatum.getFullYear(), vegDatum.getMonth(), vegDatum.getDate(), vegDatum.getHours(), vegDatum.getMinutes()) - Date.UTC(this.aktualisDatum.getFullYear(), this.aktualisDatum.getMonth(), this.aktualisDatum.getDate(), this.aktualisDatum.getHours(), this.aktualisDatum.getMinutes())) / 1000;
                
                this.config = {
                  leftTime: datumKulonbseg,
                  format: 'DD HH:mm:ss',
                  formatDate: ({ date, formatStr }) => {
                    let duration = Number(date || 0);

                    return CountdownTimeUnits.reduce((current, [name, unit]) => {
                      if (current.indexOf(name) !== -1) {
                        const v = Math.floor(duration / unit);
                        duration -= v * unit;
                        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
                          // When days is empty
                          if (name === 'D' && v <= 0) {
                            return '';
                          }
                          return v.toString().padStart(match.length, '0');
                        });
                      }
                      return current;
                    }, formatStr);
                  } };
                this.cd.begin();
              }


            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a rendezvényen résztvevők adatainak lekérdezése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  rendezvenyAdatokMentes() {

    this.recaptchaV3Service.execute('webapps_rendezveny_mentes').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            formGroup: this.rendezvenyFormGroup.value
          }

          this.rendezvenyService.rendezvenyAdatokMentes(model).subscribe(
            data => {
              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Siker',
                message: "Köszönjük a jelentkezést, regisztrációd fogadtuk, sok szeretettel várunk!",
                confirmButtonText: 'Rendben'
              });

              this.rendezvenyFormGroup.controls["cegnev"].setValue(null);
              this.rendezvenyFormGroup.controls["nev1"].setValue(null);
              this.rendezvenyFormGroup.controls["email1"].setValue(null);
              this.rendezvenyFormGroup.controls["nev2"].setValue(null);
              this.rendezvenyFormGroup.controls["email2"].setValue(null);
              this.rendezvenyFormGroup.controls["gepjarmu"].setValue(null);
              this.rendezvenyFormGroup.controls["adatkezelesi"].setValue(false);

              this.rendezvenyFormGroup.controls["cegnev"].setErrors(null);
              this.rendezvenyFormGroup.controls["nev1"].setErrors(null);
              this.rendezvenyFormGroup.controls["email1"].setErrors(null);
              this.rendezvenyFormGroup.controls["nev2"].setErrors(null);
              this.rendezvenyFormGroup.controls["email2"].setErrors(null);
              this.rendezvenyFormGroup.controls["gepjarmu"].setErrors(null);
              this.rendezvenyFormGroup.controls["adatkezelesi"].setErrors(null);

              this.rendezvenyResztvevok();

            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a résztvevők adatainak mentése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  adatkezelesi() {
    this.dialog.open(RendezvenyRegisztracioAdatkezelesiDialog, { data: { adatkezelesi_szoveg: this.rendezvenyFormGroup.controls["adatkezelesi_szoveg"].value }, width: "600px" });
  }

  hataridoLejart(e: CountdownEvent) {
    if (e.action == 'done') {
      this.regisztracioVege = "hatarido";
    }
  }

}

@Component({
  selector: 'rendezveny-regisztracio-adatkezelesi_dialog',
  templateUrl: './rendezveny-regisztracio-adatkezelesi_dialog.html',
  styleUrls: ['./rendezveny-regisztracio.component.scss']
})
export class RendezvenyRegisztracioAdatkezelesiDialog {
  constructor(
    public dialogRef: MatDialogRef<RendezvenyRegisztracioAdatkezelesiDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onCancel() {
    this.dialogRef.close();
  }
}

export class AdatValidator extends Validators {

  static resztvevoEllenor(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];

      if (control1.value != null && control1.value != "" && (control2.value == null || control2.value == "")) {
        control2.setErrors({ 'required': true });
      }

      if (control2.value != null && control2.value != "" && (control1.value == null || control1.value == "")) {
        control1.setErrors({ 'required': true });
      }

      if (control3.value != null && control3.value != "" && (control4.value == null || control4.value == "")) {
        control4.setErrors({ 'required': true });
      }

      if (control4.value != null && control4.value != "" && (control3.value == null || control3.value == "")) {
        control3.setErrors({ 'required': true });
      }

      if ((control1.value == null || control1.value == "") && (control2.value == null || control2.value == "") && (control3.value == null || control3.value == "") && (control4.value == null || control4.value == "")) {
        control1.setErrors({ 'egyKotelezo': true });
      }
    }


    return validator;
  }
}
