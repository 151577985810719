import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mfo-osszesgzes-es-kiegeszito-biztositasok',
  templateUrl: './mfo-osszesgzes-es-kiegeszito-biztositasok.component.html',
  styleUrls: ['./mfo-osszesgzes-es-kiegeszito-biztositasok.component.scss']
})
export class MfoOsszesgzesEsKiegeszitoBiztositasokComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
