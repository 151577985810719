import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { MatSelectChange } from '@angular/material/select';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'karbejelento-karesemeny',
  templateUrl: './karbejelento-karesemeny.component.html',
  styleUrls: ['./karbejelento-karesemeny.component.scss']
})
export class KarbejelentoKaresemenyComponent implements OnInit {

  @Output() karbejelentoKaresemenyFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoKaresemenyForm: FormGroup;

  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];
  orszagLista: any[];
  irszamMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  maxCharLeiras: number = 1500;
  maxCharMegjegyzes: number = 600;

  constructor(
    private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService) { }

  ngOnInit() {

    this.formGroupNew();
    this.karbejelentoKaresemenyFormOut.emit(this.karbejelentoKaresemenyForm);

    this.karbejelentoKaresemenyForm.controls['f_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltes();
    });

    this.karbejelentoKaresemenyForm.controls['f_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltes();
    });

    this.karbejelentoKaresemenyForm.controls['f_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltes();
    });

    this.orszagFeltoltes();

  }

  formGroupNew() {
    this.karbejelentoKaresemenyForm = this.formBuilder.group({
      f_modozat: new FormControl(null),
      f_leiras: new FormControl(null, [Validators.required]),
      f_cim_mo: new FormControl("I"),
      f_helyszin_tipus: new FormControl("P"),
      f_irsz: new FormControl(null),
      f_telepules: new FormControl("0"),
      f_utcanev: new FormControl("0"),
      f_uttipus: new FormControl("0"),
      f_hazszam: new FormControl(null),
      f_emelet: new FormControl(null),
      f_ajto: new FormControl(null),
      f_hrsz: new FormControl(null),
      f_kulterulet: new FormControl(null),
      f_orszag: new FormControl("0"),
      f_orszag_nev: new FormControl(null),
      f_kulf_irsz: new FormControl(null),
      f_kulf_cim: new FormControl(null),
      f_megjegyzes: new FormControl(null),
      f_adatok_megadas: new FormControl(null),
      f_hatosag_nev: new FormControl(null),
      f_hatosag_elerhetoseg: new FormControl(null),
      f_hivatkozasi_szam: new FormControl(null),
      f_uttipus_van: new FormControl(false)
    }, {
      validators: [AdatValidator.fuggosegiKotelezettseg('f_orszag', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_telepules', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_utcanev', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_uttipus', 'f_cim_mo', 'f_helyszin_tipus', 'f_uttipus_van'),
      AdatValidator.fuggosegiKotelezettseg('f_irsz', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_hazszam', 'f_cim_mo', 'f_helyszin_tipus', 'f_hrsz'),
      AdatValidator.fuggosegiKotelezettseg('f_kulf_irsz', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_kulf_cim', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_kulterulet', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_hatosag_elerhetoseg', 'f_adatok_megadas', '', '')]
    });
  }

  telepulesFeltoltes() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoKaresemenyForm.controls["f_telepules"].setValue("0");
    if (this.karbejelentoKaresemenyForm.controls["f_irsz"].value &&
      this.karbejelentoKaresemenyForm.controls["f_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoKaresemenyForm.controls["f_irsz"].value.split("_").join("")
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a települések lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });          
        });
    }
  }

  utcanevFeltoltes() {
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoKaresemenyForm.controls["f_utcanev"].setValue("0");
    if (this.karbejelentoKaresemenyForm.controls["f_telepules"].value && this.karbejelentoKaresemenyForm.controls["f_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoKaresemenyForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoKaresemenyForm.controls["f_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az utcanevek lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  uttipusFeltoltes() {
    this.uttipusLista = [];
    this.karbejelentoKaresemenyForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoKaresemenyForm.controls["f_uttipus"].setValue("0");
    if (this.karbejelentoKaresemenyForm.controls["f_utcanev"].value && this.karbejelentoKaresemenyForm.controls["f_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoKaresemenyForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoKaresemenyForm.controls["f_telepules"].value,
        f_utcanev: this.karbejelentoKaresemenyForm.controls["f_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusLista = data;
            this.karbejelentoKaresemenyForm.controls["f_uttipus_van"].setValue(true);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az úttipusok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  orszagFeltoltes() {
    this.karbejelento.orszagok().subscribe(
      data => {
        this.orszagLista = data;
/*        this.karbejelentoKaresemenyForm.controls["f_orszag"].setValue("0");
        this.karbejelentoKaresemenyForm.controls["f_orszag_nev"].setValue(null);*/
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az országok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });

  }

  telepulesValtozas() {
    this.utcanevLista = [];
    this.uttipusLista = [];
    if (this.karbejelentoKaresemenyForm.controls["f_telepules"].value != "0") {
      this.utcanevFeltoltes();
    }
  }

  utcanevValtozas() {
    this.uttipusLista = [];
    if (this.karbejelentoKaresemenyForm.controls["f_utcanev"].value != "0") {
      this.uttipusFeltoltes();
    }
  }

  orszagValtozas(event: MatSelectChange) {
    this.karbejelentoKaresemenyForm.controls["f_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  pontosCimUrites() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];    

    this.karbejelentoKaresemenyForm.controls["f_irsz"].setValue(null);
    this.karbejelentoKaresemenyForm.controls["f_telepules"].setValue("0");
    this.karbejelentoKaresemenyForm.controls["f_utcanev"].setValue("0");
    this.karbejelentoKaresemenyForm.controls["f_uttipus"].setValue("0");
    this.karbejelentoKaresemenyForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoKaresemenyForm.controls["f_hazszam"].setValue(null);
    this.karbejelentoKaresemenyForm.controls["f_emelet"].setValue(null);
    this.karbejelentoKaresemenyForm.controls["f_ajto"].setValue(null);
  }

  kozteruletCimUrites() {
    this.karbejelentoKaresemenyForm.controls["f_kulterulet"].setValue(null);
  }

  kulfoldiCimUrites() {
    this.orszagLista = [];

    this.karbejelentoKaresemenyForm.controls["f_orszag"].setValue("0");
    this.karbejelentoKaresemenyForm.controls["f_orszag_nev"].setValue(null);
    this.karbejelentoKaresemenyForm.controls["f_kulf_irsz"].setValue(null);
    this.karbejelentoKaresemenyForm.controls["f_kulf_cim"].setValue(null);
  }

  orszagTipusValtozas() {
    if (this.karbejelentoKaresemenyForm.controls["f_cim_mo"].value == "I") {
      this.kulfoldiCimUrites();
      this.karbejelentoKaresemenyForm.controls["f_orszag"].setErrors(null);
      this.karbejelentoKaresemenyForm.controls["f_kulf_irsz"].setErrors(null);
      this.karbejelentoKaresemenyForm.controls["f_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUrites();
      this.kozteruletCimUrites();
      this.karbejelentoKaresemenyForm.controls["f_irsz"].setErrors(null);
      this.karbejelentoKaresemenyForm.controls["f_telepules"].setErrors(null);
      this.karbejelentoKaresemenyForm.controls["f_utcanev"].setErrors(null);
      this.karbejelentoKaresemenyForm.controls["f_uttipus"].setErrors(null);
      this.karbejelentoKaresemenyForm.controls["f_hazszam"].setErrors(null);
    }
  }

  helyszinTipusValtozas() {
    if (this.karbejelentoKaresemenyForm.controls["f_helyszin_tipus"].value == "P") {
      this.kozteruletCimUrites();
    }
    else {
      this.pontosCimUrites();
    }
  }

}

export class AdatValidator extends Validators {

  static keremValasszon(control1_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls["f_cim_mo"];
      if (control2.value == "I") {
        if ((control1_neve == "f_telepules" ||
          control1_neve == "f_utcanev" ||
          control1_neve == "f_uttipus" ||
          control1_neve == "f_orszag") && control1.value == "0") {
          hasError = true;
        }
      }
      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      control1.setErrors(null);

      if (control2_neve == "f_cim_mo" && control2.value != "I" && (control1_neve == "f_kulf_irsz" || control1_neve == "f_kulf_cim") && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && control1_neve == "f_irsz" && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && control1_neve == "f_hazszam" && control4_neve == "f_hrsz" && ((control1.value == null || control1.value == '') && (control4.value == null || control4.value == ''))) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && (control1_neve == "f_telepules" || control1_neve == "f_utcanev" || (control1_neve == "f_uttipus" && control4.value == true)) && (control1.value == null || control1.value == "0")) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value != "I" && control1_neve == "f_orszag" && (control1.value == null || control1.value == "0")) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "K" && control1_neve == "f_kulterulet" && (control1.value == null || control1.value == "")) {
        hasError = true;
      }

      if (control2_neve == "f_adatok_megadas" && control2.value == true && control1_neve == "f_hatosag_elerhetoseg" && (control1.value == null || control1.value == "")) {
        hasError = true;
      }

      if (control2_neve == "f_adatok_megadas" && control2.value == true && control1_neve == "f_hatosag_elerhetoseg" && control1.value) {
        if (control1.value.length < 6 || control1.value.length > 20) {
          control1.setErrors({ hatosagiElerhetosegHossz: true });
        }
        else {
          if (control1.value.match("^[0-9/+-]+$") == null) {
            control1.setErrors({ hatosagiElerhetoseg: true });
          }
        }
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && control1_neve == "f_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
        control1.setErrors({ irszamHossz: true });
      }

      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }
    }
    return validator;
  }
}
