<div class="contain-lg">

  <form [formGroup]="karbejelentoKaresemenyForm" class="form">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">
          <div class="row mt-3">
            <div class="col-lg-4">
              <mat-form-field appearance="fill">
                <mat-label>Káresemény leírása:  *</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="f_leiras"
                          #notificationTextInput="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" [maxlength]="maxCharLeiras"></textarea>
                <mat-icon matSuffix matTooltip="A káresemény bekövetkezési körülményeinek összefoglalása">info</mat-icon>
                <mat-hint align="end">{{karbejelentoKaresemenyForm.get('f_leiras').value?.length || 0}}/{{maxCharLeiras}}</mat-hint>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_leiras').errors?.required">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-label class="control-label">Káresemény helyszíne:</mat-label>
              <mat-icon class="ml-3 info-icon" [matTooltip]="karbejelentoKaresemenyForm.get('f_modozat').value == 'CMR' ? 'Pontos helyszín hiányában a kiszolgáltatási helyet kérjük megadni.' : 'Lehetőség szerint a pontos címet kérjük megadni.'">info</mat-icon>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-select matInput formControlName="f_cim_mo" (selectionChange)="orszagTipusValtozas()">
                  <mat-option value="N">Külföld</mat-option>
                  <mat-option value="I">Magyarország</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-lg-2" *ngIf="karbejelentoKaresemenyForm.get('f_cim_mo').value == 'I'">
              <mat-form-field appearance="fill">
                <mat-select matInput formControlName="f_helyszin_tipus" (selectionChange)="helyszinTipusValtozas()">
                  <mat-option value="P">Pontos cím</mat-option>
                  <mat-option value="K">Külterület cím</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

          <div *ngIf="karbejelentoKaresemenyForm.get('f_cim_mo').value && karbejelentoKaresemenyForm.get('f_cim_mo').value == 'I'">
            <div *ngIf="karbejelentoKaresemenyForm.get('f_helyszin_tipus').value && karbejelentoKaresemenyForm.get('f_helyszin_tipus').value == 'P'">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Irányítószám:  *</mat-label>
                    <input type="text" matInput formControlName="f_irsz" [textMask]="{mask: irszamMask}">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_irsz').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_irsz').errors?.irszamHossz">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Település:  *</mat-label>
                    <mat-select matInput formControlName="f_telepules">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_telepules').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Utcanév:  *</mat-label>
                    <mat-select matInput formControlName="f_utcanev">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_utcanev').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Úttípus:  *</mat-label>
                    <mat-select matInput formControlName="f_uttipus" [disabled]="!karbejelentoKaresemenyForm.get('f_uttipus_van').value">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_uttipus').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Házszám  *</mat-label>
                    <input matInput formControlName="f_hazszam" maxlength="5">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_hazszam').errors?.fuggosegiKotelezettseg">
                      Házszám vagy Helyrajzi szám kötelező!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Emelet:</mat-label>
                    <input matInput formControlName="f_emelet">
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Ajtó:</mat-label>
                    <input matInput formControlName="f_ajto">
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Hrsz:</mat-label>
                    <input matInput formControlName="f_hrsz">
                  </mat-form-field>
                </div>

              </div>

            </div>

            <div *ngIf="karbejelentoKaresemenyForm.get('f_helyszin_tipus').value && karbejelentoKaresemenyForm.get('f_helyszin_tipus').value == 'K'">
              <div class="row mt-3">
                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Külterület helyszíne:  *</mat-label>
                    <input matInput formControlName="f_kulterulet">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_kulterulet').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="karbejelentoKaresemenyForm.get('f_cim_mo').value && karbejelentoKaresemenyForm.get('f_cim_mo').value == 'N'">
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Ország:  *</mat-label>
                  <mat-select matInput formControlName="f_orszag" (selectionChange)="orszagValtozas($event)">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_orszag').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Irányítószám:  *</mat-label>
                  <input matInput formControlName="f_kulf_irsz">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_kulf_irsz').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Cím:  *</mat-label>
                  <input matInput formControlName="f_kulf_cim">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_kulf_cim').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>
            </div>

          </div>

          <div class="row mt-3">
            <div class="col-lg-4">
              <mat-form-field appearance="fill">
                <mat-label>Megjegyzés:</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="f_megjegyzes"
                          #notificationTextInput="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" [maxlength]="maxCharMegjegyzes"></textarea>
                <mat-icon matSuffix *ngIf="karbejelentoKaresemenyForm.get('f_modozat').value == 'CMR'" matTooltip="Amennyiben a káresemény rendelkezik már hivatkozási számmal vagy kárszámmal, azt kérjük, megadni szíveskedjen.">info</mat-icon>
                <mat-hint align="end">{{karbejelentoKaresemenyForm.get('f_megjegyzes').value?.length || 0}}/{{maxCharMegjegyzes}}</mat-hint>
              </mat-form-field>
            </div>
          </div>

          <div class="mt-3" *ngIf="karbejelentoKaresemenyForm.get('f_modozat').value && karbejelentoKaresemenyForm.get('f_modozat').value == 'CMR'">

            <div class="row">

              <div class="col-lg-12 mb-2">
                <mat-label class="control-label font-bold">Egyéb információk, intézkedések</mat-label>
                <mat-icon class="ml-3 info-icon" matTooltip="Amennyiben a fuvarfeladat teljesítése során rendkívüli esemény történt, és rendőrségi vagy hatósági intézkedésre volt szükség, kérjük megadni az eljáró hatóság adatait, elérhetőségét valamint hivatkozási számát. Kérjük továbbá, hogy a hatósági intézkedés kapcsán rendelkezésre bocsátott dokumentumokat a fájl feltöltése menüpontban becsatolni szíveskedjen.">info</mat-icon>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-checkbox matCheckbox formControlName="f_adatok_megadas" class="mat-checkbox">Adatok kitöltése</mat-checkbox>
              </div>
            </div>
            <div class="row mt-3" *ngIf="karbejelentoKaresemenyForm.get('f_adatok_megadas').value">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Eljáró hatóság neve:</mat-label>
                  <input type="text" matInput formControlName="f_hatosag_nev">
                </mat-form-field>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Eljáró hatóság elérhetősége:</mat-label>
                  <input type="text" matInput formControlName="f_hatosag_elerhetoseg">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_hatosag_elerhetoseg').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_hatosag_elerhetoseg').errors?.hatosagiElerhetosegHossz">
                    Hibás adat!(Minimum 6 és maximum 20 karakterből állhat)
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKaresemenyForm.get('f_hatosag_elerhetoseg').errors?.hatosagiElerhetoseg">
                    Nem megfelelő telefonszám!(Megengedett karakterek: 0-9, /, +, -)
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Hivatkozási szám:</mat-label>
                  <input type="text" matInput formControlName="f_hivatkozasi_szam">
                </mat-form-field>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>

  </form>

</div>
