<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="formGroup" class="form">
          <div>
            <div class="row mt-5 mb-3">
              <div class="info">
                <span>
                  Az alábbi, egyszerűsített űrlapon, a megadott adatok alapján lehetősége van szerződéseivel
                  kapcsolatosan különböző bizonylatok, dokumentumok igénylésére. Amennyiben szerződése beazonosításra
                  került, lehetősége nyílik kiválasztani az Önnek szükséges bizonylatot, dokumentumot.
                </span>
              </div>
            </div>
            <div class="row mt-2 mb-3">
              <div class="info">
                <span>
                  Az „Igény küldése” gomb megnyomása után a kiválasztott bizonylat automatikusan elkészül és a
                  következő munkanapon kerül postázásra a biztosítási kötvényen szereplő címre. Ha Ön zöld
                  kedvezményt vett igénybe, akkor az igényelt dokumentum elektronikus úton kerül postázásra,
                  az Ön által megadott kommunikációs e-mail címre.
                </span>
              </div>
            </div>
            <div class="row mt-2 mb-3">
              <div class="info">
                <span>
                  A honlapon igényelhető dokumentumok mennyisége szerződésenként és típusonként korlátozott,
                  az igényelhetőség korlátait a beazonosítás után, dokumentum típusonként jelezzük.
                </span>
              </div>
            </div>
            <div class="row mt-2 mb-3">
              <div class="info">
                <span>
                  Tájékoztatjuk, hogy a dokumentum az <strong>igénylést követő munkanapon</strong> kerül postázásra.
                </span>
              </div>
            </div>
            <div class="row mt-2 mb-3">
              <div class="info">
                <span>
                  Amennyiben az Önnek szükséges dokumentum igénylésével problémája akadna, kérjük hívja telefonos
                  ügyfélszolgálatunkat a nyitva tartásnak megfelelő időpontban, vagy jelezze társaságunk felé e-mailben.
                </span>
              </div>
            </div>
            <div class="row mt-2 mb-3">
              <div class="info">
                <a href="https://www.granitbiztosito.hu/kapcsolat" target="_blank">Elérhetőségeink</a>
              </div>
            </div>
          </div>
          <ng-container *ngIf="toltoKereses">
            <div class="row">
              <div class="col-lg-12 bg-white">
                <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                </ngx-skeleton-loader>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!toltoKereses">
            <div [class.disableDiv]="igenyelhetoDokumentumok">
              <div class="row mb-3 mt-5">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Szerődés azonosító:  *</mat-label>
                    <input matInput type="text" pattern="[1-9][0-9]{7}" formControlName="f_szerz_azon" maxlength="50">
                    <mat-error *ngIf="formGroup.get('f_szerz_azon').errors?.required">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="formGroup.get('f_szerz_azon').errors?.pattern">
                      A szerződés azonosítónak 8 számból kell állnia
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb-3 mt-3">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Szerződő neme:  *</mat-label>
                    <mat-select formControlName="f_neme" (selectionChange)="nemeValtozas()">
                      <mat-option value="0">Kérem válasszon!</mat-option>
                      <mat-option value="N">Nő</mat-option>
                      <mat-option value="F">Férfi</mat-option>
                      <mat-option value="J">Jogi</mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.get('f_neme').errors?.defaultEllenorzo">
                      Hiányzó adat!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb-3 mt-3" *ngIf="formGroup.controls['f_neme'].value == 'N' || formGroup.controls['f_neme'].value == 'F'">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési idő:  *</mat-label>

                    <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_szulido">
                    <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                    <mat-datepicker #szulDatumPicker></mat-datepicker>
                    <mat-error *ngIf="formGroup.get('f_szulido').errors?.feltetelesenKotelezo">
                      Hiányzó adat!
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row mb-3 mt-3" *ngIf="formGroup.controls['f_neme'].value == 'J'">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Adószám:  *</mat-label>
                    <input matInput type="text" pattern="[1-9][0-9]{10}" formControlName="f_adoszam" maxlength="50">
                    <mat-error *ngIf="formGroup.get('f_adoszam').errors?.feltetelesenKotelezo">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="formGroup.get('f_adoszam').errors?.pattern">
                      Az adószámnak 11 számból kell állnia
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div>
              <div class="row mt-4">
                <div class="col-lg-4 pl-5" *ngIf="!igenyelhetoDokumentumok">
                  <button type="button" mat-raised-button color="accent" [disabled]="!formGroup.valid" (click)="kereses()">Keresés</button>
                </div>
                <div class="col-lg-4 pl-5" *ngIf="igenyelhetoDokumentumok">
                  <button type="button" mat-raised-button color="accent" (click)="torles()">Mezők törlése</button>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
        <form [formGroup]="formGroupEredmeny" class="form info" *ngIf="igenyelhetoDokumentumok">

          <ng-container *ngIf="toltoIgenyles">
            <div class="row">
              <div class="col-lg-12 bg-white">
                <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                </ngx-skeleton-loader>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!toltoIgenyles">
            <div class="row mt-5 mb-3">
              <div class="col-lg-12 ml-1 pl-5">
                <div class="form-group">
                  <label class="control-label">Igényelhető dokumentumok</label>
                </div>
              </div>
              <div class="col-lg-12 ml-1 pl-5">
                <div class="form-group">
                  <label class="control-label">Tisztelt Ügyfelünk!</label>
                </div>
              </div>
              <div class="col-lg-12 ml-1 pl-5">
                <div>
                  <label class="control-label">Az Ön szerződésének díjrendezettségi dátuma: {{formGroupEredmeny.controls["dijrendezett"].value | dateFormat:'YYYY.MM.DD'}}</label>
                </div>
              </div>

              <!-- Zöldkártya chechbox -->
              <div class="col-lg-12 ml-1 pl-5">
                <button mat-icon-button [options]="myOptions"
                        tooltip="<h5>Zöldkártya tájékoztató</h5><p>Csak az igénylés napja + min. 10 napos díjrendezettség esetén igényelhető, a KGFB szerződés érvényességének külföldön történő igazolására.</p><p><strong>Kötelező kiváltani: </strong> Albániába, Belorussziába, Iránba, Izraelbe, Marokkóba, Moldáviába, Törökországba, Tunéziába, Ukrajnába történő utazáskor.</p><p><strong>Ajánlott kiváltani: </strong> Bosznia-Hercegovinába, Bulgáriába, Görögországba, Macedóniába, Máltára, Nagy-Britannia és Észak-Írországba, Olaszországba, Romániába, Szerbia és Montenegróba történő utazáskor.</p>">
                  <mat-icon class="color-primary">info</mat-icon>
                </button>
                <mat-checkbox [disabled]="!formGroupEredmeny.controls['zoldkartya'].value" formControlName="igenyeltZoldkartya" (change)="igenyeltDoksiValt()" class="control-label">Zöldkártya (7 naponta 1db)</mat-checkbox>
              </div>
              <!-- Díjigazolás chechbox -->
              <div class="col-lg-12 ml-1 pl-5">
                <button mat-icon-button [options]="myOptions"
                        tooltip="<h5>Díjigazolás tájékoztató</h5><p>E dokumentum segítségével igazolható harmadik fél számára a biztosító kockázatban állása, a szerződés díjrendezettsége. Csak abban az esetben igényelhető, ha a szerződés díjrendezettsége nagyobb a mai napnál.</p>">
                  <mat-icon class="color-primary">info</mat-icon>
                </button>
                <mat-checkbox [disabled]="!formGroupEredmeny.controls['dijigazolas'].value" formControlName="igenyeltDijigazolas" (change)="igenyeltDoksiValt()" class="control-label">Díjigazolás (30 naponta 1db)</mat-checkbox>
              </div>
              <!-- Kötvény chechbox -->
              <div class="col-lg-12 ml-1 pl-5">
                <button mat-icon-button [options]="myOptions"
                        tooltip="<h5>Kötvény tájékoztató</h5><p>A biztosítási szerződés létrejöttét igazoló okmány.</p>">
                  <mat-icon class="color-primary">info</mat-icon>
                </button>
                <mat-checkbox [disabled]="!formGroupEredmeny.controls['kotveny'].value" formControlName="igenyeltKotveny" (change)="igenyeltDoksiValt()" class="control-label">Kötvény (30 naponta 1db)</mat-checkbox>
              </div>
              <!-- Díjbekérő chechbox -->
              <div class="col-lg-12 ml-1 pl-5">
                <button mat-icon-button [options]="myOptions"
                        tooltip="<h5>Díjbekérő tájékoztató</h5><p>A biztosítási szerződés fizetési ütemének megfelelő díjrészlet összegét, a díj időszakát és a befizetés esedékességét tartalmazó dokumentum.</p>">
                  <mat-icon class="color-primary">info</mat-icon>
                </button>
                <mat-checkbox [disabled]="!formGroupEredmeny.controls['dijbekero'].value" formControlName="igenyeltDijbekero" (change)="igenyeltDoksiValt()" class="control-label">Csekk, Díjbekérő (30 naponta 1db)</mat-checkbox>
              </div>
              <!-- Kártörténeti chechbox -->
              <div class="col-lg-12 ml-1 mb-3 pl-5">
                <button mat-icon-button [options]="myOptions"
                        tooltip="<h5>Kártörténeti tájékoztató</h5><p>A gépjármű kárelőzményeit ill. kármentességi (bónusz) besorolását és a korábbi szerződés időszakát tartalmazó dokumentum.</p><p>Csak törölt KGFB szerződésre kérhető!</p><p>Papíron történő benyújtása az újonnan kötött/kötendő szerződéshez már nem szükséges, mert az előzmény adatok szerződéskötéskori megadásával a Biztosítók Központi Kárnyilvántartó Rendszerből (KKNYR) ellenőrzik a megadott előzmény- ill. káradatok valódiságát.</p>">
                  <mat-icon class="color-primary align-items-center justify-content-end">info</mat-icon>
                </button>
                <mat-checkbox [disabled]="!formGroupEredmeny.controls['kartori'].value" formControlName="igenyeltKartori" (change)="igenyeltDoksiValt()" class="control-label">Kártörténeti igazolás (7 naponta 1db)</mat-checkbox>
              </div>
              <div class="col-lg-12 ml-1 mb-3 pl-5">
                <button mat-raised-button color="accent" (click)="onDokumentumIgenyles()" [disabled]="!formGroupEredmeny.controls['igenyeltDokumentum'].value">
                  Igény küldése
                </button>
              </div>
              <div *ngIf="eredmenyLista && eredmenyLista.length > 0">
                <div class="col-lg-12 pl-5">
                  <mat-card>
                    <mat-card-content>
                      <mat-list>
                        <label class="control-label mb-3">Eredmény: </label>
                        <mat-list-item class="control-label mb-3" *ngFor="let eredmeny of eredmenyLista">
                          {{eredmeny}}
                        </mat-list-item>
                      </mat-list>

                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</div>
