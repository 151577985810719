import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KarbejelentoService {
  private url: string = "/api/karbejelento";

  constructor(
    private httpClient: HttpClient) { }


  /* Kártipusok lekérdezése */
  kartipusok(model: any) {
    return this.httpClient.post<any[]>(this.url + "/Kartipusok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Trafik kockázatviselésihelyek lekérdezése */
  trafikKockhely(model: any) {
    return this.httpClient.post<any[]>(this.url + "/TrafikKockhely", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Trafik biztosítási tipus lekérdezése */
  trafikBiztositasTipus(model: any) {
    return this.httpClient.post<any[]>(this.url + "/TrafikBiztositasTipus", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  /* Gépjárműjelleg lekérdezése */
  jellegek() {
    return this.httpClient.post<any[]>(this.url + "/Jellegek", {}).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* CMR vonató rendszámok */
  cmrVontatoRendszamok(model: any) {

    /*const httpParams = new HttpParams()
      .append('szerzazon', szerzazon)
      .append('kardatum', kardatum);*/

    return this.httpClient.post<any[]>(this.url + "/CmrVontatoRendszamok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károsult típusok */
  karosultTipusok(model: any) {

    return this.httpClient.post<any[]>(this.url + "/KarosultTipusok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Kárbejelentés duplikásának ellenőrzése */
  karbejelentoDupla(model: any) {

    return this.httpClient.post<any[]>(this.url + "/KarbejelentoDupla", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /*
  Rendszámhoz szerződésazonosító a káresemény időpontjában
  Szerződésazonosító - módozat összerendelés ellenőrzése
  */
  ellenor(model: any) {    
    return this.httpClient.post<any>(this.url + "/Ellenor", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  /* Országok listája */
  orszagok() {

    return this.httpClient.post<any[]>(this.url + "/Orszagok", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Összes település */
  osszTelepules() {

    return this.httpClient.post<any[]>(this.url + "/OsszTelepules", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Irányítószámhoz tartozó települések */
  telepulesek(model: any) {

    return this.httpClient.post<any[]>(this.url + "/Telepulesek", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Irányítósz + településhez tartozó utcanevek */
  utcanevek(model: any) {

    return this.httpClient.post<any[]>(this.url + "/Utcanevek", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Irányítósz + település + utcanévhez tartozó úttipusok */
  uttipusok(model: any) {

    return this.httpClient.post<any[]>(this.url + "/Uttipusok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Mentett adatok feltöltése */
  adatFeltoltes(model: any) {

    return this.httpClient.post<any[]>(this.url + "/AdatFeltoltes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Számlaszám ellenőr */
  szlaEllenor(model: any) {

    return this.httpClient.post<any[]>(this.url + "/SzlaEllenor", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Bejelentő adatok */
  bejelento(model: any) {
    return this.httpClient.post<any[]>(this.url + "/Bejelento", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károsult adatok */
  karosult(model: any) {    

    return this.httpClient.post<any[]>(this.url + "/Karosult", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Káresemény adatok */
  karesemeny(model: any) {
    return this.httpClient.post<any[]>(this.url + "/Karesemeny", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Otthon szerződéshez rögzített biztosítottak */
  otthonBiztositottak(model: any) {    
    return this.httpClient.post<any[]>(this.url + "/OtthonBiztositottak", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Otthon szerződéshez tartozó biztosítás típus */
  otthonBiztositasTipus(model: any) {
    return this.httpClient.post<any[]>(this.url + "/OtthonBiztositasTipus", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Üvegtípusok */
  uvegTipusok() {

    return this.httpClient.post<any[]>(this.url + "/UvegTipusok", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Biztosítók */
  biztositok() {

    return this.httpClient.post<any[]>(this.url + "/Biztositok", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károslut személy - Otthon törlése */
  karosultOtthonTorles(model: any) {
    return this.httpClient.post<any[]>(this.url + "/KarosultOtthonTorles", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Feltöltött fájlok listája */
  feltoltottFajlokListaja(model: any) {

    return this.httpClient.post<any[]>(this.url + "/FeltoltottFajlokListaja", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Feltöltött fájl törlése */
  feltoltottFajlTorlese(model: any) {
    return this.httpClient.post(this.url + "/FeltoltottFajlTorlese", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Fájl mentése */
  fajlMentese(model: any): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      return this.httpClient.post(this.url + "/FajlMentese", model, { responseType: 'text'}).toPromise().then(
        res => {
          resolve(res);
        },
        msg => {
          reject(msg);
        });
    });
  }

  //Mentések

  /* Alapadatok mentése */
  alapadatokMentes(model: any) {    
    return this.httpClient.post(this.url + "/AlapadatokMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Káresemény mentése */
  karesemenyMentes(model: any) {
    return this.httpClient.post(this.url + "/KaresemenyMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Bejelentő mentése */
  bejelentoMentes(model: any) {
    return this.httpClient.post(this.url + "/BejelentoMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Szemle mentése */
  szemleMentes(model: any) {
    return this.httpClient.post(this.url + "/SzemleMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károsult gépjárműhöz tartozó személyek mentése */
  gepjarmuSzemelyMentes(model: any) {
    return this.httpClient.post(this.url + "/GepjarmuSzemelyMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károsult személy mentése */
  karosultMentes(model: any) {
    return this.httpClient.post(this.url + "/KarosultMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károsult személy - Otthon mentése */
  karosultOtthonMentes(model: any) {

    return this.httpClient.post(this.url + "/KarosultOtthonMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Károsult vagyontárgy mentése */
  karosultVagyontargyMentes(model: any) {
    return this.httpClient.post(this.url + "/KarosultVagyontargyMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Okozó adat illete más biztosító adat mentése */
  okozoMasBiztositoMentes(model: any) {
    return this.httpClient.post(this.url + "/OkozoMasBiztositoMentes", model).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Adatok beküldése */
  adatokBekuldese(model: any) {
    return this.httpClient.post<any>(this.url + "/AdatokBekuldese", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* PDFek */
  pdfek(model: any) {
    return this.httpClient.post(this.url + "/PDFek", model, { responseType: 'blob', observe: 'response' }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Szerződő adatainak lekérdezése */
  szerzodo(model: any) {
    return this.httpClient.post<any>(this.url + "/Szerzodo", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }  

}
