<form [formGroup]="epuletAdataiFormGroup" class="form-group">
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Főépület alapterülete</mat-label>
        <input matInput formControlName="f_foepulet_alapterulete" (change)="change()">
        <mat-error *ngIf="epuletAdataiFormGroup.get('f_foepulet_alapterulete').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a főépület alapterületét!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngFor="let i of biztositandoMellekepuletekSzamaArray" class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{i}}. Melléképület alapterülete</mat-label>
        <input matInput formControlName="{{'f_mellekepulet_alapterulete_' + i}}">
        <mat-error *ngIf="epuletAdataiFormGroup.get('f_mellekepulet_alapterulete_' + i).errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg az {{i}}. melléképület alapterületét!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Főépület falazata</mat-label>
        <mat-select formControlName="f_foepulet_falazata" [compareWith]="objectComparisonFunction">
          <mat-option *ngFor="let item of falazatTipusokLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="epuletAdataiFormGroup.get('f_foepulet_falazata').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a főépület falazatát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngFor="let i of biztositandoMellekepuletekSzamaArray" class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{i}}. Melléképület falazata</mat-label>
        <mat-select formControlName="{{'f_mellekepulet_falazata_' + i}}">
          <mat-option *ngFor="let item of falazatTipusokLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="epuletAdataiFormGroup.get('f_mellekepulet_falazata_' + i).errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg az {{i}}. melléképület falazatát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Főépület tetőzete</mat-label>
        <mat-select formControlName="f_foepulet_tetozete">
          <mat-option *ngFor="let item of tetozetTipusokLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="epuletAdataiFormGroup.get('f_foepulet_tetozete').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a főépület tetőzetét!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngFor="let i of biztositandoMellekepuletekSzamaArray" class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>{{i}}. Melléképület tetőzete</mat-label>
        <mat-select formControlName="{{'f_mellekepulet_tetozete_' + i}}">
          <mat-option *ngFor="let item of tetozetTipusokLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="epuletAdataiFormGroup.get('f_mellekepulet_tetozete_' + i).errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg az {{i}}. melléképület tetőzetét!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
