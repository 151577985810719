import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KalkulatorService {

  constructor(
    private httpClient: HttpClient) { }

  private url: string = "/api/kalkulator";

  // MFO biztosítás típus kódok lekérése
  mfoKodtablaBiztositasTipusokLekerese() {
    return this.httpClient.get<any[]>(this.url + "/MfoKodtablaBiztositasTipusokLekerese").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO épület típus kódok lekérése
  mfoKodtablaEpuletTipusokLekerese() {
    return this.httpClient.get<any[]>(this.url + "/MfoKodtablaEpuletTipusokLekerese").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO falazat típus kódok lekérése
  mfoKodtablaFalazatTipusokLekerese() {
    return this.httpClient.get<any[]>(this.url + "/MfoKodtablaFalazatTipusokLekerese").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO falazat típus kódok lekérése
  mfoKodtablaIngatlanJellegekLekerese() {
    return this.httpClient.get<any[]>(this.url + "/MfoKodtablaIngatlanJellegekLekerese").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO tetőzet típus kódok lekérése
  mfoKodtablaTetozetTipusokLekerese() {
    return this.httpClient.get<any[]>(this.url + "/MfoKodtablaTetozetTipusokLekerese").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO tulajdonviszony kódok lekérése
  mfoKodtablaTulajdonviszonyokLekerese() {
    return this.httpClient.get<any[]>(this.url + "/MfoKodtablaTulajdonviszonyokLekerese").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO alap adatok mentése
  mfoAlapAdatokMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/MfoAlapAdatokMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO épület adatai mentése
  mfoEpuletAdataiMentes(model: any) {
    return this.httpClient.post<any>(this.url + "/MfoEpuletAdataiMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO alap adatok lekérése azonosító alapján
  mfoAlapAdatokLekerese(azonosito: number) {
    const httpParams = new HttpParams()
      .append('azonosito', azonosito.toString());
    return this.httpClient.get<any>(this.url + "/MfoAlapAdatokLekerese", { params: httpParams }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // MFO épület adatinak lekérése azonosító alapján
  mfoEpuletAdataiLekerese(azonosito: number) {
    const httpParams = new HttpParams()
      .append('azonosito', azonosito.toString());
    return this.httpClient.get<any>(this.url + "/MfoEpuletAdataiLekerese", { params: httpParams }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
