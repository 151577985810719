import { Component, OnInit, HostListener } from '@angular/core';
import { UtilityService } from '@granit/common-library';
import { WebAppsService } from '../../services/webApps.service';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  logoTextVisible: boolean = true;
  teszt: boolean = false;
  message: string = "App";

  constructor(
    private webApps: WebAppsService,
   
    private utilityService: UtilityService,) {

    this.kezdoBellitasok();
    

  }

  ngOnInit() {    

  }

  kezdoBellitasok() {
    this.utilityService.environmentName().subscribe(
      data => {
        this.teszt = data != "Production";

        if (window.innerWidth < 768) {
          this.logoTextVisible = false;
        }

        this.webApps.focimke.subscribe(message => this.message = (this.teszt == true ? "Teszt " : "") + message);

      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: errorResult,
          confirmButtonText: 'Rendben'
        });
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.logoTextVisible = false;
    }
    if (event.target.innerWidth > 768) {
      this.logoTextVisible = true;
    }
  }
}
