<mat-toolbar [class.fejlec-teszt]="teszt" [class.fejlec]="!teszt">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-xxl-8 col-xl-10 col-lg-12">
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-6 background-color-primary">
            <a routerLink="/">
              <span>
                <img class="logo" src="assets/images/logo.png" />
              </span>
            </a>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-6" [class.fejlec-jobb-teszt]="teszt" [class.fejlec-jobb]="!teszt">
            <div class="h-100 d-flex align-items-center justify-content-end">
              <div *ngIf="teszt">
                <span [class.szoveg_teszt]="teszt" [class.szoveg]="!teszt">{{message}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    

</mat-toolbar>
