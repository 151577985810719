import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from '@granit/common-library';
import 'moment/locale/hu'
import emailMask from 'text-mask-addons/dist/emailMask';
import { ActivatedRoute, Params } from '@angular/router';
import { WebAppsService } from '../../services/webApps.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { IdopontUsziService } from '../../services/idopontUszi.service';

@Component({
  selector: 'idopont-uszi',
  templateUrl: './idopont-uszi.component.html',
  styleUrls: ['./idopont-uszi.component.scss']
})
export class IdopontUsziComponent implements OnInit {

  datumLista: any[];
  idopontLista: any[];
  eloHivoLista: any[];

  phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholder = "123-1234";
  emailMask = emailMask;
  foglalasUId: string = null;
  felhasznaloId: string = null;
  url: string = "";
  weboldalDokumentumok: string = "";

  idopontFoglalasFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private idopontUszi: IdopontUsziService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private webApps: WebAppsService,
    private recaptchaV3Service: ReCaptchaV3Service) {

    this.url = window.location.href;

    this.route.queryParams.subscribe((params: Params) => {
      const _params = this.toLower(params);
      if (_params['h'] && _params['uid'] && _params['mac']) {
        this.urlEllenorzes(this.url, _params['h'], _params['uid']);
      }
      else {
        this.foglalasUId = null;
        this.felhasznaloId = null;
      }
    });

  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }

  urlEllenorzes(url: string, honnan: string, azonosito: string) {
    this.utilityService.isValidUrl(url).subscribe(
      adat => {
        if (adat) {
          if (honnan == "1") {
            this.felhasznaloId = azonosito;

            let model = {
              felhasznaloId: this.felhasznaloId,
              alkalmazasNeve: "ÜgyfélPortál"
            }

            this.idopontUszi.felhasznaloEmail(model).subscribe(
              data => {
                this.idopontFoglalasFormGroup.controls["email"].setValue(data["f_email"]);
              },
              errorResult => {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba az email cím lekérdezése közben!",
                  confirmButtonText: 'Rendben'
                });
              });
          }
          if (honnan == "2") {
            this.foglalasUId = azonosito;
            this.idopontFoglalasFormGroupFeltolt();
          }
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás link!",
            confirmButtonText: 'Rendben'
          });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: errorResult,
          confirmButtonText: 'Rendben'
        });
      });
  }

  ngOnInit(): void {

    //this.utilityService.dialogMegnyitasa({
    //  info: true,
    //  title: 'Információ',
    //  message: '<div class="hirDiv"><p><b>Tisztelt Ügyfelünk!</b></p><p>Tájékoztatjuk, hogy a Gránit Biztosító Zrt.személyes ügyfélszolgálata</p><p>2024. június 7 - én(pénteken) ZÁRVA tart.</p><p>Kérjük, hogy megkeresését ezen a napon, az alábbi elérhetőségeken szíveskedjen megtenni: <br /><a href = "mailto:ugyfelszolgalat@granitbiztosito.hu" target = "_blank">ugyfelszolgalat@granitbiztosito.hu</a>;<a href="mailto:kar@granitbiztosito.hu" target="_blank">kar@granitbiztosito.hu</a><br />telefonszám: +3616666200, +3619999999</p><p>Megértését köszönjük!</p><p>Üdvözlettel,</p><p>Gránit Biztosító Zrt.</p></div>',
    //  confirmButtonText: 'Rendben'
    //},
    //  '650px'
    //);


    /*Megnevezés kiírása fejlécbe*/
    this.webApps.changeMessage(this.foglalasUId ? "Személyes ügyfélszolgálatra foglalt időpont lemondása" : "Időpontfoglalás személyes ügyfélszolgálatra");

    /*Form alaphelyzetbe állítása*/
    this.formClear();
  }

  /*Form alaphelyzetbe állítása*/
  formClear() {

    /*Formgroup inicializálása*/
    this.idopontFoglalasFormGroupInit();

    /*Választható dátumok lekérdezése/feltöltése*/
    this.datumListaFeltoltes();

    /*Telefonszám előhívók lekérdezése, feltöltése*/
    this.telefonElohivoFeltoltes();

    this.link();
  }

  async link() {

    let model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

  /*Formgroup inicializálása*/
  idopontFoglalasFormGroupInit() {
    this.idopontFoglalasFormGroup = this.formBuilder.group({
      valasztottDatum: new FormControl("0"),
      valasztottIdopont: new FormControl("0"),
      nev: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      telefonTipus: new FormControl("M"),
      telefonElohivo: new FormControl("0"),
      telefonSzam: new FormControl(null),
      telefonSzamEgyben: new FormControl(null),
      megjegyzes: new FormControl(null)
    }, {
      validators: [AdatValidator.telefonszam('telefonSzam', 'telefonTipus', 'telefonElohivo')]
    });
  }

  /*Választható dátumok lekérdezése/feltöltése*/
  datumListaFeltoltes() {
    this.datumLista = [];
    this.idopontUszi.datumLista().subscribe(
      data => {
        this.datumLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a választható dátumok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  /*Dátum választás esemény - a dátumhoz tartozó szabad időpontok feltöltése*/
  onDatumValasztas() {
    if (this.idopontFoglalasFormGroup.controls["valasztottDatum"].value != "0") {

      let model = {
        datum: this.idopontFoglalasFormGroup.controls["valasztottDatum"].value.toString()
      };

      this.idopontUszi.idopontLista(model).subscribe(
        data => {
          this.idopontLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a választott dátumhoz tartozó szabd időpontok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
    else {
      this.idopontFoglalasFormGroupInit();
      this.idopontLista = [];
    }
  }

  /*A megadott adatokkal időpont foglalás mentése*/
  onidopontFoglalas() {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_befizetes_kereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          this.idopontUszi.idopontFoglalas(this.idopontFoglalasFormGroup.value).subscribe(
            data => {
              this.formClear();
              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Siker',
                message: "Az időpontfoglalását rendszerünk sikeresen rögzítette.",
                confirmButtonText: 'Rendben'
              });
            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az időpont foglalás mentése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        })
      });
  }

  /*Telefonszám mask beállítás*/
  telefonBeallitas() {
    this.idopontFoglalasFormGroup.controls["telefonSzam"].setValue(null);
    if (this.idopontFoglalasFormGroup.controls["telefonTipus"].value == "M") {
      this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholder = "123-1234";
    }
    if (this.idopontFoglalasFormGroup.controls["telefonTipus"].value == "V") {
      if (this.idopontFoglalasFormGroup.controls["telefonElohivo"].value == "01") {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-1234";
      }
      else {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-123";
      }
    }
  }

  /*Telefonszám előhívók lekérdezése, feltöltése*/
  telefonElohivoFeltoltes() {
    this.telefonBeallitas();
    this.eloHivoLista = [];
    this.idopontFoglalasFormGroup.controls["telefonElohivo"].setValue("0");
    this.utilityService.korzetszamok(this.idopontFoglalasFormGroup.controls["telefonTipus"].value).subscribe(
      data => {
        this.eloHivoLista = data;
      })

  }

  /*Telefonszám előhívó változás esemény*/
  eloHivoValtozas() {
    /*Telefonszám mask beállítás*/
    this.telefonBeallitas();
  }

  /*Lemondás esetében form feltöltése a mentett adatokkal*/
  idopontFoglalasFormGroupFeltolt() {

    let model = { uid: this.foglalasUId.toString() }

    this.idopontUszi.idopontFoglalasAdatok(model).subscribe(
      data => {
        this.idopontFoglalasFormGroup.controls["valasztottDatum"].setValue(data["f_datum"]);
        this.idopontFoglalasFormGroup.controls["valasztottIdopont"].setValue(data["f_idopont"]);
        this.idopontFoglalasFormGroup.controls["nev"].setValue(data["f_nev"]);
        this.idopontFoglalasFormGroup.controls["email"].setValue(data["f_email"]);

        let telefonSzam: string = "(+36)" + data["f_telefon"].substring(0, 2) + "/" + data["f_telefon"].substring(2, 9);
        let insertPositinon: number;

        this.idopontFoglalasFormGroup.controls["telefonTipus"].setValue("V");

        if (data["f_telefon"].length > 0) {
          if (data["f_telefon"].substring(0, 2) == "20" ||
            data["f_telefon"].substring(0, 2) == "30" ||
            data["f_telefon"].substring(0, 2) == "50" ||
            data["f_telefon"].substring(0, 2) == "70" ||
            data["f_telefon"].substring(0, 2) == "01") {

            insertPositinon = telefonSzam.length - 4;

            if (data["f_telefon"].substring(0, 2) != "01") {
              this.idopontFoglalasFormGroup.controls["telefonTipus"].setValue("M");
            }
          }
          else {
            insertPositinon = telefonSzam.length - 3;
          }
          //this.telefonSzam = "(+36)" + data["f_telefon"].substring(0, 2) + "/" + data["f_telefon"].substring(2, 9);
          telefonSzam = [telefonSzam.slice(0, insertPositinon), "-", telefonSzam.slice(insertPositinon)].join("");

          this.idopontFoglalasFormGroup.controls["telefonElohivo"].setValue(data["f_telefon"].substring(0, 2));
          this.idopontFoglalasFormGroup.controls["telefonSzam"].setValue([data["f_telefon"].substring(2, 9).slice(0, insertPositinon), "-", data["f_telefon"].substring(2, 9).slice(insertPositinon)].join(""));

          this.idopontFoglalasFormGroup.controls["telefonSzamEgyben"].setValue(telefonSzam);
        }

        this.idopontFoglalasFormGroup.controls["megjegyzes"].setValue(data["f_megjegyzes"]);
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba történt a mentett adatok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  /*Időpont lemondás mentése*/
  onidopontLemondas() {
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_befizetes_kereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = { uid: this.foglalasUId.toString() }

          this.idopontUszi.idopontFoglalasTorles(model).subscribe(
            () => {
              this.utilityService.dialogMegnyitasa({
                success: true,
                title: 'Siker',
                message: "Az időpontfoglalás törlése sikeresen megtörtént.",
                confirmButtonText: 'Rendben'
              });
              this.formClear();
            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt az időpontfogalálás törlése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        })
      });
  }


}

export class AdatValidator extends Validators {

  /*Telefonszám validátor*/
  static telefonszam(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      control1.setErrors(null);


      if (control1_neve == "telefonSzam") {
        if (control1.value == null || control1.value == "") {
          if (control3.value != "0") {
            /*Ha a telefonszám üres, de az előhívó nem '0'*/
            control1.setErrors({ telefonszamUres: true });
          }
        }
        else {

          if (control1.value.substring(0, 1) == "0") {
            /*Ha a telefonszám első karaktere '0'*/
            control1.setErrors({ telefonszamElso0: true });
          }
          else {
            if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
              if (control1.value.split("_").join("").split("-").join("").length != 7) {
                /*Ha a telefonszám tipusa mobil vagy budapesti és nem 7 hosszú*/
                control1.setErrors({ telefonszam: true });
              }
            }

            if (control2.value == "V" && control3.value != "01") {
              if (control1.value.split("_").join("").split("-").join("").length != 6) {
                /*Ha a telefonszám tipusa veetékes és nem budapesti és nem 6 hosszú*/
                control1.setErrors({ telefonszam: true });
              }
            }
          }

        }
      }
    }
    return validator;
  }

}
