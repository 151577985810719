<div class="contain-lg">

  <form [formGroup]="karbejelentoAlapadatokForm" class="form">

    <ng-container *ngIf="toltoKereses">
      <div class="row">
        <div class="col-lg-12 bg-white">
          <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!toltoKereses">

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="mt-4">

            <div class="row mt-5">
              <div class="col-lg-4 mb-4">
                <mat-label class="control-label">Adatkezelési nyilatkozat</mat-label>
                <div class="mt-2">
                  <mat-checkbox matCheckbox class="mr-2" formControlName="f_adatkezelesi"></mat-checkbox>
                  <span>* Az <a href="{{weboldalDokumentumok}}adatvedelmi_nyilatkozat_es_adatkezelesi_tajekoztato.pdf" target="_blank">adatkezelési tájékoztató</a> tartalmát megismertem, az abban foglaltakat tudomásul vettem.</span>
                  <div class="col-lg-12 pt-2">
                    <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_adatkezelesi').errors?.required">
                      Az online kárbejelelentés funkció használatának feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>

            <div [class.disableDiv]="!karbejelentoAlapadatokForm.get('f_adatkezelesi').value">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Módozat:  *</mat-label>
                    <mat-select matInput formControlName="f_modozat" (selectionChange)="modozatValtozas()" [disabled]="karbejelentoAlapadatokForm.get('f_azonosito').value != '0'">
                      <mat-option value="0">Kérem válasszon..!</mat-option>
                      <mat-option value="KGFB">KGFB kár</mat-option>
                      <mat-option value="CASCO">CASCO kár</mat-option>
                      <mat-option value="CMR">CMR/BÁF kár</mat-option>
                      <mat-option value="OTTHON">LAKÁS kár</mat-option>
                      <mat-option value="TRAFIK">TRAFIK kár</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-2" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value != '0'">
                  <mat-form-field appearance="fill">
                    <mat-label>Káresemény időpontja: *</mat-label>
                    <input matInput [matDatepicker]="karidoPicker" formControlName="f_karido" (dateChange)="karidoValtozas()" [max]="karidoMaxDate">
                    <mat-datepicker-toggle matSuffix [for]="karidoPicker"></mat-datepicker-toggle>
                    <mat-datepicker #karidoPicker></mat-datepicker>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karido').errors?.required">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karido').errors?.isDate">
                      Hibás dátum!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karido').errors?.maxDateHiba">
                      Káresemény időpontja nem lehet nagyobb az aktuális dátumnál!
                    </mat-error>
                  </div>
                </div>
                <div class="col-lg-2" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB' || karbejelentoAlapadatokForm.get('f_modozat').value == 'CASCO'">
                  <mat-form-field appearance="fill">
                    <mat-label>Adat tipus azonosításhoz:</mat-label>
                    <mat-select matInput formControlName="f_azon_tip" (selectionChange)="azonositasTipustValtozas('felület')">
                      <mat-option value="0">Rendszám</mat-option>
                      <mat-option value="1">Szerződés azonosító</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-2" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB' || karbejelentoAlapadatokForm.get('f_modozat').value == 'CASCO'">
                  <mat-form-field appearance="fill">
                    <mat-label>{{azonositasSzoveg}}</mat-label>
                    <input type="{{karbejelentoAlapadatokForm.get('f_azon_tip').value == '0' ? 'text' : 'number'}}" matInput formControlName="f_azon_adat" (change)="azonositoValtozas()" oninput="this.value = this.value.toUpperCase()">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_azon_adat').errors?.modozatSpecifikusKotelezoElem">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
                <div class="col-lg-2" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'CMR' || karbejelentoAlapadatokForm.get('f_modozat').value == 'OTTHON' || karbejelentoAlapadatokForm.get('f_modozat').value == 'TRAFIK'">
                  <mat-form-field appearance="fill">
                    <mat-label>Szerződés azonosító:  *</mat-label>
                    <input type="number" matInput formControlName="f_szerzazon" (change)="szerzodesszamValtozas()">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_szerzazon').errors?.required">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
                <div class="col-lg-2" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value != '0'">
                  <button mat-raised-button color="accent" (click)="adatokLekerese()">Adatok lekérése</button>
                </div>
              </div>
              <div class="row" *ngIf="karbejelentoAlapadatokForm.get('f_ellenorzes_ok').value==true">
                <div class="col-lg-12">

                  <div class="row mt-3">

                    <div class="col-lg-3" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB'">
                      <mat-form-field appearance="fill">
                        <mat-label>Kártípus kiválasztása:  *</mat-label>
                        <mat-select matInput formControlName="f_kartipus_gfb" (selectionChange)="karTipustValtozas()" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB'" multiple="true">
                          <mat-option *ngFor="let kartipus of kartipusLista" [value]="kartipus.f_bekod">{{kartipus.f_kartipus}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="pt-2">
                        <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_kartipus_gfb').errors?.modozatSpecifikusKotelezoElem">
                          Kérem válasszon!
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-lg-3" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'CASCO' || karbejelentoAlapadatokForm.get('f_modozat').value == 'CMR'|| (karbejelentoAlapadatokForm.get('f_modozat').value == 'OTTHON' && karbejelentoAlapadatokForm.get('f_szerzazon').value)">
                      <mat-form-field appearance="fill">
                        <mat-label>Kártípus kiválasztása:  *</mat-label>
                        <mat-select matInput formControlName="f_kartipus" (selectionChange)="karTipustValtozas()" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value != 'KGFB'" multiple="false">
                          <mat-option value="0">Kérem válasszon..</mat-option>
                          <mat-option *ngFor="let kartipus of kartipusLista" [value]="kartipus.f_bekod">{{kartipus.f_kartipus}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="pt-2">
                        <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_kartipus').errors?.keremValasszon">
                          Kérem válasszon!
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-lg-3" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'OTTHON' || karbejelentoAlapadatokForm.get('f_modozat').value == 'TRAFIK'">
                      <mat-form-field appearance="fill">
                        <mat-label>A kár becsült összege:  *</mat-label>
                        <mat-select matInput formControlName="f_becsult_osszeg">
                          <mat-option value="0">100.000,-Ft vagy alatti a kár</mat-option>
                          <mat-option value="1">100.000 feletti a kár</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="pt-2">
                        <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_becsult_osszeg').errors?.modozatSpecifikusKotelezoElem">
                          Hiányzó adat!
                        </mat-error>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3" *ngIf="(karbejelentoAlapadatokForm.get('f_kartipus_gfb').value && karbejelentoAlapadatokForm.get('f_kartipus_gfb').value.indexOf('E56101') != -1) || (karbejelentoAlapadatokForm.get('f_kartipus').value && karbejelentoAlapadatokForm.get('f_kartipus').value == 'E57111')">

                    <div class="row">

                      <div class="col-lg-12 mb-2">
                        <mat-label class="control-label font-bold">Károsult gépjármű adatai</mat-label>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Gépjármű jellege:  *</mat-label>
                          <mat-select matInput formControlName="f_gjm_jelleg" (selectionChange)="jellegValtozas($event)">
                            <mat-option value="0">Kérem válasszon..</mat-option>
                            <mat-option *ngFor="let jelleg of jellegLista2" [value]="jelleg.f_jelleg">{{jelleg.f_jellegnev}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_gjm_jelleg').errors?.modozatSpecifikusKotelezoElem">
                            Kérem válasszon!
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Rendszám típusa:</mat-label>
                          <mat-select matInput formControlName="f_rendszam_tipus">
                            <mat-option value="M">magyarországi</mat-option>
                            <mat-option value="K">külföldi</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Rendszám:  </mat-label><mat-label *ngIf="karbejelentoAlapadatokForm.get('f_gjm_jelleg').value !='13' && karbejelentoAlapadatokForm.get('f_gjm_jelleg').value !='51' && karbejelentoAlapadatokForm.get('f_gjm_jelleg').value !='52'">*</mat-label>
                          <input matInput formControlName="f_karosult_rendszam" oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karosult_rendszam').errors?.modozatSpecifikusKotelezoElem">
                            Hiányzó adat!
                          </mat-error>
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karosult_rendszam').errors?.rendszamHossz">
                            A rendszámnak 6 vagy 7 karakterből kell állnia!
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value=='CMR'">

                    <div class="row">

                      <div class="col-lg-12 mb-2">
                        <mat-label class="control-label font-bold">Fuvarfeladatban résztvevő gépjárművek adatai</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Gépjármű rendszáma:</mat-label>
                          <mat-select matInput formControlName="f_vontato_rendszam">
                            <mat-option value="0">Kérem válasszon..</mat-option>
                            <mat-option *ngFor="let vontatoRendszam of vontatoRendszamLista" [value]="vontatoRendszam.f_rendszam1">{{vontatoRendszam.f_rendszam1}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_vontato_rendszam').errors?.modozatSpecifikusKotelezoElem">
                            Gépjármű és/vagy pótkocsi rendszám kötelező!
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Pótkocsi rendszám típusa:</mat-label>
                          <mat-select matInput formControlName="f_potkocsi_tipus">
                            <mat-option value="M">magyarországi</mat-option>
                            <mat-option value="K">külföldi</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Pótkocsi rendszám:</mat-label>
                          <input matInput formControlName="f_potkocsi_rendszam" oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3" *ngIf="(karbejelentoAlapadatokForm.get('f_kartipus_gfb').value && karbejelentoAlapadatokForm.get('f_kartipus_gfb').value.indexOf('E56201') != -1)">

                    <div class="row">

                      <div class="col-lg-12 mb-2">
                        <mat-label class="control-label font-bold">Károsult személy alapadatai</mat-label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Károsult személy típusa:  *</mat-label>
                          <mat-select matInput formControlName="f_karosult_tipus">
                            <mat-option value="0">Kérem válasszon..</mat-option>
                            <mat-option *ngFor="let karosultTipus of karosultTipusLista" [value]="karosultTipus.f_kod">{{karosultTipus.f_megnevezes}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karosult_tipus').errors?.modozatSpecifikusKotelezoElem">
                            Kérem válasszon!
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Személyi károsult neve:  *</mat-label>
                          <input matInput formControlName="f_karosult_nev">
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_karosult_nev').errors?.modozatSpecifikusKotelezoElem">
                            Hiányzó adat!
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value=='TRAFIK'">

                    <div class="row">

                      <div class="col-lg-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Kockázatviselési hely:  *</mat-label>
                          <mat-select matInput formControlName="f_trafik_kockhely_ssz" (selectionChange)="trafikKockhelyModositas()">
                            <mat-option value="0">Kérem válasszon..</mat-option>
                            <mat-option *ngFor="let kockhely of kockhelyLista" [value]="kockhely.f_sorrend">{{kockhely.f_cim_egyben}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_trafik_kockhely_ssz').errors?.keremValasszon">
                            Kérem válasszon!
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Vagyontárgy kiválasztása:  *</mat-label>
                          <mat-select matInput formControlName="f_trafik_reszmodozat" (selectionChange)="trafikVagyontargyModositas('adatbázis')">
                            <mat-option value="0">Kérem válasszon..</mat-option>
                            <mat-option *ngFor="let vagyontargy of vagyontargyLista" [value]="vagyontargy.f_fmodkod">{{vagyontargy.f_megnevezes}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_trafik_reszmodozat').errors?.keremValasszon">
                            Kérem válasszon!
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <mat-form-field appearance="fill">
                          <mat-label>Kártípus kiválasztása:  *</mat-label>
                          <mat-select matInput formControlName="f_kartipus" multiple="false" (selectionChange)="karTipustValtozas()">
                            <mat-option value="0">Kérem válasszon..</mat-option>
                            <mat-option *ngFor="let kartipus of kartipusLista" [value]="kartipus.f_fbekod">{{kartipus.f_fesemeny}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="pt-2">
                          <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_kartipus').errors?.keremValasszon">
                            Kérem válasszon!
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-lg-12">
                      <div class="row" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB'">
                        <div class="col-lg-4 mb-2">
                          <mat-label class="control-label">KGFB adatkezelési nyilatkozat</mat-label>
                          <div class="mt-2">
                            <mat-checkbox matCheckbox class="mr-2" formControlName="f_adatkezelesi_kgfb"></mat-checkbox>
                            <span>* <a href="{{weboldalDokumentumok}}adatkezelesi_tajekoztato_kgfb_karbejelentohoz.pdf" target="_blank">Adatkezelés tájékoztató a kötelező gépjármű-felelősségbiztosítási gépjármű káresemény bejelentésével kapcsolatos adatkezeléshez</a></span>
                            <div class="col-lg-12 pt-2">
                              <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_adatkezelesi_kgfb').errors?.required">
                                Az online kárbejelelentés funkció használatának feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                              </mat-error>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB' && karbejelentoAlapadatokForm.get('f_kartipus_gfb').value && karbejelentoAlapadatokForm.get('f_kartipus_gfb').value.indexOf('E56201') > -1">
                        <div class="col-lg-4 mb-2">
                          <mat-label class="control-label">KGFB személyi kár adatkezelési nyilatkozat</mat-label>
                          <div class="mt-2">
                            <mat-checkbox matCheckbox class="mr-2" formControlName="f_adatkezelesi_kgfb_szemelyi"></mat-checkbox>
                            <span>* <a href="{{weboldalDokumentumok}}kgfb_adatkez_tajek_szemelyi_serult_karesemeny.pdf" target="_blank">Adatkezelési tájékoztató a kötelező gépjármű-felelősségbiztosítási személyi sérüléses káresemény bejelentésével kapcsolatos adatkezeléshez</a></span>
                            <div class="col-lg-12 pt-2">
                              <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_adatkezelesi_kgfb_szemelyi').errors?.required">
                                Az online kárbejelelentés funkció használatának feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                              </mat-error>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'CASCO'">
                        <div class="col-lg-4 mb-2">
                          <mat-label class="control-label">CASCO adatkezelési nyilatkozat</mat-label>
                          <div class="mt-2">
                            <mat-checkbox matCheckbox class="mr-2" formControlName="f_adatkezelesi_casco"></mat-checkbox>
                            <span>* <a href="{{weboldalDokumentumok}}casco_adatkez_tajek_gj_es_kieg_bizt_adatkez.pdf" target="_blank">Adatkezelési tájékoztató casco biztosítási gépjármű- és kiegészítő biztosítási káresemény bejelentésével kapcsolatos adatkezeléshez</a></span>
                            <div class="col-lg-12 pt-2">
                              <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_adatkezelesi_casco').errors?.required">
                                Az online kárbejelelentés funkció használatának feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                              </mat-error>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--
                      <div class="row" *ngIf="karbejelentoAlapadatokForm.get('f_modozat').value == 'OTTHON'">
                        <div class="col-lg-4 mb-2">
                          <mat-label class="control-label">OTTHON adatkezelési nyilatkozat</mat-label>
                          <div class="mt-2">
                            <mat-checkbox matCheckbox class="mr-2" formControlName="f_adatkezelesi_otthon"></mat-checkbox>
                            <span>* <a href="" target="_blank">Adatkezelési tájékoztató otthon</a></span>
                            <div class="col-lg-12 pt-2">
                              <mat-error *ngIf="karbejelentoAlapadatokForm.get('f_adatkezelesi_otthon').errors?.required">
                                Az online kárbejelelentés funkció használatának feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                              </mat-error>
                            </div>
                          </div>
                        </div>
                      </div>
                      -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>

</div>
