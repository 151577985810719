import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonValidator, UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DokumentumService } from '../../services/dokumentum.service';
import { TooltipOptions } from 'ng2-tooltip-directive';
import { WebAppsService } from '../../services/webApps.service';

@Component({
  selector: 'dokumentum-igenyles',
  templateUrl: './dokumentum-igenyles.component.html',
  styleUrls: ['./dokumentum-igenyles.component.scss']
})
export class DokumentumIgenylesComponent implements OnInit {

  formGroup: FormGroup;
  formGroupEredmeny: FormGroup;
  igenyelhetoDokumentumok: boolean = false;
  eredmenyLista: string[];
  toltoKereses: boolean = false;
  toltoIgenyles: boolean = false;

  /* tooltip beállítások*/
  myOptions: TooltipOptions = {
    "placement": "right",
    "max-width": 400,
    "displayTouchscreen": false,
    "tooltip-class": "my-tooltip"
  }

  constructor(private formBuilder: FormBuilder,
    private dokumentumService: DokumentumService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef,
    private webApps: WebAppsService) {
  }

  ngOnInit(): void {
    this.webApps.changeMessage("Dokumentum igénylés");
    this.formGroupInit();
    this.formGroupEredmenyInit();
  }

  formGroupInit() {
    this.formGroup = this.formBuilder.group({
      f_szerz_azon: new FormControl(null, [Validators.required]),
      f_neme: new FormControl("0", CommonValidator.defaultEllenorzo("0")),
      f_szulido: new FormControl(null),
      f_adoszam: new FormControl(null),
    }, {
      validators: [AdatValidator.feltetelesenKotelezo("f_neme", "f_szulido", ["N", "F"]),
      AdatValidator.feltetelesenKotelezo("f_neme", "f_adoszam", ["J"])]
    });
  }

  formGroupEredmenyInit() {
    this.formGroupEredmeny = this.formBuilder.group({
      eredmenykod: new FormControl(null),
      dijrendezett: new FormControl(null),
      zoldkartya: new FormControl(null),
      dijigazolas: new FormControl(null),
      kotveny: new FormControl(null),
      dijbekero: new FormControl(null),
      kartori: new FormControl(null),
      igenyeltZoldkartya: new FormControl(false),
      igenyeltDijigazolas: new FormControl(false),
      igenyeltKotveny: new FormControl(false),
      igenyeltDijbekero: new FormControl(false),
      igenyeltKartori: new FormControl(false),
      igenyeltDokumentum: new FormControl(false)
    });
  }

  scrollToBottom() {
    this.cdr.detectChanges();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  nemeValtozas() {
    this.formGroup.controls["f_szulido"].setValue(null);
    this.formGroup.controls["f_adoszam"].setValue(null);
  }

  torles() {
    this.formGroupInit();
    this.formGroupEredmenyInit();
    this.igenyelhetoDokumentumok = false;
    this.eredmenyLista = [];
  }

  kereses() {

    this.igenyelhetoDokumentumok = false;
    this.toltoKereses = true;
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_dokumentum_igenyles_szerzodes_kereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            adatok: this.formGroup.value
          }

          this.dokumentumService.szerzodesKereses(model).subscribe(
            data => {
              this.formGroupEredmenyInit();
              this.formGroupEredmeny.controls["eredmenykod"].setValue(data["eredmenyKod"]);
              if (data["eredmenyKod"] == 0) {
                this.formGroupEredmeny.controls["dijrendezett"].setValue(data["dijrendezett"]);
                this.formGroupEredmeny.controls["zoldkartya"].setValue(data["zoldkartya"]);
                this.formGroupEredmeny.controls["dijigazolas"].setValue(data["dijigazolas"]);
                this.formGroupEredmeny.controls["kotveny"].setValue(data["kotveny"]);
                this.formGroupEredmeny.controls["dijbekero"].setValue(data["dijbekero"]);
                this.formGroupEredmeny.controls["kartori"].setValue(data["kartorteneti"]);

                if (data["zoldkartya"] || data["dijigazolas"] || data["kotveny"] || data["dijbekero"] || data["kartorteneti"]) {
                  this.igenyelhetoDokumentumok = true;
                  this.toltoKereses = false;
                  this.scrollToBottom();
                }
                else {
                  this.utilityService.dialogMegnyitasa({
                    info: true,
                    title: 'Információ',
                    message: "Nincs igényelhető dokumentum!",
                    confirmButtonText: 'Rendben'
                  });
                  this.toltoKereses = false;
                }
              }
              else {
                this.utilityService.dialogMegnyitasa({
                  info: true,
                  title: 'Információ',
                  message: data["eredmenySzoveg"],
                  confirmButtonText: 'Rendben'
                });
                this.toltoKereses = false;
              }              
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt a szerződés keresés közben!",
                confirmButtonText: 'Rendben'
              });
              this.toltoKereses = false;
            });
        })
      });
  }

  igenyeltDoksiValt() {
    if (this.formGroupEredmeny.controls["igenyeltZoldkartya"].value == true ||
      this.formGroupEredmeny.controls["igenyeltDijigazolas"].value == true ||
      this.formGroupEredmeny.controls["igenyeltKotveny"].value == true ||
      this.formGroupEredmeny.controls["igenyeltDijbekero"].value == true ||
      this.formGroupEredmeny.controls["igenyeltKartori"].value == true) {
      this.formGroupEredmeny.controls["igenyeltDokumentum"].setValue(true);
    }
    else {
      this.formGroupEredmeny.controls["igenyeltDokumentum"].setValue(false);
    }
  }

  onDokumentumIgenyles() {
    this.eredmenyLista = [];
    this.toltoIgenyles = true;
    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_dokumentum_igenyles_igeny_kulddes').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            adatok: this.formGroup.value,
            igenyles: this.formGroupEredmeny.value
          }

          this.dokumentumService.igenyKuldes(model).subscribe(
            data => {
              data.forEach(e => {
                this.eredmenyLista.push(e.dokumentum + " " + (e.eredmeny_kod == 0 ? "igénylését fogadtuk" : "igénylését nem tudtuk fogadni") + (e.eredmeny_kod == 0 ? "" : " Hibakód: " + e.eredmeny_kod + " -  Hibaszöveg: " + e.eredmeny_szoveg));
              });
              this.toltoIgenyles = false;
              this.scrollToBottom();
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt a dokumentum igénylés közben!",
                confirmButtonText: 'Rendben'
              });
              this.toltoIgenyles = false;
            });
        })
      });

  }

}

export class AdatValidator extends Validators {

  static feltetelesenKotelezo(feltetel_control_neve: string, vizsgalt_control_neve: string, feltetel: any[]) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const feltetel_control = formGroup.controls[feltetel_control_neve];
      const vizsgalt_control = formGroup.controls[vizsgalt_control_neve];

      vizsgalt_control.setErrors(null);

      if (feltetel.includes(feltetel_control.value) && (vizsgalt_control.value == null || vizsgalt_control.value == '')) {
        hasError = true;
      }

      if (hasError) {
        vizsgalt_control.setErrors({ feltetelesenKotelezo: true });
      }

    }
    return validator;
  }

}
