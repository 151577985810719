import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { UtilityService } from '@granit/common-library';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as moment from 'moment';

@Component({
  selector: 'karbejelento-vagyontargy',
  templateUrl: './karbejelento-vagyontargy.component.html',
  styleUrls: ['./karbejelento-vagyontargy.component.scss']
})
export class KarbejelentoVagyontargyComponent implements OnInit {

  @Output() karbejelentoVagyontargyFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoVagyontargyForm: FormGroup;
  vagyonTipus: string = "";
  uvegTipusLista: any[];
  numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    allowNegative: false,
    integerLimit: 4
  });

  currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: false,
    allowNegative: false,
    integerLimit: 9
  });

  biztositasTipusEgyebLista: string[] = ['E57111', 'E43201', 'E98101', 'E98103', 'E24001', 'E25001', 'E36001', 'E36101'];
  maxCharLeiras: number = 600;

  constructor(
    private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService  ) { }

  ngOnInit() {
    this.formGroupNew();


    this.karbejelentoVagyontargyFormOut.emit(this.karbejelentoVagyontargyForm);

    this.karbejelentoVagyontargyForm.controls['f_kartipus'].valueChanges.subscribe(value => {
      if ((this.karbejelentoVagyontargyForm.controls['f_modozat'].value == "OTTHON" && this.karbejelentoVagyontargyForm.controls['f_kartipus'].value == "E22001") ||
        (this.karbejelentoVagyontargyForm.controls['f_modozat'].value == "TRAFIK" && this.karbejelentoVagyontargyForm.controls['f_kartipus'].value.indexOf("TDUVU") > -1)) {
        this.uvegTipusokFeltoltes();
      }
    });

    this.karbejelentoVagyontargyForm.controls['f_szerzazon'].valueChanges.subscribe(value => {
      if (this.karbejelentoVagyontargyForm.controls['f_modozat'].value == "OTTHON") {
        this.biztositasTipus();
      }      
    });

    this.karbejelentoVagyontargyForm.controls['f_uveg_tipus'].valueChanges.subscribe(value => {
      if (this.karbejelentoVagyontargyForm.controls['f_uveg_tipus'].value &&
        this.karbejelentoVagyontargyForm.controls['f_uveg_tipus'].value != "0" &&
        this.uvegTipusLista.length > 0) {
        this.karbejelentoVagyontargyForm.controls['f_uveg_tipus_megnev'].setValue(this.uvegTipusLista.find(x => x.f_kod == this.karbejelentoVagyontargyForm.controls['f_uveg_tipus'].value).f_megnevezes)
      }
    });
  }

  formGroupNew() {
    this.karbejelentoVagyontargyForm = this.formBuilder.group({
      f_modozat: new FormControl(null),
      f_kartipus: new FormControl(null),
      f_szerzazon: new FormControl(null),
      f_karido: new FormControl(null),
      f_trafik_kockhely_ssz: new FormControl(null),
      f_vagyontargy_tipus: new FormControl("0"),
      f_vagyontargy_leiras: new FormControl(null, Validators.required),
      f_uveg_tipus: new FormControl("0"),
      f_uveg_tipus_megnev: new FormControl(""),
      f_uveg_meret1: new FormControl(null),
      f_uveg_meret2: new FormControl(null),
      f_becsult_osszeg: new FormControl(null),
      f_becsult_osszeg_tipus: new FormControl(null),
      f_idegen_vagyontargy: new FormControl(false)
    }, {
      validators: [AdatValidator.fuggosegiKotelezettseg('f_vagyontargy_tipus', 'f_modozat', ''),
      AdatValidator.fuggosegiKotelezettseg('f_uveg_tipus', 'f_kartipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_uveg_meret1', 'f_kartipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_uveg_meret2', 'f_kartipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_becsult_osszeg', 'f_modozat', 'f_becsult_osszeg_tipus')]
    });
  }

  uvegTipusokFeltoltes() {
    this.uvegTipusLista = [];
    this.karbejelento.uvegTipusok().subscribe(
      data => {
        this.uvegTipusLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az üveg tipusok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  biztositasTipus() {
    if (this.karbejelentoVagyontargyForm.controls["f_szerzazon"].value && this.karbejelentoVagyontargyForm.controls["f_karido"].value) {

      let model = {
        f_szerzazon: this.karbejelentoVagyontargyForm.controls["f_szerzazon"].value,
        f_karido: moment(this.karbejelentoVagyontargyForm.controls["f_karido"].value).format('YYYY.MM.DD')
      }

      this.karbejelento.otthonBiztositasTipus(model).subscribe(
        data => {
          if (this.biztositasTipusEgyebLista.indexOf(this.karbejelentoVagyontargyForm.controls["f_kartipus"].value) > -1) {
            this.vagyonTipus = "G";
          }
          else {
            if (data) {
              this.vagyonTipus = data["f_tipus"];
            }            
          }
          if (this.vagyonTipus != "M") {
            this.karbejelentoVagyontargyForm.controls["f_vagyontargy_tipus"].setValue(this.vagyonTipus);
          }
          //this.karbejelentoVagyontargyForm.controls["f_vagyontargy_tipus"].setValue(this.vagyonTipus == "M" ? "0" : this.vagyonTipus);
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a biztosítási tipus lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

}

export class AdatValidator extends Validators {
  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      control1.setErrors(null);

      if (control2_neve == "f_modozat" && control2.value == "OTTHON" && (control1.value == null || control1.value == "0" || control1.value == "")) {
        hasError = true;
      }

      if (control2_neve == "f_kartipus" && control2.value != null && control2.value != "" && (control2.value == "E22001" || control2.value.indexOf("TDUVU") > -1) && control1_neve == "f_uveg_tipus" && (control1.value == null || control1.value == "0")) {
        hasError = true;
      }

      if (control2_neve == "f_kartipus" && control2.value != null && control2.value != "" && (control2.value == "E22001" || control2.value.indexOf("TDUVU") > -1) && (control1_neve == "f_uveg_meret1" || control1_neve == "f_uveg_meret2") && (control1.value == null || control1.value == "")) {
        hasError = true;
      }

      if (control2_neve == "f_modozat" && (control2.value == "OTTHON" || control2.value == "TRAFIK") && control1_neve == "f_becsult_osszeg") {

        if (control1.value == null || control1.value == "") {
          control1.setErrors({ fuggosegiKotelezettseg: true });
        }
        else {
          var ertek = Number(control1.value.toString().split(" ").join(""));
          if (control3.value == 0 && ertek > 100000) {
            control1.setErrors({ becsOsszegNagy: true });
          }
          if (control3.value == 0 && ertek < 1000) {
            control1.setErrors({ becsOsszegMin: true });
          }
          if (control3.value == 1 && ertek < 100001) {
            control1.setErrors({ becsOsszegKicsi: true });
          }
        }

      }

      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

    }
    return validator;
  }
}
