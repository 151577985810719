import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CommonValidator, HibaService, UtilityService } from '@granit/common-library';
import { WebAppsService } from '../../services/webApps.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DokumentumService } from '../../services/dokumentum.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import * as moment from 'moment';

@Component({
  selector: 'dokumentum-letoltes',
  templateUrl: './dokumentum-letoltes.component.html',
  styleUrls: ['./dokumentum-letoltes.component.scss']
})
export class DokumentumLetoltesComponent implements OnInit {

  url = window.location.href;
  urlHiba: boolean = false;
  homokora: boolean = false;
  formGroup: FormGroup;
  constructor(private route: ActivatedRoute,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef,
    private webAppsService: WebAppsService,
    private formBuilder: FormBuilder,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dokumentumService: DokumentumService,
    public hibaService: HibaService) { }

  ngOnInit(): void {

    this.webAppsService.changeMessage("Dokumentum letöltés");

    this.formGroupInit();

    this.homokora = true;
    this.urlHiba = false

    let modelUrl = {
      f_url: this.url
    }

    this.webAppsService.urlValid(modelUrl).subscribe(
      data => {
        if (data && data == true) {
          // kliens id querystring kiolvasása
          this.route.queryParams.subscribe(async params => {
            // Ha a kliens id querystringnek van értéke
            const _params = this.toLower(params);
            this.formGroup.controls["f_levazon"].setValue(((_params['l']) as string));
            this.formGroup.controls["f_hatarido"].setValue(((_params['d']) as string));

            if (((_params['t']) as number) == 1) {
              this.formGroup.controls["f_tipus"].setValue("normal");
            }
            else if (((_params['t']) as number) == 2) {
              this.formGroup.controls["f_tipus"].setValue("kar");
            }
            else if (((_params['t']) as number) == 3) {
              this.formGroup.controls["f_tipus"].setValue("flotta");
            }

          });
          this.cdr.detectChanges();
          this.homokora = false;
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás url",
            confirmButtonText: 'Rendben'
          });
          this.homokora = false;
          this.urlHiba = true;
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az url ellenőrzése közben",
          confirmButtonText: 'Rendben'
        });
        this.urlHiba = true;
        this.homokora = false;
      });
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }
    return lowerParams;
  }

  formGroupInit() {
    this.formGroup = this.formBuilder.group({
      f_tipus: new FormControl(null),
      f_levazon: new FormControl(null),
      f_hatarido: new FormControl(null),
      f_szerz_azon: new FormControl(null),
      f_karszam: new FormControl(null),
      f_flotta: new FormControl(null),
      f_neme: new FormControl("0"),
      f_szulido: new FormControl(null),
      f_adoszam: new FormControl(null),
    }, {
      validators: [AdatValidator.feltetelesenKotelezoSzerzodo("f_neme", "f_szulido", ["N", "F"]),
      AdatValidator.feltetelesenKotelezoSzerzodo("f_neme", "f_adoszam", ["J"]),
      AdatValidator.feltetelesenKotelezoNemSzerzodo("f_tipus", "f_szerz_azon", ["normal"]),
      AdatValidator.feltetelesenKotelezoNemSzerzodo("f_tipus", "f_karszam", ["kar"]),
      AdatValidator.feltetelesenKotelezoNemSzerzodo("f_tipus", "f_flotta", ["flotta"]),
      AdatValidator.neme("f_neme"),
        AdatValidator.szamEllenor("f_szerz_azon"),
        AdatValidator.szamEllenor("f_karszam"),
        AdatValidator.szamEllenor("f_flotta"),
        AdatValidator.szamEllenor("f_adoszam")
      ]
    });
  }

  nemeValtozas() {
    this.formGroup.controls["f_szulido"].setValue(null);
    this.formGroup.controls["f_adoszam"].setValue(null);
  }

  scrollToBottom() {
    this.cdr.detectChanges();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  letoltes() {
    this.homokora = true;
    let modelUrl = {
      adatok: this.formGroup.value
    }

    this.recaptchaV3Service.execute('webapps_dokumentum_letoltes').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          let model = {
            adatok: this.formGroup.value
          }
          this.dokumentumService.dokumentumLetoltes(model).subscribe(
            data => {
              try {
                let filename = "granit_kuldemeny_" + moment(new Date()).format("YYYYMMDDhhmmss") + ".pdf";
                let contentType = 'application/pdf';
                let blob = base64StringToBlob(data["file"], contentType);
                saveAs(blob, filename);

                this.utilityService.dialogMegnyitasa({
                  success: true,
                  title: 'Siker',
                  message: "Sikeres dokumentum letöltés",
                  confirmButtonText: 'Rendben'
                });
              }
              catch {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba történt a dokumentum letöltés közben!",
                  confirmButtonText: 'Rendben'
                });
              }

              this.homokora = false;
            },
            errorResult => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba történt a dokumentum letöltés közben!",
                confirmButtonText: 'Rendben'
              });
              this.homokora = false;
            });
        })
      });
  }

}

export class AdatValidator extends Validators {

  static feltetelesenKotelezoNemSzerzodo(feltetel_control_neve: string, vizsgalt_control_neve: string, feltetel: any[]) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const feltetel_control = formGroup.controls[feltetel_control_neve];
      const vizsgalt_control = formGroup.controls[vizsgalt_control_neve];

      vizsgalt_control.setErrors(null);

      if (feltetel.includes(feltetel_control.value) && (vizsgalt_control.value == null || vizsgalt_control.value === '')) {
        vizsgalt_control.setErrors({ feltetelesenKotelezo: true });
      }     
    }
    return validator;
  }

  static feltetelesenKotelezoSzerzodo(feltetel_control_neve: string, vizsgalt_control_neve: string, feltetel: any[]) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const feltetel_control = formGroup.controls[feltetel_control_neve];
      const vizsgalt_control = formGroup.controls[vizsgalt_control_neve];

      vizsgalt_control.setErrors(null);

      if (formGroup.controls["f_tipus"].value == "normal" || formGroup.controls["f_tipus"].value == "flotta") {
        if (feltetel.includes(feltetel_control.value) && (vizsgalt_control.value == null || vizsgalt_control.value === '')) {
          vizsgalt_control.setErrors({ feltetelesenKotelezo: true });
        }        
      }
    }
    return validator;
  }

  static neme(control_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control = formGroup.controls[control_neve];

      control.setErrors(null);

      if ((formGroup.controls["f_tipus"].value == "normal" || formGroup.controls["f_tipus"].value == "flotta") && (control.value == null || control.value == '' || control.value == '0')) {
        hasError = true;
      }

      if (hasError) {
        control.setErrors({ nemeHiba: true });
      }

    }
    return validator;
  }

  static szamEllenor(control_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control = formGroup.controls[control_neve];
      
      control.setErrors(null);

      if ((formGroup.controls["f_tipus"].value == "normal" && control_neve == "f_szerz_azon") && (control.value?.length != 8 || !Number(control.value) || control.value[0] == "0")) {
        control.setErrors({ szerzAzonHiba: true });
      }
      if ((formGroup.controls["f_tipus"].value == "flotta" && control_neve == "f_flotta") && (!Number(control.value) || control.value[0] == "0")) {
        control.setErrors({ flottaAzonHiba: true });
      }
      if ((formGroup.controls["f_tipus"].value == "kar" && control_neve == "f_karszam") && (!Number(control.value) || control.value[0] == "0")) {
        control.setErrors({ karszamHiba: true });
      }
      if ((formGroup.controls["f_neme"].value == "J" && control_neve == "f_adoszam") && (control.value?.length != 11 || !Number(control.value) || control.value[0] == "0")) {
        control.setErrors({ adoszamHiba: true });
      }

    }
    return validator;
  }


}
