import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
//import * as moment from 'moment';
import { SimplePayService } from '../../services/simplepay.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';

@Component({
  selector: 'simplepay-result',
  templateUrl: './simplepay-result.component.html',
  styleUrls: ['./simplepay-result.component.scss']
})
export class SimplePayResultComponent implements OnInit, OnDestroy {
  intervalId?: number; // refresh intervalId

  simplepayId: string = "";
  transactionId: string = "";

  fizetesUjraUrl: string = null; // sikertelen fizetés esetén az újrapróbálás linkje
  redirectUrl: string = null; // sikeres fizetés esetén, ha ajánlatra történt a fizetés
  modozat: string = null;
  modozat2: string = null;
  ajazon: number = null;
  szerzazon: number = null;

  // a kapott url paraméterek feldolgozása
  url: string = "";
  routerUrl: string = "";
  sParam: string = "";
  rParam: string = "";

  toltoKereses: boolean = false;
  isCurrentStatusFinal: boolean = false;

  befizetesEredmenyForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private simplePay: SimplePayService,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    private sanitized: DomSanitizer,
    private recaptchaV3Service: ReCaptchaV3Service) {

    // Kiszedjuk a route nevét
    this.routerUrl = this.router.url.split('?')[0].substring(1);
    this.url = window.location.href;

    if (this.url.lastIndexOf("&amp;") >= 0) {
      this.url = this.sanitized.bypassSecurityTrustHtml(this.url).toString();
      this.router.navigate([this.url]);
    }
    else {
      this.formGroupNew();
      this.route.queryParams.subscribe((params: Params) => {
        const _params = this.toLower(params);
        if (_params["s"]) {
          this.sParam = _params["s"];
        }
        if (_params["r"]) {
          this.rParam = _params["r"];
        }
      });
      this.fizetesEredmeny();
    }
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }
    return lowerParams;
  }

  formGroupNew() {
    this.befizetesEredmenyForm = this.formBuilder.group({
      f_simplepayId: new FormControl(""),
      f_statusCode: new FormControl(""),
      f_statusText: new FormControl(""),
      f_hibaszoveg: new FormControl(""),
    });
  }

  ngOnInit(): void {
    //this.setAutoRefresh();
  }

  ngOnDestroy(): void {
    this.clearAutoRefresh();
  }

  refreshData() {
    console.log('refreshing page');
    // refresh current status
    this.refreshCurrentStatus();
  }

  clearAutoRefresh() {
    console.log('refresh clearup');
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  setAutoRefresh() {
    if (!this.isCurrentStatusFinal) {
      console.log('refresh setup');
      this.intervalId = window.setInterval(() => {
        this.refreshData();
      }, 10000);
    }
  }

  //KoteshezVissza() {
  //  if (this.redirectUrl) {
  //    //this.router.navigate([this.redirectUrl]);
  //    window.location.replace(this.redirectUrl);
  //  }
  //}

  FizetesUjra() {
    if (this.fizetesUjraUrl) {
      //this.router.navigate([this.fizetesUjraUrl]);
      window.location.replace(this.fizetesUjraUrl);

    }
  }

  // az ipn státusz lekérdezése
  refreshCurrentStatus() {

    this.clearAutoRefresh();

    this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("");
    this.befizetesEredmenyForm.controls["f_statusCode"].setValue("");
    this.befizetesEredmenyForm.controls["f_statusText"].setValue("");
    this.befizetesEredmenyForm.controls["f_simplepayId"].setValue("");

    this.recaptchaV3Service.execute('simplepay_result').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            transactionId: this.transactionId,
            simplePayId: this.simplepayId
          };

          this.simplePay.simplePayIpnCheck(model).subscribe(
            data => {
              // hiba esetén kiírni a sikertelen fizetés tényét + fizetés ujra feltöltése
              this.befizetesEredmenyForm.controls["f_statusCode"].setValue(data.statusCode);
              this.fizetesUjraUrl = data.fizetesUjraUrl;
              this.redirectUrl = data.redirectUrl;
              this.isCurrentStatusFinal = data.isFinalStatus;

              // sikertelen fizetés esetén kiírni a sikertelen fizetést és fizetés újra
              //IPN eredmény esetén kiírni az eredményt és ha kell a fizetés újrát vagy visszairányítani a kötési felületre
              if (this.isCurrentStatusFinal) {
                if (data.statusCode == "FINISHED") {
                  // ajánlat befizetés esetén továbbdobás...
                  if (this.ajazon && this.redirectUrl) {
                    window.location.replace(this.redirectUrl);
                  }
                  this.befizetesEredmenyForm.controls["f_simplepayId"].setValue(data.simplepayId);
                  this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);
                  this.toltoKereses = false;
                }
                else if (data.statusCode == "NOTAUTHORIZED")
                {
                  this.befizetesEredmenyForm.controls["f_simplepayId"].setValue(data.simplepayId);
                  this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);
                  this.toltoKereses = false;
                }
                else /*if (data.statusCode == "CANCELLED" || data.statusCode == "TIMEOUT")*/ {
                  this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);
                  this.toltoKereses = false;
                }
              }
              else
              {
                this.setAutoRefresh();
              }
            },
            errorResult => {
              this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue(errorResult);
              this.toltoKereses = false;
            });
        })
      });
  }

  // csak az oldal betöltésekor kerül meghívásra ()
  fizetesEredmeny() {
    // URL-ben kapott eredmények feldolgozása
    this.clearAutoRefresh();

    // amíg nincs IPN eredmény, addig töltőképernyő!!!
    this.toltoKereses = true;
    this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("");
    this.befizetesEredmenyForm.controls["f_simplepayId"].setValue("");
    this.befizetesEredmenyForm.controls["f_statusText"].setValue("");
    this.befizetesEredmenyForm.controls["f_statusCode"].setValue("");

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('simplepay_result').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(
          result => {
            let model = {
              f_s: this.sParam,
              f_r: this.rParam
            };

            this.simplePay.simplePayBack(model).subscribe(
              data => {
                // fel kell tölteni a form adatokat
                this.transactionId = data.transactionId;
                this.simplepayId = data.simplepayId;
                //this.befizetesEredmenyForm.controls["f_transactionId"].setValue(data.transactionId);
                //this.befizetesEredmenyForm.controls["f_simplepayId"].setValue(data.simplePayId);
                //this.fizetesUjraUrl = data.fizetesUjraUrl;
                //this.redirectUrl = data.redirectUrl;
                this.modozat = data.modozat;
                this.modozat2 = data.modozat2;
                this.ajazon = data.ajazon;
                this.szerzazon = data.szerzAzon;
                this.befizetesEredmenyForm.controls["f_statusCode"].setValue(data.statusCode);

                if (data.statusCode == 'TIMEOUT' || data.statusCode == 'CANCELLED') {
                  this.fizetesUjraUrl = data.fizetesUjraUrl;
                  this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);
                  this.isCurrentStatusFinal = true;
                  this.toltoKereses = false;
                }
                else if (data.statusCode == 'NOTAUTHORIZED') {
                  this.fizetesUjraUrl = data.fizetesUjraUrl;
                  this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);
                  this.befizetesEredmenyForm.controls["f_simplepayId"].setValue(data.simplepayId);
                  this.isCurrentStatusFinal = true;
                  this.toltoKereses = false;
                }
                else {
                  this.isCurrentStatusFinal = false;
                  this.refreshCurrentStatus();
                }
              },
              errorResult => {
                this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue(errorResult);
                //this.clearAutoRefresh();
                this.toltoKereses = false;
              });
          })
      });
  }
}
