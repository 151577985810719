<div class="contain-lg">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <!--<div class="mt-4 ml-4">-->
      <div class="m-4">
        <div>
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
              <form [formGroup]="befizetesEredmenyForm" class="form">
                <label class="control-label font-bold">Gránit Bank Fairpay azonnali utalás eredménye</label>
                <div class="ml-4">
                  <div class="mb-5">
                    <div class="row mt-5">
                      <div class="col-lg-12">
                        <mat-label class="font-bold">Az azonnali utalás eredménye - {{befizetesEredmenyForm.get('f_eredmenyszoveg').value}}</mat-label>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="befizetesEredmenyForm.get('f_hibaszoveg').value != ''">
                      <div class="col-lg-4">
                        <mat-label>Hibaüzenet:</mat-label>
                      </div>
                      <div class="col-lg-4">
                        <span class="font-bold">{{befizetesEredmenyForm.get('f_hibaszoveg').value}}</span>
                      </div>
                    </div>
                    <div *ngIf="befizetesEredmenyForm.get('f_hibaszoveg').value == ''">
                      <div class="row mt-2">
                        <div class="col-lg-4">
                          <mat-label>Összeg:</mat-label>
                        </div>
                        <div class="col-lg-4">
                          <mat-label class="font-bold">{{befizetesEredmenyForm.get('f_osszeg').value | textTransform : 'thousand' : 'number'}} Ft</mat-label>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-4">
                          <mat-label>FairPay azonosító:</mat-label>
                        </div>
                        <div class="col-lg-4">
                          <mat-label class="font-bold">{{befizetesEredmenyForm.get('f_fairpayId').value}}</mat-label>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-4">
                          <mat-label>Válaszkód:</mat-label>
                        </div>
                        <div class="col-lg-4">
                          <mat-label class="font-bold">{{befizetesEredmenyForm.get('f_statusCode').value}}</mat-label>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-4">
                          <mat-label>Válaszüzenet:</mat-label>
                        </div>
                        <div class="col-lg-4">
                          <mat-label class="font-bold">{{befizetesEredmenyForm.get('f_statusText').value}}</mat-label>
                        </div>
                      </div>

                      <div class="row mt-5" *ngIf="(isCurrentStatusFinal || routerUrl=='fairpay-error') && fizetesUjraUrl">
                        <div class="col-lg-4">
                          <button mat-raised-button color="accent" (click)="FizetesUjra()">Vissza a fizetés oldalra</button>
                        </div>
                      </div>

                      <div class="row mt-5" *ngIf="isCurrentStatusFinal && befizetesEredmenyForm.controls['f_file'].value">
                        <div class="col-lg-4">
                          <button mat-raised-button color="accent" (click)="EredmenyLetoltes()">Eredmény letöltése PDF formátumban</button>
                        </div>
                      </div>

                      <div class="row mt-5" *ngIf="redirectUrl && (modozat == '31101' || modozat == '23000')">
                        <div class="col-lg-4">
                          <button mat-raised-button color="accent" (click)="KoteshezVissza()">Vissza a kötési felületre</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
