<div class="contain-lg">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <!--<div class="mt-4 ml-4">-->
      <div class="m-4">
        <div *ngIf="uzemSzunetCibMost == null || uzemSzunetFairpayMost == null">
          <mat-card class="mat-elevation-z3" *ngIf="visszateres == false">
            <mat-card-content>
              <div class="row">
                <div class="col-lg-6 col-md-6">

                  <ng-container *ngIf="toltoKereses">
                    <div class="row">
                      <div class="col-lg-12 bg-white">
                        <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!toltoKereses">

                    <form [formGroup]="befizetesForm" class="form" *ngIf="ajanlatKereses == false">
                      <label class="control-label font-bold">Szerződő adatai</label>
                      <div class="ml-4" id="kereses">
                        <div [class.disableDiv]="befizetesForm.get('f_kereses_panel').value == 'N'">
                          <div class="row mt-5">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Szerződés azonosító:</mat-label>
                                <input type="text" matInput formControlName="f_szerzazon" [textMask]="{mask: szerzazonMask}">
                              </mat-form-field>
                            </div>
                            <div class="pt-2">
                              <mat-error *ngIf="befizetesForm.get('f_szerzazon').errors?.required">
                                Hiányzó adat!
                              </mat-error>
                              <mat-error *ngIf="befizetesForm.get('f_szerzazon').errors?.hosszValidator">
                                Az szerződés azonosítónak 8 karakterből kell állnia!
                              </mat-error>
                            </div>
                          </div>
                          <div class="row mt-3" *ngIf="nevVisible">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Ügyfélnév:</mat-label>
                                <input type="text" matInput formControlName="f_ugyfelnev">
                              </mat-form-field>
                            </div>
                            <div class="pt-2">
                              <mat-error *ngIf="befizetesForm.get('f_ugyfelnev').errors?.required">
                                Hiányzó adat!
                              </mat-error>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Szerződő neme:</mat-label>
                                <mat-select matInput formControlName="f_neme" (selectionChange)="nemValtozas()">
                                  <mat-option value="0">Kérem válasszon!</mat-option>
                                  <mat-option value="N">Nő</mat-option>
                                  <mat-option value="F">Férfi</mat-option>
                                  <mat-option value="J">Jogi</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="pt-2">
                              <mat-error *ngIf="befizetesForm.get('f_neme').errors?.defaultEllenorzo">
                                Hiányzó adat!
                              </mat-error>
                            </div>
                          </div>
                          <div class="row mt-3" *ngIf="befizetesForm.get('f_neme').value != 'J' && befizetesForm.get('f_neme').value != '0'">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Születési idő:</mat-label>
                                <input matInput [matDatepicker]="szuldatPicker" formControlName="f_szuldat">
                                <mat-datepicker-toggle matSuffix [for]="szuldatPicker"></mat-datepicker-toggle>
                                <mat-datepicker #szuldatPicker></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="pt-2">
                              <mat-error *ngIf="befizetesForm.get('f_szuldat').errors?.matDatepickerParse">
                                Hibás dátum!
                              </mat-error>
                              <mat-error *ngIf="!befizetesForm.get('f_szuldat').errors?.matDatepickerParse && befizetesForm.get('f_szuldat').errors?.required">
                                Hiányzó adat!
                              </mat-error>
                            </div>
                          </div>
                          <div class="row mt-3" *ngIf="befizetesForm.get('f_neme').value == 'J'">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Adószám:</mat-label>
                                <input type="text" matInput formControlName="f_adoszam" [textMask]="{mask: adoszamMask}">
                              </mat-form-field>
                            </div>
                            <div class="pt-2">
                              <mat-error *ngIf="befizetesForm.get('f_adoszam').errors?.required">
                                Hiányzó adat!
                              </mat-error>
                              <mat-error *ngIf="befizetesForm.get('f_adoszam').errors?.hosszValidator">
                                Az adószámnak 11 karakterből kell állnia!
                              </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5" [class.disableDiv]="szerzazon != '0'">
                          <div class="col-lg-6 pb-4">
                            <button mat-raised-button color="accent" (click)="formGroupNew()">Adatok törlése</button>
                          </div>
                          <div class="col-lg-6 pb-4">
                            <button mat-raised-button color="accent" [disabled]="!befizetesForm.valid || befizetesForm.get('f_kereses_panel').value == 'N'" (click)="kereses()">Keresés</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="row mt-5">
                    </div>
                    <form [formGroup]="befizetesOsszegekForm" class="form" *ngIf="befizetesForm.get('f_kereses_panel').value == 'N'">
                      <div id="eredmeny">
                        <label class="control-label font-bold">Fizetés</label>
                        <div class="ml-4" *ngIf="befizetesForm.get('f_befizetes_tipus').value == 'ajanlat-befizetes' || befizetesForm.get('f_befizetes_tipus').value == 'ajanlat-kp-pos' || ((befizetesForm.get('f_befizetes_tipus').value == 'kp-pos' || befizetesForm.get('f_befizetes_tipus').value == 'befizetes') &&  !(befizetesForm.get('f_modkod').value != '31101' && befizetesForm.get('f_dijfizgyak').value == 'H' && befizetesForm.get('f_torolt').value == 'N'))" [class.disableDiv]="visszateres != false">
                          <div *ngIf="befizetesForm.get('f_torolt').value == 'I'" class="font-bold mb-5">
                            <label>Felhívjuk figyelmét, hogy a törölt szerződés hátralékának rendezése, nem jelenti a szerződés újra kockázatba helyezését!</label>
                          </div>
                          <div *ngIf="befizetesForm.get('f_modkod').value == '31101'" class="mb-5">
                            <div class="row mt-5" *ngIf="befizetesOsszegekForm.get('f_elmaradt_dij').value != '0'">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill">
                                  <mat-label>Elmaradt díj:</mat-label>
                                  <input type="text" matInput formControlName="f_elmaradt_dij" readonly="readonly">
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row mt-2" *ngIf="befizetesOsszegekForm.get('f_fedezetlensegi_dij').value != '0'">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill">
                                  <mat-label>Fedezetlenségi díj:</mat-label>
                                  <input type="text" matInput formControlName="f_fedezetlensegi_dij" readonly="readonly">
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row mt-2" *ngIf="befizetesOsszegekForm.get('f_elso_dij').value != '0'">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill">
                                  <mat-label>Gyakoriság szerint fizetendő első biztosítási díj:</mat-label>
                                  <input type="text" matInput formControlName="f_elso_dij" readonly="readonly">
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="row mt-2" *ngIf="befizetesOsszegekForm.get('f_egyenleg').value != '0'">
                              <div class="col-lg-6">
                                <mat-form-field appearance="fill">
                                  <mat-label>Szerződés egyenlege:</mat-label>
                                  <input type="text" matInput formControlName="f_egyenleg" readonly="readonly">
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2" *ngIf="befizetesForm.get('f_befizetes_tipus').value != 'befizetes' || (befizetesForm.get('f_befizetes_tipus').value == 'befizetes' && befizetesOsszegekForm.get('f_osszesen').value != 0)">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Összesen fizetendő díj:</mat-label>
                                <input type="text" matInput formControlName="f_osszesen" readonly="readonly">
                              </mat-form-field>
                            </div>
                          </div>

                        </div>
                        <div class="ml-4" *ngIf="(befizetesForm.get('f_befizetes_tipus').value == 'befizetes' || befizetesForm.get('f_befizetes_tipus').value == 'kp-pos') && befizetesForm.get('f_modkod').value != '31101' && befizetesForm.get('f_dijfizgyak').value == 'H' && befizetesForm.get('f_torolt').value == 'N'" [class.disableDiv]="visszateres != false">
                          <div class="row mt-2 mb-3">
                            <div class="col-lg-8">
                              <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <td *ngFor="let column of haviAdatok" class="cellaban-kozepre">
                                        {{column.header}}
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let adat of haviAdatokLista; let i = index;">
                                      <td *ngFor="let column of haviAdatok" [class]="column.class">
                                        <div *ngIf="column.adattipus == 'check'">
                                          <mat-checkbox color="primary" [checked]="adat[column.columnDef]" (change)="haviFizetesValaszt($event.checked, i)"></mat-checkbox>
                                        </div>
                                        <div *ngIf="column.adattipus != 'check'">
                                          <span [innerHTML]="adat[column.columnDef] | textTransform: (column.adattipus == 'date' ? (column.dateFormat ? column.dateFormat : dateFormat) : column.numberFormat) : column.adattipus"></span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5">
                            <div class="col-lg-6">
                              <mat-form-field appearance="fill">
                                <mat-label>Összesen fizetendő díj:</mat-label>
                                <input type="text" matInput formControlName="f_osszesen" readonly="readonly">
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <div class="ml-4" *ngIf="routerUrl!='kp-pos' && routerUrl!='ajanlat-kp-pos' && befizetesOsszegekForm.get('f_hibaszoveg').value == ''">
                          <div class="row mt-2">
                            <div class="col-lg-12">
                              <mat-label>Fizetési mód:</mat-label>
                            </div>
                            <div class="col-lg-12">
                              <mat-radio-group formControlName="f_fizmod" (change)="fizmodValtozas()">
                                <mat-radio-button value="F" *ngIf="!uzemSzunetFairpayMost">
                                  <div class="col-lg-6">
                                    <a href="https://fairpay.granitbank.hu" target="_blank"><img src="/assets/images/fairpay-logo-horizontal-green-30px.png" alt="Gránit Bank FairPay azonnali utalás" title="Gránit Bank FairPay azonnali utalás" /></a>
                                  </div>
                                  <div class="col-lg-6">Gránit Bank FairPay azonnali utalás</div>
                                </mat-radio-button>

                                <!--<mat-radio-button value="C" *ngIf="!uzemSzunetCibMost">
                                  <div class="col-lg-6">
                                    <img src="/assets/images/cib_logo_30px.png" alt="CIB Bank online bankkártyás fizetés" />
                                  </div>
                                  <div class="col-lg-6">CIB Bank online bankkártyás fizetés</div>
                                </mat-radio-button>-->

                                <mat-radio-button value="S" *ngIf="!uzemSzunetSimplePayMost">
                                  <div class="col-lg-6">
                                    <a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank"><img src="/assets/images/simplepay-logo-sm.png" title="SimplePay - Online bankkártyás fizetés" alt=" SimplePay vásárlói tájékoztató" /></a>
                                  </div>
                                  <div class="col-lg-6">SimplePay - Online bankkártyás fizetés</div>
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </div>
                        </div>
                        <div class="ml-4" *ngIf="routerUrl!='kp-pos' && routerUrl!='ajanlat-kp-pos' && befizetesOsszegekForm.get('f_fizmod').value == 'S' && befizetesOsszegekForm.get('f_hibaszoveg').value == ''">
                          <div class="row mt-2">
                            <div class="form-group col-lg-6">
                                <mat-checkbox matCheckbox formControlName="f_at_nyilatkozat" class="mat-checkbox"> Elolvastam és elfogadom az <a href="https://dokumentum.granitbiztosito.hu/simple_adattovabbitasi_nyilatkozat.pdf" title="Adattovábbítási nyilatkozat" target="_blank">adattovábbítási nyilaktozat</a>ban leírtakat.</mat-checkbox>
                            </div>
                          </div>
                        </div>
                              <div class="ml-4" *ngIf="befizetesForm.get('f_befizetes_tipus').value=='kp-pos' || befizetesForm.get('f_befizetes_tipus').value=='ajanlat-kp-pos'">
                                <div class="row mt-2">
                                  <div class="col-lg-6">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Befizetés összege:</mat-label>
                                      <input type="text" matInput formControlName="f_befizetes_osszeg" [readonly]="(routerUrl == 'ajanlat-kp-pos' ? 'readonly' : null)">
                                    </mat-form-field>
                                  </div>
                                  <div class="pt-2">
                                    <mat-error *ngIf="befizetesOsszegekForm.get('f_befizetes_osszeg').errors?.hianyValidator">
                                      Hiányzó adat!
                                    </mat-error>
                                    <mat-error *ngIf="befizetesOsszegekForm.get('f_befizetes_osszeg').errors?.szamValidator">
                                      A befizetés összege csak számokat tartalmazhat!
                                    </mat-error>
                                    <mat-error *ngIf="befizetesOsszegekForm.get('f_befizetes_osszeg').errors?.pozitivSzamValidator">
                                      A befizetés összegének nagyobbnak kell lennie 0 forintnál!
                                    </mat-error>
                                  </div>
                                </div>
                                <div class="row mt-2">
                                  <div class="col-lg-6">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Fizetés tipus:</mat-label>
                                      <mat-select matInput formControlName="f_fizetes_tipus">
                                        <mat-option value="0">Kérem válasszon!</mat-option>
                                        <mat-option value="1">Pos</mat-option>
                                        <mat-option value="2">Kp</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="pt-2">
                                    <mat-error *ngIf="befizetesOsszegekForm.get('f_fizetes_tipus').errors?.keremValasszon">
                                      Hiányzó adat!
                                    </mat-error>
                                  </div>
                                </div>
                                <div class="row mt-2">
                                  <div class="col-lg-6">
                                    <mat-form-field appearance="fill">
                                      <mat-label>Fizetés azonosító:</mat-label>
                                      <input type="text" matInput formControlName="f_fizetes_azonosito">
                                    </mat-form-field>
                                  </div>
                                  <div class="pt-2">
                                    <mat-error *ngIf="befizetesOsszegekForm.get('f_fizetes_azonosito').errors?.hianyValidator">
                                      Hiányzó adat!
                                    </mat-error>
                                  </div>
                                </div>
                              </div>

                              <div class="row mt-5" *ngIf="this.befizetesForm.controls['f_allapot'].vlaue == '63'">
                                <div class="col-lg-6 col-md-6">
                                  <div class="information-wrapper mb-4">
                                    <p>
                                      Felhívjuk figyelmét, hogy biztosítási szerződése díjnemfizetéssel megszűnt. Kérjük a szerződésével kapcsolatosan keresse ügyfélszolgálatunkat!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-5">
                                <div class="col-lg-12 pb-3">
                                  <mat-error>
                                    <div [innerHtml]="befizetesOsszegekForm.get('f_hibaszoveg').value"></div>
                                  </mat-error>
                                </div>
                                <div class="col-lg-2 pb-4">
                                  <button mat-raised-button color="primary" [disabled]="befizetesOsszegekForm.get('f_hibaszoveg').value != '' || (befizetesOsszegekForm.get('f_fizmod').value == 'S' && !befizetesOsszegekForm.get('f_at_nyilatkozat').value) || (((routerUrl == 'kp-pos' || routerUrl == 'ajanlat-kp-pos') && (!befizetesOsszegekForm.valid || befizetesOsszegekForm.get('f_befizetes_osszeg').value <= 0)) || (routerUrl == 'befizetes' &&  befizetesOsszegekForm.get('f_osszesen').value < 10))" (click)="fizetesInditas()">Fizetés</button>
                                </div>
                              </div>
                            </div>
                      </form>
                  </ng-container>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="information-wrapper mb-4">
                    <p><strong>Fizetési módok a Gránit Biztosító oldalán</strong></p>
                    <p>Azonnali utalás</p>
                    <p>Partnerünk, a Gránit Bank megoldásával, bankkártya adatainak megadása és akár regisztráció nélkül is, tud fizetni az oldalon.</p>
                    <p>Ezen fizetési mód választása esetén, a Gránit Bank Fairpay rendszererén kereszül elküldjük önnek, az Ön által megadott bankszámlára, a fizetési kérelmet.</p>
                    <p>A kapott fizetési kérelmet, Ön a saját bankjának internetes banki felületén vagy mobilalkalmazásában tud majd jóváhagyni.</p>
                    <p>&nbsp;</p>
                    <p>Online bankkártyás fizetés</p>
                    <p>Ügyfeleink részére, az eddig megszokott módon, továbbra is elérhető az online bankkártyás fizetés.</p>
                    <p>A bankkártyás fizetéshez a SimplePay Online Fizetési Rendszer használható.</p>
                    <p>A SimplePay Online Fizetési Rendszert az OTP Mobil Kft. fejleszti és üzemelteti. Az OTP Mobil Kft. az OTP Csoport tagja.</p>
                  </div>
                  <!--<div class="information-wrapper mb-4">
                    <p>
                      Nevet változtattunk: a korábbi Wáberer Hungária Biztosító 2024.02.15-től Gránit Biztosító néven működik tovább. Ügyfeleinknek nincs teendője, minden szerződésünk jogfolytonosan tovább él. Ha a korábbi Wábereres szerződésére szeretne díjat befizetni, itt megteheti!
                    </p>
                  </div>-->
                  <div class="information-wrapper mb-4">
                    <p>Tájékoztatók, információk a Gránit Bank Fairpay azonnali utaláshoz</p>
                    <p><a href="https://fairpay.granitbank.hu/" target="_blank"><img src="/assets/images/fairpay-logo-horizontal-divider-green.png" title="Gránitbank Fairpay honlap" alt="Gránitbank Fairpay honlap"></a></p>
                    <p><a mat-raised-button href="https://fairpay.granitbank.hu/" target="_blank"><mat-icon>link</mat-icon>&nbsp;Gránitbank Fairpay honlap</a></p>
                    <p><a mat-raised-button href="https://fairpay.granitbank.hu/maganszemely-aff" target="_blank"><mat-icon>link</mat-icon>&nbsp;Általános felhasználási feltételek</a></p>
                  </div>
                  <div class="information-wrapper mb-4">
                    <p>Tájékoztatók, információk a SimplePay online bankkártyás fizetéshez</p>

                    <p><a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank"><img src="/assets/images/simplepay_h120.png" title="SimplePay - Online bankkártyás fizetés" alt="SimplePay vásárlói tájékoztató"></a></p>
                    <p><a mat-raised-button href="https://dokumentum.granitbiztosito.hu/simple_adattovabbitasi_nyilatkozat.pdf" target="_blank"><mat-icon>link</mat-icon>&nbsp;Adattovábbítási nyilatkozat</a></p>
                    <p><a mat-raised-button href="https://simplepay.hu/" target="_blank"><mat-icon>link</mat-icon>&nbsp;OTP SimplePay Honlap</a></p>
                    <p><a mat-raised-button href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank"><mat-icon>link</mat-icon>&nbsp;Online fizetési tájékoztató</a></p>
                  </div>
                  <!--<div class="information-wrapper mb-4">-->
                  <!--
            <p>Tájékoztatók, információk a bankkártyás fizetéshez</p>
            <p><img src="/assets/images/cib_es_kartyalogok.png"></p>
            <p><a mat-raised-button href="https://www.granitbiztosito.hu/online-fizetesi-tajekoztato" target="_blank"><mat-icon>link</mat-icon>&nbsp;Online fizetési tájékoztató</a></p>
            <p><a mat-raised-button href="https://www.granitbiztosito.hu/fizetesi-tajekoztato-gyfk" target="_blank"><mat-icon>link</mat-icon>&nbsp;Kérdések és válaszok internetes kártyás fizetésről</a></p>
            -->
                  <!--</div>-->
                  <!--<p><img src="/assets/images/cib_es_kartyalogok.png"></p>-->
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <!--          <mat-card class="mat-elevation-z3" *ngIf="befizetesForm.get('f_kereses_panel').value == 'N'">
                <mat-card-content>

                </mat-card-content>
              </mat-card>-->

          <mat-card class="mat-elevation-z3" *ngIf="routerUrl == 'befizetes' && visszateres == true">
            <mat-card-content>
              <form [formGroup]="befizetesEredmenyForm" class="form">
                <label class="control-label font-bold">Eredmény</label>
                <ng-container *ngIf="toltoKereses">
                  <div class="row">
                    <div class="col-lg-12 bg-white">
                      <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!toltoKereses">
                  <div class="ml-4">
                    <div class="mb-5">
                      <div class="row mt-5" *ngIf="befizetesEredmenyForm.get('f_hibaszoveg').value == ''">
                        <div class="col-lg-2">
                          <button mat-raised-button color="accent" (click)="eredmenyLetoltes()">Eredmény letöltése PDF formátumba</button>
                        </div>
                      </div>

                      <div class="row mt-5">
                        <div class="col-lg-2">
                          <mat-label class="font-bold">Kártyás fizetés eredménye - {{befizetesEredmenyForm.get('valaszkod').value == '00' ? "Sikeres" : "Sikertelen"}}</mat-label>
                        </div>
                      </div>
                      <div class="row mt-2" *ngIf="befizetesEredmenyForm.get('f_hibaszoveg').value != ''">
                        <div class="col-lg-2">
                          <mat-label>Hibaüzenet:</mat-label>
                        </div>
                        <div class="col-lg-2">
                          <span class="font-bold">{{befizetesEredmenyForm.get('f_hibaszoveg').value}}</span>
                        </div>
                      </div>
                      <div *ngIf="befizetesEredmenyForm.get('f_hibaszoveg').value == ''">
                        <div class="row mt-2">
                          <div class="col-lg-2">
                            <mat-label>Tranzakció azonosító:</mat-label>
                          </div>
                          <div class="col-lg-2">
                            <mat-label class="font-bold">{{befizetesEredmenyForm.get('tranazon').value}}</mat-label>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-lg-2">
                            <mat-label>Válaszkód:</mat-label>
                          </div>
                          <div class="col-lg-2">
                            <mat-label class="font-bold">{{befizetesEredmenyForm.get('valaszkod').value}}</mat-label>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-lg-2">
                            <mat-label>Válaszüzenet:</mat-label>
                          </div>
                          <div class="col-lg-2">
                            <mat-label class="font-bold">{{befizetesEredmenyForm.get('valaszuzenet').value}}</mat-label>
                          </div>
                        </div>
                        <div class="row mt-2" *ngIf="befizetesEredmenyForm.get('valaszkod').value == '00'">
                          <div class="col-lg-2">
                            <mat-label>Engedélyszám:</mat-label>
                          </div>
                          <div class="col-lg-2">
                            <mat-label class="font-bold">{{befizetesEredmenyForm.get('engedelyszam').value}}</mat-label>
                          </div>
                        </div>
                        <div class="row mt-2" *ngIf="befizetesEredmenyForm.get('valaszkod').value == '00'">
                          <div class="col-lg-2">
                            <mat-label>Fizetett összeg:</mat-label>
                          </div>
                          <div class="col-lg-2">
                            <mat-label class="font-bold">{{befizetesEredmenyForm.get('osszeg').value | textTransform : 'thousand' : 'number'}} Ft</mat-label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
        <!--<div *ngIf="uzemSzunetCibMost">
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
              <div class="col-4">
                <div class="d-flex justify-content-center align-items-center dialog-header background-color error">
                  <mat-icon>credit_card_off</mat-icon>
                </div>
                <div class="dialog-body pt-2 pr-4 pb-4 pl-4">
                  <div mat-dialog-content class="d-flex justify-content-center">
                    <p><b>Tisztelt ügyfelünk!</b></p>
                    <p>A bankkártyás fizetés szolgáltatás az oldalon jelenleg nem elérhető.</p>
                    <p>A szolgáltatáskiesés várható intervalluma:<br /><b>{{uzemSzunetCibMost.kezdete | dateFormat:'YYYY.MM.DD HH:mm'}} - {{uzemSzunetCibMost.vege | dateFormat:'YYYY.MM.DD HH:mm'}}</b></p>
                    <p>A szolgáltatáskiesés oka:<br /><b>{{uzemSzunetCibMost.leiras}}</b></p>
                    <p>Üdvözlettel:<br />Gránit Biztosító</p>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>-->
        <div *ngIf="uzemSzunetFairpayMost">
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
              <div class="col-4">
                <div class="d-flex justify-content-center align-items-center dialog-header background-color error">
                  <mat-icon>credit_card_off</mat-icon>
                </div>
                <div class="dialog-body pt-2 pr-4 pb-4 pl-4">
                  <div mat-dialog-content class="d-flex justify-content-center">
                    <p><b>Tisztelt ügyfelünk!</b></p>
                    <p>Az azonnali utalás szolgáltatás az oldalon jelenleg nem elérhető.</p>
                    <p>A szolgáltatáskiesés várható intervalluma:<br /><b>{{uzemSzunetFairpayMost.kezdete | dateFormat:'YYYY.MM.DD HH:mm'}} - {{uzemSzunetFairpayMost.vege | dateFormat:'YYYY.MM.DD HH:mm'}}</b></p>
                    <p>A szolgáltatáskiesés oka:<br /><b>{{uzemSzunetFairpayMost.leiras}}</b></p>
                    <p>Üdvözlettel:<br />Gránit Biztosító</p>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="uzemSzunetSimplePayMost">
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
              <div class="col-4">
                <div class="d-flex justify-content-center align-items-center dialog-header background-color error">
                  <mat-icon>credit_card_off</mat-icon>
                </div>
                <div class="dialog-body pt-2 pr-4 pb-4 pl-4">
                  <div mat-dialog-content class="d-flex justify-content-center">
                    <p><b>Tisztelt ügyfelünk!</b></p>
                    <p>A bankkártyás fizetés szolgáltatás az oldalon jelenleg nem elérhető.</p>
                    <p>A szolgáltatáskiesés várható intervalluma:<br /><b>{{uzemSzunetSimplePayMost.kezdete | dateFormat:'YYYY.MM.DD HH:mm'}} - {{uzemSzunetSimplePayMost.vege | dateFormat:'YYYY.MM.DD HH:mm'}}</b></p>
                    <p>A szolgáltatáskiesés oka:<br /><b>{{uzemSzunetSimplePayMost.leiras}}</b></p>
                    <p>Üdvözlettel:<br />Gránit Biztosító</p>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </div>
    </div>
  </div>
</div>
