import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebAppsService {
  private url: string = "/api/webApps";

  private messageSource = new BehaviorSubject<string>("");
  focimke = this.messageSource.asObservable();

  constructor(
    private httpClient: HttpClient) { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  // Visszatérő url valid vagy sem
  urlValid(model: any) {

    return this.httpClient.post<any>(this.url + "/UrlValid", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
 
}
