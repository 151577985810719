<form [formGroup]="idopontFoglalasFormGroup" class="form">

  <div *ngIf="!foglalasUId">

    <div class="col-lg-6 mt-5 mb-3 pr-5 info">
      <p>Foglaljon időpontot online rendszerünkben, amely előre tervezhető, várakozásmentes és ez által kényelmes ügyintézést biztosít. A sikeres foglalásról visszaigazoló e-mailt fog kapni. Amennyiben nem kapta meg az e-mailt, a foglalása nem volt sikeres, kérjük, próbálja meg újra.</p>
      <p>Mielőtt a foglalást elindítaná, kérjük, szíveskedjék elolvasni az online időpontfoglalással összefüggésben a Biztosító által végzett adatkezelésére vonatkozó tájékoztatót, amely <a class="download" href="{{weboldalDokumentumok}}/online_idopontfoglalas_adatkezelesi_tajekoztato.pdf" target="_blank">itt elérhető.</a></p>
      <p>
        Felhívjuk figyelmét, hogy hétfői munkanapon 17:00 -19:00 óra között csak időpontfoglalással rendelkező ügyfeleinket tudjuk fogadni.<br />
        Megértését és együttműködését köszönjük!
      </p>
    </div>

    <div class="row mt-5">
      <div class="col-lg-6">

        <mat-card class="mat-elevation-z3">
          <mat-card-content>

            <div class="row ml-2">
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="info-text font-smaller">
                    <p>A csillaggal(*) jelölt mezőket kötelező kitölteni</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="row mt-2 ml-2">
              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Dátum:  *</mat-label>
                  <mat-select formControlName="valasztottDatum" (selectionChange)="onDatumValasztas()">
                    <mat-option value="0">Kérem válasszon!</mat-option>
                    <mat-option *ngFor="let datum of datumLista" [value]="datum.f_datum">{{datum.f_datum_szoveg}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Időpont:  *</mat-label>
                  <mat-select formControlName="valasztottIdopont" [disabled]="idopontFoglalasFormGroup.get('valasztottDatum').value == '0'">
                    <mat-option value="0">Kérem válasszon!</mat-option>
                    <mat-option *ngFor="let idopont of idopontLista" [value]="idopont.f_idopont_szoveg">{{idopont.f_idopont_szoveg}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>


            <div [class.disableDiv]="idopontFoglalasFormGroup.get('valasztottIdopont').value == '0'">

              <div class="row mt-2 ml-2">

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Név:  *</mat-label>
                    <input matInput formControlName="nev" maxlength="50">
                  </mat-form-field>
                  <div>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('nev').errors?.required">
                      <mat-icon>error</mat-icon>
                      Kérjük, adja meg a nevét!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>E-mail cím:  *</mat-label>
                    <input type="text" matInput formControlName="email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                  </mat-form-field>
                  <div>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('email').errors?.required">
                      <mat-icon>error</mat-icon>
                      Kérjük, adja meg az e-mail címét!
                    </mat-error>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('email').errors?.pattern">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

              </div>

              <div class="row mt-2 ml-2">

                <div class="col-lg-2">
                  <div>
                    <mat-label class="control-label">Telefon típusa:</mat-label>
                    <mat-radio-group formControlName="telefonTipus" (change)="telefonElohivoFeltoltes()" class="radio-group-vertical">
                      <mat-radio-button value="M">Mobil</mat-radio-button>
                      <mat-radio-button value="V">Vezetékes</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Előhívó:</mat-label>
                    <mat-select matInput formControlName="telefonElohivo" (selectionChange)="eloHivoValtozas()">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let eloHivo of eloHivoLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('telefonElohivo').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefonszám:</mat-label>
                    <input type="text" matInput formControlName="telefonSzam" [textMask]="{mask: phoneMask}" [placeholder]="phonePlaceholder">
                  </mat-form-field>
                  <div>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('telefonSzam').errors?.telefonszamUres">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('telefonSzam').errors?.telefonszam">
                      Hibás adat!
                    </mat-error>
                    <mat-error *ngIf="idopontFoglalasFormGroup.get('telefonSzam').errors?.telefonszamElso0">
                      Hibás adat! (első karakter nem lehet nulla)
                    </mat-error>
                  </div>
                </div>

              </div>

              <div class="row mt-2 ml-2">
                <div class="col-lg-8">
                  <mat-form-field appearance="fill">
                    <mat-label>Megjegyzés:</mat-label>
                    <textarea type="text" class="md-textarea md-textarea-auto" matInput rows="3" formControlName="megjegyzes" maxlength="500"></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="row mt-4 ml-2">
                <div class="col-lg-4">
                  <button type="button" mat-raised-button color="accent" [disabled]="!idopontFoglalasFormGroup.valid" (click)="onidopontFoglalas()">Időpont foglalása</button>
                </div>
              </div>
            </div>

          </mat-card-content>
        </mat-card>

      </div>
    </div>
  </div>

  <div *ngIf="foglalasUId">

    <div class="col-lg-6 mt-5 mb-3 pr-5 info">
      <p>Ön a korábban lefoglalt személyes ügyfélszolgálati időpontjának lemondását kezdeményzte.<br />Kérjük, hogy az "Időpont lemondása" feliratú gombra kattintással erősítse meg lemondási szándékát!</p>
    </div>

    <div class="row mt-5">
      <div class="col-lg-6">

        <mat-card class="mat-elevation-z3">
          <mat-card-content>

            <div class="row ml-2">
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="info-text">
                    <p>Az időpont foglalásának adatai</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2 ml-2">

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Dátum:</mat-label>
                  <input matInput formControlName="valasztottDatum" readonly>
                </mat-form-field>
              </div>

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Időpont:</mat-label>
                  <input matInput formControlName="valasztottIdopont" readonly>
                </mat-form-field>
              </div>

            </div>

            <div class="row mt-2 ml-2">

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Név:</mat-label>
                  <input matInput formControlName="nev" readonly>
                </mat-form-field>
              </div>

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>E-mail cím:  *</mat-label>
                  <input matInput formControlName="email" readonly>
                </mat-form-field>
              </div>

            </div>

            <div class="row mt-2 ml-2">

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Telefonszám:</mat-label>
                  <input matInput formControlName="telefonSzamEgyben" readonly>
                </mat-form-field>
              </div>

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Megjegyzés:</mat-label>
                  <textarea type="text" class="md-textarea md-textarea-auto" matInput rows="3" formControlName="megjegyzes" readonly></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="row mt-4 ml-2">
              <div class="col-lg-4">
                <button type="button" mat-raised-button color="accent" [disabled]="!idopontFoglalasFormGroup.valid" (click)="onidopontLemondas()">Időpont lemondása</button>
              </div>
            </div>

          </mat-card-content>
        </mat-card>

      </div>
    </div>

  </div>

</form>
