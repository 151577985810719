import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'karbejelento-hiba-oldal',
  templateUrl: './karbejelento-hiba-oldal.component.html',
  styleUrls: ['./karbejelento-hiba-oldal.component.scss']
})
export class KarbejelentoHibaOldalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
