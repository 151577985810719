import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'karbejelento-eredmeny',
  templateUrl: './karbejelento-eredmeny.component.html',
  styleUrls: ['./karbejelento-eredmeny.component.scss']
})
export class KarbejelentoEredmenyComponent implements OnInit {

    @Output() karbejelentoEredmenyFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    karbejelentoEredmenyForm: FormGroup;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {

        this.formGroupNew();

        this.karbejelentoEredmenyFormOut.emit(this.karbejelentoEredmenyForm);        

    }

    formGroupNew() {
        this.karbejelentoEredmenyForm = this.formBuilder.group({            
            f_uzenet: new FormControl("")
        });
    }

}

export class AdatValidator extends Validators {
    static boolNotTrue(control1_neve: string) {

        let validator = (formGroup: FormGroup) => {

            const control1 = formGroup.controls[control1_neve];
            
            control1.setErrors(null);

            if (control1_neve == "f_adatkezelesi" && (control1.value == null || control1.value == false)) {
                control1.setErrors({ boolNotTrue: true });
            }            
            
        }
        return validator;
    }
}
