import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BefizetesService {
  private url: string = "/api/befizetes";

  constructor(
    private httpClient: HttpClient) { }


  /* Szerződés és adatainak keresése onlie fizetéshez */
  keresesOnlineFizeteshez(model: any) {
    return this.httpClient.post<any>(this.url + "/KeresesOnlineFizeteshez", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Szerződés és adatainak keresése onlie fizetéshez */
  /*keresesOnlineFizeteshezUgyfelPortal(szerzazon: string) {

    const httpParams = new HttpParams()
      .append('szerzazon', szerzazon);

    return this.httpClient.get<any>(this.url + "/KeresesOnlineFizeteshezUgyfelportal", { params: httpParams }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }*/


  fizetesInditas(model: any) {
    return this.httpClient.post<any>(this.url + "/FizetesInditas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Fizetés lezárása */
  fizetesLezaras(model: any) {

    return this.httpClient.post<any>(this.url + "/FizetesLezaras", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }


  onlineBefizetesEredmeny(model: any) {

    return this.httpClient.post(this.url + "/OnlineBefizetesEredmeny", model, { responseType: "text" }).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  uzemSzunet() {

    return this.httpClient.post<any[]>(this.url + "/FizetesUzemSzunet", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }
}
