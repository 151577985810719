<form [formGroup]="rendezvenyFormGroup" class="form">

  <mat-card class="mat-elevation-z3">
    <mat-card-content>

      <div *ngIf="regisztracioVege != 'nincs'">

        <div class="row ml-3 mt-5 info">
          <div class="col-12">
            {{rendezvenyFormGroup.controls['megnevezes'].value}}
          </div>
        </div>

        <div *ngIf="regisztracioVege == ''">

          <div class="row ml-3 mt-3 info">
            <div class="col-12">
              <countdown #cd [config]="config" (event)="hataridoLejart($event)"></countdown>
            </div>
          </div>

          <div class="row ml-3 mt-3">
            <div class="col-6">
              <div [innerHtml]="rendezvenyFormGroup.controls['meghivo_szoveg'].value"></div>
            </div>
          </div>

          <div class="row ml-3 mt-3">
            <div class="col-6">
              <div [innerHtml]="rendezvenyFormGroup.controls['regisztracio_szoveg'].value"></div>
            </div>
          </div>

          <div class="row ml-3 mt-3">
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Cégnév</mat-label>
                <input type="text" matInput formControlName="cegnev" maxlength="150" minlength="2">
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'cegnev', 'required')">
                  Hiányzó adat!
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'cegnev', 'minlength')">
                  Minimum 2 karkter
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row ml-3 mt-2">
            <div class="col-3">
              <mat-label>Résztvevők</mat-label>
            </div>
          </div>
          <div class="row ml-3 mt-2">
            <div class="col-3">
              <mat-form-field appearance="fill">
                <mat-label>Név</mat-label>
                <input type="text" matInput formControlName="nev1" maxlength="150">
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'nev1', 'required')">
                  Hiányzó adat!
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'nev1', 'egyKotelezo')">
                  Legalább egy résztvevő adatainak megadása kötelező
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email1" maxlength="150" minlength="7">
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email1', 'required')">
                  Hiányzó adat!
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email1', 'pattern')">
                  Hibás email cím!
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email1', 'minlength')">
                  Minimum 7 karkter
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email1', 'regisztraltEmail')">
                  Erre az email címre már történt regisztráció
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row ml-3 mt-2">
            <div class="col-3">
              <mat-form-field appearance="fill">
                <mat-label>Név</mat-label>
                <input type="text" matInput formControlName="nev2" maxlength="150">
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'nev2', 'required')">
                  Hiányzó adat!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email2" maxlength="150" minlength="7">
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email2', 'required')">
                  Hiányzó adat!
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email2', 'pattern')">
                  Hibás email cím!
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email2', 'minlength')">
                  Minimum 7 karkter
                </mat-error>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'email2', 'regisztraltEmail')">
                  Erre az email címre már történt regisztráció
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!--div class="row ml-3 mt-2">
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Gépjárművek</mat-label>
                <input type="text" matInput formControlName="gepjarmu" maxlength="150">
              </mat-form-field>
            </div>
          </div-->
          <div class="row ml-3 mt-2">
            <div class="col-lg-6">
              <mat-label class="control-label">Adatkezelési nyilatkozat</mat-label>
              <div class="mt-2">
                <mat-checkbox matCheckbox class="mr-2" formControlName="adatkezelesi"></mat-checkbox>
                <span>Az <a [routerLink]="" (click)="adatkezelesi();">adatkezelési tájékoztató</a> tartalmát megismertem, az abban foglaltakat tudomásul vettem.</span>
                <mat-error *ngIf="hibaService.hiba(rendezvenyFormGroup, 'adatkezelesi', 'defaultEllenorzo')">
                  A rendezvébyre való regisztráció feltétele az adatkezelési tájékoztató tudomásul vétele, amit a jelölőnégyzet megjelölésével igazolhat vissza.
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row ml-3 mt-5 mb-5">
            <div class="col-lg-2">
              <button mat-raised-button color="accent" (click)="rendezvenyAdatokMentes()" [disabled]="!this.rendezvenyFormGroup.valid">Jelentkezem</button>
            </div>
          </div>

        </div>

        <div *ngIf="regisztracioVege == 'hatarido'">
          <div class="row ml-3 mt-3">
            <div class="col-6">
              <div [innerHtml]="rendezvenyFormGroup.controls['hatarido_szoveg'].value"></div>
            </div>
          </div>
        </div>

        <div *ngIf="regisztracioVege == 'maxFo'">
          <div class="row ml-3 mt-3">
            <div class="col-6">
              <div [innerHtml]="rendezvenyFormGroup.controls['maxfo_szoveg'].value"></div>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="regisztracioVege == 'nincs'">
        <div class="row ml-3 mt-3">
          <div class="col-6">
            <spn>Jelenleg nincs rendezvényünk.</spn>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</form>
