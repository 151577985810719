import { Component, OnInit, Input } from '@angular/core';
import { KalkulatorService } from '../../../../services/kalkulator.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mfo-ajanlott-biztositasi-osszegek',
  templateUrl: './mfo-ajanlott-biztositasi-osszegek.component.html',
  styleUrls: ['./mfo-ajanlott-biztositasi-osszegek.component.scss']
})
export class MfoAjanlottBiztositasiOsszegekComponent implements OnInit {

  constructor(
    private kalkulatorService: KalkulatorService) { }

  @Input()
  get formGroup(): FormGroup { return this._formGroup; }
  set formGroup(value: FormGroup) {
    this._formGroup = value;
    this.mfoTarifalasFormGroup = value;
    this.ajanlottBiztositasiOsszegekFormGroup = value.controls['ajanlottBiztositasiOsszegekFormGroup'] as FormGroup;
  }
  private _formGroup: FormGroup;

  mfoTarifalasFormGroup: FormGroup;
  ajanlottBiztositasiOsszegekFormGroup: FormGroup;
  biztositandoMellekepuletekSzamaArray: any[] = Array(0);

  ngOnInit(): void {
    this.azonositoBeallitas();
    this.mellekEpuletekSzamaBeallitas();
  }

  // Azonosító beállítás
  azonositoBeallitas() {
    this.mfoTarifalasFormGroup.controls['f_azonosito'].valueChanges.subscribe(
      value => {
        this.ajanlottBiztositasiOsszegekFormGroup.controls['f_azonosito'].setValue(value);
      },
    );
  }

  // Melléképületek száma beállítás
  mellekEpuletekSzamaBeallitas() {
    (this.mfoTarifalasFormGroup.controls['alapAdatokFormGroup'] as FormGroup).controls['f_biztositando_mellekepuletek_szama'].valueChanges.subscribe(
      value => {
        this.biztositandoMellekepuletekSzamaArray = [...Array(+value).keys()].map(i => i + 1);
      }
    );
  }
}
