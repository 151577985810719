<h1 mat-dialog-title>Dupla bejelentés ellenőrzése</h1>
<div mat-dialog-content>
  <div class="row mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p>Ez a káresemény már lehet, hogy be lett jelentve. Folytatja?</p>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()" cdkFocusInitial>Mégsem</button>
  <button mat-button [mat-dialog-close]="true">Igen</button>
</div>
