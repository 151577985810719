import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { UtilityService, GranitTableComponent, GranitFileUploadComponent, Dokumentum } from '@granit/common-library';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'karbejelento-fajlfeltoltes',
  templateUrl: './karbejelento-fajlfeltoltes.component.html',
  styleUrls: ['./karbejelento-fajlfeltoltes.component.scss']
})
export class KarbejelentoFajlfeltoltesComponent implements OnInit {

  @Output() karbejelentoFajlFeltoltesFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoFajlFeltoltesForm: FormGroup;
  fajldb: number = 0;
  toltoKereses: boolean = false;

  feltoltottFajlokOszlopok: any[] = [
    { columnDef: 'f_sorszam', header: 'Sorszám', class: 'col-lg-2' },
    { columnDef: 'f_filename', header: 'Fájlnév', class: 'col-lg-7' },
    { columnDef: 'f_meret', header: 'Méret (MB)', class: 'col-lg-2' },
    { columnDef: 'f_fullpath', header: 'Elérési út', visible: false },
    { columnDef: 'f_egyeb_muvelet_gomb', header: '', class: 'col-lg-1', buttonText: "Törlés", buttonIcon: "delete" }
  ];
  getDisplayedFeltoltottFajlokColumns(): string[] {
    let displayedColumns = this.feltoltottFajlokOszlopok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  feltoltottFajlokForm: FormGroup = this.formBuilder.group({
    tableDataSource: this.formBuilder.array([]),
  });
  feltoltottFajlokTabla: MatTableDataSource<any>;

  fajlKiterjesztesek: string[] = [".jpg", ".jpeg", ".bmp", ".tif", ".png", ".pdf", ".doc", ".docx", ".odt"];
  biztositoLista: any[];

  hibak: any[] = [];
  weboldalDokumentumok: string = "";

  @ViewChild('fileUpload', { static: true }) fileUpload: GranitFileUploadComponent;

  constructor(private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef ) { }

  ngOnInit() {

    this.link();
    this.formGroupNew();
    this.biztsoitokFeltoltes();


    this.karbejelentoFajlFeltoltesFormOut.emit(this.karbejelentoFajlFeltoltesForm);

    this.karbejelentoFajlFeltoltesForm.controls['f_modozat'].valueChanges.subscribe(value => {
      if (this.karbejelentoFajlFeltoltesForm.controls['f_modozat'].value != "" && this.karbejelentoFajlFeltoltesForm.controls['f_modozat'].value != null) {
        this.feltoltottFajlokListaja();
      }
    });

  }

  async link() {

    let model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

  feltoltottFajlokListaja() {
    this.feltoltottFajlokTabla = new MatTableDataSource();
    this.karbejelentoFajlFeltoltesForm.controls['f_fajl_db'].setValue(0);
    this.karbejelentoFajlFeltoltesForm.controls['f_fajlok_merete'].setValue(0);

    let model = {
      f_azonosito: this.karbejelentoFajlFeltoltesForm.controls["f_azonosito"].value,
      f_modozat: this.karbejelentoFajlFeltoltesForm.controls["f_modozat"].value,
    }

    this.karbejelento.feltoltottFajlokListaja(model).subscribe(
      data => {

        const fgs = data.map(item => {
          return this.utilityService.formGroupObjektumbol(item);
        });
        if (fgs.length > 0) {
          this.feltoltottFajlokForm.setControl('tableDataSource', this.formBuilder.array(fgs));
          this.feltoltottFajlokTabla.data = (this.feltoltottFajlokForm.get('tableDataSource') as FormArray).controls;
          this.karbejelentoFajlFeltoltesForm.controls['f_fajl_db'].setValue(fgs.length);

          let osszMeret: number = 0;
          data.forEach(file => {
            osszMeret += file.f_meret
          });
          this.karbejelentoFajlFeltoltesForm.controls['f_fajlok_merete'].setValue(Math.round(osszMeret * 100) / 100);

        }
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a feltöltött fájlok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  formGroupNew() {
    this.karbejelentoFajlFeltoltesForm = this.formBuilder.group({
      f_azonosito: new FormControl(null),
      f_modozat: new FormControl(null),
      f_bejelento: new FormControl(null),
      f_dokumentum: new FormControl(null),
      f_okozo_felelos: new FormControl("-1"),
      f_okozo_felelos_egyeb: new FormControl(null),
      f_okozo_vezeto: new FormControl("-1"),
      f_okozo_vezeto_egyeb: new FormControl(null),
      f_mas_biztositonal_bejelentes: new FormControl("-1"),
      f_mas_biztosito: new FormControl(null),
      f_mas_biztosito_kod: new FormControl("0"),
      f_egeszsegugyi_adatkezeles: new FormControl(false, Validators.requiredTrue),
      f_szemely_serultes: new FormControl(false),
      f_fajl_db: new FormControl(0),
      f_fajlok_merete: new FormControl(0)
    }, {
      validators: [AdatValidator.fuggosegiKotelezettseg('f_okozo_felelos', 'f_modozat', 'f_bejelento'),
      AdatValidator.fuggosegiKotelezettseg('f_okozo_felelos_egyeb', 'f_okozo_felelos', ''),
      AdatValidator.fuggosegiKotelezettseg('f_okozo_vezeto', 'f_modozat', 'f_bejelento'),
      AdatValidator.fuggosegiKotelezettseg('f_okozo_vezeto_egyeb', 'f_okozo_vezeto', ''),
      AdatValidator.fuggosegiKotelezettseg('f_mas_biztositonal_bejelentes', 'f_modozat', ''),
      AdatValidator.fuggosegiKotelezettseg('f_mas_biztosito_kod', 'f_mas_biztositonal_bejelentes', ''),
      AdatValidator.fuggosegiKotelezettseg('f_egeszsegugyi_adatkezeles', 'f_modozat', 'f_szemely_serultes')
      ]
    });
  }

  async fajlEllenorzes(event: any) {

    this.toltoKereses = true;

    this.hibak = [];

    if (event.length > 0) {
      event.forEach(element => {
        this.hibak.push({ azonosito: element.f_azonosito, hibaSzoveg: element.f_hiba });
      })
    };

    if (this.karbejelentoFajlFeltoltesForm.controls["f_dokumentum"].value != null && (this.karbejelentoFajlFeltoltesForm.controls["f_dokumentum"].value instanceof Dokumentum || this.karbejelentoFajlFeltoltesForm.controls["f_dokumentum"].value instanceof Array)) {

      let dokumentumok: any[] = this.karbejelentoFajlFeltoltesForm.controls["f_dokumentum"].value as Array<Dokumentum>;

      let meretSzum: number = this.karbejelentoFajlFeltoltesForm.controls["f_fajlok_merete"].value;

      for (var i = 0; i < dokumentumok.length; i++) {
        let dokumentum = dokumentumok[i];

        let dokMeret: number = Math.round(((dokumentum.f_meret / 1024) / 1024) * 100) / 100;

        if (meretSzum + dokMeret > 15) {
          this.hibak.push({ azonosito: dokumentum.f_nev, hibaSzoveg: "A fájl mérete miatt túllépné a megengedett méret korlátot." });
        }
        else {
          meretSzum += dokMeret;
          /*eredmeny = this.fajlMentese(dokumentum);*/

          let model = {
            f_azonosito: this.karbejelentoFajlFeltoltesForm.controls["f_azonosito"].value,
            f_modozat: this.karbejelentoFajlFeltoltesForm.controls["f_modozat"].value,
            f_dokumentum: dokumentum
          }

          let eredmeny = await this.karbejelento.fajlMentese(model);
          if (eredmeny != "ok") {
            this.hibak.push({ azonosito: dokumentum.f_nev, hibaSzoveg: eredmeny });
          }
        }

      }

      this.toltoKereses = false;
      
      this.feltoltottFajlokListaja();
      this.cdr.detectChanges();

    }
    //this.fileUpload.clearFilesList();    

  }

  fajlMentese(dokumentum: Dokumentum): Promise<any> {
    this.toltoKereses = true;
    let model = {
      f_azonosito: this.karbejelentoFajlFeltoltesForm.controls["f_azonosito"].value,
      f_modozat: this.karbejelentoFajlFeltoltesForm.controls["f_modozat"].value,
      f_dokumentum: dokumentum
    }

    return new Promise<string>((resolve, reject) => {
      this.karbejelento.fajlMentese(model).then(
        res => {
          this.fileUpload.clearFilesList();
          this.karbejelentoFajlFeltoltesForm.controls["f_dokumentum"].setValue(null);
          resolve(res);
          this.toltoKereses = false;
        },
        msg => {
          reject(msg);
          this.toltoKereses = false;
        });
    });
  }

  biztsoitokFeltoltes() {
    this.biztositoLista = [];
    this.karbejelento.biztositok().subscribe(
      data => {
        this.biztositoLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a biztosítók lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  biztositoValtozas(event: MatSelectChange) {
    this.karbejelentoFajlFeltoltesForm.controls["f_mas_biztosito"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  feltoltottFajlTorlese(element: FormGroup) {
    this.toltoKereses = true;
    let model = {
      f_fullpath: element.get(['f_fullpath']).value
    }

    this.karbejelento.feltoltottFajlTorlese(model).subscribe(
      () => {
        this.utilityService.dialogMegnyitasa({
          success: true,
          title: 'Siker',
          message: "A törlés sikeresen megtörtént.",
          confirmButtonText: 'Rendben'          
        });
        this.feltoltottFajlokListaja();
        this.toltoKereses = false;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a fájl törlése közben!",
          confirmButtonText: 'Rendben'          
        });
        this.toltoKereses = false;
      });
  }

  karbejelentesTortentValtozas() {
    this.karbejelentoFajlFeltoltesForm.controls["f_mas_biztosito_kod"].setValue("0");
  }

}

export class AdatValidator extends Validators {
  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {

      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls['f_fajl_db'];
      control1.setErrors(null);

      if (control2_neve == "f_modozat" && control2.value == "KGFB" && control3_neve == "f_bejelento" && control3.value == "e" && (control1_neve == "f_okozo_felelos" || control1_neve == "f_okozo_vezeto") && (control1.value == null || control1.value == "-1" || control1.value == "")) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

      if (((control2_neve == "f_okozo_felelos" && control1_neve == "f_okozo_felelos_egyeb" && control2.value == "3") || (control2_neve == "f_okozo_vezeto" && control1_neve == "f_okozo_vezeto_egyeb" && control2.value == "2")) && (control1.value == null || control1.value == "")) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

      if (control2_neve == "f_modozat" && (control2.value == "OTTHON" || control2.value == "TRAFIK")) {
        if (control1_neve == "f_mas_biztositonal_bejelentes" && (control1.value == null || control1.value == "-1" || control1.value == "")) {
          control1.setErrors({ fuggosegiKotelezettseg: true });
        }
      }

      if ((control2_neve == "f_mas_biztositonal_bejelentes" && control1_neve == "f_mas_biztosito_kod" && control2.value == "I") && (control1.value == null || control1.value == "" || control1.value == "0")) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

      if (control1_neve == "f_egeszsegugyi_adatkezeles" && (control2_neve == "f_modozat" && control2.value != "CMR") && (control3_neve == "f_szemely_serultes" && control3.value == true) && control4.value > 0 && control1.value == false) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

    }
    return validator;
  }
}
