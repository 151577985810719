import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as moment from 'moment';
import { FairpayService } from '../../services/fairpay.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';

@Component({
  selector: 'fairpay-success',
  templateUrl: './fairpay-success.component.html',
  styleUrls: ['./fairpay-success.component.scss']
})
export class FairPaySuccessComponent implements OnInit, OnDestroy {

  intervalId?: number;
  fairpayId: string = "";
  transactionId: string = "";
  statusCode: string = "";
  currentStatusCode: string = "";
  isCurrentStatusFinal: boolean = true; // alapértelmezetten nem kell frissítés
  fizetesUjraUrl: string = null;

  redirectUrl: string = null;
  modozat: string = null;
  modozat2: string = null;
  itemCode: string = null;

  url: string = "";
  routerUrl: string = "";
  token: string = "";

  befizetesEredmenyForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private fairpay: FairpayService,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    private sanitized: DomSanitizer,
    private recaptchaV3Service: ReCaptchaV3Service) {

    // Kiszedjuk a route nevét
    this.routerUrl = this.router.url.split('?')[0].substring(1);
    this.url = window.location.href;

    if (this.url.lastIndexOf("&amp;") >= 0) {
      this.url = this.sanitized.bypassSecurityTrustHtml(this.url).toString();
      this.router.navigate([this.url]);
    }
    else {
      this.formGroupNew();
      this.route.queryParams.subscribe((params: Params) => {
        const _params = this.toLower(params);
        if (_params["fairpayid"]) {
          this.fairpayId = _params["fairpayid"];
        }
        if (_params["transactionId"]) {
          this.transactionId = _params["transactionid"];
        }
        if (_params["statuscode"]) {
          this.currentStatusCode = _params["statuscode"];

        }
      });

      if (Headers["Authentication"]) {
        this.token = Headers["Authentication"];
      }

      this.fizetesEredmeny();
    }
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }
    return lowerParams;
  }

  formGroupNew() {
    this.befizetesEredmenyForm = this.formBuilder.group({
      f_transactionId: new FormControl(""),
      f_osszeg: new FormControl(""),
      f_fairpayId: new FormControl(""),
      f_statusCode: new FormControl(""),
      f_statusText: new FormControl(""),
      f_hibaszoveg: new FormControl(""),
      f_eredmenyszoveg: new FormControl(""),
      f_file: new FormControl(""),
      f_filenev: new FormControl("")
    });
  }

  ngOnInit(): void {
    this.setAutoRefresh();
  }
  
  ngOnDestroy(): void {
    this.clearAutoRefresh();
  }

  refreshData() {
    console.log('refreshing page');
    // refresh current status
    this.refreshData();
  }

  clearAutoRefresh() {
    //console.log('refresh clearup');
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  setAutoRefresh() {
    this.clearAutoRefresh();
    if (!this.isCurrentStatusFinal && this.routerUrl == 'fairpay-success') {
      console.log('refresh setup');
      this.intervalId = window.setInterval(() => {
        this.refreshData();
      }, 10000);
    }
  }

  isFinalStatus(statusCode: string): boolean {
    if (statusCode == "SUCCESS" || statusCode == "USER_CANCELED" || statusCode == "CANCELED_BY_MERCHANT" || statusCode == "REJECTED" || statusCode == "DELETED" || statusCode == "TIMEOUT")
      return true;
    return false;
  }


  EredmenyLetoltes()
  {
    if (this.befizetesEredmenyForm.controls["f_file"].value && this.befizetesEredmenyForm.controls["f_filenev"].value) {
      let contentType = 'application/pdf';
      let blob = base64StringToBlob(this.befizetesEredmenyForm.controls["f_file"].value, contentType);
      saveAs(blob, this.befizetesEredmenyForm.controls["f_filenev"].value, { autoBOM: true });
    }
  }

  KoteshezVissza() {
    if (this.redirectUrl) {
      //this.router.navigate([this.redirectUrl]);
      window.location.replace(this.redirectUrl);
    }
  }

  FizetesUjra() {
    if (this.fizetesUjraUrl) {
      //this.router.navigate([this.fizetesUjraUrl]);
      window.location.replace(this.fizetesUjraUrl);

    }
  }

  // az aktuális státusz lekérdezése
  refreshCurrentStatus() {
    this.recaptchaV3Service.execute('fairpay_success').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            f_transactionId: this.transactionId,
            f_fairpayId: this.fairpayId
          };

          //this.befizetes.fizetesInditas(this.befizetesForm.controls["f_szerzazon"].value.toString(), this.routerUrl, this.rogzito, this.dazon, this.befizetesOsszegekForm.value).subscribe(
          this.fairpay.fairpayTransactionStatus(model).subscribe(
            data => {
              //feldolgozas
              this.currentStatusCode = data.statusCode;
              this.isCurrentStatusFinal = this.isFinalStatus(this.currentStatusCode);

              this.befizetesEredmenyForm.controls["f_osszeg"].setValue(data.totalAmount);
              this.befizetesEredmenyForm.controls["f_fairpayId"].setValue(data.fairpayId);
              this.befizetesEredmenyForm.controls["f_statusCode"].setValue(this.currentStatusCode);
              this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);

              var fizetesEredmenySzoveg: string = "Folyamatban";

              if (this.isCurrentStatusFinal)
              {
                if (this.currentStatusCode == "SUCCESS") {
                  fizetesEredmenySzoveg = "Sikeres utalás";
                }
                else
                {
                  fizetesEredmenySzoveg = "Sikertelen utalás";
                }
              }
              this.befizetesEredmenyForm.controls["f_eredmenyszoveg"].setValue(fizetesEredmenySzoveg);
              this.befizetesEredmenyForm.controls["f_statusText"].setValue(data.statusText);
              this.befizetesEredmenyForm.controls["f_file"].setValue(data.pdfDocument);
              this.befizetesEredmenyForm.controls["f_filenev"].setValue(data.documentName);
              this.fizetesUjraUrl = data.retryUrl;

              this.modozat = data.modozat;
              this.redirectUrl = data.redirectUrl;

            },
            errorResult => {
              this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue(errorResult);
            });
        })
      });
  }
  
  eredmenyLetoltes() {
    let contentType = 'application/pdf';
    let blob = base64StringToBlob(this.befizetesEredmenyForm.controls["f_file"].value, contentType);
    saveAs(blob, this.befizetesEredmenyForm.controls["f_filenev"].value, { autoBOM: true });
  }

  // csak az oldal betöltésekor kerül meghívásra
  fizetesEredmeny() {

    this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("");

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('fairpay_success').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(
          result => {
            let model = {
              f_transactionId: this.transactionId,
              f_fairpayId: this.fairpayId,
              f_statusCode: this.currentStatusCode,
              f_url: this.url,
              f_token: this.token
            };

            this.fairpay.fairpayResult(model).subscribe(
              data => {
                this.refreshCurrentStatus();
                this.setAutoRefresh();
              },
              errorResult => {
                this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue(errorResult);
              });
          },
          errorResult => {
            this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue(errorResult)
          });
    });
  }
}



