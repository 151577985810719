import { Component, OnInit, Output, EventEmitter, Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { UtilityService } from '@granit/common-library';
import * as moment from 'moment';
import { MatSelectChange } from '@angular/material/select';
import emailMask from 'text-mask-addons/dist/emailMask';
import { MAT_DATE_FORMATS } from '@angular/material/core';


@Component({
  selector: 'karbejelento-bejelento',
  templateUrl: './karbejelento-bejelento.component.html',
  styleUrls: ['./karbejelento-bejelento.component.scss']
})

export class KarbejelentoBejelentoComponent implements OnInit {

  @Output() karbejelentoBejelentoFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoBejelentoForm: FormGroup;

  szulTelepulesLista: any[];
  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];
  orszagLista: any[];
  eloHivoLista: any[];
  kedvEloHivoLista: any[];
  kedvEloHivoUjraLista: any[];
  kapcsolattartoEloHivoLista: any[];
  szulDatumMaxDate: string = moment(new Date()).add('years', -14).format('YYYY-MM-DD');

  phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholder = "123-1234";
  emailMask = emailMask;
  faxMask = ['+', '3', '6', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  phoneMaskKapcsolattarto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholderKapcsolattarto = "123-1234";
  emailMaskKapcsolattarto = emailMask;
  faxMaskKapcsolattarto = ['+', '3', '6', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  irszamMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  kedvAdatMask = [];
  kedvTipAdatLabel = "Adat";
  kedvAdatPlaceholder = "";
  kedvPhoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  kedvPhonePlaceholder = "123-1234";
  kedvPhoneUjraMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  kedvPhoneUjraPlaceholder = "123-1234";
  szlaBank = "";
  bejelentoNevCimke = "Név:  *"
  bejelentoLakcimCimke = "Lakcím helyszíne:"
  weboldalDokumentumok: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.link();
    this.formGroupNew();
    this.orszagFeltoltes();
    this.telefonElohivoFeltoltes();
    this.kedvTelefonElohivoFeltoltes();
    this.kedvTelefonElohivoUjraFeltoltes();
    this.telefonKapcsolattartoElohivoFeltoltes();

    this.karbejelentoBejelentoFormOut.emit(this.karbejelentoBejelentoForm);

    this.szulTelepulesFeltoltes();

    this.karbejelentoBejelentoForm.controls['f_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_telefon_tipus'].valueChanges.subscribe(value => {
      this.telefonElohivoFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_kedv_telefon_tipus'].valueChanges.subscribe(value => {
      this.kedvTelefonElohivoFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_kedv_telefon_tipus_ujra'].valueChanges.subscribe(value => {
      this.kedvTelefonElohivoUjraFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_kedv_adat_tipus'].valueChanges.subscribe(value => {
      this.kedvAdatTipusValtozas();
    });

    this.karbejelentoBejelentoForm.controls['f_kedv_adat'].valueChanges.subscribe(value => {
      this.kedvAdatValtozas();
    });

    this.karbejelentoBejelentoForm.controls['f_bejelento_tipusa'].valueChanges.subscribe(value => {
      this.bejelentoTipusValtozas();
      if (this.karbejelentoBejelentoForm.controls['f_modozat'].value == "KGFB" && this.karbejelentoBejelentoForm.controls['f_bejelento_tipusa'].value == "e") {
        this.szerzodoAdatFeltolt();
      }
    });

    this.karbejelentoBejelentoForm.controls['f_modozat'].valueChanges.subscribe(value => {
      if (this.karbejelentoBejelentoForm.controls['f_modozat'].value == "CMR" || (this.karbejelentoBejelentoForm.controls['f_modozat'].value == "KGFB" && this.karbejelentoBejelentoForm.controls['f_bejelento_tipusa'].value == "e")) {
        this.szerzodoAdatFeltolt();
      }
    });

    this.karbejelentoBejelentoForm.controls['f_telefon_tipus_kapcsolattarto'].valueChanges.subscribe(value => {
      this.telefonKapcsolattartoElohivoFeltoltes();
    });

    this.karbejelentoBejelentoForm.controls['f_modozat'].valueChanges.subscribe(value => {
      if (this.karbejelentoBejelentoForm.get('f_modozat').value == 'TRAFIK') {
        this.bejelentoNevCimke = "Név/Cégnév:  *"
        this.bejelentoLakcimCimke = "Lakcím helyszíne/Cég székhelye:"
      }
      else {
        this.bejelentoNevCimke = "Név:  *"
        if (this.karbejelentoBejelentoForm.get('f_modozat').value == 'CMR') {
          this.bejelentoLakcimCimke = "Telephely helyszíne:"
        }
        else {
          this.bejelentoLakcimCimke = "Lakcím helyszíne:"
        }
      }
    });    

  }

  async link() {

    let model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

  formGroupNew() {
    this.karbejelentoBejelentoForm = this.formBuilder.group({
      f_szerzazon: new FormControl(null),
      f_modozat: new FormControl(null),
      f_adatkezelesi_okozoi: new FormControl(true, Validators.requiredTrue),
      f_bejelento_tipusa: new FormControl("0"),
      f_nev: new FormControl(null, [Validators.required]),
      f_szul_datum: new FormControl(null),
      f_szul_hely: new FormControl("0"),
      f_cim_mo: new FormControl("I"),
      f_irsz: new FormControl(null),
      f_telepules: new FormControl("0"),
      f_utcanev: new FormControl("0"),
      f_uttipus: new FormControl("0"),
      f_hazszam: new FormControl(null),
      f_emelet: new FormControl(null),
      f_ajto: new FormControl(null),
      f_hrsz: new FormControl(null),
      f_orszag: new FormControl(null),
      f_orszag_nev: new FormControl(null),
      f_kulf_irsz: new FormControl(null),
      f_kulf_cim: new FormControl(null),
      f_telefon_tipus: new FormControl("M"),
      f_telefon_elohivo: new FormControl("0"),
      f_telefon_szam: new FormControl(null),
      f_email: new FormControl(null),
      f_fax: new FormControl(null),
      f_kedv_adat_tipus: new FormControl("0"),
      f_kedv_adat: new FormControl(null),
      f_kedv_telefon_tipus: new FormControl("M"),
      f_kedv_telefon_elohivo: new FormControl("0"),
      f_kedv_telefon_szam: new FormControl(null),
      f_kedv_adat_ujra: new FormControl(null),
      f_kedv_telefon_tipus_ujra: new FormControl("M"),
      f_kedv_telefon_elohivo_ujra: new FormControl("0"),
      f_kedv_telefon_szam_ujra: new FormControl(null),
      f_uttipus_van: new FormControl(false),
      f_nev_kapcsolattarto: new FormControl(null),
      f_telefon_tipus_kapcsolattarto: new FormControl("M"),
      f_telefon_elohivo_kapcsolattarto: new FormControl("0"),
      f_telefon_szam_kapcsolattarto: new FormControl(null),
      f_email_kapcsolattarto: new FormControl(null),
      f_fax_kapcsolattarto: new FormControl(null),
      f_ugyfelportal: new FormControl(false)
    },
      {
        validators: [AdatValidator.keremValasszon('f_telepules', ''),
        AdatValidator.keremValasszon('f_utcanev', ''),
        AdatValidator.keremValasszon('f_uttipus', 'f_uttipus_van'),
        AdatValidator.keremValasszon('f_bejelento_tipusa', ''),
        AdatValidator.fuggosegiKotelezettseg('f_irsz', 'f_cim_mo', ''),
        AdatValidator.fuggosegiKotelezettseg('f_hazszam', 'f_cim_mo', 'f_hrsz'),
        AdatValidator.fuggosegiKotelezettseg('f_kulf_irsz', 'f_cim_mo', ''),
        AdatValidator.fuggosegiKotelezettseg('f_kulf_cim', 'f_cim_mo', ''),
        AdatValidator.fuggosegiKotelezettseg('f_telefon_elohivo', '', ''),
        AdatValidator.telefonszam('f_telefon_szam', 'f_telefon_tipus', 'f_telefon_elohivo', '', ''),
        AdatValidator.telefonszam('f_fax', '', '', '', ''),
        AdatValidator.fuggosegiKotelezettseg('f_kedv_adat_tipus', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_kedv_telefon_elohivo', 'f_kedv_adat_tipus', 'f_modozat'),
        AdatValidator.telefonszam('f_kedv_telefon_szam', 'f_kedv_telefon_tipus', 'f_kedv_telefon_elohivo', 'f_modozat', 'f_kedv_adat_tipus'),
        AdatValidator.fuggosegiKotelezettseg('f_kedv_adat', 'f_kedv_adat_tipus', 'f_modozat'),
        AdatValidator.fuggosegiKotelezettseg('f_kedv_adat_ujra', 'f_kedv_adat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_email', '', 'f_modozat'),
        AdatValidator.kedvTelefonUjra('f_kedv_telefon_szam_ujra', 'f_kedv_telefon_elohivo_ujra', 'f_kedv_telefon_szam', 'f_kedv_telefon_elohivo', 'f_modozat', 'f_kedv_adat_tipus'),
        AdatValidator.fuggosegiKotelezettseg('f_telefon_elohivo_kapcsolattarto', '', ''),
        AdatValidator.telefonszam('f_telefon_szam_kapcsolattarto', 'f_telefon_tipus_kapcsolattarto', 'f_telefon_elohivo_kapcsolattarto', 'f_modozat', ''),
        AdatValidator.telefonszam('f_fax_kapcsolattarto', '', '', '', ''),]
      });
  }

  szerzodoAdatFeltolt() {
    if (this.karbejelentoBejelentoForm.controls['f_modozat'].value == "CMR") {
      this.karbejelentoBejelentoForm.controls['f_bejelento_tipusa'].setValue("t");
    }
    /*if (this.karbejelentoBejelentoForm.controls['f_modozat'].value == "CMR") {
      this.karbejelentoBejelentoForm.controls['f_bejelento_tipusa'].setValue("t");
    }*/

    if (this.karbejelentoBejelentoForm.controls['f_szerzazon'].value) {

      let model = {
        f_szerzazon: this.karbejelentoBejelentoForm.controls['f_szerzazon'].value
      }

      this.karbejelento.szerzodo(model).subscribe(
        data => {
          this.karbejelentoBejelentoForm.controls['f_nev'].setValue(data["f_ugyfelnev"]);
          this.karbejelentoBejelentoForm.controls['f_cim_mo'].setValue("I");
          this.karbejelentoBejelentoForm.controls['f_irsz'].setValue(data["f_irszam"]);
          this.karbejelentoBejelentoForm.controls['f_telepules'].setValue(data["f_helynev"]);
          this.karbejelentoBejelentoForm.controls['f_utcanev'].setValue(data["f_utcanev"]);
          this.karbejelentoBejelentoForm.controls['f_uttipus'].setValue(data["f_uttipus"]);
          this.karbejelentoBejelentoForm.controls['f_hazszam'].setValue(data["f_hazszam"]);
          this.karbejelentoBejelentoForm.controls['f_emelet'].setValue(data["f_emelet"]);
          this.karbejelentoBejelentoForm.controls['f_ajto'].setValue(data["f_ajto"]);
          if (this.karbejelentoBejelentoForm.controls['f_telefon_szam'].value == null) {
            this.karbejelentoBejelentoForm.controls['f_telefon_tipus'].setValue("M");
            if (data["f_tel_mobil"]) {
              this.karbejelentoBejelentoForm.controls['f_telefon_elohivo'].setValue(data["f_tel_mobil"].toString().substring(0, 2));
              this.karbejelentoBejelentoForm.controls['f_telefon_szam'].setValue(data["f_tel_mobil"].toString().substring(2));
            }
          }
          if (this.karbejelentoBejelentoForm.controls['f_email'].value == null) {
            this.karbejelentoBejelentoForm.controls['f_email'].setValue(data["f_email"]);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a szerződő adatainak lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  bejelentoTipusValtozas() {
    if (this.karbejelentoBejelentoForm.controls['f_modozat'].value == "KGFB" && this.karbejelentoBejelentoForm.controls['f_bejelento_tipusa'].value == "e") {
      this.karbejelentoBejelentoForm.controls['f_adatkezelesi_okozoi'].setValue(false);
    }
    else {
      this.karbejelentoBejelentoForm.controls['f_adatkezelesi_okozoi'].setValue(true);
    }
  }

  szulTelepulesFeltoltes() {
    this.szulTelepulesLista = [];
    this.karbejelentoBejelentoForm.controls["f_szul_hely"].setValue("0");
    this.karbejelento.osszTelepules().subscribe(
      data => {
        this.szulTelepulesLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a települések lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  telepulesFeltoltes() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoBejelentoForm.controls["f_telepules"].setValue("0");
    if (this.karbejelentoBejelentoForm.controls["f_irsz"].value && this.karbejelentoBejelentoForm.controls["f_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoBejelentoForm.controls["f_irsz"].value
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a települések lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  utcanevFeltoltes() {
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoBejelentoForm.controls["f_utcanev"].setValue("0");
    if (this.karbejelentoBejelentoForm.controls["f_telepules"].value && this.karbejelentoBejelentoForm.controls["f_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoBejelentoForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoBejelentoForm.controls["f_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az utcanevek lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  uttipusFeltoltes() {
    this.uttipusLista = [];
    this.karbejelentoBejelentoForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoBejelentoForm.controls["f_uttipus"].setValue("0");
    if (this.karbejelentoBejelentoForm.controls["f_utcanev"].value && this.karbejelentoBejelentoForm.controls["f_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoBejelentoForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoBejelentoForm.controls["f_telepules"].value,
        f_utcanev: this.karbejelentoBejelentoForm.controls["f_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusLista = data;
            this.karbejelentoBejelentoForm.controls["f_uttipus_van"].setValue(true);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az úttipusok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  orszagFeltoltes() {
    this.karbejelento.orszagok().subscribe(
      data => {
        this.orszagLista = data;
        this.karbejelentoBejelentoForm.controls["f_orszag"].setValue("0");
        this.karbejelentoBejelentoForm.controls["f_orszag_nev"].setValue(null);
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az országok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });

  }

  telefonBeallitas() {
    this.karbejelentoBejelentoForm.controls["f_telefon_szam"].setValue(null);
    if (this.karbejelentoBejelentoForm.controls["f_telefon_tipus"].value == "M") {
      this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholder = "123-1234";
    }
    if (this.karbejelentoBejelentoForm.controls["f_telefon_tipus"].value == "V") {
      if (this.karbejelentoBejelentoForm.controls["f_telefon_elohivo"].value == "01") {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-1234";
      }
      else {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-123";
      }
    }
    if (this.karbejelentoBejelentoForm.controls["f_telefon_tipus"].value == "K") {
      this.phonePlaceholder = "";
    }
  }

  telefonElohivoFeltoltes() {
    this.telefonBeallitas();
    this.eloHivoLista = [];
    this.karbejelentoBejelentoForm.controls["f_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoBejelentoForm.controls["f_telefon_tipus"].value).subscribe(
      data => {
        this.eloHivoLista = data;
      })

  }

  kedvTelefonBeallitas() {
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_szam"].setValue(null);
    if (this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus"].value == "M") {
      this.kedvPhoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.kedvPhonePlaceholder = "123-1234";
    }
    if (this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus"].value == "V") {
      if (this.karbejelentoBejelentoForm.controls["f_kedv_telefon_elohivo"].value == "01") {
        this.kedvPhoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.kedvPhonePlaceholder = "123-1234";
      }
      else {
        this.kedvPhoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.kedvPhonePlaceholder = "123-123";
      }
    }
  }

  kedvTelefonElohivoFeltoltes() {
    this.kedvTelefonBeallitas();
    this.kedvEloHivoLista = [];
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus"].value).subscribe(
      data => {
        this.kedvEloHivoLista = data;
      })

  }

  kedvTelefonUjraBeallitas() {
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_szam_ujra"].setValue(null);
    if (this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus_ujra"].value == "M") {
      this.kedvPhoneUjraMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.kedvPhoneUjraPlaceholder = "123-1234";
    }
    if (this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus_ujra"].value == "V") {
      if (this.karbejelentoBejelentoForm.controls["f_kedv_telefon_elohivo_ujra"].value == "01") {
        this.kedvPhoneUjraMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.kedvPhoneUjraPlaceholder = "123-1234";
      }
      else {
        this.kedvPhoneUjraMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.kedvPhoneUjraPlaceholder = "123-123";
      }
    }
  }

  kedvTelefonElohivoUjraFeltoltes() {
    this.kedvTelefonUjraBeallitas();
    this.kedvEloHivoUjraLista = [];
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_elohivo_ujra"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus_ujra"].value).subscribe(
      data => {
        this.kedvEloHivoUjraLista = data;
      })

  }

  orszagTipusValtozas() {
    if (this.karbejelentoBejelentoForm.controls["f_cim_mo"].value == "I") {
      this.kulfoldiCimUrites();
      this.karbejelentoBejelentoForm.controls["f_orszag"].setErrors(null);
      this.karbejelentoBejelentoForm.controls["f_kulf_irsz"].setErrors(null);
      this.karbejelentoBejelentoForm.controls["f_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUrites();
      this.karbejelentoBejelentoForm.controls["f_irsz"].setErrors(null);
      this.karbejelentoBejelentoForm.controls["f_telepules"].setErrors(null);
      this.karbejelentoBejelentoForm.controls["f_utcanev"].setErrors(null);
      this.karbejelentoBejelentoForm.controls["f_uttipus"].setErrors(null);
      this.karbejelentoBejelentoForm.controls["f_hazszam"].setErrors(null);
    }
  }

  orszagValtozas(event: MatSelectChange) {
    this.karbejelentoBejelentoForm.controls["f_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  eloHivoValtozas() {
    this.telefonBeallitas();
  }

  kedvEloHivoValtozas() {
    this.kedvTelefonBeallitas();
  }

  kedvEloHivoUjraValtozas() {
    this.kedvTelefonUjraBeallitas();
  }

  kedvAdatTipusValtozas() {

    this.karbejelentoBejelentoForm.controls["f_kedv_adat"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_kedv_adat_ujra"].setValue(null);


    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus"].setValue("M");
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_elohivo"].setValue("0");
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_szam"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_tipus_ujra"].setValue("M");
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_elohivo_ujra"].setValue("0");
    this.karbejelentoBejelentoForm.controls["f_kedv_telefon_szam_ujra"].setValue(null);

    this.szlaBank = "";

    switch (this.karbejelentoBejelentoForm.controls["f_kedv_adat_tipus"].value) {
      case "szla":
        this.kedvTipAdatLabel = "Számlaszám:"
        this.kedvAdatMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        this.kedvAdatPlaceholder = "1234 1234 1234 1234 1234 1234"
        break;
      case "tel":
        this.kedvTipAdatLabel = "Telefonszám:"
        break;
      case "mail":
        this.kedvTipAdatLabel = "E-mail cím:"
        this.kedvAdatMask = emailMask;
        this.kedvAdatPlaceholder = "pelda@email.hu";
        break;
      case "ado":
        this.kedvTipAdatLabel = "Adóazonosítójel:"
        this.kedvAdatMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        this.kedvAdatPlaceholder = "1234567890";
        break;
    }
  }

  pontosCimUrites() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];

    if (this.karbejelentoBejelentoForm.controls["f_cim_mo"].value == "K") {
      this.karbejelentoBejelentoForm.controls["f_helyszin_tipus"].setValue("P");
    }
    this.karbejelentoBejelentoForm.controls["f_irsz"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_telepules"].setValue("0");
    this.karbejelentoBejelentoForm.controls["f_utcanev"].setValue("0");
    this.karbejelentoBejelentoForm.controls["f_uttipus"].setValue("0");
    this.karbejelentoBejelentoForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoBejelentoForm.controls["f_hazszam"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_emelet"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_ajto"].setValue(null);
  }

  kulfoldiCimUrites() {
    this.orszagLista = [];

    this.karbejelentoBejelentoForm.controls["f_orszag"].setValue("0");
    this.karbejelentoBejelentoForm.controls["f_orszag_nev"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_kulf_irsz"].setValue(null);
    this.karbejelentoBejelentoForm.controls["f_kulf_cim"].setValue(null);
  }

  kedvAdatValtozas() {
    if (this.karbejelentoBejelentoForm.controls["f_kedv_adat_tipus"].value == "szla" && this.karbejelentoBejelentoForm.controls["f_kedv_adat"].value) {
      if (this.karbejelentoBejelentoForm.controls["f_kedv_adat"].value.split("_").join("").split(" ").join("").length >= 8) {

        let model = {
          f_szla: this.karbejelentoBejelentoForm.controls["f_kedv_adat"].value.split("_").join("").split(" ").join("").substring(0, 8)
        }

        this.karbejelento.szlaEllenor(model).subscribe(
          data => {
            if (data == null) {
              this.szlaBank = "";
              this.karbejelentoBejelentoForm.controls["f_kedv_adat"].setErrors({ szlaHiba: true });
            }
            else {
              this.szlaBank = data["f_bank"];
            }
          },
          () => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a számlaszám ellenőrzése köben!",
              confirmButtonText: 'Rendben'
            });
          });
      }
      else {
        this.szlaBank = "";
      }
    }
  }

  eloHivoValtozasKapcsolattarto() {
    this.telefonBeallitasKapcsolattarto();
  }

  telefonBeallitasKapcsolattarto() {
    this.karbejelentoBejelentoForm.controls["f_telefon_szam_kapcsolattarto"].setValue(null);
    if (this.karbejelentoBejelentoForm.controls["f_telefon_tipus_kapcsolattarto"].value == "M") {
      this.phoneMaskKapcsolattarto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholderKapcsolattarto = "123-1234";
    }
    if (this.karbejelentoBejelentoForm.controls["f_telefon_tipus_kapcsolattarto"].value == "V") {
      if (this.karbejelentoBejelentoForm.controls["f_telefon_elohivo_kapcsolattarto"].value == "01") {
        this.phoneMaskKapcsolattarto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholderKapcsolattarto = "123-1234";
      }
      else {
        this.phoneMaskKapcsolattarto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholderKapcsolattarto = "123-123";
      }
    }
    if (this.karbejelentoBejelentoForm.controls["f_telefon_tipus_kapcsolattarto"].value == "K") {
      this.phonePlaceholderKapcsolattarto = "";
    }
  }

  telefonKapcsolattartoElohivoFeltoltes() {
    this.telefonBeallitasKapcsolattarto();
    this.kapcsolattartoEloHivoLista = [];
    this.karbejelentoBejelentoForm.controls["f_telefon_elohivo_kapcsolattarto"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoBejelentoForm.controls["f_telefon_tipus_kapcsolattarto"].value).subscribe(
      data => {
        this.kapcsolattartoEloHivoLista = data;
      })
  }

}

export class AdatValidator extends Validators {


  static isDate(control_neve: string) {
    let validator = (formGroup: FormGroup) => {
      const control = formGroup.controls[control_neve];
      if (control.value != null) {
        let isDate = false;
        isDate = control.value && !isNaN(Date.parse(control.value));
        if (!isDate) {
          control.setErrors({ isDate: true });
        }
        else {
          control.setErrors(null);
        }
      }
    }
    return validator;
  }

  static keremValasszon(control1_neve: string, control2_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls["f_cim_mo"];
      if (control3.value == "I") {
        if ((control2_neve == "" || (control2_neve == "f_uttipus_van" && control2.value == true)) &&
          (control1_neve == "f_telepules" ||
            control1_neve == "f_utcanev" ||
            control1_neve == "f_uttipus" ||
            control1_neve == "f_bejelento_tipusa") && control1.value == "0") {
          hasError = true;
        }
      }
      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      control1.setErrors(null);

      if (control2_neve == "f_cim_mo" && control2.value != "I" && (control1_neve == "f_kulf_irsz" || control1_neve == "f_kulf_cim") && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_irsz" && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_hazszam" && control3_neve == "f_hrsz" && ((control1.value == null || control1.value == '') && (control3.value == null || control3.value == ''))) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
        control1.setErrors({ irszamHossz: true });
      }

      if (control2_neve == "f_telefon_tipus" && control2.value != "K" && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }

      if (control2_neve == "f_telefon_tipus_kapcsolattarto" && control2.value != "K" && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }

      if (control1_neve == "f_telefon_elohivo" && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }

      if (control2_neve == "f_kedv_telefon_tipus" && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }

      if (control1_neve == "f_kedv_adat_tipus" && (control2.value == "OTTHON" || control2.value == "TRAFIK") && (control1.value == null || control1.value == '0')) {
        hasError = true;
      }

      if (control3_neve == "f_modozat" && (control3.value == "OTTHON" || control3.value == "TRAFIK")) {
        if (control1_neve == "f_kedv_adat" && control2.value != "tel") {
          if (control1.value) {
            if (control2.value == "mail") {
              if (control1.value.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$") == null) {
                hasError = true;
              }
            }
            if (control2.value == "szla") {
              if (!(control1.value.split("_").join("").split(" ").join("").length == 16 || control1.value.split("_").join("").split(" ").join("").length == 24)) {
                hasError = true;
              }
            }
            if (control2.value == "ado") {
              if (control1.value.split("_").join("").length != 10) {
                hasError = true;
              }
            }
          }
          else {
            hasError = true;
          }
        }

        if (control1_neve == "f_kedv_telefon_elohivo" && control2.value == "tel") {
          if (control1.value == null || control1.value == "0") {
            hasError = true;
          }
        }

      }

      if (control1_neve == "f_kedv_adat_ujra" && control2.value) {
        if (control1.value) {
          if (control1.value.split("_").join("").split(" ").join("") != control2.value.split("_").join("").split(" ").join("")) {
            hasError = true;
          }
        }
        else {
          hasError = true;
        }
      }

      if (control3_neve == "f_modozat" && control3.value == "CMR") {
        if (control1_neve == "f_email" && (control1.value == null || (control1.value == ""))) {
          hasError = true;
        }
      }

      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

    }
    return validator;
  }

  static telefonszam(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string, control5_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      const control5 = formGroup.controls[control5_neve];
      control1.setErrors(null);


      if (control1_neve == "f_fax" && control1.value) {

        if (control1.value.split("_").join("").length < 15) {
          control1.setErrors({ fax: true });
        }

      }

      if (control1_neve == "f_telefon_szam") {
        if (control1.value == null || control1.value == "") {
          control1.setErrors({ telefonszamUres: true });
        }
        else {


          if (control2.value != "K") {

            if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
              if (control1.value.split("_").join("").split("-").join("").length != 7) {
                control1.setErrors({ telefonszam: true });
              }
            }

            if (control2.value == "V" && control3.value != "01") {
              if (control1.value.split("_").join("").split("-").join("").length != 6) {
                control1.setErrors({ telefonszam: true });
              }
            }

          }
          else {
            if (control1.value.split("_").join("").length < 6 || control1.value.split("_").join("").length > 20) {
              control1.setErrors({ telefonszamKulfHossz: true });
            }
            else {

              if (control1.value.match("^[0-9/+-]+$") == null) {
                control1.setErrors({ telefonszamKulf: true });
              }
            }
          }
        }
      }

      if (control4_neve == "f_modozat" && (control4.value == "OTTHON" || control4.value == "TRAFIK") && control5_neve != "" && control5.value == "tel") {
        if (control1_neve == "f_kedv_telefon_szam") {
          if (control1.value == null || control1.value == "") {
            control1.setErrors({ telefonszamUres: true });
          }
          else {
            if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
              if (control1.value.split("_").join("").split("-").join("").length != 7) {
                control1.setErrors({ telefonszam: true });
              }
            }

            if (control2.value == "V" && control3.value != "01") {
              if (control1.value.split("_").join("").split("-").join("").length != 6) {
                control1.setErrors({ telefonszam: true });
              }
            }
          }
        }
      }

      if (control4_neve == "f_modozat" && control4.value == "CMR") {
        if (control1_neve == "f_fax_kapcsolattarto" && control1.value) {

          if (control1.value.split("_").join("").length < 15) {
            control1.setErrors({ fax: true });
          }

        }

        if (control1_neve == "f_telefon_szam_kapcsolattarto") {
          if (control1.value != null && control1.value != "") {

            if (control2.value != "K") {
              if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
                if (control1.value.split("_").join("").split("-").join("").length != 7) {
                  control1.setErrors({ telefonszam: true });
                }
              }

              if (control2.value == "V" && control3.value != "01") {
                if (control1.value.split("_").join("").split("-").join("").length != 6) {
                  control1.setErrors({ telefonszam: true });
                }
              }

            }
            else {
              if (control1.value.split("_").join("").length < 6 || control1.value.split("_").join("").length > 20) {
                control1.setErrors({ telefonszamKulfHossz: true });
              }
              else {

                if (control1.value.match("^[0-9/+-]+$") == null) {
                  control1.setErrors({ telefonszamKulf: true });
                }
              }
            }
          }
        }
      }

    }
    return validator;
  }

  static kedvTelefonUjra(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string, control5_neve: string, control6_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      const control5 = formGroup.controls[control5_neve];
      const control6 = formGroup.controls[control6_neve];
      control1.setErrors(null);

      if ((control5.value == "OTTHON" || control5.value == "TRAFIK") && control6.value == "tel") {
        if (control1.value == null || control1.value == "") {
          control1.setErrors({ kedvTelefonUjra: true });
        }
        else {

          let kedvTelo: string = "";
          kedvTelo += control4.value.split("_").join("");
          kedvTelo += (control3.value == null || control3.value == "") ? "" : control3.value.split("_").join("").split("-").join("");

          let kedvTeloUjra: string = "";
          kedvTeloUjra += control2.value.split("_").join("");
          kedvTeloUjra += (control1.value == null || control1.value == "") ? "" : control1.value.split("_").join("").split("-").join("");

          if (kedvTelo != kedvTeloUjra) {
            control1.setErrors({ kedvTelefonUjra: true });
          }
        }
      }
    }
    return validator;
  }
}
