import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FairpayService {
  private url: string = "/api/fairpay";
  
  private messageSource = new BehaviorSubject<string>("");
  focimke = this.messageSource.asObservable();

  constructor(
    private httpClient: HttpClient) { }

  // fizetés eredmény oldalhívás paramétereinek feldolgozása
  fairpayResult(model: any) {
    return this.httpClient.post<any>(this.url + "/FairPayResult", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // új fizetés indítása
  newFairpayTransaction(model: any) {
    return this.httpClient.post<any>(this.url + "/NewFairpayTransaction", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // aktuális státusz lekérdezése
  fairpayTransactionStatus(model: any) {
    return this.httpClient.post<any>(this.url + "/FairpayTransactionStatus", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  // üzemszünet lekérdezése
  fairpayUzemSzunet() {
    return this.httpClient.post<any[]>(this.url + "/FairpayUzemSzunet", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }
}
