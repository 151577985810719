import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { MatSelectChange } from '@angular/material/select';
import { UtilityService } from '@granit/common-library';

@Component({
  selector: 'karbejelento-szemle',
  templateUrl: './karbejelento-szemle.component.html',
  styleUrls: ['./karbejelento-szemle.component.scss']
})
export class KarbejelentoSzemleComponent implements OnInit {

  @Output() karbejelentoSzemleFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoSzemleForm: FormGroup;

  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];
  orszagLista: any[];
  irszamMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  tooltipLabel = "";
  egyezikLabel = null;
  maxCharMegjegyzes: number = 600;

  constructor(
    private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService) { }

  ngOnInit() {
    this.formGroupNew();
    this.karbejelentoSzemleFormOut.emit(this.karbejelentoSzemleForm);

    this.karbejelentoSzemleForm.controls['f_helyszin_tipus'].valueChanges.subscribe(value => {
      this.helyszinTipusValtozas();
    });

    this.karbejelentoSzemleForm.controls['f_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltes();
    });

    this.karbejelentoSzemleForm.controls['f_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltes();
    });

    this.karbejelentoSzemleForm.controls['f_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltes();
    });

    this.karbejelentoSzemleForm.controls['f_modozat'].valueChanges.subscribe(value => {
      if (this.karbejelentoSzemleForm.controls['f_modozat'].value == "CMR") {
        this.tooltipLabel = "A sérült áru jelenlegi elérhetősége, ahol az szakértő által szemlézhető.";
      }

      if (this.karbejelentoSzemleForm.controls['f_modozat'].value == "CASCO") {
        this.tooltipLabel = "Ahol a sérült jármű munkaidőben megtekinthető.";
      }

      switch (this.karbejelentoSzemleForm.controls['f_modozat'].value) {
        case "OTTHON":
          this.egyezikLabel = "Az adatok megegyeznek a káresemény helyszínével";
          break;
        case "TRAFIK":
          this.egyezikLabel = "Az adatok megegyeznek a káresemény helyszínével";
          break;
        case "CMR":
          this.egyezikLabel = "Az adatok megegyeznek a károsult adataiva";
          break;
        default:
          this.egyezikLabel = "Az adatok megegyeznek a bejelentő adataival";
          break;
      }      
    });

    this.karbejelentoSzemleForm.controls['f_kartipus'].valueChanges.subscribe(value => {
      if (this.karbejelentoSzemleForm.controls['f_modozat'].value == "KGFB") {

        if (this.karbejelentoSzemleForm.controls['f_kartipus'].value != null) {

          if (this.karbejelentoSzemleForm.controls['f_kartipus'].value.indexOf("E56101") > -1 ||
            this.karbejelentoSzemleForm.controls['f_kartipus'].value.indexOf("E56201") > -1) {
            this.tooltipLabel = "Ahol a sérült jármű munkaidőben megtekinthető.";
          }
          else {
            this.tooltipLabel = "Ahol a sérült jármű vagyontárgy munkaidőben megtekinthető.";
          }
        }
      }
    });

    this.orszagFeltoltes();
  }

  formGroupNew() {
    this.karbejelentoSzemleForm = this.formBuilder.group({
      f_azonosito: new FormControl(null),
      f_modozat: new FormControl(null),
      f_kartipus: new FormControl(null),
      f_egyezik: new FormControl(false),
      f_cim_mo: new FormControl("I"),
      f_helyszin_tipus: new FormControl("P"),
      f_irsz: new FormControl(null),
      f_telepules: new FormControl("0"),
      f_utcanev: new FormControl("0"),
      f_uttipus: new FormControl("0"),
      f_hazszam: new FormControl(null),
      f_emelet: new FormControl(null),
      f_ajto: new FormControl(null),
      f_hrsz: new FormControl(null),
      f_kulterulet: new FormControl(null),
      f_orszag: new FormControl(null),
      f_orszag_nev: new FormControl(null),
      f_kulf_irsz: new FormControl(null),
      f_kulf_cim: new FormControl(null),
      f_kapcsolattarto_neve: new FormControl(null),
      f_kapcsolattarto_elerhetosege: new FormControl(null),
      f_megjegyzes: new FormControl(null),
      f_uttipus_van: new FormControl(false)
    }, {
      validators: [AdatValidator.fuggosegiKotelezettseg('f_orszag', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_telepules', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_utcanev', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_uttipus', 'f_cim_mo', 'f_helyszin_tipus', 'f_uttipus_van'),
      AdatValidator.fuggosegiKotelezettseg('f_irsz', 'f_cim_mo', 'f_helyszin_tipus', ''),
      AdatValidator.fuggosegiKotelezettseg('f_hazszam', 'f_cim_mo', 'f_helyszin_tipus', 'f_hrsz'),
      AdatValidator.fuggosegiKotelezettseg('f_kulf_irsz', 'f_cim_mo', '', ''),
      AdatValidator.fuggosegiKotelezettseg('f_kulf_cim', 'f_cim_mo', '', ''),
      AdatValidator.fuggosegiKotelezettseg('f_kulterulet', 'f_helyszin_tipus', '', ''),
      AdatValidator.elerhetosegEllenor('f_kapcsolattarto_elerhetosege')]
    });
  }

  telepulesFeltoltes() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoSzemleForm.controls["f_telepules"].setValue("0");
    if (this.karbejelentoSzemleForm.controls["f_irsz"].value &&
      this.karbejelentoSzemleForm.controls["f_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoSzemleForm.controls["f_irsz"].value.split("_").join("")
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a települések lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  utcanevFeltoltes() {
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoSzemleForm.controls["f_utcanev"].setValue("0");
    if (this.karbejelentoSzemleForm.controls["f_telepules"].value && this.karbejelentoSzemleForm.controls["f_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoSzemleForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoSzemleForm.controls["f_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevLista = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az utcanevek lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  uttipusFeltoltes() {
    this.uttipusLista = [];
    this.karbejelentoSzemleForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoSzemleForm.controls["f_uttipus"].setValue("0");
    if (this.karbejelentoSzemleForm.controls["f_utcanev"].value && this.karbejelentoSzemleForm.controls["f_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoSzemleForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoSzemleForm.controls["f_telepules"].value,
        f_utcanev: this.karbejelentoSzemleForm.controls["f_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusLista = data;
            this.karbejelentoSzemleForm.controls["f_uttipus_van"].setValue(true);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az úttipusok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  orszagFeltoltes() {
    this.karbejelento.orszagok().subscribe(
      data => {
        this.orszagLista = data;
        this.karbejelentoSzemleForm.controls["f_orszag"].setValue("0");
        this.karbejelentoSzemleForm.controls["f_orszag_nev"].setValue(null);
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az országok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });

  }

  telepulesValtozas() {
    this.utcanevLista = [];
    this.uttipusLista = [];
    if (this.karbejelentoSzemleForm.controls["f_telepules"].value != "0") {
      this.utcanevFeltoltes();
    }
  }

  utcanevValtozas() {
    this.uttipusLista = [];
    if (this.karbejelentoSzemleForm.controls["f_utcanev"].value != "0") {
      this.uttipusFeltoltes();
    }
  }

  orszagValtozas(event: MatSelectChange) {
    this.karbejelentoSzemleForm.controls["f_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  pontosCimUrites() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];

    if (this.karbejelentoSzemleForm.controls["f_cim_mo"].value == "K") {
      this.karbejelentoSzemleForm.controls["f_helyszin_tipus"].setValue("P");
    }
    this.karbejelentoSzemleForm.controls["f_irsz"].setValue(null);
    this.karbejelentoSzemleForm.controls["f_telepules"].setValue("0");
    this.karbejelentoSzemleForm.controls["f_utcanev"].setValue("0");
    this.karbejelentoSzemleForm.controls["f_uttipus"].setValue("0");
    this.karbejelentoSzemleForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoSzemleForm.controls["f_hazszam"].setValue(null);
    this.karbejelentoSzemleForm.controls["f_emelet"].setValue(null);
    this.karbejelentoSzemleForm.controls["f_ajto"].setValue(null);
    this.karbejelentoSzemleForm.controls["f_hrsz"].setValue(null);
  }

  kozteruletCimUrites() {
    this.karbejelentoSzemleForm.controls["f_kulterulet"].setValue(null);
  }

  kulfoldiCimUrites() {
    this.orszagLista = [];

    this.karbejelentoSzemleForm.controls["f_orszag"].setValue("0");
    this.karbejelentoSzemleForm.controls["f_orszag_nev"].setValue(null);
    this.karbejelentoSzemleForm.controls["f_kulf_irsz"].setValue(null);
    this.karbejelentoSzemleForm.controls["f_kulf_cim"].setValue(null);
  }

  orszagTipusValtozas() {
    if (this.karbejelentoSzemleForm.controls["f_cim_mo"].value == "I") {
      this.kulfoldiCimUrites();
      this.karbejelentoSzemleForm.controls["f_orszag"].setErrors(null);
      this.karbejelentoSzemleForm.controls["f_kulf_irsz"].setErrors(null);
      this.karbejelentoSzemleForm.controls["f_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUrites();
      this.kozteruletCimUrites();
      this.karbejelentoSzemleForm.controls["f_irsz"].setErrors(null);
      this.karbejelentoSzemleForm.controls["f_telepules"].setErrors(null);
      this.karbejelentoSzemleForm.controls["f_utcanev"].setErrors(null);
      this.karbejelentoSzemleForm.controls["f_uttipus"].setErrors(null);
      this.karbejelentoSzemleForm.controls["f_hazszam"].setErrors(null);
    }
  }

  helyszinTipusValtozas() {
    if (this.karbejelentoSzemleForm.controls["f_helyszin_tipus"].value == "P") {
      this.kozteruletCimUrites();
    }
    else {
      this.pontosCimUrites();
    }
  }

  egyezikValtozas() {
    if (this.karbejelentoSzemleForm.controls["f_egyezik"].value == true) {
      if (this.karbejelentoSzemleForm.controls["f_modozat"].value == "OTTHON" || this.karbejelentoSzemleForm.controls["f_modozat"].value == "TRAFIK") {

        let model = {
          f_azonosito: this.karbejelentoSzemleForm.controls["f_azonosito"].value          
        }

        this.karbejelento.karesemeny(model).subscribe(
          data => {
            if (data != null) {
              this.karbejelentoSzemleForm.controls["f_cim_mo"].setValue(data["f_cim_mo"]);
              //this.karbejelentoSzemleForm.controls["f_helyszin_tipus"].setValue(null"]);
              this.karbejelentoSzemleForm.controls["f_irsz"].setValue(data["f_helyszin_irszam"]);
              this.karbejelentoSzemleForm.controls["f_telepules"].setValue(data["f_helyszin_telepules"]);
              this.karbejelentoSzemleForm.controls["f_utcanev"].setValue(data["f_helyszin_utcanev"]);
              this.karbejelentoSzemleForm.controls["f_uttipus"].setValue(data["f_helyszin_uttipus"]);
              this.karbejelentoSzemleForm.controls["f_uttipus_van"].setValue(data["f_helyszin_uttipus"] != "" && data["f_helyszin_uttipus"] != "0");
              this.karbejelentoSzemleForm.controls["f_hazszam"].setValue(data["f_helyszin_hazszam"]);
              this.karbejelentoSzemleForm.controls["f_emelet"].setValue(data["f_helyszin_emelet"]);
              this.karbejelentoSzemleForm.controls["f_ajto"].setValue(data["f_helyszin_ajto"]);
              this.karbejelentoSzemleForm.controls["f_hrsz"].setValue(data["f_helyszin_hrsz"]);
              //this.karbejelentoSzemleForm.controls["f_kulterulet"].setValue(data["f_cim_mo"]);
              this.karbejelentoSzemleForm.controls["f_orszag"].setValue(data["f_kulf_orszag"]);
              this.karbejelentoSzemleForm.controls["f_orszag_nev"].setValue(data["f_kulf_orszag_nev"]);
              this.karbejelentoSzemleForm.controls["f_kulf_irsz"].setValue(data["f_kulf_irszam"]);
              this.karbejelentoSzemleForm.controls["f_kulf_cim"].setValue(data["f_kulf_cim"]);
            }
          },
          () => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a káresemény adatainak lekérdezése közben!",
              confirmButtonText: 'Rendben'
            });
          });
      }
      if (this.karbejelentoSzemleForm.controls["f_modozat"].value == "CMR") {

        let model = {
          f_azonosito: this.karbejelentoSzemleForm.controls["f_azonosito"].value          
        }

        this.karbejelento.karosult(model).subscribe(
          data => {
            if (data != null) {
              this.karbejelentoSzemleForm.controls["f_cim_mo"].setValue(data["f_cim_mo"]);
              //this.karbejelentoSzemleForm.controls["f_helyszin_tipus"].setValue(null"]);
              this.karbejelentoSzemleForm.controls["f_irsz"].setValue(data["f_cim_irszam"]);
              this.karbejelentoSzemleForm.controls["f_telepules"].setValue(data["f_cim_telepules"]);
              this.karbejelentoSzemleForm.controls["f_utcanev"].setValue(data["f_cim_utcanev"]);
              this.karbejelentoSzemleForm.controls["f_uttipus"].setValue(data["f_cim_uttipus"]);
              this.karbejelentoSzemleForm.controls["f_uttipus_van"].setValue(data["f_cim_uttipus"] != "" && data["f_cim_uttipus"] != "0");
              this.karbejelentoSzemleForm.controls["f_hazszam"].setValue(data["f_cim_hazszam"]);
              this.karbejelentoSzemleForm.controls["f_emelet"].setValue(data["f_cim_emelet"]);
              this.karbejelentoSzemleForm.controls["f_ajto"].setValue(data["f_cim_ajto"]);
              this.karbejelentoSzemleForm.controls["f_hrsz"].setValue(data["f_cim_hrsz"]);
              //this.karbejelentoSzemleForm.controls["f_kulterulet"].setValue(data["f_cim_mo"]);
              this.karbejelentoSzemleForm.controls["f_orszag"].setValue(data["f_kulf_orszag"]);
              this.karbejelentoSzemleForm.controls["f_orszag_nev"].setValue(data["f_kulf_orszag_nev"]);
              this.karbejelentoSzemleForm.controls["f_kulf_irsz"].setValue(data["f_kulf_irszam"]);
              this.karbejelentoSzemleForm.controls["f_kulf_cim"].setValue(data["f_kulf_cim"]);
            }
          },
          () => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a károsult adatainak lekérdezése közben!",
              confirmButtonText: 'Rendben'
            });            
          });

      }
      if (this.karbejelentoSzemleForm.controls["f_modozat"].value != "OTTHON" && this.karbejelentoSzemleForm.controls["f_modozat"].value != "TRAFIK" && this.karbejelentoSzemleForm.controls["f_modozat"].value != "CMR") {

        let model = {
          f_azonosito: this.karbejelentoSzemleForm.controls["f_azonosito"].value
        }

        this.karbejelento.bejelento(model).subscribe(
          data => {
            if (data != null) {
              this.karbejelentoSzemleForm.controls["f_cim_mo"].setValue(data["f_cim_mo"]);
              //this.karbejelentoSzemleForm.controls["f_helyszin_tipus"].setValue(null"]);
              this.karbejelentoSzemleForm.controls["f_irsz"].setValue(data["f_cim_irszam"]);
              this.karbejelentoSzemleForm.controls["f_telepules"].setValue(data["f_cim_telepules"]);
              this.karbejelentoSzemleForm.controls["f_utcanev"].setValue(data["f_cim_utcanev"]);
              this.karbejelentoSzemleForm.controls["f_uttipus"].setValue(data["f_cim_uttipus"]);
              this.karbejelentoSzemleForm.controls["f_uttipus_van"].setValue(data["f_cim_uttipus"] != "" && data["f_cim_uttipus"] != "0");
              this.karbejelentoSzemleForm.controls["f_hazszam"].setValue(data["f_cim_hazszam"]);
              this.karbejelentoSzemleForm.controls["f_emelet"].setValue(data["f_cim_emelet"]);
              this.karbejelentoSzemleForm.controls["f_ajto"].setValue(data["f_cim_ajto"]);
              this.karbejelentoSzemleForm.controls["f_hrsz"].setValue(data["f_cim_hrsz"]);
              //this.karbejelentoSzemleForm.controls["f_kulterulet"].setValue(data["f_cim_mo"]);
              this.karbejelentoSzemleForm.controls["f_orszag"].setValue(data["f_kulf_orszag"]);
              this.karbejelentoSzemleForm.controls["f_orszag_nev"].setValue(data["f_kulf_orszag_nev"]);
              this.karbejelentoSzemleForm.controls["f_kulf_irsz"].setValue(data["f_kulf_irszam"]);
              this.karbejelentoSzemleForm.controls["f_kulf_cim"].setValue(data["f_kulf_cim"]);
            }
          },
          () => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a bejelento adatainak lekérdezése közben!",
              confirmButtonText: 'Rendben'
            });
          });
      }
    }
  }

}

export class AdatValidator extends Validators {

  static keremValasszon(control1_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls["f_cim_mo"];
      if (control2.value == "I") {
        if ((control1_neve == "f_telepules" ||
          control1_neve == "f_utcanev" ||
          control1_neve == "f_uttipus" ||
          control1_neve == "f_orszag") && control1.value == "0") {
          hasError = true;
        }
      }
      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      control1.setErrors(null);

      if (control2_neve == "f_cim_mo" && control2.value != "I" && (control1_neve == "f_kulf_irsz" || control1_neve == "f_kulf_cim") && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && control1_neve == "f_irsz" && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && control1_neve == "f_hazszam" && control4_neve == "f_hrsz" && ((control1.value == null || control1.value == '') && (control4.value == null || control4.value == ''))) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "P" && (control1_neve == "f_telepules" || control1_neve == "f_utcanev" || (control1_neve == "f_uttipus" && control4.value == true)) && (control1.value == null || control1.value == "0")) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value != "I" && control1_neve == "f_orszag" && (control1.value == null || control1.value == "0")) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control3_neve == "f_helyszin_tipus" && control3.value == "K" && control1_neve == "f_kulterulet" && (control1.value == null || control1.value == "")) {
        hasError = true;
      }

      if (control2_neve == "f_adatok_megadas" && control2.value == true && control1_neve == "f_hatosag_elerhetoseg" && (control1.value == null || control1.value == "0")) {
        hasError = true;
      }

      if (control2_neve == "f_adatok_megadas" && control2.value == true && control1_neve == "f_hatosag_elerhetoseg" && control1.value) {
        if (control1.value.length < 6 || control1.value.length > 20) {
          control1.setErrors({ hatosagiElerhetosegHossz: true });
        }
        else {
          if (control1.value.match("^[0-9/+-]+$") == null) {
            control1.setErrors({ hatosagiElerhetoseg: true });
          }
        }
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
        control1.setErrors({ irszamHossz: true });
      }

      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }
    }
    return validator;
  }


  static elerhetosegEllenor(control1_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      control1.setErrors(null);

      if (control1_neve == "f_kapcsolattarto_elerhetosege" && control1.value != null && control1.value != "") {
        if (control1.value.split("_").join("").length < 6 || control1.value.split("_").join("").length > 20) {
          control1.setErrors({ elerhetosegHossz: true });
        }
        else {

          if (control1.value.match("^[0-9/+-]+$") == null) {
            control1.setErrors({ elerhetoseg: true });
          }
        }
      }
    }
    return validator;
  }

}
