<div class="contain-lg">
  <form [formGroup]="karbejelentoVagyontargyForm" class="form">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">
          <div class="row mt-3">
            <div class="col-lg-2" *ngIf="karbejelentoVagyontargyForm.get('f_modozat').value == 'OTTHON'">
              <mat-form-field appearance="fill">
                <mat-label>Károsult vagyontárgy típusa:</mat-label>
                <mat-select matInput formControlName="f_vagyontargy_tipus" [disabled]="vagyonTipus != 'M'">
                  <mat-option value="0" *ngIf="karbejelentoVagyontargyForm.get('f_modozat').value != 'TRAFIK' && vagyonTipus == 'M'">Kérem válasszon..</mat-option>
                  <mat-option value="E" *ngIf="vagyonTipus == 'E' || vagyonTipus == 'M'">Épület</mat-option>
                  <mat-option value="I" *ngIf="vagyonTipus == 'I' || vagyonTipus == 'M'">Ingóság</mat-option>
                  <mat-option value="M" *ngIf="vagyonTipus == 'M'">Mindkettő</mat-option>
                  <mat-option value="G" *ngIf="biztositasTipusEgyebLista.indexOf(karbejelentoVagyontargyForm.get('f_kartipus').value) > -1">Egyéb</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_vagyontargy_tipus').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>

            <div class="col-lg-4">
              <mat-form-field appearance="fill">
                <mat-label>Károsult vagyontárgy leírása:  *</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="f_vagyontargy_leiras"
                          #notificationTextInput="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" [maxlength]="maxCharLeiras"></textarea>
                <mat-icon matSuffix *ngIf="karbejelentoVagyontargyForm.get('f_modozat').value != 'CMR'" matTooltip="Káreseménnyel kapcsolatosan sérült vagyontárgyak felsorolása.">info</mat-icon>
                <mat-hint align="end">{{karbejelentoVagyontargyForm.get('f_vagyontargy_leiras').value?.length || 0}}/{{maxCharLeiras}}</mat-hint>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_vagyontargy_leiras').errors?.required">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>

          </div>

          <div class="row mt-5" *ngIf="karbejelentoVagyontargyForm.get('f_modozat').value == 'TRAFIK'">
            <div class="col-lg-4">
              <mat-label class="control-label">Idegen vagyontárgyban keletkezett a károsodás?</mat-label>
              <div class="mt-2">
                <mat-checkbox matCheckbox class="mr-2" formControlName="f_idegen_vagyontargy"></mat-checkbox>
              </div>
            </div>
          </div>          
          <div class="row mt-5" *ngIf="(karbejelentoVagyontargyForm.get('f_modozat').value == 'OTTHON' && karbejelentoVagyontargyForm.get('f_kartipus').value == 'E22001') || (karbejelentoVagyontargyForm.get('f_modozat').value == 'TRAFIK' && karbejelentoVagyontargyForm.get('f_kartipus').value.indexOf('TDUVU') > -1)">
            <div class="col-lg-2">
              <mat-form-field appearance="fill">
                <mat-label>Üveg típusa:</mat-label>
                <mat-select matInput formControlName="f_uveg_tipus">
                  <mat-option value="0">Kérem válasszon..</mat-option>
                  <mat-option *ngFor="let uvegTipus of uvegTipusLista" [value]="uvegTipus.f_kod">{{uvegTipus.f_megnevezes}}</mat-option>
                </mat-select>
                <mat-icon matSuffix matTooltip="Az üveg jellegét,típusát,méretét kérjük megadni. Pl: konyhaablak 2 db, hőszigetelt, 30×50 cm.">info</mat-icon>
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_uveg_tipus').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
              </div>
            </div>

            <div class="col-lg-2">
              <mat-label class="control-label">Méret megadása (cm x cm):</mat-label>
              <div class="row">

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <input type="text" matInput formControlName="f_uveg_meret1" class="form-control" [textMask]="{mask: numberMask}">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_uveg_meret1').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
                <div class="col-lg-1 labelCenter">
                  <mat-label class="control-label">X</mat-label>
                </div>
                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <input type="text" matInput formControlName="f_uveg_meret2" class="form-control" [textMask]="{mask: numberMask}">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_uveg_meret2').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="row mt-5" *ngIf="karbejelentoVagyontargyForm.get('f_modozat').value == 'OTTHON' || karbejelentoVagyontargyForm.get('f_modozat').value == 'TRAFIK'">
            <div class="col-lg-2">
              <mat-label>A helyi kisipari árakar figyelembe véve a biztositott vagyontárgy(ak)ban keletkezett kár becslésem szerint</mat-label>
            </div>
            <div class="col-lg-1">
              <mat-form-field appearance="fill">
                <input type="text" matInput formControlName="f_becsult_osszeg" [textMask]="{mask: currencyMask}">
              </mat-form-field>
              <div class="pt-2">
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_becsult_osszeg').errors?.fuggosegiKotelezettseg">
                  Hiányzó adat!
                </mat-error>
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_becsult_osszeg').errors?.becsOsszegNagy">
                  Hibás adat!(100.000,-Ft vagy alatti a kár lett megjelölve)
                </mat-error>
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_becsult_osszeg').errors?.becsOsszegMin">
                  Hibás adat!(A legkisebb megadható összeg 1.000,-Ft)
                </mat-error>
                <mat-error *ngIf="karbejelentoVagyontargyForm.get('f_becsult_osszeg').errors?.becsOsszegKicsi">
                  Hibás adat!(100.000 feletti a kár lett megjelölve)
                </mat-error>
              </div>
            </div>
            <div class="col-lg-2">
              <mat-label>Ft összegből állítható helyre</mat-label>
              <mat-icon class="ml-3 info-icon" matTooltip="Nem kell építőipari szakembert hívnia az összeg kiszámításához. Tényleg csak egy nagyságrendi becslésre van szükség, hogy az Önnek megfelelő módon indíthassuk a kárrendezést.">info</mat-icon>
            </div>
          </div>
        </div>
        </div>
    </div>
  </form>
</div>
