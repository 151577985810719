<div class="contain-lg">

  <form [formGroup]="karbejelentoGepjarmuSzemelyForm" class="form">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">
          <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_modozat').value == 'KGFB'">
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-label class="control-label font-bold">Károsult jármű tulajdonosának adatai:</mat-label>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4" *ngIf="!(karbejelentoGepjarmuSzemelyForm.get('f_modozat').value == 'KGFB' && karbejelentoGepjarmuSzemelyForm.get('f_bejelento_tipusa').value == 'e')">
                <mat-checkbox matCheckbox formControlName="f_tulaj_egyezik" class="mat-checkbox" (change)="egyezikValtozas('tulaj')">Az adatok megegyeznek a bejelentőével</mat-checkbox>
              </div>
            </div>
            <div [class.disableDiv]="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_egyezik').value">
              <div class="row mt-3">

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Név:  *</mat-label>
                    <input matInput formControlName="f_tulaj_nev">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_nev').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési dátum:</mat-label>
                    <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_tulaj_szul_datum" [max]="szulDatumMaxDate">
                    <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                    <mat-datepicker #szulDatumPicker></mat-datepicker>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_szul_datum').errors?.matDatepickerParse">
                      Hibás dátum!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési hely:</mat-label>
                    <mat-select matInput formControlName="f_tulaj_szul_hely">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let telepules of szulTelepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-label class="control-label">Lakcím helyszíne:</mat-label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-select matInput formControlName="f_tulaj_cim_mo" (selectionChange)="orszagTipusValtozasTulaj()">
                      <mat-option value="N">Külföld</mat-option>
                      <mat-option value="I">Magyarország</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_cim_mo').value && karbejelentoGepjarmuSzemelyForm.get('f_tulaj_cim_mo').value == 'I'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input type="text" matInput formControlName="f_tulaj_irsz" [textMask]="{mask: irszamMask}">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_irsz').errors?.irszamHossz">
                        Hibás adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Település:  *</mat-label>
                      <mat-select matInput formControlName="f_tulaj_telepules">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let telepules of telepulesListaTulaj" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telepules').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Utcanév:  *</mat-label>
                      <mat-select matInput formControlName="f_tulaj_utcanev">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let utcanev of utcanevListaTulaj" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_utcanev').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Úttípus:  *</mat-label>
                      <mat-select matInput formControlName="f_tulaj_uttipus" [disabled]="!karbejelentoGepjarmuSzemelyForm.get('f_tulaj_uttipus_van').value">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let uttipus of uttipusListaTulaj" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_uttipus').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>
                </div>


                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Házszám  *</mat-label>
                      <input matInput formControlName="f_tulaj_hazszam" maxlength="5">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_hazszam').errors?.fuggosegiKotelezettseg">
                        Házszám vagy Helyrajzi szám kötelező!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Emelet:</mat-label>
                      <input matInput formControlName="f_tulaj_emelet">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ajtó:</mat-label>
                      <input matInput formControlName="f_tulaj_ajto">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Hrsz:</mat-label>
                      <input matInput formControlName="f_tulaj_hrsz">
                    </mat-form-field>
                  </div>

                </div>


              </div>

              <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_cim_mo').value && karbejelentoGepjarmuSzemelyForm.get('f_tulaj_cim_mo').value == 'N'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ország:  *</mat-label>
                      <mat-select matInput formControlName="f_tulaj_orszag" (selectionChange)="orszagValtozasTulaj($event)">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input matInput formControlName="f_tulaj_kulf_irsz">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_kulf_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Cím:  *</mat-label>
                      <input matInput formControlName="f_tulaj_kulf_cim">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_kulf_cim').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefon típusa:</mat-label>
                    <mat-select matInput formControlName="f_tulaj_telefon_tipus">
                      <mat-option value="M">Mobil</mat-option>
                      <mat-option value="V">Vezetékes</mat-option>
                      <mat-option value="K">Külföldi</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2" *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_tipus').value != 'K'">
                  <mat-form-field appearance="fill">
                    <mat-label>Előhívó:  *</mat-label>
                    <mat-select matInput formControlName="f_tulaj_telefon_elohivo" (selectionChange)="eloHivoValtozasTulaj()">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let eloHivo of eloHivoListaTulaj" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefonszám:  *</mat-label>
                    <input type="text" matInput formControlName="f_tulaj_telefon_szam" [textMask]="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_tipus').value != 'K' ? {mask: phoneMaskTulaj} : {mask: false}" [placeholder]="phonePlaceholderTulaj">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_szam').errors?.telefonszamUres">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_szam').errors?.telefonszam">
                      Hibás adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_szam').errors?.telefonszamKulfHossz">
                      Hibás adat! (Minimum 6 és maximum 20 karakterből állhat)
                    </mat-error>
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_telefon_szam').errors?.telefonszamKulf">
                      Hibás adat! (Megengedett karakterek: 0-9, /, +, -)
                    </mat-error>
                  </div>
                </div>

              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>E-mail cím:</mat-label>
                    <input type="text" matInput formControlName="f_tulaj_email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_email').errors?.pattern">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Fax:</mat-label>
                    <input type="text" matInput formControlName="f_tulaj_fax" [textMask]="{mask: faxMask}" placeholder="+36(12) 123-1234">
                  </mat-form-field>
                  <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_tulaj_fax').errors?.fax">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

            </div>
          </div>


          <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_modozat').value == 'KGFB'">
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-label class="control-label font-bold">Károsult jármű üzembentartójának adatai:</mat-label>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4">
                <mat-checkbox matCheckbox formControlName="f_uzem_egyezik" class="mat-checkbox" (change)="egyezikValtozas('uzem')">Az adatok megegyeznek a károsult gépjármű tualjdonosának adataival</mat-checkbox>
              </div>
            </div>
            <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_egyezik').value == false">
              <div class="row mt-3">

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Név:  *</mat-label>
                    <input matInput formControlName="f_uzem_nev">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_nev').errors?.required">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési dátum:</mat-label>
                    <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_uzem_szul_datum" [max]="szulDatumMaxDate">
                    <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                    <mat-datepicker #szulDatumPicker></mat-datepicker>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_szul_datum').errors?.matDatepickerParse">
                      Hibás dátum!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési hely:</mat-label>
                    <mat-select matInput formControlName="f_uzem_szul_hely">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let telepules of szulTelepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-label class="control-label">Lakcím helyszíne:</mat-label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-select matInput formControlName="f_uzem_cim_mo" (selectionChange)="orszagTipusValtozasUzem()">
                      <mat-option value="N">Külföld</mat-option>
                      <mat-option value="I">Magyarország</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_cim_mo').value && karbejelentoGepjarmuSzemelyForm.get('f_uzem_cim_mo').value == 'I'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input type="text" matInput formControlName="f_uzem_irsz" [textMask]="{mask: irszamMask}">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_irsz').errors?.irszamHossz">
                        Hibás adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Település:  *</mat-label>
                      <mat-select matInput formControlName="f_uzem_telepules">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let telepules of telepulesListaUzem" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_telepules').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Utcanév:  *</mat-label>
                      <mat-select matInput formControlName="f_uzem_utcanev">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let utcanev of utcanevListaUzem" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_utcanev').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Úttípus:  *</mat-label>
                      <mat-select matInput formControlName="f_uzem_uttipus" [disabled]="!karbejelentoGepjarmuSzemelyForm.get('f_uzem_uttipus_van').value">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let uttipus of uttipusListaUzem" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_uttipus').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>
                </div>


                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Házszám  *</mat-label>
                      <input matInput formControlName="f_uzem_hazszam" maxlength="5">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_hazszam').errors?.fuggosegiKotelezettseg">
                        Házszám vagy Helyrajzi szám kötelező!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Emelet:</mat-label>
                      <input matInput formControlName="f_uzem_emelet">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ajtó:</mat-label>
                      <input matInput formControlName="f_uzem_ajto">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Hrsz:</mat-label>
                      <input matInput formControlName="f_uzem_hrsz">
                    </mat-form-field>
                  </div>

                </div>


              </div>

              <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_cim_mo').value && karbejelentoGepjarmuSzemelyForm.get('f_uzem_cim_mo').value == 'N'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ország:  *</mat-label>
                      <mat-select matInput formControlName="f_uzem_orszag" (selectionChange)="orszagValtozasUzem($event)">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input matInput formControlName="f_uzem_kulf_irsz">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_kulf_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Cím:  *</mat-label>
                      <input matInput formControlName="f_uzem_kulf_cim">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_kulf_cim').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefon típusa:</mat-label>
                    <mat-select matInput formControlName="f_uzem_telefon_tipus">
                      <mat-option value="M">Mobil</mat-option>
                      <mat-option value="V">Vezetékes</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2" *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_telefon_tipus').value != 'K'">
                  <mat-form-field appearance="fill">
                    <mat-label>Előhívó:  *</mat-label>
                    <mat-select matInput formControlName="f_uzem_telefon_elohivo" (selectionChange)="eloHivoValtozasUzem()">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let eloHivo of eloHivoListaUzem" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefonszám:  *</mat-label>
                    <input type="text" matInput formControlName="f_uzem_telefon_szam" [textMask]="karbejelentoGepjarmuSzemelyForm.get('f_uzem_telefon_tipus').value != 'K' ? {mask: phoneMaskUzem} : {mask: false}" [placeholder]="phonePlaceholderUzem">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_telefon_szam').errors?.telefonszamUres">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_telefon_szam').errors?.telefonszam">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>E-mail cím:</mat-label>
                    <input type="text" matInput formControlName="f_uzem_email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_email').errors?.pattern">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Fax:</mat-label>
                    <input type="text" matInput formControlName="f_uzem_fax" [textMask]="{mask: faxMask}" placeholder="+36(12) 123-1234">
                  </mat-form-field>
                  <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_uzem_fax').errors?.fax">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

            </div>
          </div>



          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-label class="control-label font-bold">Károsult jármű vezetőjének adatai:</mat-label>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4">
              <mat-checkbox matCheckbox formControlName="f_vezeto_egyezik" class="mat-checkbox" (change)="egyezikValtozas('vezeto')">{{vezetoEgyezikLabel}}</mat-checkbox>
            </div>
          </div>
          <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_modozat').value == 'CASCO' || (karbejelentoGepjarmuSzemelyForm.get('f_modozat').value == 'KGFB' && karbejelentoGepjarmuSzemelyForm.get('f_vezeto_egyezik').value == false)">

            <div [class.disableDiv]="karbejelentoGepjarmuSzemelyForm.get('f_modozat').value == 'CASCO' && karbejelentoGepjarmuSzemelyForm.get('f_vezeto_egyezik').value">

              <div class="row mt-3">

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Név:  *</mat-label>
                    <input matInput formControlName="f_vezeto_nev">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_nev').errors?.required">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési dátum:</mat-label>
                    <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_vezeto_szul_datum" [max]="szulDatumMaxDate">
                    <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                    <mat-datepicker #szulDatumPicker></mat-datepicker>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_szul_datum').errors?.matDatepickerParse">
                      Hibás dátum!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Születési hely:</mat-label>
                    <mat-select matInput formControlName="f_vezeto_szul_hely">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let telepules of szulTelepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-label class="control-label">Lakcím helyszíne:</mat-label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-select matInput formControlName="f_vezeto_cim_mo" (selectionChange)="orszagTipusValtozasVezeto()">
                      <mat-option value="N">Külföld</mat-option>
                      <mat-option value="I">Magyarország</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_cim_mo').value && karbejelentoGepjarmuSzemelyForm.get('f_vezeto_cim_mo').value == 'I'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input type="text" matInput formControlName="f_vezeto_irsz" [textMask]="{mask: irszamMask}">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_irsz').errors?.irszamHossz">
                        Hibás adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Település:  *</mat-label>
                      <mat-select matInput formControlName="f_vezeto_telepules">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let telepules of telepulesListaVezeto" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_telepules').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Utcanév:  *</mat-label>
                      <mat-select matInput formControlName="f_vezeto_utcanev">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let utcanev of utcanevListaVezeto" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_utcanev').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Úttípus:  *</mat-label>
                      <mat-select matInput formControlName="f_vezeto_uttipus" [disabled]="!karbejelentoGepjarmuSzemelyForm.get('f_vezeto_uttipus_van').value">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let uttipus of uttipusListaVezeto" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_uttipus').errors?.keremValasszon">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>
                </div>


                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Házszám  *</mat-label>
                      <input matInput formControlName="f_vezeto_hazszam" maxlength="5">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_hazszam').errors?.fuggosegiKotelezettseg">
                        Házszám vagy Helyrajzi szám kötelező!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Emelet:</mat-label>
                      <input matInput formControlName="f_vezeto_emelet">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ajtó:</mat-label>
                      <input matInput formControlName="f_vezeto_ajto">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Hrsz:</mat-label>
                      <input matInput formControlName="f_vezeto_hrsz">
                    </mat-form-field>
                  </div>

                </div>


              </div>

              <div *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_cim_mo').value && karbejelentoGepjarmuSzemelyForm.get('f_vezeto_cim_mo').value == 'N'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ország:  *</mat-label>
                      <mat-select matInput formControlName="f_vezeto_orszag" (selectionChange)="orszagValtozasVezeto($event)">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input matInput formControlName="f_vezeto_kulf_irsz">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_kulf_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Cím:  *</mat-label>
                      <input matInput formControlName="f_vezeto_kulf_cim">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_kulf_cim').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefon típusa:</mat-label>
                    <mat-select matInput formControlName="f_vezeto_telefon_tipus">
                      <mat-option value="M">Mobil</mat-option>
                      <mat-option value="V">Vezetékes</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2" *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_telefon_tipus').value != 'K'">
                  <mat-form-field appearance="fill">
                    <mat-label>Előhívó:  *</mat-label>
                    <mat-select matInput formControlName="f_vezeto_telefon_elohivo" (selectionChange)="eloHivoValtozasVezeto()">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let eloHivo of eloHivoListaVezeto" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Telefonszám:  *</mat-label>
                    <input type="text" matInput formControlName="f_vezeto_telefon_szam" [textMask]="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_telefon_tipus').value != 'K' ? {mask: phoneMaskVezeto} : {mask: false}" [placeholder]="phonePlaceholderVezeto">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_telefon_szam').errors?.telefonszamUres">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_telefon_szam').errors?.telefonszam">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

              </div>

              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>E-mail cím:</mat-label>
                    <input type="text" matInput formControlName="f_vezeto_email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_email').errors?.pattern">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Fax:</mat-label>
                    <input type="text" matInput formControlName="f_vezeto_fax" [textMask]="{mask: faxMask}" placeholder="+36(12) 123-1234">
                  </mat-form-field>
                  <mat-error *ngIf="karbejelentoGepjarmuSzemelyForm.get('f_vezeto_fax').errors?.fax">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </form>
</div>
