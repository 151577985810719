import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SimplePayService {
  private url: string = "/api/simplepay";
  
  private messageSource = new BehaviorSubject<string>("");
  focimke = this.messageSource.asObservable();

  constructor(
    private httpClient: HttpClient) { }

  // fizetés eredmény oldalhívás paramétereinek feldolgozása
  simplePayBack(model: any) {
    return this.httpClient.post<any>(this.url + "/SimplePayBack", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // új fizetés indítása
  newSimplePayTransaction(model: any) {
    return this.httpClient.post<any>(this.url + "/NewSimplePayTransaction", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  //SimplePayIpnCheck
  simplePayIpnCheck(model: any) {
    return this.httpClient.post<any>(this.url + "/SimplePayIpnCheck", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  
  // aktuális státusz lekérdezése
  //SimplePayQuery(model: any) {
  //  return this.httpClient.post<any>(this.url + "/SimplePayQuery", model).pipe(
  //    map(data => {
  //      return data;
  //    }),
  //    catchError(error => {
  //      return throwError(error);
  //    }));
  //}

  // üzemszünet lekérdezése
  simplePayUzemSzunet() {
    return this.httpClient.post<any[]>(this.url + "/SimplePayUzemSzunet", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }
}
