<div class="contain-lg">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <!--<div class="mt-4 ml-4">-->
      <div class="m-4">
        <div>
          <mat-card class="mat-elevation-z3">
            <mat-card-content>
              <form [formGroup]="befizetesEredmenyForm" class="form">
                <div class="row">
                  <div class="col-lg-6 col-md-6">

                    <ng-container *ngIf="toltoKereses">
                      <div class="row">
                        <div class="col-lg-12 bg-white">
                          <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!toltoKereses">
                      <label class="control-label font-bold">SimplePay bankkártyás fizetés eredménye</label>
                      <div class="ml-4">
                        <div class="mb-5">
                          <div class="row mt-5">
                            <div class="col-lg-12">
                              <mat-label class="font-bold">{{befizetesEredmenyForm.get('f_statusText').value}}</mat-label>
                            </div>
                          </div>
                          <div class="row mt-5" *ngIf="(befizetesEredmenyForm.get('f_statusCode').value == 'FINISHED' || befizetesEredmenyForm.get('f_statusCode').value == 'NOTAUTHORIZED')">
                            <div class="col-lg-12">
                              <mat-label class="font-bold">SimplePay tranzakció azonosító: {{befizetesEredmenyForm.get('f_simplepayId').value}}</mat-label>
                            </div>
                          </div>
                          <div class="row mt-5" *ngIf="befizetesEredmenyForm.get('f_statusCode').value == 'NOTAUTHORIZED' ">
                            <div class="col-lg-12">
                              <mat-label class="font-bold">
                                Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét.
                                Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
                              </mat-label>
                            </div>
                          </div>
                          <div class="row mt-2" *ngIf="befizetesEredmenyForm.get('f_hibaszoveg').value != ''">
                            <div class="col-lg-6">
                              <mat-label>Hibaüzenet:</mat-label>
                            </div>
                            <div class="col-lg-4">
                              <span class="font-bold">{{befizetesEredmenyForm.get('f_hibaszoveg').value}}</span>
                            </div>
                          </div>

                          <div class="row mt-5" *ngIf="isCurrentStatusFinal && fizetesUjraUrl">
                            <div class="col-lg-4">
                              <button mat-raised-button color="accent" (click)="FizetesUjra()">Vissza a fizetés oldalra</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="information-wrapper mb-4">
                      <p>Tájékoztatók, információk a SimplePay online bankkártyás fizetéshez</p>

                      <p><a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank"><img src="/assets/images/simplepay_h120.png" title="SimplePay - Online bankkártyás fizetés" alt="SimplePay vásárlói tájékoztató"></a></p>
                      <p><a mat-raised-button href="https://dokumentum.granitbiztosito.hu/simple_adattovabbitasi_nyilatkozat.pdf" target="_blank"><mat-icon>link</mat-icon>&nbsp;Adattovábbítási nyilatkozat</a></p>
                      <p><a mat-raised-button href="https://simplepay.hu/" target="_blank"><mat-icon>link</mat-icon>&nbsp;OTP SimplePay Honlap</a></p>
                      <p><a mat-raised-button href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank"><mat-icon>link</mat-icon>&nbsp;Online fizetési tájékoztató</a></p>
                    </div>
                  </div>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
