<div class="contain-lg">
  <div class="row">
    <div class="col-12 ml-5 mt-5">
      <h3>
        Tisztelt Ügyfelünk!
      </h3>
      <p>
        Az online kárbejelentő karbantartás miatt szünetel.<br />Kérjük nézzen vissza később!
      </p>
      <p>
        Addig is kérjük a kárbejelentését a kar@granitbiztosito.hu email címen tegye meg!
      </p>
      <p>
        Türelmét köszönjük!
      </p>
    </div>
  </div>
</div>
