import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { KarbejelentoComponent } from './components/karbejelento/karbejelento.component';
import { KarbejelentoHibaOldalComponent } from './components/karbejelento/karbejelento-hiba-oldal/karbejelento-hiba-oldal.component';
import { KarbejelentoAlapadatokComponent } from './components/karbejelento/aloldalak/karbejelento-alapadatok/karbejelento-alapadatok.component';
import { KarbejelentoKaresemenyComponent } from './components/karbejelento/aloldalak/karbejelento-karesemeny/karbejelento-karesemeny.component';
import { KarbejelentoBejelentoComponent } from './components/karbejelento/aloldalak/karbejelento-bejelento/karbejelento-bejelento.component';
import { KarbejelentoSzemleComponent } from './components/karbejelento/aloldalak/karbejelento-szemle/karbejelento-szemle.component';
import { KarbejelentoGepjarmuSzemelyComponent } from './components/karbejelento/aloldalak/karbejelento-gepjarmu-szemely/karbejelento-gepjarmu-szemely.component';
import { KarbejelentoKarosultComponent } from './components/karbejelento/aloldalak/karbejelento-karosult/karbejelento-karosult.component';
import { KarbejelentoKarosultOtthonComponent } from './components/karbejelento/aloldalak/karbejelento-karosult-otthon/karbejelento-karosult-otthon.component';
import { KarbejelentoVagyontargyComponent } from './components/karbejelento/aloldalak/karbejelento-vagyontargy/karbejelento-vagyontargy.component';
import { KarbejelentoFajlfeltoltesComponent } from './components/karbejelento/aloldalak/karbejelento-fajlfeltoltes/karbejelento-fajlfeltoltes.component';
import { KarbejelentoEredmenyComponent } from './components/karbejelento/aloldalak/karbejelento-eredmeny/karbejelento-eredmeny.component';
import { BefizetesComponent } from './components/befizetes/befizetes.component';
import { IdopontUsziComponent } from './components/idopont-uszi/idopont-uszi.component';
import { MfoTarifalasComponent } from './components/kalkulatorok/mfo-tarifalas/mfo-tarifalas.component';
import { RendezvenyRegisztracioComponent } from './components/rendezveny-regisztracio/rendezveny-regisztracio.component';
import { DokumentumIgenylesComponent } from './components/dokumentum-igenyles/dokumentum-igenyles.component';
import { DokumentumLetoltesComponent } from './components/dokumentum-letoltes/dokumentum-letoltes.component';
import { FairPaySuccessComponent } from './components/fairpay/fairpay-success.component';
import { SimplePayResultComponent } from './components/simplepay/simplepay-result.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'karbejelento', component: KarbejelentoComponent },
  { path: 'karbejelento-alapadatok', component: KarbejelentoAlapadatokComponent },
  { path: 'karbejelento-karesemeny', component: KarbejelentoKaresemenyComponent },
  { path: 'karbejelento-bejelento', component: KarbejelentoBejelentoComponent },
  { path: 'karbejelento-szemle', component: KarbejelentoSzemleComponent },
  { path: 'karbejelento-gepjarmu-szemely', component: KarbejelentoGepjarmuSzemelyComponent },
  { path: 'karbejelento-karosult', component: KarbejelentoKarosultComponent },
  { path: 'karbejelento-karosult-otthon', component: KarbejelentoKarosultOtthonComponent },
  { path: 'karbejelento-vagyontargy', component: KarbejelentoVagyontargyComponent },
  { path: 'karbejelento-fajlfeltoltes', component: KarbejelentoFajlfeltoltesComponent },
  { path: 'karbejelento-eredmeny', component: KarbejelentoEredmenyComponent },
  { path: 'befizetes', component: BefizetesComponent },
  { path: 'kp-pos', component: BefizetesComponent },
  { path: 'ajanlat-befizetes', component: BefizetesComponent },
  { path: 'ajanlat-kp-pos', component: BefizetesComponent },
  { path: 'mfo-tarifalas', component: MfoTarifalasComponent },
  { path: 'idopont-uszi', component: IdopontUsziComponent },
  { path: 'karbejelento-hiba-oldal', component: KarbejelentoHibaOldalComponent },
  { path: 'rendezveny-regisztracio', component: RendezvenyRegisztracioComponent },
  { path: 'dokumentum-igenyles', component: DokumentumIgenylesComponent },
  { path: 'dokumentum-letoltes', component: DokumentumLetoltesComponent },
  { path: 'fairpay-error', component: FairPaySuccessComponent },
  { path: 'fairpay-success', component: FairPaySuccessComponent },
  { path: 'simplepay-result', component: SimplePayResultComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
