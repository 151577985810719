import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mfo-biztositasi-osszegek-modositasa',
  templateUrl: './mfo-biztositasi-osszegek-modositasa.component.html',
  styleUrls: ['./mfo-biztositasi-osszegek-modositasa.component.scss']
})
export class MfoBiztositasiOsszegekModositasaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
