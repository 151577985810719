import { Component, OnInit, EventEmitter, Output, ɵSWITCH_CHANGE_DETECTOR_REF_FACTORY__POST_R3__, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { UtilityService } from '@granit/common-library';
import * as moment from 'moment';
import { MatSelectChange } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';


@Component({
  selector: 'karbejelento-alapadatok',
  templateUrl: './karbejelento-alapadatok.component.html',
  styleUrls: ['./karbejelento-alapadatok.component.scss']
})
export class KarbejelentoAlapadatokComponent implements OnInit {

  @Output() karbejelentoAlapadatokFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() modozatOut: EventEmitter<string> = new EventEmitter<string>();
  karbejelentoAlapadatokForm: FormGroup;
  azonositasSzoveg: string;
  kartipusLista: any[];
  kockhelyLista: any[];
  jellegLista: any[];
  jellegLista2: any[];
  vagyontargyLista: any[];
  vontatoRendszamLista: any[];
  karosultTipusLista: any[];
  karidoMaxDate: string = moment(new Date()).format('YYYY-MM-DD');
  toltoKereses: boolean = false;
  weboldalDokumentumok: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
    private utilityService: UtilityService) { }

  ngOnInit() {

    this.link();
    this.formGroupNew();
    this.jellegFeltoltes();
    this.karosultTipusFeltoltes();

    this.karbejelentoAlapadatokFormOut.emit(this.karbejelentoAlapadatokForm);

    this.karbejelentoAlapadatokForm.controls['f_azon_tip'].valueChanges.subscribe(value => {
      this.azonositasTipustValtozas("adatbázis");
    });

    this.karbejelentoAlapadatokForm.controls['f_modozat'].valueChanges.subscribe(value => {
      //this.modozatValtozas();
      this.vontatoRendszamLista = [];
      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value != "OTTHON" && this.karbejelentoAlapadatokForm.controls["f_modozat"].value != "TRAFIK") {
        this.kartipusFeltoltes("adatbázis");
      }
      this.vonatatoRendszamFeltoltes("adatbázis");

      this.modozatOut.emit(this.karbejelentoAlapadatokForm.controls["f_modozat"].value);

    });
    

    this.karbejelentoAlapadatokForm.controls['f_trafik_kockhely_ssz'].valueChanges.subscribe(value => {
      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "TRAFIK" &&
        this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value &&
        this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value.length >= 8 &&
        this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value) {
        this.trafikVagyontargy();
      }
    });

    this.karbejelentoAlapadatokForm.controls['f_trafik_reszmodozat'].valueChanges.subscribe(value => {
      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "TRAFIK" &&
        this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value &&
        this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value.length >= 8 &&
        this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value &&
        this.karbejelentoAlapadatokForm.controls["f_trafik_reszmodozat"].value) {
        this.kartipusFeltoltes("adatbázis");
      }
    });

    

    this.cdr.detectChanges();
  }

  async link() {

    let model = {
      key: "WeboldalDokumentumok"
    }
    this.weboldalDokumentumok = await this.utilityService.appsettingsKeyErtekLekerese(model);
  }

  formGroupNew() {
    this.karbejelentoAlapadatokForm = this.formBuilder.group({
      f_adatkezelesi: new FormControl(false, Validators.requiredTrue),
      f_adatkezelesi_kgfb: new FormControl(true, Validators.requiredTrue),
      f_adatkezelesi_kgfb_szemelyi: new FormControl(true, Validators.requiredTrue),
      f_adatkezelesi_casco: new FormControl(true, Validators.requiredTrue),
      //f_adatkezelesi_otthon: new FormControl(true, Validators.requiredTrue),
      f_azonosito: new FormControl("0"),
      f_modozat: new FormControl("0"),
      f_modkod: new FormControl("0"),
      f_azon_tip: new FormControl("0"),
      f_azon_adat: new FormControl(null),
      f_karido: new FormControl(null),
      f_szerzazon: new FormControl(null, [Validators.required]),
      f_kartipus_gfb: new FormControl(null),
      f_kartipus: new FormControl("0"),
      f_becsult_osszeg: new FormControl(null),
      f_gjm_jelleg: new FormControl("0"),
      f_gjm_jellegnev: new FormControl("0"),
      f_rendszam_tipus: new FormControl("M"),
      f_rendszam: new FormControl(null),
      f_karosult_rendszam: new FormControl(null),
      f_vontato_rendszam: new FormControl("0"),
      f_potkocsi_rendszam: new FormControl(null),
      f_potkocsi_tipus: new FormControl("M"),
      f_karosult_tipus: new FormControl("0"),
      f_karosult_nev: new FormControl(null),
      f_dupla: new FormControl(false),
      f_elviKockVeg: new FormControl(null),
      f_kocKezd: new FormControl(null),
      f_rendszamHiba: new FormControl(false),
      f_modozatHiba: new FormControl(false),
      f_trafik_kockhely_ssz: new FormControl("0"),
      f_trafik_kockhely: new FormControl(null),
      f_trafik_reszmodozat: new FormControl("0"),
      f_ellenorzes_ok: new FormControl(false)
    }, {
      validators: [AdatValidator.keremValasszon('f_kartipus', 'f_modozat'),
      AdatValidator.keremValasszon('f_modozat', 'f_modozat'),
      AdatValidator.keremValasszon('f_modkod', 'f_modozat'),
      AdatValidator.modozatSpecifikusKotelezoElem('f_azon_adat', 'f_modozat', '', '', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_kartipus_gfb', 'f_modozat', '', '', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_gjm_jelleg', 'f_modozat', 'f_kartipus', 'f_kartipus_gfb', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_karosult_rendszam', 'f_modozat', 'f_kartipus', 'f_kartipus_gfb', 'f_gjm_jellegnev'),
      AdatValidator.modozatSpecifikusKotelezoElem('f_vontato_rendszam', 'f_modozat', 'f_potkocsi_rendszam', '', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_karosult_tipus', 'f_modozat', 'f_kartipus', 'f_kartipus_gfb', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_karosult_nev', 'f_modozat', 'f_kartipus', 'f_kartipus_gfb', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_becsult_osszeg', 'f_modozat', '', '', ''),
      AdatValidator.modozatSpecifikusKotelezoElem('f_karido', 'f_modozat', '', '', ''),
      AdatValidator.keremValasszon('f_trafik_kockhely_ssz', 'f_modozat'),
      AdatValidator.keremValasszon('f_trafik_reszmodozat', 'f_modozat')]
    });
  }

  errorsClear() {
    this.karbejelentoAlapadatokForm.controls["f_kartipus"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_gjm_jelleg"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_gjm_jellegnev"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_rendszam"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_karosult_rendszam"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_vontato_rendszam"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_karido"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_szerzazon"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_karosult_tipus"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_karosult_nev"].setErrors(null);
  }

  trafikVagyontargy() {

    if (this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value && this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value != 0 && this.kockhelyLista.length > 0) {
      this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely"].setValue(this.kockhelyLista.find(x => x.f_sorrend == this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value).f_cim_egyben);

      this.vagyontargyLista = [];

      // Recaptcha kliens validálás
      this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
        token => {
          let model = { encodedResponse: token }
          // Recaptcha szerver validálás
          this.utilityService.recaptchaValidalas(model).subscribe(result => {

            let model = {
              f_szerzazon: this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value,
              f_trafik_kockhely: this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value
            }

            this.karbejelento.trafikBiztositasTipus(model).subscribe(
              data => {
                this.vagyontargyLista = data;
              },
              () => {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba a vagyontárgy lekérdezése közben!",
                  confirmButtonText: 'Rendben'
                });
              });
          });
        });
    }

  }

  trafikVagyontargyModositas(honnan: string) {
    this.karbejelentoAlapadatokForm.controls["f_kartipus"].setValue("0");
    this.kartipusFeltoltes(honnan);
  }

  kartipusFeltoltes(honnan: string) {

    this.kartipusLista = [];
    //this.karbejelentoAlapadatokForm.controls["f_kartipus"].setValue("0");
    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value != "0") {
      if ((this.karbejelentoAlapadatokForm.controls["f_modozat"].value != "OTTHON" && this.karbejelentoAlapadatokForm.controls["f_modozat"].value != "TRAFIK") ||
        (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "OTTHON" && (this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value != "" &&
          this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value != null)) ||
        (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "TRAFIK" && (this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value != "" &&
          this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value != null) && (this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value != "" &&
            this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value != null))
      ) {

        // Recaptcha kliens validálás
        this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
          token => {
            let model = { encodedResponse: token }
            // Recaptcha szerver validálás
            this.utilityService.recaptchaValidalas(model).subscribe(result => {
              let model = {
                f_modozat: this.karbejelentoAlapadatokForm.controls["f_modozat"].value,
                f_szerzazon: this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value,
                f_trafik_kockhely: this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value,
                f_trafik_reszmodozat: this.karbejelentoAlapadatokForm.controls["f_trafik_reszmodozat"].value
              }

              this.karbejelento.kartipusok(model).subscribe(
                data => {
                  this.kartipusLista = data;
                },
                () => {
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hiba',
                    message: "Hiba a kártipus lekérdezése közben!",
                    confirmButtonText: 'Rendben'
                  });
                });
            });
          });
      }
    }
  }

  jellegFeltoltes() {
    this.jellegLista = [];
    this.karbejelentoAlapadatokForm.controls["f_gjm_jelleg"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_gjm_jellegnev"].setValue(null);

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          this.karbejelento.jellegek().subscribe(
            data => {
              this.jellegLista = data;
              this.jellegLista2 = data;
            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a gépjármű jellegek lekérdezése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  karosultTipusFeltoltes() {
    this.karosultTipusLista = [];
    this.karbejelentoAlapadatokForm.controls["f_karosult_tipus"].setValue("0");
    /*if (this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].value && this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].value.indexOf("E56201") == -1) {*/

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            f_modozat: "KGFB"
          }

          this.karbejelento.karosultTipusok(model).subscribe(
            data => {
              this.karosultTipusLista = data;
            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a károsult tipusok lekérdezése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  vonatatoRendszamFeltoltes(honnan: string) {
    this.vontatoRendszamLista = [];
    this.karbejelentoAlapadatokForm.controls["f_vontato_rendszam"].setValue("0");
    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "CMR" && this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value && this.karbejelentoAlapadatokForm.controls["f_karido"].value) {

      // Recaptcha kliens validálás
      this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
        token => {
          let model = { encodedResponse: token }
          // Recaptcha szerver validálás
          this.utilityService.recaptchaValidalas(model).subscribe(result => {

            let model = {
              f_karido: moment(this.karbejelentoAlapadatokForm.controls["f_karido"].value).format('YYYY.MM.DD'),
              f_szerzazon: this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value
            }

            this.karbejelento.cmrVontatoRendszamok(model).subscribe(
              data => {
                this.vontatoRendszamLista = data;
                if (honnan != "adatbázis") {
                }
              },
              () => {

                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba a CMR rendszámok lekérdezése közben!",
                  confirmButtonText: 'Rendben'
                });
              });
          })
        });
    }
  }

  trafikKockhelyFeltoltes() {
    this.kockhelyLista = [];
    this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely"].setValue(null);

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let model = {
            f_szerzazon: this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value,
          }

          this.karbejelento.trafikKockhely(model).subscribe(
            data => {
              this.kockhelyLista = data;

              if (this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value && this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value != 0) {
                this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely"].setValue(this.kockhelyLista.find(x => x.f_sorrend == this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].value).f_cim_egyben);
              }

            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba a kockázatviselésihelyek lekérdezése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        })
      });

  }

  trafikKockhelyModositas() {
    this.karbejelentoAlapadatokForm.controls["f_trafik_reszmodozat"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_kartipus"].setValue("0");
    this.trafikVagyontargy();
  }

  modozatValtozas() {

    this.errorsClear();

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB" ||
      this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "CASCO") {
      this.azonositasTipustValtozas("felület");
    }
    this.karbejelentoAlapadatokForm.controls["f_azon_tip"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_azon_adat"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_karido"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_szerzazon"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_kartipus"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_becsult_osszeg"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_gjm_jelleg"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_gjm_jellegnev"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_rendszam_tipus"].setValue("M");
    this.karbejelentoAlapadatokForm.controls["f_rendszam"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_karosult_rendszam"].setErrors(null);
    this.karbejelentoAlapadatokForm.controls["f_vontato_rendszam"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_potkocsi_rendszam"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_karosult_tipus"].setValue("0");
    this.karbejelentoAlapadatokForm.controls["f_karosult_nev"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_elviKockVeg"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_kocKezd"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].setValue(null);
    this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely"].setValue(null);

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB") {
      this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_kgfb"].setValue(false);
      this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_casco"].setValue(true);
      //this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_otthon"].setValue(true);
    }

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "CASCO") {
      this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_casco"].setValue(false);
      this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_kgfb"].setValue(true);
      //this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_otthon"].setValue(true);
    }

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "OTTHON") {
      //this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_otthon"].setValue(false);
      this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_kgfb"].setValue(true);
      //this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_casco"].setValue(true);
    }
  }

  azonositasTipustValtozas(honnan: string) {
    if (this.karbejelentoAlapadatokForm.controls["f_azon_tip"].value == "0") {
      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB") {
        this.azonositasSzoveg = "Károkozó rendszáma:  *";
      }
      else {
        this.azonositasSzoveg = "Szerződő rendszáma:  *";
      }
    }
    else {
      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB") {
        this.azonositasSzoveg = "Károkozó szerződés azonosítója:  *";
      }
      else {
        this.azonositasSzoveg = "Szerződő szerződés azonosítója:  *";
      }
    }
    if (honnan != "adatbázis") {
      this.karbejelentoAlapadatokForm.controls["f_azon_adat"].setValue(null);
      this.karbejelentoAlapadatokForm.controls["f_szerzazon"].setValue(null);
    }
  }

  karTipustValtozas() {
    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "OTTHON" || (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB" && this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].value.indexOf("E56101") == -1)) {
      this.karbejelentoAlapadatokForm.controls["f_gjm_jelleg"].setValue("0");
      this.karbejelentoAlapadatokForm.controls["f_gjm_jellegnev"].setValue(null);
      this.karbejelentoAlapadatokForm.controls["f_rendszam_tipus"].setValue("M");
      this.karbejelentoAlapadatokForm.controls["f_karosult_rendszam"].setErrors(null);
      this.karbejelentoAlapadatokForm.controls["f_karosult_tipus"].setValue("0");
      this.karbejelentoAlapadatokForm.controls["f_karosult_nev"].setValue(null);
      //this.duplaEllenorzes();
    }

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "TRAFIK") {
      //this.duplaEllenorzes();
    }

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB" && this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].value.indexOf("E56201") != -1) {
      this.karbejelentoAlapadatokForm.controls["f_adatkezelesi_kgfb_szemelyi"].setValue(false);
    }

    if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "OTTHON" && this.karbejelentoAlapadatokForm.controls["f_kartipus"].value == "E57111") {
      this.jellegLista2 = [];
      this.jellegLista2.push(this.jellegLista.find(x => x.f_jellegnev == "személygépkocsi"));
      this.jellegLista2.push(this.jellegLista.find(x => x.f_jellegnev == "motorkerékpár"));
      this.jellegLista2.push(this.jellegLista.find(x => x.f_jellegnev == "segédmotoros kerékpár"));
    }
    else {
      this.jellegLista2 = this.jellegLista;
    }

    //this.jellegFeltoltes();
  }

  szerzodesszamValtozas() {
    this.karbejelentoAlapadatokForm.controls['f_ellenorzes_ok'].setValue(false);
  }

  karidoValtozas() {
    this.karbejelentoAlapadatokForm.controls['f_ellenorzes_ok'].setValue(false);
  }

  azonositoValtozas() {
    this.karbejelentoAlapadatokForm.controls['f_ellenorzes_ok'].setValue(false);
  }

  jellegValtozas(event: MatSelectChange) {
    this.karbejelentoAlapadatokForm.controls["f_gjm_jellegnev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  ellenorzes() {
    /*if ((!this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value || this.karbejelentoAlapadatokForm.controls["f_azon_adat"].valid) &&
      (!this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value || this.karbejelentoAlapadatokForm.controls["f_szerzazon"].valid)) {*/
    if (this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value || this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value) {

      this.karbejelentoAlapadatokForm.controls['f_rendszamHiba'].setValue(false);
      this.karbejelentoAlapadatokForm.controls['f_modozatHiba'].setValue(false);

      this.toltoKereses = true;

      // Recaptcha kliens validálás
      this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
        token => {
          let model = { encodedResponse: token }
          // Recaptcha szerver validálás
          this.utilityService.recaptchaValidalas(model).subscribe(result => {
            let model = {
              f_azon_tip: this.karbejelentoAlapadatokForm.controls["f_azon_tip"].value,
              f_azon_adat: this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value == null ? "" : this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value,
              f_szerzazon: this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value == null ? "" : this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value,
              f_modozat: this.karbejelentoAlapadatokForm.controls["f_modozat"].value,
              f_karido: this.karbejelentoAlapadatokForm.controls["f_karido"].value ? moment(this.karbejelentoAlapadatokForm.controls["f_karido"].value).format('YYYY.MM.DD') : ""
            }

            this.karbejelento.ellenor(model).subscribe(
              data => {

                if ((this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB" || this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "CASCO") &&
                  this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value && this.karbejelentoAlapadatokForm.controls["f_karido"].value) {

                  if (data["rendszam_szerzazon"] == null) {
                    this.karbejelentoAlapadatokForm.controls['f_rendszamHiba'].setValue(true);
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Nem megfelelő adat!",
                      confirmButtonText: 'Rendben'
                    });
                  }
                  else {
                    if (this.karbejelentoAlapadatokForm.controls["f_azon_tip"].value == "0") {
                      this.karbejelentoAlapadatokForm.controls["f_szerzazon"].setValue(data["rendszam_szerzazon"].f_szerz_azon);
                    }
                    else {
                      this.karbejelentoAlapadatokForm.controls["f_rendszam"].setValue(data["rendszam_szerzazon"].f_rendszam);
                    }
                  }

                }

                if (this.karbejelentoAlapadatokForm.controls['f_rendszamHiba'].value == false) {

                  if (data["szerzazon_modozat"] != null) {
                    if (data["szerzazon_modozat"].f_modkod == "") {
                      this.karbejelentoAlapadatokForm.controls['f_modozatHiba'].setValue(true);
                      this.karbejelentoAlapadatokForm.controls['f_modkod'].setValue(null);
                    }
                    else {

                      this.karbejelentoAlapadatokForm.controls['f_elviKockVeg'].setValue(new Date(moment(data["szerzazon_modozat"].f_elvi_kockveg).format('YYYY.MM.DD')));
                      this.karbejelentoAlapadatokForm.controls['f_kocKezd'].setValue(new Date(moment(data["szerzazon_modozat"].f_kockezd).format('YYYY.MM.DD')));
                      this.karbejelentoAlapadatokForm.controls['f_modkod'].setValue(data["szerzazon_modozat"].f_modkod);


                      let elvikockveg = this.karbejelentoAlapadatokForm.controls["f_elviKockVeg"].value;
                      let kockezd = this.karbejelentoAlapadatokForm.controls["f_kocKezd"].value;
                      let karido = new Date(moment(this.karbejelentoAlapadatokForm.controls['f_karido'].value).format('YYYY.MM.DD'));
                      let modozat = this.karbejelentoAlapadatokForm.controls['f_modozat'].value;
                      let hibauzi = "";

                      if (elvikockveg && elvikockveg < karido) {

                        switch (modozat) {
                          case "KGFB": {
                            /*hibauzi = "A bejelentett károkozó nem rendelkezik érvényes Kötelező Gépjármű Felelősség Biztosítással Társaságunknál, kérjük keresse fel saját biztosítóját, és kérjen szívességi kárfelvételt.";*/
                            hibauzi = "Az Ön által megadott károkozóként megjelölt gépjármű nem rendelkezik Társaságunknál érvényes Kötelező Gépjármű Felelősség Biztosítással. Kérjük kárbejelentésével forduljon a MABISZ ESZE-hez. MABISZ ESZE Elérhetőségei Központi telefonszám: (+36 1) 802-8400, Kárügyintézés telefax: (+36 1) 802-8499, Kárbejelentés: kar@mabisz.hu";
                            break;
                          }
                          case "CASCO": {
                            hibauzi = "A kiválasztott vagyon biztosítási szerződés Társaságunknál nem rendelkezik érvényes fedezettel. Kérjük keresse fel Kollégáinkat munkaidőben a 061/6666-283-as telefonszámon.";
                            break;
                          }
                          case "CMR": {
                            hibauzi = "A kiválasztott felelősségbiztosítási szerződés Társaságunknál nem rendelkezik érvényes fedezettel. Kérjük keresse fel Kollégáinkat munkaidőben a 061/6666-283-as telefonszámon.";
                            break;
                          }
                          case "OTTHON": {
                            hibauzi = "A kiválasztott vagyon biztosítási szerződés Társaságunknál nem rendelkezik érvényes fedezettel. Kérjük keresse fel Kollégáinkat munkaidőben a 061/6666-283-as telefonszámon.";
                            break;
                          }
                          case "TRAFIK": {
                            hibauzi = "A kiválasztott vagyon biztosítási szerződés Társaságunknál nem rendelkezik érvényes fedezettel. Kérjük keresse fel Kollégáinkat munkaidőben a 061/6666-283-as telefonszámon.";
                            break;
                          }

                        }
                      }
                      else {
                        if (kockezd && kockezd > karido) {
                          hibauzi = "Káresemény időpontja nem lehet kisebb mint a szerződés kockázat kezdete!";
                        }
                      }
                      if (hibauzi != "") {
                        this.karbejelentoAlapadatokForm.controls['f_modozatHiba'].setValue(true);
                        this.utilityService.dialogMegnyitasa({
                          error: true,
                          title: 'Hiba',
                          message: hibauzi,
                          confirmButtonText: 'Rendben'
                        });
                      }


                    }
                  }
                  else {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Nem megfelelő adat!",
                      confirmButtonText: 'Rendben'
                    });
                  }

                }

                if (!this.karbejelentoAlapadatokForm.controls['f_modozatHiba'].value && !this.karbejelentoAlapadatokForm.controls['f_rendszamHiba'].value) {
                  this.karbejelentoAlapadatokForm.controls['f_ellenorzes_ok'].setValue(true);
                }

                //this.duplaEllenorzes();
                this.toltoKereses = false;
              },
              () => {
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hiba az ellenörző függvények futtatása közben!",
                  confirmButtonText: 'Rendben'
                });
                this.toltoKereses = false;
              });
          });
        });
    }
  }

  /*duplaEllenorzes() {

    if (this.karbejelentoAlapadatokForm.controls["f_azonosito"].value == "0" &&
      this.karbejelentoAlapadatokForm.controls["f_szerzazon"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_karido"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_vontato_rendszam"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_rendszam"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_karosult_tipus"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_karosult_nev"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_modozat"].status == "VALID" &&
      this.karbejelentoAlapadatokForm.controls["f_modkod"].status == "VALID" &&
      ((this.karbejelentoAlapadatokForm.controls["f_modozat"].value != "KGFB" && this.karbejelentoAlapadatokForm.controls["f_kartipus"].status == "VALID") ||
        (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "KGFB" && this.karbejelentoAlapadatokForm.controls["f_kartipus_gfb"].status == "VALID"))) {
      this.karbejelentoDupla();
    }
    else {
      this.karbejelentoAlapadatokForm.controls["f_dupla"].setValue(true);
    }
  }

  karbejelentoDupla() {
    this.karbejelentoAlapadatokForm.controls["f_dupla"].setValue(false);

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          this.karbejelento.karbejelentoDupla(this.karbejelentoAlapadatokForm.value).subscribe(
            data => {
              if (data[0] != "N" || data[1] != "N" || data[2] != "N") {
                this.karbejelentoDuplaDialog();
              }
              else {
                if (data[0] == "N" && data[1] == "N" && data[2] === "N") {
                  this.karbejelentoAlapadatokForm.controls["f_dupla"].setValue(true);
                }
              }
            },
            () => {
              this.utilityService.dialogMegnyitasa({
                error: true,
                title: 'Hiba',
                message: "Hiba duplikált bejelentés ellenőrzése közben!",
                confirmButtonText: 'Rendben'
              });
            });
        });
      });
  }

  karbejelentoDuplaDialog() {
    const dialogRef = this.dialog.open(KarbejelentoDuplaDialog, {
      data: {}
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.karbejelentoAlapadatokForm.controls["f_dupla"].setValue(true);
      }
      else {
        this.formGroupNew();
        this.jellegFeltoltes();
        this.karosultTipusFeltoltes();
      }
    });
  }*/

  adatokLekerese() {


    if ((this.karbejelentoAlapadatokForm.get('f_modozat').value == 'KGFB' || this.karbejelentoAlapadatokForm.get('f_modozat').value == 'CASCO') && this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value) {
      if (this.karbejelentoAlapadatokForm.controls["f_azon_tip"].value == "1") {
        this.karbejelentoAlapadatokForm.controls["f_szerzazon"].setValue(this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value);
      }
      else {
        this.karbejelentoAlapadatokForm.controls["f_rendszam"].setValue(this.karbejelentoAlapadatokForm.controls["f_azon_adat"].value);
      }
      //this.ellenorzes();
    }

    this.kartipusFeltoltes("felület");

    if ((this.karbejelentoAlapadatokForm.get('f_modozat').value == 'CMR' || this.karbejelentoAlapadatokForm.get('f_modozat').value == 'OTTHON' || this.karbejelentoAlapadatokForm.get('f_modozat').value == 'TRAFIK') && this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value) {
      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "OTTHON" && this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value) {        
        this.kartipusFeltoltes("felület");
        this.karbejelentoAlapadatokForm.controls["f_gjm_jelleg"].setValue("0");
        this.karbejelentoAlapadatokForm.controls["f_gjm_jellegnev"].setValue(null);
        this.karbejelentoAlapadatokForm.controls["f_rendszam_tipus"].setValue("M");
        this.karbejelentoAlapadatokForm.controls["f_rendszam"].setValue(null);
        this.karbejelentoAlapadatokForm.controls["f_karosult_rendszam"].setErrors(null);
      }

      if (this.karbejelentoAlapadatokForm.controls["f_modozat"].value == "TRAFIK" && this.karbejelentoAlapadatokForm.controls["f_szerzazon"].value) {
        this.karbejelentoAlapadatokForm.controls["f_trafik_kockhely_ssz"].setValue("0");
        this.trafikKockhelyFeltoltes();
      }

      this.vonatatoRendszamFeltoltes("felület");      
      //this.duplaEllenorzes();
    }
    this.ellenorzes();
  }

}

export class AdatValidator extends Validators {

  static isDate(control: AbstractControl): { [key: string]: boolean } | null {

    if (control.value != null) {
      let isDate = false;
      isDate = control.value && !isNaN(Date.parse(control.value));
      if (!isDate) {
        return { 'isDate': true };
      }
    }
    return null;
  }


  static keremValasszon(control1_neve: string, control2_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      if (control2.value != "KGFB" && control1_neve == "f_kartipus" && control1.value == "0") {
        hasError = true;
      }

      if (control2.value == "TRAFIK" && control1_neve == "f_trafik_kockhely_ssz" && control1.value == "0") {
        hasError = true;
      }

      if (control2.value == "TRAFIK" && control1_neve == "f_trafik_reszmodozat" && control1.value == "0") {
        hasError = true;
      }

      if ((control1_neve == "f_modozat" || control1_neve == "f_modkod") &&
        (control1.value == null || control1.value == "" || control1.value == "0")) {
        hasError = true;
      }

      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static modozatSpecifikusKotelezoElem(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string, control5_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;

      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      const control5 = formGroup.controls[control5_neve];

      if (control2 != null && control2.value == "KGFB") {

        if (control1_neve == "f_azon_adat" && control1.value == null) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

        if (control1_neve == "f_kartipus_gfb" && control1.value == null) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

        if (control1_neve == "f_gjm_jelleg" && control4.value && control4.value.indexOf('E56101') > -1 && control1.value == "0") {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }


        if (control1_neve == "f_karosult_tipus" && control4.value && control4.value.indexOf('E56201') > -1 && control1.value == "0") {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

        if (control1_neve == "f_karosult_nev" && control4.value && control4.value.indexOf('E56201') > -1 && (control1.value == null || control1.value == "")) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

      }
      else {
        if (control1_neve == "f_kartipus" && control1.value == null) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }
      }

      if (control1_neve == "f_karosult_rendszam") {
        if (control4 != null && control4.value && control4.value.indexOf('E56101') > -1 && (control1.value == null || control1.value == "") && (control5 != null && control5.value != "segédmotoros kerékpár" && control5.value != "munkagép" && control5.value != "quad")) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }
        else {
          const rendszam_tipus = formGroup.controls["f_rendszam_tipus"];
          if (control2 != null && rendszam_tipus.value == "M" && control1.status == "VALID" && (control1.value != null && control1.value != "") && (control1.value.length < 6 || control1.value.length > 7)) {
            hasError = true;
            control1.setErrors({ rendszamHossz: true });
          }
          else {
            if (control2 != null && control2.value == "OTTHON" && control3.value == 'E57111' && (control5.value != "segédmotoros kerékpár" && control5.value != "motorkerékpár" && control5.value != "személygépkocsi")) {
              hasError = true;
              control1.setErrors({ modozatSpecifikusKotelezoElem: true });
            }
          }
        }
      }

      if (control2 != null && control2.value == "CASCO") {
        if (control1_neve == "f_azon_adat" && control1.value == null) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }
      }

      if (control2 != null && control2.value == "OTTHON") {
        if (control1_neve == "f_gjm_jelleg" && control3.value == 'E57111' && control1.value == "0") {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

      }

      if (control2 != null && (control2.value == "OTTHON" || control2.value == "TRAFIK")) {

        if (control1_neve == "f_becsult_osszeg" && control1.value == null) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

      }

      if (control2 != null && control2.value == "CMR") {
        if ((control1_neve == "f_vontato_rendszam" && control1.value == "0") && (control3_neve == "f_rendszam" && (control3.value == null || control3.value == ""))) {
          hasError = true;
          control1.setErrors({ modozatSpecifikusKotelezoElem: true });
        }

      }

      if (control1_neve == "f_karido") {
        if (control1.value == null || control1.value == "") {
          hasError = true;
          control1.setErrors({ required: true });
        }
        else {
          let isDate = false;
          isDate = control1.value && !isNaN(Date.parse(control1.value));
          if (!isDate) {
            hasError = true;
            control1.setErrors({ isDate: true });
          }
          else {
            if (new Date(moment(new Date()).format('YYYY-MM-DD')) < new Date(moment(control1.value).format('YYYY-MM-DD'))) {
              hasError = true;
              control1.setErrors({ maxDateHiba: true });
            }
          }
        }
      }

      if (!hasError) {
        control1.setErrors(null);
      }

    }
    return validator;
  }

}
