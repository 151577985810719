import { ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { WebAppsService } from '../../services/webApps.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BefizetesService } from '../../services/befizetes.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { CommonValidator, UtilityService } from '@granit/common-library';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { FairpayService } from '../../services/fairpay.service';
import { SimplePayService } from '../../services/simplepay.service';


@Component({
  selector: 'befizetes',
  templateUrl: './befizetes.component.html',
  styleUrls: ['./befizetes.component.scss']
})
export class BefizetesComponent implements OnInit {

  befizetesForm: FormGroup;
  befizetesOsszegekForm: FormGroup;
  befizetesEredmenyForm: FormGroup;
  fizetesMentesForm: FormGroup;
  szerzazonMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  adoszamMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  hibaszoveg: string;
  url: string = "";
  visszateres: boolean = false;
  szerzazon: string = "0";
  nevVisible: boolean = false;
  routerUrl: string = '';
  rogzito: string = '';
  dazon: string = '';
  uzemSzunetCibLista: any[];
  uzemSzunetCibMost: any;
  uzemSzunetFairpayLista: any[];
  uzemSzunetFairpayMost: any;
  ajanlatAzon: string = "0";
  ajanlatKereses: boolean = false;
  toltoKereses: boolean = false;
  uzemSzunetSimplePayLista: any[];
  uzemSzunetSimplePayMost: any;

  haviAdatokLista: any[] = [];
  haviAdatok: any[] = [
    { columnDef: 'f_valasztva', header: '', adattipus: 'check' },
    { columnDef: 'f_idoszaktol', header: 'Időszaktól', adattipus: 'date', dateFormat: 'YYYY.MM.DD', class: "cellaban-kozepre" },
    { columnDef: 'f_idoszakig', header: 'Időzakig', adattipus: 'date', dateFormat: 'YYYY.MM.DD', class: "cellaban-kozepre" },
    { columnDef: 'f_szoveg', header: 'Típus', class: "cellaban-kozepre" },
    { columnDef: 'f_fizetendo', header: 'Fizetendő', adattipus: 'number', numberFormat: 'thousand', class: "cellaban-jobbra" }
  ];
  getDisplayedHhaviAdatok(): string[] {
    let displayedColumns = this.haviAdatok.filter(c => c.visible != false).map(c => c.columnDef);
    return displayedColumns;
  }
  haviAdatokDataSource: MatTableDataSource<any>;

  constructor(private webApps: WebAppsService,
    private formBuilder: FormBuilder,
    private befizetes: BefizetesService,
    private fairpay: FairpayService,
    private simplePay: SimplePayService,
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityService,
    private sanitized: DomSanitizer,
    private recaptchaV3Service: ReCaptchaV3Service,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private cdr: ChangeDetectorRef) {


    // Kiszedjuk a route nevét
    this.routerUrl = this.router.url.split('?')[0].substring(1);

    if (this.routerUrl == "befizetes" || this.routerUrl == "ajanlat-befizetes") {
      this.uzemSzunet();
    }

    if (this.uzemSzunetCibMost == null || this.uzemSzunetFairpayMost == null) {
      this.url = window.location.href;
      if (this.url.lastIndexOf("&amp;") >= 0) {
        this.url = this.sanitized.bypassSecurityTrustHtml(this.url).toString();
        this.router.navigate([this.url]);
      }
      else {
        this.formGroupNew();

        if (this.routerUrl == "befizetes" || this.routerUrl == "ajanlat-befizetes") {
          this.befizetesOsszegekForm.controls["f_fizmod"].setValue("S");
        }

        this.befizetesForm.controls["f_befizetes_tipus"].setValue(this.routerUrl);
        this.visszateres = false;
        this.route.queryParams.subscribe((params: Params) => {
          const _params = this.toLower(params);

          if (this.routerUrl == "ajanlat-befizetes" || this.routerUrl == "ajanlat-kp-pos") {
            if (_params['mac']) {
              if (this.routerUrl == "ajanlat-kp-pos") {
                this.rogzito = _params['allssz'];
                this.dazon = _params['dazon'];
              }
              this.ajanlatKereses = true;
              this.urlEllenorzesAjanlat(this.url, _params['a']);
            }
            else {
              this.ajanlatAzon = "0";
              if (_params['pid'] && _params['data']) {
                this.visszateres = true;
                //this.eredmeny(_params['data']);
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hibás link!",
                  confirmButtonText: 'Rendben'
                });                
              }
            }
          }
          else if (this.routerUrl == "kp-pos") {
            this.rogzito = _params['allssz'];
            this.dazon = _params['dazon'];
          }
          else {
            if (_params['mac']) {
              this.urlEllenorzes(this.url, _params['sz']);
            }
            else {
              this.szerzazon = "0";
              if (_params['pid'] && _params['data']) {
                this.visszateres = true;
                /*this.eredmeny(_params['data']);*/
                this.utilityService.dialogMegnyitasa({
                  error: true,
                  title: 'Hiba',
                  message: "Hibás link!",
                  confirmButtonText: 'Rendben'
                });                
              }
            }
          }
        });
      }
    }
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }

  urlEllenorzes(url: string, azonosito: string) {
    this.utilityService.isValidUrl(url).subscribe(
      adat => {
        if (adat) {
          this.szerzazon = azonosito;
          this.befizetesForm.controls["f_szerzazon"].setValue(this.szerzazon);
          this.befizetesForm.controls["f_egyszeru_kereses"].setValue("I");
          this.befizetesForm.controls["f_ajazon"].setValue("0");
          this.kereses();
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás link!",
            confirmButtonText: 'Rendben'
          });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: errorResult,
          confirmButtonText: 'Rendben'
        });
      });
  }

  fizmodValtozas() {
    console.info(this.befizetesOsszegekForm.controls["f_fizmod"].value);
    this.befizetesOsszegekForm.controls["f_at_nyilatkozat"].setValue(null);
  }
  urlEllenorzesAjanlat(url: string, azonosito: string) {
    this.utilityService.isValidUrl(url).subscribe(
      adat => {
        if (adat) {
          this.ajanlatAzon = azonosito;
          this.befizetesForm.controls["f_ajazon"].setValue(this.ajanlatAzon);
          this.kereses();
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás link!",
            confirmButtonText: 'Rendben'
          });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: errorResult,
          confirmButtonText: 'Rendben'
        });
      });
  }

  ngOnInit() {
    this.webApps.changeMessage(this.routerUrl == "kp-pos" || this.routerUrl == "ajanlat-kp-pos" ? "Kp-Pos Befizetés" : "Online Befizetés");
    /*if (this.szerzazon != "0") {
      this.befizetesForm.controls["f_szerzazon"].setValue(this.szerzazon);
      this.befizetesForm.controls["f_egyszeru_kereses"].setValue("I");
      this.kereses();
    }*/
    //this._renderer2.appendChild(this._document.body, script);
  }

  formGroupNew() {
    this.befizetesForm = this.formBuilder.group({
      f_szerzazon: new FormControl(null, [Validators.required]),
      f_ugyfelnev: new FormControl(null),
      f_neme: new FormControl("0", CommonValidator.defaultEllenorzo("0")),
      f_szuldat: new FormControl(null),
      f_adoszam: new FormControl(null),
      f_modkod: new FormControl(''),
      f_allapot: new FormControl(''),
      f_dijfizgyak: new FormControl(null),
      f_kereses_panel: new FormControl("I"),
      f_egyszeru_kereses: new FormControl("N"),
      f_befizetes_tipus: new FormControl(this.routerUrl),
      f_torolt: new FormControl(""),
      f_ajazon: new FormControl("0"),
      f_alkuszkod: new FormControl(null),
      f_vonalkod: new FormControl(null),
      f_fedezet: new FormControl(null),
      f_email: new FormControl(null),
      f_irsz: new FormControl(null),
      f_telepules: new FormControl(null),
      f_cim: new FormControl(null)
    }, {
      validators: [
        //AdatValidator.keremValasszon('f_neme'),
        AdatValidator.hosszValidator('f_szerzazon'),
        AdatValidator.hosszValidator('f_adoszam')
      ]
    });

    this.befizetesOsszegekForm = this.formBuilder.group({
      f_elmaradt_dij: new FormControl(0),
      f_fedezetlensegi_dij: new FormControl(0),
      //f_baleseti_ado: new FormControl(0),
      f_elso_dij: new FormControl(0),
      f_egyenleg: new FormControl(0),
      f_osszesen: new FormControl(0),
      f_befizetes_osszeg: new FormControl(null),
      f_fizetes_tipus: new FormControl("0"),
      f_fizetes_azonosito: new FormControl(null),
      f_hibaszoveg: new FormControl(""),
      f_fizmod: new FormControl(""),
      f_at_nyilatkozat: new FormControl(null)
    }, {
      validators: [
        AdatValidator.keremValasszon('f_fizetes_tipus', this.routerUrl),
        AdatValidator.kp_posValidator('f_befizetes_osszeg', this.routerUrl),
        AdatValidator.kp_posValidator('f_fizetes_azonosito', this.routerUrl)
      ]
    });

    this.befizetesEredmenyForm = this.formBuilder.group({
      tranazon: new FormControl(""),
      valaszkod: new FormControl(""),
      valaszuzenet: new FormControl(""),
      engedelyszam: new FormControl(""),
      osszeg: new FormControl(""),
      filenev: new FormControl(""),
      file: new FormControl(""),
      f_hibaszoveg: new FormControl("")
    });

    this.scrollToElement('kereses');
  }

  nemValtozas() {

    if (this.befizetesForm.controls["f_neme"].value == "0") {

      this.befizetesForm.controls["f_szuldat"].setValue(null);
      this.befizetesForm.controls['f_szuldat'].setValidators(null);

      this.befizetesForm.controls["f_adoszam"].setValue(null);
      this.befizetesForm.controls['f_adoszam'].setValidators(null);
    }
    if (this.befizetesForm.controls["f_neme"].value == "J") {

      this.befizetesForm.controls["f_szuldat"].setValue(null);
      this.befizetesForm.controls['f_szuldat'].setValidators(null);

      this.befizetesForm.controls['f_adoszam'].setValidators(Validators.required);
    }
    else {
      this.befizetesForm.controls["f_adoszam"].setValue(null);
      this.befizetesForm.controls['f_adoszam'].setValidators(null);

      this.befizetesForm.controls['f_szuldat'].setValidators(Validators.required);
    }
    this.befizetesForm.controls['f_adoszam'].updateValueAndValidity();
    this.befizetesForm.controls['f_szuldat'].updateValueAndValidity();
  }

  kereses() {

    this.toltoKereses = true;

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_befizetes_kereses').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          this.befizetesForm.controls["f_kereses_panel"].setValue("N");
          this.befizetes.keresesOnlineFizeteshez(this.befizetesForm.value).subscribe(
            data => {

              this.befizetesForm.controls["f_ugyfelnev"].setValue(data.ugyfelNev);

              if (this.befizetesForm.controls["f_egyszeru_kereses"].value == "I") {
                this.befizetesForm.controls["f_neme"].setValue(data.ugyfelTipus);
                this.befizetesForm.controls["f_szuldat"].setValue(data.szulDat);
                this.befizetesForm.controls["f_adoszam"].setValue(data.adoszam);
              }

              if (data.eredmenyKod != 0) {

                this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue("<pre>" + data.eredmenySzoveg + "</pre>");
              }
              else {

                let osszesen: number;
                let elmaradt: number;
                let fedezetlensegi: number;
                let egyenleg: number;

                if (this.routerUrl == "ajanlat-kp-pos" || this.routerUrl == "ajanlat-befizetes") {

                  this.befizetesForm.controls["f_ugyfelnev"].setValue(data["ajanlatAdatok"].ugyfelNev);
                  this.befizetesForm.controls["f_neme"].setValue(data["ajanlatAdatok"].ugyfelTipus);
                  this.befizetesForm.controls["f_szuldat"].setValue(data["ajanlatAdatok"].szulDat);
                  this.befizetesForm.controls["f_adoszam"].setValue(data["ajanlatAdatok"].adoszam);

                  this.befizetesForm.controls["f_modkod"].setValue(data["ajanlatAdatok"].modozat);
                  this.befizetesForm.controls["f_allapot"].setValue(data["ajanlatAdatok"].allapot);
                  this.befizetesForm.controls["f_dijfizgyak"].setValue(data["ajanlatAdatok"].dijFizGyak);
                  this.befizetesForm.controls["f_alkuszkod"].setValue(data["ajanlatAdatok"].alkuszkod);
                  this.befizetesForm.controls["f_fedezet"].setValue(data["ajanlatAdatok"].fedezet);

                  this.befizetesForm.controls["f_email"].setValue(data["ajanlatAdatok"].email);
                  this.befizetesForm.controls["f_irsz"].setValue(data["ajanlatAdatok"].irsz);
                  this.befizetesForm.controls["f_telepules"].setValue(data["ajanlatAdatok"].telepules);
                  this.befizetesForm.controls["f_cim"].setValue(data["ajanlatAdatok"].cim);

                  this.befizetesForm.controls["f_torolt"].setValue("N");

                  elmaradt = data["ajanlatAdatok"].elmaradtDij == null ? 0 : Math.trunc(data["ajanlatAdatok"].elmaradtDij);
                  fedezetlensegi = data["ajanlatAdatok"].fedezetlensegiDij == null ? 0 : Math.trunc(data["ajanlatAdatok"].fedezetlensegiDij);
                  egyenleg = data["ajanlatAdatok"].egyenleg == null ? 0 : Math.trunc(data["ajanlatAdatok"].egyenleg);
                  osszesen = egyenleg;

                  if (this.routerUrl == "ajanlat-befizetes") {
                    this.befizetesOsszegekForm.controls["f_egyenleg"].setValue(this.befizetesForm.controls["f_modkod"].value == "31101" ? 0 : egyenleg);
                    this.befizetesOsszegekForm.controls["f_elso_dij"].setValue(this.befizetesForm.controls["f_modkod"].value == "31101" ? egyenleg : 0);

                    this.befizetesOsszegekForm.controls["f_elmaradt_dij"].setValue(this.befizetesForm.controls["f_modkod"].value == "31101" ? elmaradt : 0);
                    this.befizetesOsszegekForm.controls["f_fedezetlensegi_dij"].setValue(this.befizetesForm.controls["f_modkod"].value == "31101" ? fedezetlensegi : 0);
                    osszesen = elmaradt + fedezetlensegi + egyenleg;
                  }
                  if (this.routerUrl == "ajanlat-kp-pos") {
                    this.befizetesOsszegekForm.controls["f_befizetes_osszeg"].setValue(egyenleg);
                  }
                  //this.befizetesOsszegekForm.controls["f_osszesen"].setValue(osszesen);
                  this.befizetesOsszegekForm.controls["f_osszesen"].setValue(osszesen < 0 ? 0 : osszesen);
                }
                else {
                  this.befizetesForm.controls["f_ugyfelnev"].setValue(data["szerzodesAdatok"].ugyfelNev);
                  this.befizetesForm.controls["f_neme"].setValue(data["szerzodesAdatok"].ugyfelTipus);
                  this.befizetesForm.controls["f_szuldat"].setValue(data["szerzodesAdatok"].szulDat);
                  this.befizetesForm.controls["f_adoszam"].setValue(data["szerzodesAdatok"].adoszam);

                  this.befizetesForm.controls["f_modkod"].setValue(data["szerzodesAdatok"].modozat);
                  this.befizetesForm.controls["f_allapot"].setValue(data["szerzodesAdatok"].allapot);
                  this.befizetesForm.controls["f_dijfizgyak"].setValue(data["szerzodesAdatok"].dijFizGyak);
                  this.befizetesForm.controls["f_alkuszkod"].setValue(data["szerzodesAdatok"].alkuszkod);

                  this.befizetesForm.controls["f_email"].setValue(data["szerzodesAdatok"].email);
                  this.befizetesForm.controls["f_irsz"].setValue(data["szerzodesAdatok"].irsz);
                  this.befizetesForm.controls["f_telepules"].setValue(data["szerzodesAdatok"].telepules);
                  this.befizetesForm.controls["f_cim"].setValue(data["szerzodesAdatok"].cim);

                  this.befizetesForm.controls["f_torolt"].setValue(data["szerzodesAdatok"].allapot.substring(0, 1) >= "6" ? "I" : "N");

                  elmaradt = data["dijAdatok"].elmaradtDij == null ? 0 : Math.trunc(data["dijAdatok"].elmaradtDij);
                  fedezetlensegi = data["dijAdatok"].fedezetlensegiDij == null ? 0 : Math.trunc(data["dijAdatok"].fedezetlensegiDij);
                  egyenleg = data["dijAdatok"].egyenleg == null ? 0 : Math.trunc(data["dijAdatok"].egyenleg);

                  if (data["szerzodesAdatok"].modozat != "31101") {
                    if (data["szerzodesAdatok"].dijFizGyak == "H" && this.befizetesForm.controls["f_torolt"].value == "N") {
                      osszesen = 0;
                      this.haviAdatokLista = [];
                      this.haviAdatokLista = data["haviDijAdatok"];
                    }
                    else {
                      this.befizetesOsszegekForm.controls["f_elmaradt_dij"].setValue(0);
                      this.befizetesOsszegekForm.controls["f_fedezetlensegi_dij"].setValue(0);
                      this.befizetesOsszegekForm.controls["f_elso_dij"].setValue(0);
                      this.befizetesOsszegekForm.controls["f_egyenleg"].setValue(egyenleg);
                      osszesen = egyenleg;
                    }
                  }
                  else {
                    this.befizetesOsszegekForm.controls["f_elmaradt_dij"].setValue(elmaradt);
                    this.befizetesOsszegekForm.controls["f_fedezetlensegi_dij"].setValue(fedezetlensegi);
                    this.befizetesOsszegekForm.controls["f_egyenleg"].setValue(egyenleg);
                    this.befizetesOsszegekForm.controls["f_elso_dij"].setValue(0);

                    osszesen = elmaradt + fedezetlensegi + egyenleg;
                  }
                  this.befizetesOsszegekForm.controls["f_osszesen"].setValue(osszesen < 0 ? 0 : osszesen);
                }
              }
              this.toltoKereses = false;

              this.cdr.detectChanges();
              this.scrollToElement('eredmeny');
            },
            errorResult => {
              this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
              this.toltoKereses = false;
            });
        })
      });
  }

  scrollToElement(elementId: string): void {
    const element = this._document.getElementById(elementId);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const top = absoluteElementTop - 100; // Offset by -100px
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    }
  }

  haviFizetesValaszt(checked: boolean, index?: number) {
    this.haviAdatokLista[index].f_valasztva = checked;
    let szumma: number = 0;
    this.haviAdatokLista.forEach(adat => {
      if (adat.f_valasztva) {
        szumma += adat.f_fizetendo;
      }
    })
    this.befizetesOsszegekForm.controls["f_osszesen"].setValue(szumma);
  }

  fizetesInditas() {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_befizetes_fizetesInditas').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {

          let szerzAzon: string = null;

          if (this.befizetesForm.controls["f_szerzazon"].value != null) {
            szerzAzon = this.befizetesForm.controls["f_szerzazon"].value.toString();
          }

          if (this.befizetesOsszegekForm.controls["f_fizmod"].value.toString() == "C" || this.routerUrl == "kp-pos" || this.routerUrl == "ajanlat-kp-pos") {
            let model = {
              f_szerzazon: szerzAzon,
              f_ajazon: this.ajanlatAzon == "0" ? "" : this.ajanlatAzon,
              f_modkod: this.befizetesForm.controls["f_modkod"].value,
              f_alkuszkod: this.befizetesForm.controls["f_alkuszkod"].value,
              routerUrl: this.routerUrl,
              rogzito: this.rogzito,
              dazon: this.dazon,
              befizetesOsszegekForm: this.befizetesOsszegekForm.value,
            };

            //this.befizetes.fizetesInditas(this.befizetesForm.controls["f_szerzazon"].value.toString(), this.routerUrl, this.rogzito, this.dazon, this.befizetesOsszegekForm.value).subscribe(
            this.befizetes.fizetesInditas(model).subscribe(
              data => {
                switch (data.eredmenyKod) {
                  case 0:
                    if (this.routerUrl == "befizetes" || this.routerUrl == "ajanlat-befizetes") {
                      window.location.replace(data.onlineFizetesUgyfelUrl);
                    }
                    else {
                      this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue("Sikeres mentés");
                    }
                    break;
                  case 1:
                    this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue("Hibás paraméterek");
                    break;
                  case 2:
                    this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue("Fogadó oldali hiba");
                    break;
                  case 3:
                    this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue("Fizetési tranzakció elutasítása");
                    break;
                  case 4:
                    this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue("Üzemszünet");
                    break;
                }
              },
              errorResult => {
                this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
              });
          }
          else if (this.befizetesOsszegekForm.controls["f_fizmod"].value.toString() == "F") {
            let model = {
              f_befizetes_osszeg: this.befizetesOsszegekForm.controls["f_osszesen"].value,
              f_ugyfelnev: this.befizetesForm.controls["f_ugyfelnev"].value,
              f_modkod: this.befizetesForm.controls["f_modkod"].value,
              f_ajazon: this.ajanlatAzon,
              f_szerz_azon: szerzAzon,
              f_dijfizgyak: this.befizetesForm.controls["f_dijfizgyak"].value,
              f_fedezet: this.befizetesForm.controls["f_fedezet"].value,
              f_mododozat_nev: this.modozatNev(this.befizetesForm.controls["f_modkod"].value),
              f_fizetes_tipus: this.routerUrl
              //befizetesOsszegekForm: this.befizetesOsszegekForm.value
            };

            this.fairpay.newFairpayTransaction(model).subscribe(
              data => {
                if (data.redirectUrl) {
                  window.location.replace(data.redirectUrl); // atirányítás a fairpay oldalra
                }
                else {
                  this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(data.fairpayId ? "Hiba történt a tranzakció indítás során (FairPay azonosító:" + data.fairpayId + ")" : "Hiba történt a tranzakció indítás során.");
                }
              },
              errorResult => {
                this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
              });
          }
          else if (this.befizetesOsszegekForm.controls["f_fizmod"].value.toString() == "S") {
            let model = {
              f_befizetes_osszeg: this.befizetesOsszegekForm.controls["f_osszesen"].value,
              f_ugyfelnev: this.befizetesForm.controls["f_ugyfelnev"].value,
              f_modkod: this.befizetesForm.controls["f_modkod"].value,
              f_ajazon: this.ajanlatAzon,
              f_szerz_azon: szerzAzon,
              f_dijfizgyak: this.befizetesForm.controls["f_dijfizgyak"].value,
              f_fedezet: this.befizetesForm.controls["f_fedezet"].value,
              f_mododozat_nev: this.modozatNev(this.befizetesForm.controls["f_modkod"].value),
              f_fizetes_tipus: this.routerUrl,
              f_email: this.befizetesForm.controls["f_email"].value,
              f_irsz: this.befizetesForm.controls["f_irsz"].value,
              f_telepules: this.befizetesForm.controls["f_telepules"].value,
              f_cim: this.befizetesForm.controls["f_cim"].value
            };

            this.simplePay.newSimplePayTransaction(model).subscribe(
              data => {
                if (data.redirectUrl) {
                  window.location.replace(data.redirectUrl); // atirányítás a SimplePay oldalra
                }
                else {
                  this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(data.simplePayId ? "Hiba történt a tranzakció indítás során (SimplePay azonosító:" + data.simplePayId + ")" : "Hiba történt a tranzakció indítás során.");
                }
              },
              errorResult => {
                this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
              });
          }


        })
      });
  }

  modozatNev(modozatKod: string) {
    switch (modozatKod) {
      case "23000": return "Utasbiztosítás";
      case "31101": return "Kötelező gépjármű-felelősségbiztosítás";
      case "21101": return "CASCO gépjármű biztosítás";
      case "21111": return "Flotta CASCO gépjármű biztosítás";
      case "22101": return "Otthon - Garzon biztosítás";
      case "22102": return "Otthon - Centrum biztosítás";
      case "22103": return "Otthon - Trend biztosítás";
      case "22104": return "Megóv-lak Otthonbiztosítás";
      case "22105": return "Házmester Otthonbiztosítás";
      case "29001": return "Általános vagyon biztosítás";
      case "32101": return "Közúti Árufuvarozói Felelősségbiztosítás";
      case "32102": return "Közúti Személyszállítók Felelősségbiztosítás";
      case "32121": return "Nemzetközi közúti árufuvarozói felelősség biztosítás";
      case "32141": return "Szállítmánybiztosítás";
      case "32142": return "Szállítmánybiztosítás";
      case "39001": return "Általános felelősség biztosítás";
      default: return "Biztosítás";
    }
  }

  eredmeny(data: any) {
    if (data != "") {
      this.toltoKereses = true;

      let model = { fizetesEredmeny: encodeURI(data) };

      this.befizetes.fizetesLezaras(model).subscribe(
        adat => {
          switch (adat.eredmenyKod) {
            case 0:
            case 3:
              this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("");
              this.befizetesEredmenyForm.controls["filenev"].setValue(adat.fajlnev);
              this.befizetesEredmenyForm.controls["file"].setValue(adat.fajl);
              this.befizetesEredmenyForm.controls["tranazon"].setValue(adat.trid);
              this.befizetesEredmenyForm.controls["valaszkod"].setValue(adat.rc);
              this.befizetesEredmenyForm.controls["valaszuzenet"].setValue(adat.rt);
              this.befizetesEredmenyForm.controls["engedelyszam"].setValue(adat.anum);
              this.befizetesEredmenyForm.controls["osszeg"].setValue(adat.amo);
              break;
            case 1:
              this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("Hibás paraméterek");
              break;
            case 2:
              this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("Fogadó oldali hiba");
              break;
            case 4:
              this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue("Üzemszünet");
              break;
          }
          this.toltoKereses = false;
        },
        errorResult => {
          this.befizetesEredmenyForm.controls["f_hibaszoveg"].setValue(errorResult);
          this.toltoKereses = false;
        });
    }
  }

  eredmenyLetoltes() {
    let contentType = 'application/pdf';
    let blob = base64StringToBlob(this.befizetesEredmenyForm.controls["file"].value, contentType);
    saveAs(blob, this.befizetesEredmenyForm.controls["filenev"].value, { autoBOM: true });
  }

  uzemSzunet() {

    this.befizetes.uzemSzunet().subscribe(
      data => {
        if (data["eredmenyKod"] == "0") {
          if (data["uzemszunetek"]) {
            this.uzemSzunetCibLista = data["uzemszunetek"];
            this.uzemSzunetCibMost = data["uzemszunetek"].find(item => moment(item.kezdete) <= moment(new Date()) && moment(item.vege) >= moment(new Date()));
            //if (this.uzemSzunetCibMost) {
            //  this.uzemSzunetCibSzoveg = "<pre><p><b>Tisztelt ügyfelünk!</b></p><p>A bankkártyás fizetés szolgáltatás az oldalon jelenleg nem elérhető.<p>A szolgáltatáskiesés várható intervalluma: <b>" + this.uzemSzunetCibMost.kezdete + " - " + this.uzemSzunetCibMost.vege + "</b></p><p>A szolgáltatáskiesés oka:<br /><b>" + this.uzemSzunetCibMost.leiras + "</b></p><p>Üdvözlettel:<br />Gránit Biztosító</p></pre>";
            //}
          }
        }
      },
      errorResult => {
        this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
      });

    this.fairpay.fairpayUzemSzunet().subscribe(
      data => {
        if (data["eredmenyKod"] == "0") {
          if (data["uzemszunetek"]) {
            this.uzemSzunetFairpayLista = data["uzemszunetek"];
            this.uzemSzunetFairpayMost = data["uzemszunetek"].find(item => moment(item.kezdete) <= moment(new Date()) && moment(item.vege) >= moment(new Date()));
            //if (this.uzemSzunetFairpayMost) {
            //  this.uzemSzunetFairpaySzoveg = "<pre><p><b>Tisztelt ügyfelünk!</b></p><p>A bankkártyás fizetés szolgáltatás az oldalon jelenleg nem elérhető.<p>A szolgáltatáskiesés várható intervalluma: <b>" + this.uzemSzunetFairpayMost.kezdete + " - " + this.uzemSzunetFairpayMost.vege + "</b></p><p>A szolgáltatáskiesés oka:<br /><b>" + this.uzemSzunetFairpayMost.leiras + "</b></p><p>Üdvözlettel:<br />Gránit Biztosító</p></pre>";
            //}
          }
        }
      },
      errorResult => {
        this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
      });

    this.simplePay.simplePayUzemSzunet().subscribe(
      data => {
        if (data["eredmenyKod"] == "0") {
          if (data["uzemszunetek"]) {
            this.uzemSzunetSimplePayLista = data["uzemszunetek"];
            this.uzemSzunetSimplePayMost = data["uzemszunetek"].find(item => moment(item.kezdete) <= moment(new Date()) && moment(item.vege) >= moment(new Date()));
            //if (this.uzemSzunetFairpayMost) {
            //  this.uzemSzunetFairpaySzoveg = "<pre><p><b>Tisztelt ügyfelünk!</b></p><p>A bankkártyás fizetés szolgáltatás az oldalon jelenleg nem elérhető.<p>A szolgáltatáskiesés várható intervalluma: <b>" + this.uzemSzunetFairpayMost.kezdete + " - " + this.uzemSzunetFairpayMost.vege + "</b></p><p>A szolgáltatáskiesés oka:<br /><b>" + this.uzemSzunetFairpayMost.leiras + "</b></p><p>Üdvözlettel:<br />Gránit Biztosító</p></pre>";
            //}
          }
        }
      },
      errorResult => {
        this.befizetesOsszegekForm.controls["f_hibaszoveg"].setValue(errorResult);
      });

  }
}

export class AdatValidator extends Validators {
  static keremValasszon(control1_neve: string, router: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      if ((((router == "kp-pos" || router == "ajanlat-kp-pos") && control1_neve == "f_fizetes_tipus") || (router != "kp-pos" && router != "ajanlat-kp-pos" && control1_neve == "f_fizmod")) && control1.value == "0") {
        hasError = true;
      }
      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static hosszValidator(control1_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      if (control1.value != null && control1.value != "") {
        control1.setErrors(null);
        if (control1_neve == "f_szerzazon" && control1.value.split("_").join("").length != 8) {
          control1.setErrors({ hosszValidator: true });
        }

        if (control1_neve == "f_adoszam" && control1.value.split("_").join("").length != 11) {
          control1.setErrors({ hosszValidator: true });
        }
      }
    }
    return validator;
  }

  static kp_posValidator(control1_neve: string, router: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      if (control1) {
        control1.setErrors(null);
        if (router == "kp-pos" || router == "ajanlat-kp-pos") {
          if (control1.value != null && control1.value != "") {
            if (control1_neve == "f_befizetes_osszeg") {
              if (Number.isNaN(Number(control1.value))) {
                control1.setErrors({ szamValidator: true });
              }
              else if (Number(control1.value) <= 0) {
                control1.setErrors({ pozitivSzamValidator: true });
              }
            }
          }
          else {
            control1.setErrors({ hianyValidator: true });
          }
        }
      }
    }
    return validator;
  }

}
