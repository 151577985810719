<div class="contain-lg">

  <form [formGroup]="karbejelentoKarosultForm" class="form">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">
          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-icon class="info-icon" matTooltip={{tooltipLabel}} *ngIf="tooltipLabel">info</mat-icon>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-4" *ngIf="karbejelentoKarosultForm.get('f_modozat').value != 'CMR' && karbejelentoKarosultForm.get('f_bejelento_tipusa').value != 'J' && !(karbejelentoKarosultForm.get('f_modozat').value == 'KGFB' && karbejelentoKarosultForm.get('f_bejelento_tipusa').value == 'e')">
              <mat-checkbox matCheckbox formControlName="f_egyezik" class="mat-checkbox" (change)="egyezikValtozas()">Az adatok megegyeznek a bejelentőével</mat-checkbox>
            </div>
          </div>

          <div [class.disableDiv]="karbejelentoKarosultForm.get('f_egyezik').value">

            <div class="row mt-3">
              <div class="col-lg-2" *ngIf="karbejelentoKarosultForm.get('f_modozat').value != 'CMR' && karbejelentoKarosultForm.get('f_modozat').value != 'OTTHON' && karbejelentoKarosultForm.get('f_modozat').value != 'TRAFIK'">
                <mat-form-field appearance="fill">
                  <mat-label>Károsult típusa:  *</mat-label>
                  <mat-select matInput formControlName="f_karosult_tipusa">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let tipus of karosultTipusLista" [value]="tipus.f_kod">{{tipus.f_megnevezes}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_karosult_tipusa').errors?.keremValasszon">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Név:  *</mat-label>
                  <input matInput formControlName="f_nev">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_nev').errors?.required">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2" *ngIf="karbejelentoKarosultForm.get('f_modozat').value != 'CMR'">
                <mat-form-field appearance="fill">
                  <mat-label>Születési dátum:</mat-label>
                  <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_szul_datum" [max]="szulDatumMaxDate">
                  <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                  <mat-datepicker #szulDatumPicker></mat-datepicker>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_szul_datum').errors?.matDatepickerParse">
                    Hibás dátum!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2" *ngIf="karbejelentoKarosultForm.get('f_modozat').value != 'CMR'">
                <mat-form-field appearance="fill">
                  <mat-label>Születési hely:</mat-label>
                  <mat-select matInput formControlName="f_szul_hely">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let telepules of szulTelepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-label class="control-label">{{lakcimLabel}}</mat-label>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-select matInput formControlName="f_cim_mo" (selectionChange)="orszagTipusValtozas()">
                    <mat-option value="N">Külföld</mat-option>
                    <mat-option value="I">Magyarország</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="karbejelentoKarosultForm.get('f_cim_mo').value && karbejelentoKarosultForm.get('f_cim_mo').value == 'I'">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Irányítószám:  *</mat-label>
                    <input type="text" matInput formControlName="f_irsz" [textMask]="{mask: irszamMask}">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_irsz').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_irsz').errors?.irszamHossz">
                      Hibás adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Település:  *</mat-label>
                    <mat-select matInput formControlName="f_telepules">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_telepules').errors?.keremValasszon">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Utcanév:  *</mat-label>
                    <mat-select matInput formControlName="f_utcanev">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_utcanev').errors?.keremValasszon">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Úttípus:  *</mat-label>
                    <mat-select matInput formControlName="f_uttipus" [disabled]="!karbejelentoKarosultForm.get('f_uttipus_van').value">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_uttipus').errors?.keremValasszon">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
              </div>


              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Házszám  *</mat-label>
                    <input matInput formControlName="f_hazszam" maxlength="5">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_hazszam').errors?.fuggosegiKotelezettseg">
                      Házszám vagy Helyrajzi szám kötelező!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Emelet:</mat-label>
                    <input matInput formControlName="f_emelet">
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Ajtó:</mat-label>
                    <input matInput formControlName="f_ajto">
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Hrsz:</mat-label>
                    <input matInput formControlName="f_hrsz">
                  </mat-form-field>
                </div>

              </div>


            </div>

            <div *ngIf="karbejelentoKarosultForm.get('f_cim_mo').value && karbejelentoKarosultForm.get('f_cim_mo').value == 'N'">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Ország:  *</mat-label>
                    <mat-select matInput formControlName="f_orszag" (selectionChange)="orszagValtozas($event)">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Irányítószám:  *</mat-label>
                    <input matInput formControlName="f_kulf_irsz">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_kulf_irsz').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Cím:  *</mat-label>
                    <input matInput formControlName="f_kulf_cim">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoKarosultForm.get('f_kulf_cim').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Telefon típusa:</mat-label>
                  <mat-select matInput formControlName="f_telefon_tipus">
                    <mat-option value="M">Mobil</mat-option>
                    <mat-option value="V">Vezetékes</mat-option>
                    <mat-option *ngIf="karbejelentoKarosultForm.get('f_modozat').value == 'CMR'" value="K">Külföldi</mat-option>
                  </mat-select>
                  <mat-icon matSuffix *ngIf="karbejelentoKarosultForm.get('f_modozat').value == 'CMR'" matTooltip="A kárügyintézésben eljáró fél elérhetősége.">info</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-lg-2" *ngIf="karbejelentoKarosultForm.get('f_telefon_tipus').value != 'K'">
                <mat-form-field appearance="fill">
                  <mat-label>Előhívó:  *</mat-label>
                  <mat-select matInput formControlName="f_telefon_elohivo" (selectionChange)="eloHivoValtozas()">
                    <mat-option value="0">Kérem válasszon..</mat-option>
                    <mat-option *ngFor="let eloHivo of eloHivoLista" [value]="eloHivo.f_korzetszam">{{eloHivo.f_korzetszam}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_telefon_elohivo').errors?.fuggosegiKotelezettseg">
                    Hiányzó adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Telefonszám:  *</mat-label>
                  <input type="text" matInput formControlName="f_telefon_szam" [textMask]="karbejelentoKarosultForm.get('f_telefon_tipus').value != 'K' ? {mask: phoneMask} : {mask: false}" [placeholder]="phonePlaceholder">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_telefon_szam').errors?.telefonszamUres">
                    Hiányzó adat!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_telefon_szam').errors?.telefonszam">
                    Hibás adat!
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_telefon_szam').errors?.telefonszamKulfHossz">
                    Hibás adat! (Minimum 6 és maximum 20 karakterből állhat)
                  </mat-error>
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_telefon_szam').errors?.telefonszamKulf">
                    Hibás adat! (Megengedett karakterek: 0-9, /, +, -)
                  </mat-error>
                </div>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>E-mail cím:</mat-label>
                  <input type="text" matInput formControlName="f_email" [textMask]="{mask: emailMask}" placeholder="pelda@email.hu" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoKarosultForm.get('f_email').errors?.pattern">
                    Hibás adat!
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Fax:</mat-label>
                  <input type="text" matInput formControlName="f_fax" [textMask]="{mask: faxMask}" placeholder="+36(12) 123-1234">
                </mat-form-field>
                <mat-error *ngIf="karbejelentoKarosultForm.get('f_fax').errors?.fax">
                  Hibás adat!
                </mat-error>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </form>
</div>
