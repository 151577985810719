import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import emailMask from 'text-mask-addons/dist/emailMask';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { UtilityService } from '@granit/common-library';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'karbejelento-karosult',
  templateUrl: './karbejelento-karosult.component.html',
  styleUrls: ['./karbejelento-karosult.component.scss']
})
export class KarbejelentoKarosultComponent implements OnInit {


  @Output() karbejelentoKarosultFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoKarosultForm: FormGroup;

  szulTelepulesLista: any[];
  telepulesLista: any[];
  utcanevLista: any[];
  uttipusLista: any[];
  orszagLista: any[];
  eloHivoLista: any[];
  karosultTipusLista: any[];
  szulDatumMaxDate: string = moment(new Date()).add('years', -14).format('YYYY-MM-DD');

  phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholder = "123-1234";
  emailMask = emailMask;
  faxMask = ['+', '3', '6', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  irszamMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  tooltipLabel = "";
  lakcimLabel = "";

  constructor(
    private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.formGroupNew();
    this.orszagFeltoltes();
    this.telefonElohivoFeltoltes();

    this.karbejelentoKarosultFormOut.emit(this.karbejelentoKarosultForm);

    this.szulTelepulesFeltoltes();

    this.karbejelentoKarosultForm.controls['f_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltes();
    });

    this.karbejelentoKarosultForm.controls['f_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltes();
    });

    this.karbejelentoKarosultForm.controls['f_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltes();
    });

    this.karbejelentoKarosultForm.controls['f_telefon_tipus'].valueChanges.subscribe(value => {
      this.telefonElohivoFeltoltes();
    });

    this.karbejelentoKarosultForm.controls['f_modozat'].valueChanges.subscribe(value => {
      this.karosultTipusFeltoltes();

      this.tooltipLabel = null;
      if (this.karbejelentoKarosultForm.controls['f_modozat'].value == "CMR") {
        this.tooltipLabel = "A tényleges károsult fél, vagy a felszólamlást tevő (megbízó) adatai.";
        this.lakcimLabel = "Telephely helyszíne:";
      }
      else {
        this.lakcimLabel = "Lakcím helyszíne:";
        if (this.karbejelentoKarosultForm.controls['f_modozat'].value == "KGFB") {
          this.tooltipLabel = "Kérjük, a káresemény során megsérült személy adatait megadni szíveskedjen.";
        }
        if (this.karbejelentoKarosultForm.controls['f_modozat'].value == "CASCO") {
          this.tooltipLabel = "A tényleges károsult adatai.";
        }
      }
    });

  }


  formGroupNew() {
    this.karbejelentoKarosultForm = this.formBuilder.group({
      f_azonosito: new FormControl(null),
      f_modozat: new FormControl(null),
      f_bejelento_tipusa: new FormControl("0"),
      f_egyezik: new FormControl(false),
      f_karosult_tipusa: new FormControl("0"),
      f_nev: new FormControl(null, [Validators.required]),
      f_szul_datum: new FormControl(null),
      f_szul_hely: new FormControl("0"),
      f_cim_mo: new FormControl("I"),
      f_irsz: new FormControl(null),
      f_telepules: new FormControl("0"),
      f_utcanev: new FormControl("0"),
      f_uttipus: new FormControl("0"),
      f_hazszam: new FormControl(null),
      f_emelet: new FormControl(null),
      f_ajto: new FormControl(null),
      f_hrsz: new FormControl(null),
      f_orszag: new FormControl(null),
      f_orszag_nev: new FormControl(null),
      f_kulf_irsz: new FormControl(null),
      f_kulf_cim: new FormControl(null),
      f_telefon_tipus: new FormControl("M"),
      f_telefon_elohivo: new FormControl("0"),
      f_telefon_szam: new FormControl(null),
      f_email: new FormControl(null),
      f_fax: new FormControl(null),
      f_uttipus_van: new FormControl(false)
    }, {
      validators: [AdatValidator.keremValasszon('f_telepules', ''),
      AdatValidator.keremValasszon('f_utcanev', ''),
      AdatValidator.keremValasszon('f_uttipus', 'f_uttipus_van'),
      AdatValidator.keremValasszon('f_karosult_tipusa', ''),
      AdatValidator.fuggosegiKotelezettseg('f_irsz', 'f_cim_mo', ''),
      AdatValidator.fuggosegiKotelezettseg('f_hazszam', 'f_cim_mo', 'f_hrsz'),
      AdatValidator.fuggosegiKotelezettseg('f_kulf_irsz', 'f_cim_mo', ''),
      AdatValidator.fuggosegiKotelezettseg('f_kulf_cim', 'f_cim_mo', ''),
      AdatValidator.fuggosegiKotelezettseg('f_telefon_elohivo', '', ''),
      AdatValidator.telefonszam('f_telefon_szam', 'f_telefon_tipus', 'f_telefon_elohivo', ''),
      AdatValidator.telefonszam('f_fax', '', '', '')]
    });
  }

  karosultTipusFeltoltes() {

    let model = {
      f_modozat: this.karbejelentoKarosultForm.controls["f_modozat"].value
    }

    this.karbejelento.karosultTipusok(model).subscribe(
      data => {
        if (this.karbejelentoKarosultForm.controls["f_modozat"].value != "CMR" && this.karbejelentoKarosultForm.controls["f_modozat"].value != "OTTHON" && this.karbejelentoKarosultForm.controls["f_modozat"].value != "TRAFIK") {
          this.karosultTipusLista = data;
        }
        if (this.karbejelentoKarosultForm.controls["f_modozat"].value == "CMR") {
          this.karbejelentoKarosultForm.controls["f_karosult_tipusa"].setValue("E");
        }
        if (this.karbejelentoKarosultForm.controls["f_modozat"].value == "OTTHON" || this.karbejelentoKarosultForm.controls["f_modozat"].value == "TRAFIK") {
          this.karbejelentoKarosultForm.controls["f_karosult_tipusa"].setValue("E");
        }
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a károsult tipusok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  szulTelepulesFeltoltes() {
    this.szulTelepulesLista = [];
    this.karbejelentoKarosultForm.controls["f_szul_hely"].setValue("0");
    this.karbejelento.osszTelepules().subscribe(
      data => {
        this.szulTelepulesLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a települések lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  telepulesFeltoltes() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoKarosultForm.controls["f_telepules"].setValue("0");
    if (this.karbejelentoKarosultForm.controls["f_irsz"].value && this.karbejelentoKarosultForm.controls["f_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoKarosultForm.controls["f_irsz"].value
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesLista = data;
        })
    }
  }

  utcanevFeltoltes() {
    this.utcanevLista = [];
    this.uttipusLista = [];
    this.karbejelentoKarosultForm.controls["f_utcanev"].setValue("0");
    if (this.karbejelentoKarosultForm.controls["f_telepules"].value && this.karbejelentoKarosultForm.controls["f_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoKarosultForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoKarosultForm.controls["f_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevLista = data;
        })
    }
  }

  uttipusFeltoltes() {
    this.uttipusLista = [];
    this.karbejelentoKarosultForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoKarosultForm.controls["f_uttipus"].setValue("0");
    if (this.karbejelentoKarosultForm.controls["f_utcanev"].value && this.karbejelentoKarosultForm.controls["f_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoKarosultForm.controls["f_irsz"].value,
        f_telepules: this.karbejelentoKarosultForm.controls["f_telepules"].value,
        f_utcanev: this.karbejelentoKarosultForm.controls["f_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusLista = data;
            this.karbejelentoKarosultForm.controls["f_uttipus_van"].setValue(true);
          }
        })
    }
  }

  orszagFeltoltes() {
    this.karbejelento.orszagok().subscribe(
      data => {
        this.orszagLista = data;
        /*this.karbejelentoKarosultForm.controls["f_orszag"].setValue("0");
        this.karbejelentoKarosultForm.controls["f_orszag_nev"].setValue(null);*/
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az országok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });

  }

  telefonBeallitas() {
    this.karbejelentoKarosultForm.controls["f_telefon_szam"].setValue(null);
    if (this.karbejelentoKarosultForm.controls["f_telefon_tipus"].value == "M") {
      this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholder = "123-1234";
    }
    if (this.karbejelentoKarosultForm.controls["f_telefon_tipus"].value == "V") {
      if (this.karbejelentoKarosultForm.controls["f_telefon_elohivo"].value == "01") {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-1234";
      }
      else {
        this.phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholder = "123-123";
      }
    }
    if (this.karbejelentoKarosultForm.controls["f_telefon_tipus"].value == "K") {
      this.phonePlaceholder = "";
    }
  }

  telefonElohivoFeltoltes() {
    this.telefonBeallitas();
    this.eloHivoLista = [];
    this.karbejelentoKarosultForm.controls["f_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoKarosultForm.controls["f_telefon_tipus"].value).subscribe(
      data => {
        this.eloHivoLista = data;
      })

  }

  orszagTipusValtozas() {
    if (this.karbejelentoKarosultForm.controls["f_cim_mo"].value == "I") {
      this.kulfoldiCimUrites();
      this.karbejelentoKarosultForm.controls["f_orszag"].setErrors(null);
      this.karbejelentoKarosultForm.controls["f_kulf_irsz"].setErrors(null);
      this.karbejelentoKarosultForm.controls["f_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUrites();
      this.karbejelentoKarosultForm.controls["f_irsz"].setErrors(null);
      this.karbejelentoKarosultForm.controls["f_telepules"].setErrors(null);
      this.karbejelentoKarosultForm.controls["f_utcanev"].setErrors(null);
      this.karbejelentoKarosultForm.controls["f_uttipus"].setErrors(null);
      this.karbejelentoKarosultForm.controls["f_hazszam"].setErrors(null);
    }
  }

  orszagValtozas(event: MatSelectChange) {
    this.karbejelentoKarosultForm.controls["f_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  eloHivoValtozas() {
    this.telefonBeallitas();
  }

  pontosCimUrites() {
    this.telepulesLista = [];
    this.utcanevLista = [];
    this.uttipusLista = [];

    if (this.karbejelentoKarosultForm.controls["f_cim_mo"].value == "K") {
      this.karbejelentoKarosultForm.controls["f_helyszin_tipus"].setValue("P");
    }
    this.karbejelentoKarosultForm.controls["f_irsz"].setValue(null);
    this.karbejelentoKarosultForm.controls["f_telepules"].setValue("0");
    this.karbejelentoKarosultForm.controls["f_utcanev"].setValue("0");
    this.karbejelentoKarosultForm.controls["f_uttipus"].setValue("0");
    this.karbejelentoKarosultForm.controls["f_uttipus_van"].setValue(false);
    this.karbejelentoKarosultForm.controls["f_hazszam"].setValue(null);
    this.karbejelentoKarosultForm.controls["f_emelet"].setValue(null);
    this.karbejelentoKarosultForm.controls["f_ajto"].setValue(null);
    this.karbejelentoKarosultForm.controls["f_hrsz"].setValue(null);
  }

  kulfoldiCimUrites() {
    this.orszagLista = [];

    this.karbejelentoKarosultForm.controls["f_orszag"].setValue("0");
    this.karbejelentoKarosultForm.controls["f_orszag_nev"].setValue(null);
    this.karbejelentoKarosultForm.controls["f_kulf_irsz"].setValue(null);
    this.karbejelentoKarosultForm.controls["f_kulf_cim"].setValue(null);
  }

  egyezikValtozas() {
    if (this.karbejelentoKarosultForm.controls["f_egyezik"].value == true) {

      let model = {
        f_azonosito: this.karbejelentoKarosultForm.controls["f_azonosito"].value
      }

      this.karbejelento.bejelento(model).subscribe(
        data => {
          if (data != null) {
            this.karbejelentoKarosultForm.controls["f_nev"].setValue(data["f_nev"]);
            this.karbejelentoKarosultForm.controls["f_szul_datum"].setValue(data["f_szuldat"].split(".").join("-"));
            this.karbejelentoKarosultForm.controls["f_szul_hely"].setValue(data["f_szulhely"]);
            this.karbejelentoKarosultForm.controls["f_cim_mo"].setValue(data["f_cim_mo"]);
            this.karbejelentoKarosultForm.controls["f_irsz"].setValue(data["f_cim_irszam"]);
            this.karbejelentoKarosultForm.controls["f_telepules"].setValue(data["f_cim_telepules"]);
            this.karbejelentoKarosultForm.controls["f_utcanev"].setValue(data["f_cim_utcanev"]);
            this.karbejelentoKarosultForm.controls["f_uttipus"].setValue(data["f_cim_uttipus"]);
            this.karbejelentoKarosultForm.controls["f_uttipus_van"].setValue(data["f_cim_uttipus"] != "" && data["f_cim_uttipus"] != "0");
            this.karbejelentoKarosultForm.controls["f_hazszam"].setValue(data["f_cim_hazszam"]);
            this.karbejelentoKarosultForm.controls["f_emelet"].setValue(data["f_cim_emelet"]);
            this.karbejelentoKarosultForm.controls["f_ajto"].setValue(data["f_cim_ajto"]);
            this.karbejelentoKarosultForm.controls["f_hrsz"].setValue(data["f_cim_hrsz"]);
            this.karbejelentoKarosultForm.controls["f_orszag"].setValue(data["f_kulf_orszag"]);
            this.karbejelentoKarosultForm.controls["f_orszag_nev"].setValue(data["f_kulf_orszag_nev"]);
            this.karbejelentoKarosultForm.controls["f_kulf_irsz"].setValue(data["f_kulf_irszam"]);
            this.karbejelentoKarosultForm.controls["f_kulf_cim"].setValue(data["f_kulf_cim"]);
            this.karbejelentoKarosultForm.controls["f_telefon_tipus"].setValue(data["f_telefon_tipus"]);
            this.karbejelentoKarosultForm.controls["f_telefon_elohivo"].setValue(data["f_telefon_elohivo"]);
            this.karbejelentoKarosultForm.controls["f_telefon_szam"].setValue(data["f_telefon"]);
            this.karbejelentoKarosultForm.controls["f_email"].setValue(data["f_email"]);
            this.karbejelentoKarosultForm.controls["f_fax"].setValue(data["f_fax"]);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a bejelentő adatainak lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }

  }

}

export class AdatValidator extends Validators {


  static isDate(control_neve: string) {
    let validator = (formGroup: FormGroup) => {
      const control = formGroup.controls[control_neve];
      if (control.value != null) {
        let isDate = false;
        isDate = control.value && !isNaN(Date.parse(control.value));
        if (!isDate) {
          control.setErrors({ isDate: true });
        }
        else {
          control.setErrors(null);
        }
      }
    }
    return validator;
  }

  static keremValasszon(control1_neve: string, control2_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls["f_cim_mo"];
      if (control3.value == "I") {
        if ((control2_neve == "" || (control2_neve == "f_uttipus_van" && control2.value == true)) &&
          (control1_neve == "f_telepules" ||
            control1_neve == "f_utcanev" ||
            control1_neve == "f_uttipus" ||
            control1_neve == "f_karosult_tipusa") && control1.value == "0") {
          hasError = true;
        }
      }
      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls["f_modozat"];
      control1.setErrors(null);

      if (control2_neve == "f_cim_mo" && control2.value != "I" && (control1_neve == "f_kulf_irsz" || control1_neve == "f_kulf_cim") && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_irsz" && (control1.value == null || control1.value == '')) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_hazszam" && control3_neve == "f_hrsz" && ((control1.value == null || control1.value == '') && (control4.value == null || control4.value == ''))) {
        hasError = true;
      }

      if (control2_neve == "f_cim_mo" && control2.value == "I" && control1_neve == "f_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
        control1.setErrors({ irszamHossz: true });
      }

      if (control4.value != "CMR") {
        if (control2_neve == "f_telefon_tipus" && control2.value != "K" && (control1.value == null || control1.value == '0')) {
          hasError = true;
        }

        if (control1_neve == "f_telefon_elohivo" && (control1.value == null || control1.value == '0')) {
          hasError = true;
        }
      }

      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

    }
    return validator;
  }

  static telefonszam(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      const control5 = formGroup.controls["f_modozat"];
      control1.setErrors(null);


      if (control1_neve == "f_fax" && control1.value) {

        if (control1.value.split("_").join("").length < 15) {
          control1.setErrors({ fax: true });
        }

      }

      if (control5.value != "CMR") {
        if (control1_neve == "f_telefon_szam") {
          if (control1.value == null || control1.value == "") {
            control1.setErrors({ telefonszamUres: true });
          }
          else {


            if (control2.value != "K") {
                if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
                  if (control1.value.split("_").join("").split("-").join("").length != 7) {
                    control1.setErrors({ telefonszam: true });
                  }
                }

                if (control2.value == "V" && control3.value != "01") {
                  if (control1.value.split("_").join("").split("-").join("").length != 6) {
                    control1.setErrors({ telefonszam: true });
                  }
                }
            }
            else {
              if (control1.value.split("_").join("").length < 6 || control1.value.split("_").join("").length > 20) {
                control1.setErrors({ telefonszamKulfHossz: true });
              }
              else {

                if (control1.value.match("^[0-9/+-]+$") == null) {
                  control1.setErrors({ telefonszamKulf: true });
                }
              }
            }
          }
        }
      }
    }
    return validator;
  }
}
