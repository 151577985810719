import { Component, OnInit } from '@angular/core';
import { WebAppsService } from '../../services/webApps.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private webApps: WebAppsService) { }

  listItems = [
    { linkTitle: 'Kárbejelentő', link: '/karbejelento' },
    { linkTitle: 'Befizetés', link: '/befizetes' },
    { linkTitle: 'Időpont - ÜSZI', link: '/idopont-uszi' },
    { linkTitle: 'MFO tarifálás', link: '/mfo-tarifalas' },
    { linkTitle: 'Rendezveny regisztráció', link: '/rendezveny-regisztracio' },
    { linkTitle: 'Dokumentum igénylés', link: '/dokumentum-igenyles' },
    { linkTitle: 'Dokumentum letöltés', link: '/dokumentum-letoltes' }
  ];

  ngOnInit() {

    this.webApps.changeMessage("Apps");

  }

  routeToExternal(value: string) {
    window.open(value);
  }

}
