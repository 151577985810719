import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { KalkulatorService } from '../../../../services/kalkulator.service';

@Component({
  selector: 'mfo-epulet-adatai',
  templateUrl: './mfo-epulet-adatai.component.html',
  styleUrls: ['./mfo-epulet-adatai.component.scss']
})
export class MfoEpuletAdataiComponent implements OnInit {

  constructor(
    private kalkulatorService: KalkulatorService) { }

  @Input()
  get formGroup(): FormGroup { return this._formGroup; }
  set formGroup(value: FormGroup) {
    this._formGroup = value;
    this.mfoTarifalasFormGroup = value;
    this.epuletAdataiFormGroup = value.controls['epuletAdataiFormGroup'] as FormGroup;
  }
  private _formGroup: FormGroup;

  mfoTarifalasFormGroup: FormGroup;
  epuletAdataiFormGroup: FormGroup;
  biztositandoMellekepuletekSzamaArray: any[] = Array(0);
  falazatTipusokLista: any[];
  tetozetTipusokLista: any[];
  objectComparisonFunction = function (option, value): boolean {
    return value ? option.f_id === value.f_id : false;
  }

  ngOnInit(): void {
    this.formListakFeltoltese();
    this.azonositoBeallitas();    
    this.mellekEpuletekSzamaBeallitas(); 
  }

  // Form listák feltöltése adatokkal
  formListakFeltoltese() {
    forkJoin(this.kalkulatorService.mfoKodtablaFalazatTipusokLekerese(), this.kalkulatorService.mfoKodtablaTetozetTipusokLekerese()).subscribe(
      data => {
        this.falazatTipusokLista = data[0];
        this.tetozetTipusokLista = data[1];
      },
      errorResult => {
        console.log(errorResult);
      }
    );
  }

  // Azonosító beállítás
  azonositoBeallitas() {
    this.mfoTarifalasFormGroup.controls['f_azonosito'].valueChanges.subscribe(
      value => {
        this.epuletAdataiFormGroup.controls['f_azonosito'].setValue(value);
      },
    );
  }

  // Melléképületek száma beállítás
  mellekEpuletekSzamaBeallitas() {
    (this.mfoTarifalasFormGroup.controls['alapAdatokFormGroup'] as FormGroup).controls['f_biztositando_mellekepuletek_szama'].valueChanges.subscribe(
      value => {
        this.biztositandoMellekepuletekSzamaArray = [...Array(+value).keys()].map(i => i + 1);

        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_1"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_1"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_1"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_1"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_1"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_1"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_2"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_2"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_2"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_2"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_2"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_2"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_3"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_3"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_3"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_3"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_3"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_3"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_4"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_4"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_4"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_4"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_4"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_4"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_5"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_5"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_5"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_5"].setErrors(null);
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_5"].clearValidators();
        (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_5"].setErrors(null);

        if (value > 0) {
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_1"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_1"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_1"].setValidators(Validators.required);
        }

        if (value > 1) {
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_2"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_2"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_2"].setValidators(Validators.required);
        }

        if (value > 2) {
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_3"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_3"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_3"].setValidators(Validators.required);
        }

        if (value > 3) {
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_4"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_4"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_4"].setValidators(Validators.required);
        }

        if (value > 4) {
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_alapterulete_5"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_falazata_5"].setValidators(Validators.required);
          (this.mfoTarifalasFormGroup.controls['epuletAdataiFormGroup'] as FormGroup).controls["f_mellekepulet_tetozete_5"].setValidators(Validators.required);
        }

      }
    );  
  }

  change() {
    ;
  }
}
