import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { KarbejelentoService } from '../../../../services/karbejelento.service';
import { UtilityService } from '@granit/common-library';
import * as moment from 'moment';
import emailMask from 'text-mask-addons/dist/emailMask';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'karbejelento-gepjarmu-szemely',
  templateUrl: './karbejelento-gepjarmu-szemely.component.html',
  styleUrls: ['./karbejelento-gepjarmu-szemely.component.scss']
})
export class KarbejelentoGepjarmuSzemelyComponent implements OnInit {

  @Output() karbejelentoGepjarmuSzemelyFormOut: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  karbejelentoGepjarmuSzemelyForm: FormGroup;

  orszagLista: any[];
  szulTelepulesLista: any[];
  szulDatumMaxDate: string = moment(new Date()).add('years', -14).format('YYYY-MM-DD');
  emailMask = emailMask;
  faxMask = ['+', '3', '6', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  irszamMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  vezetoEgyezikLabel = "";

  telepulesListaTulaj: any[];
  utcanevListaTulaj: any[];
  uttipusListaTulaj: any[];
  eloHivoListaTulaj: any[];
  phoneMaskTulaj = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholderTulaj = "123-1234";

  telepulesListaUzem: any[];
  utcanevListaUzem: any[];
  uttipusListaUzem: any[];
  eloHivoListaUzem: any[];
  phoneMaskUzem = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholderUzem = "123-1234";

  telepulesListaVezeto: any[];
  utcanevListaVezeto: any[];
  uttipusListaVezeto: any[];
  eloHivoListaVezeto: any[];
  phoneMaskVezeto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phonePlaceholderVezeto = "123-1234";


  constructor(private formBuilder: FormBuilder,
    private karbejelento: KarbejelentoService,
    private utilityService: UtilityService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.formGroupNew();
    this.szulTelepulesFeltoltes();
    this.orszagFeltoltes();
    this.telefonElohivoFeltoltesTulaj();
    this.telefonElohivoFeltoltesUzem();
    this.telefonElohivoFeltoltesVezeto();

    this.karbejelentoGepjarmuSzemelyFormOut.emit(this.karbejelentoGepjarmuSzemelyForm);

    this.karbejelentoGepjarmuSzemelyForm.controls['f_modozat'].valueChanges.subscribe(value => {
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_modozat"].value == "KGFB") {
        this.vezetoEgyezikLabel = "Az adatok megegyeznek a károsult gépjármű tualjdonosának adataival"
      }
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_modozat"].value == "CASCO") {
        this.vezetoEgyezikLabel = "Az adatok megegyeznek a bejelentőével"
      }
    });

    /*tulajdonos*/
    this.karbejelentoGepjarmuSzemelyForm.controls['f_tulaj_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltesTulaj();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_tulaj_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltesTulaj();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_tulaj_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltesTulaj();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_tulaj_telefon_tipus'].valueChanges.subscribe(value => {
      this.telefonElohivoFeltoltesTulaj();
    });

    /*üzembentartó*/
    this.karbejelentoGepjarmuSzemelyForm.controls['f_uzem_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltesUzem();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_uzem_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltesUzem();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_uzem_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltesUzem();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_uzem_telefon_tipus'].valueChanges.subscribe(value => {
      this.telefonElohivoFeltoltesUzem();
    });

    /*vezető*/
    this.karbejelentoGepjarmuSzemelyForm.controls['f_vezeto_irsz'].valueChanges.subscribe(value => {
      this.telepulesFeltoltesVezeto();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_vezeto_telepules'].valueChanges.subscribe(value => {
      this.utcanevFeltoltesVezeto();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_vezeto_utcanev'].valueChanges.subscribe(value => {
      this.uttipusFeltoltesVezeto();
    });

    this.karbejelentoGepjarmuSzemelyForm.controls['f_vezeto_telefon_tipus'].valueChanges.subscribe(value => {
      this.telefonElohivoFeltoltesVezeto();
    });

  }

  formGroupNew() {
    this.karbejelentoGepjarmuSzemelyForm = this.formBuilder.group({
      f_azonosito: new FormControl(null),
      f_modozat: new FormControl(null),
      f_bejelento_tipusa: new FormControl("0"),

      f_tulaj_egyezik: new FormControl(false),
      f_tulaj_nev: new FormControl(null),
      f_tulaj_szul_datum: new FormControl(null),
      f_tulaj_szul_hely: new FormControl("0"),
      f_tulaj_cim_mo: new FormControl("I"),
      f_tulaj_irsz: new FormControl(null),
      f_tulaj_telepules: new FormControl("0"),
      f_tulaj_utcanev: new FormControl("0"),
      f_tulaj_uttipus: new FormControl("0"),
      f_tulaj_hazszam: new FormControl(null),
      f_tulaj_emelet: new FormControl(null),
      f_tulaj_ajto: new FormControl(null),
      f_tulaj_hrsz: new FormControl(null),
      f_tulaj_orszag: new FormControl(null),
      f_tulaj_orszag_nev: new FormControl(null),
      f_tulaj_kulf_irsz: new FormControl(null),
      f_tulaj_kulf_cim: new FormControl(null),
      f_tulaj_telefon_tipus: new FormControl("M"),
      f_tulaj_telefon_elohivo: new FormControl("0"),
      f_tulaj_telefon_szam: new FormControl(null),
      f_tulaj_email: new FormControl(null),
      f_tulaj_fax: new FormControl(null),
      f_tulaj_uttipus_van: new FormControl(false),

      f_uzem_egyezik: new FormControl(false),
      f_uzem_nev: new FormControl(null),
      f_uzem_szul_datum: new FormControl(null),
      f_uzem_szul_hely: new FormControl("0"),
      f_uzem_cim_mo: new FormControl("I"),
      f_uzem_irsz: new FormControl(null),
      f_uzem_telepules: new FormControl("0"),
      f_uzem_utcanev: new FormControl("0"),
      f_uzem_uttipus: new FormControl("0"),
      f_uzem_hazszam: new FormControl(null),
      f_uzem_emelet: new FormControl(null),
      f_uzem_ajto: new FormControl(null),
      f_uzem_hrsz: new FormControl(null),
      f_uzem_orszag: new FormControl(null),
      f_uzem_orszag_nev: new FormControl(null),
      f_uzem_kulf_irsz: new FormControl(null),
      f_uzem_kulf_cim: new FormControl(null),
      f_uzem_telefon_tipus: new FormControl("M"),
      f_uzem_telefon_elohivo: new FormControl("0"),
      f_uzem_telefon_szam: new FormControl(null),
      f_uzem_email: new FormControl(null),
      f_uzem_fax: new FormControl(null),
      f_uzem_uttipus_van: new FormControl(false),

      f_vezeto_egyezik: new FormControl(false),
      f_vezeto_nev: new FormControl(null),
      f_vezeto_szul_datum: new FormControl(null),
      f_vezeto_szul_hely: new FormControl("0"),
      f_vezeto_cim_mo: new FormControl("I"),
      f_vezeto_irsz: new FormControl(null),
      f_vezeto_telepules: new FormControl("0"),
      f_vezeto_utcanev: new FormControl("0"),
      f_vezeto_uttipus: new FormControl("0"),
      f_vezeto_hazszam: new FormControl(null),
      f_vezeto_emelet: new FormControl(null),
      f_vezeto_ajto: new FormControl(null),
      f_vezeto_hrsz: new FormControl(null),
      f_vezeto_orszag: new FormControl(null),
      f_vezeto_orszag_nev: new FormControl(null),
      f_vezeto_kulf_irsz: new FormControl(null),
      f_vezeto_kulf_cim: new FormControl(null),
      f_vezeto_telefon_tipus: new FormControl("M"),
      f_vezeto_telefon_elohivo: new FormControl("0"),
      f_vezeto_telefon_szam: new FormControl(null),
      f_vezeto_email: new FormControl(null),
      f_vezeto_fax: new FormControl(null),
      f_vezeto_uttipus_van: new FormControl(false),

    }, {
      validators: [AdatValidator.fuggosegiKotelezettseg('f_tulaj_nev', '', 'f_modozat', ''),
      AdatValidator.keremValasszon('f_tulaj_telepules', '', 'f_modozat'),
      AdatValidator.keremValasszon('f_tulaj_utcanev', '', 'f_modozat'),
      AdatValidator.keremValasszon('f_tulaj_uttipus', 'f_tulaj_uttipus_van', 'f_modozat'),
      AdatValidator.fuggosegiKotelezettseg('f_tulaj_irsz', 'f_tulaj_cim_mo', 'f_modozat', ''),
      AdatValidator.fuggosegiKotelezettseg('f_tulaj_hazszam', 'f_tulaj_cim_mo', 'f_modozat', 'f_tulaj_hrsz'),
      AdatValidator.fuggosegiKotelezettseg('f_tulaj_kulf_irsz', 'f_tulaj_cim_mo', 'f_modozat', ''),
      AdatValidator.fuggosegiKotelezettseg('f_tulaj_kulf_cim', 'f_tulaj_cim_mo', 'f_modozat', ''),
      AdatValidator.fuggosegiKotelezettseg('f_tulaj_telefon_elohivo', '', 'f_modozat', ''),
      AdatValidator.telefonszam('f_tulaj_telefon_szam', 'f_tulaj_telefon_tipus', 'f_tulaj_telefon_elohivo', 'f_modozat'),
      AdatValidator.telefonszam('f_tulaj_fax', '', '', 'f_modozat'),

      AdatValidator.keremValasszon('f_uzem_telepules', '', 'f_modozat'),
      AdatValidator.keremValasszon('f_uzem_utcanev', '', 'f_modozat'),
      AdatValidator.keremValasszon('f_uzem_uttipus', 'f_uzem_uttipus_van', 'f_modozat'),
      AdatValidator.fuggosegiKotelezettseg('f_uzem_nev', '', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_uzem_irsz', 'f_uzem_cim_mo', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_uzem_hazszam', 'f_uzem_cim_mo', 'f_modozat', 'f_uzem_hrsz'),
        AdatValidator.fuggosegiKotelezettseg('f_uzem_kulf_irsz', 'f_uzem_cim_mo', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_uzem_kulf_cim', 'f_uzem_cim_mo', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_uzem_telefon_elohivo', '', 'f_modozat', ''),
      AdatValidator.telefonszam('f_uzem_telefon_szam', 'f_uzem_telefon_tipus', 'f_uzem_telefon_elohivo', 'f_modozat'),
      AdatValidator.telefonszam('f_uzem_fax', '', '', 'f_modozat'),

      AdatValidator.keremValasszon('f_vezeto_telepules', '', 'f_modozat'),
      AdatValidator.keremValasszon('f_vezeto_utcanev', '', 'f_modozat'),
      AdatValidator.keremValasszon('f_vezeto_uttipus', 'f_vezeto_uttipus_van', 'f_modozat'),
        AdatValidator.fuggosegiKotelezettseg('f_vezeto_nev', '', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_vezeto_irsz', 'f_vezeto_cim_mo', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_vezeto_hazszam', 'f_vezeto_cim_mo', 'f_modozat', 'f_vezeto_hrsz'),
        AdatValidator.fuggosegiKotelezettseg('f_vezeto_kulf_irsz', 'f_vezeto_cim_mo', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_vezeto_kulf_cim', 'f_vezeto_cim_mo', 'f_modozat', ''),
        AdatValidator.fuggosegiKotelezettseg('f_vezeto_telefon_elohivo', '', 'f_modozat', ''),
      AdatValidator.telefonszam('f_vezeto_telefon_szam', 'f_vezeto_telefon_tipus', 'f_vezeto_telefon_elohivo', 'f_modozat'),
      AdatValidator.telefonszam('f_vezeto_fax', '', '', 'f_modozat')
      ]
    });
  }

  szulTelepulesFeltoltes() {
    this.szulTelepulesLista = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_szul_hely"].setValue("0");
    this.karbejelento.osszTelepules().subscribe(
      data => {
        this.szulTelepulesLista = data;
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba a települések lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });
  }

  orszagFeltoltes() {
    this.karbejelento.orszagok().subscribe(
      data => {
        this.orszagLista = data;
        this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag"].setValue("0");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag_nev"].setValue(null);
      },
      () => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: "Hiba az országok lekérdezése közben!",
          confirmButtonText: 'Rendben'
        });
      });

  }

  /*tulajdonos*/

  telepulesFeltoltesTulaj() {
    this.telepulesListaTulaj = [];
    this.utcanevListaTulaj = [];
    this.uttipusListaTulaj = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].value
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesListaTulaj = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a települések lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  utcanevFeltoltesTulaj() {
    this.utcanevListaTulaj = [];
    this.uttipusListaTulaj = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].value,
        f_telepules: this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevListaTulaj = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az utcanevek lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  uttipusFeltoltesTulaj() {
    this.uttipusListaTulaj = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus_van"].setValue(false);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].value,
        f_telepules: this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].value,
        f_utcanev: this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusListaTulaj = data;
            this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus_van"].setValue(true);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az úttipusok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  telefonBeallitasTulaj() {
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_szam"].setValue(null);
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_tipus"].value == "M") {
      this.phoneMaskTulaj = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholderTulaj = "123-1234";
    }
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_tipus"].value == "V") {
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_elohivo"].value == "01") {
        this.phoneMaskTulaj = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholderTulaj = "123-1234";
      }
      else {
        this.phoneMaskTulaj = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholderTulaj = "123-123";
      }
    }
  }

  telefonElohivoFeltoltesTulaj() {
    this.telefonBeallitasTulaj();
    this.eloHivoListaTulaj = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_tipus"].value).subscribe(
      data => {
        this.eloHivoListaTulaj = data;
      })

  }

  orszagTipusValtozasTulaj() {
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_cim_mo"].value == "I") {
      this.kulfoldiCimUritesTulaj();
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_kulf_irsz"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUritesTulaj();
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_hazszam"].setErrors(null);
    }
  }

  orszagValtozasTulaj(event: MatSelectChange) {
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  eloHivoValtozasTulaj() {
    this.telefonBeallitasTulaj();
  }

  pontosCimUritesTulaj() {
    this.telepulesListaTulaj = [];
    this.utcanevListaTulaj = [];
    this.uttipusListaTulaj = [];

    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_cim_mo"].value == "K") {
      this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_helyszin_tipus"].setValue("P");
    }
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus_van"].setValue(false);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_hazszam"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_emelet"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_ajto"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_hrsz"].setValue(null);
  }

  kulfoldiCimUritesTulaj() {

    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag_nev"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_kulf_irsz"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_kulf_cim"].setValue(null);
  }

  /*tulajdonos vége*/

  /*üzembentartó*/

  telepulesFeltoltesUzem() {
    this.telepulesListaUzem = [];
    this.utcanevListaUzem = [];
    this.uttipusListaUzem = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].value,
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesListaUzem = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a települések lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  utcanevFeltoltesUzem() {
    this.utcanevListaUzem = [];
    this.uttipusListaUzem = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].value,
        f_telepules: this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevListaUzem = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az utcanevek lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  uttipusFeltoltesUzem() {
    this.uttipusListaUzem = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus_van"].setValue(false);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].value,
        f_telepules: this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].value,
        f_utcanev: this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusListaUzem = data;
            this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus_van"].setValue(true);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az úttipusok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });          
        });
    }
  }

  telefonBeallitasUzem() {
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_szam"].setValue(null);
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_tipus"].value == "M") {
      this.phoneMaskUzem = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholderUzem = "123-1234";
    }
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_tipus"].value == "V") {
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_elohivo"].value == "01") {
        this.phoneMaskUzem = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholderUzem = "123-1234";
      }
      else {
        this.phoneMaskUzem = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholderUzem = "123-123";
      }
    }
  }

  telefonElohivoFeltoltesUzem() {
    this.telefonBeallitasUzem();
    this.eloHivoListaUzem = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_tipus"].value).subscribe(
      data => {
        this.eloHivoListaUzem = data;
      })

  }

  orszagTipusValtozasUzem() {
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_cim_mo"].value == "I") {
      this.kulfoldiCimUritesUzem();
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_orszag"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_kulf_irsz"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUritesUzem();
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_hazszam"].setErrors(null);
    }
  }

  orszagValtozasUzem(event: MatSelectChange) {
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  eloHivoValtozasUzem() {
    this.telefonBeallitasUzem();
  }

  pontosCimUritesUzem() {
    this.telepulesListaUzem = [];
    this.utcanevListaUzem = [];
    this.uttipusListaUzem = [];

    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_cim_mo"].value == "K") {
      this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_helyszin_tipus"].setValue("P");
    }
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus_van"].setValue(false);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_hazszam"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_emelet"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_ajto"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_hrsz"].setValue(null);
  }

  kulfoldiCimUritesUzem() {

    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_orszag"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_orszag_nev"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_kulf_irsz"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_kulf_cim"].setValue(null);
  }

  /*üzembentartó vége*/

  /*vezető*/

  telepulesFeltoltesVezeto() {
    this.telepulesListaVezeto = [];
    this.utcanevListaVezeto = [];
    this.uttipusListaVezeto = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].value.split("_").join("").length == 4) {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].value,
      }

      this.karbejelento.telepulesek(model).subscribe(
        data => {
          this.telepulesListaVezeto = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a települések lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  utcanevFeltoltesVezeto() {
    this.utcanevListaVezeto = [];
    this.uttipusListaVezeto = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].setValue("0");   

    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].value,
        f_telepules: this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].value
      }

      this.karbejelento.utcanevek(model).subscribe(
        data => {
          this.utcanevListaVezeto = data;
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az utcanevek lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  uttipusFeltoltesVezeto() {
    this.uttipusListaVezeto = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus_van"].setValue(false);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus"].setValue("0");
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].value && this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].value != "0") {

      let model = {
        f_irsz: this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].value,
        f_telepules: this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].value,
        f_utcanev: this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].value
      }

      this.karbejelento.uttipusok(model).subscribe(
        data => {
          if (data[0] != null) {
            this.uttipusListaVezeto = data;
            this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus_van"].setValue(true);
          }
        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba az úttipusok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    }
  }

  telefonBeallitasVezeto() {
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_szam"].setValue(null);
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_tipus"].value == "M") {
      this.phoneMaskVezeto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      this.phonePlaceholderVezeto = "123-1234";
    }
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_tipus"].value == "V") {
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_elohivo"].value == "01") {
        this.phoneMaskVezeto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.phonePlaceholderVezeto = "123-1234";
      }
      else {
        this.phoneMaskVezeto = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        this.phonePlaceholderVezeto = "123-123";
      }
    }
  }

  telefonElohivoFeltoltesVezeto() {
    this.telefonBeallitasVezeto();
    this.eloHivoListaVezeto = [];
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_elohivo"].setValue("0");
    this.utilityService.korzetszamok(this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_tipus"].value).subscribe(
      data => {
        this.eloHivoListaVezeto = data;
      })

  }

  orszagTipusValtozasVezeto() {
    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_cim_mo"].value == "I") {
      this.kulfoldiCimUritesVezeto();
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_irsz"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_cim"].setErrors(null);
    }
    else {
      this.pontosCimUritesVezeto();
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus"].setErrors(null);
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hazszam"].setErrors(null);
    }
  }

  orszagValtozasVezeto(event: MatSelectChange) {
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag_nev"].setValue(event.value == "0" ? null : event.source.triggerValue);
  }

  eloHivoValtozasVezeto() {
    this.telefonBeallitasVezeto();
  }

  pontosCimUritesVezeto() {
    this.telepulesListaVezeto = [];
    this.utcanevListaVezeto = [];
    this.uttipusListaVezeto = [];

    if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_cim_mo"].value == "K") {
      this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_helyszin_tipus"].setValue("P");
    }
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus_van"].setValue(false);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hazszam"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_emelet"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_ajto"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hrsz"].setValue(null);
  }

  kulfoldiCimUritesVezeto() {

    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag"].setValue("0");
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag_nev"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_irsz"].setValue(null);
    this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_cim"].setValue(null);
  }

  /*vezető vége*/


  egyezikValtozas(mi: string) {

    if (mi == "tulaj") {

      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_egyezik"].value == true) {

        let model = {
          f_azonosito: this.karbejelentoGepjarmuSzemelyForm.controls["f_azonosito"].value
        }

        this.karbejelento.bejelento(model).subscribe(
          data => {
            if (data != null) {
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_nev"].setValue(data["f_nev"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_szul_datum"].setValue(data["f_szuldat"].split(".").join("-"));
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_szul_hely"].setValue(data["f_szulhely"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_cim_mo"].setValue(data["f_cim_mo"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_irsz"].setValue(data["f_cim_irszam"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telepules"].setValue(data["f_cim_telepules"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_utcanev"].setValue(data["f_cim_utcanev"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus"].setValue(data["f_cim_uttipus"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_uttipus_van"].setValue(data["f_cim_uttipus"] != "" && data["f_cim_uttipus"] != "");
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_hazszam"].setValue(data["f_cim_hazszam"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_emelet"].setValue(data["f_cim_emelet"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_ajto"].setValue(data["f_cim_ajto"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_hrsz"].setValue(data["f_cim_hrsz"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag"].setValue(data["f_kulf_orszag"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_orszag_nev"].setValue(data["f_kulf_orszag_nev"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_kulf_irsz"].setValue(data["f_kulf_irszam"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_kulf_cim"].setValue(data["f_kulf_cim"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_tipus"].setValue(data["f_telefon_tipus"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_elohivo"].setValue(data["f_telefon_elohivo"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_telefon_szam"].setValue(data["f_telefon"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_email"].setValue(data["f_email"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_tulaj_fax"].setValue(data["f_fax"]);
            }
          },
          () => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a bejelentő adatainak lekérdezése közben!",
              confirmButtonText: 'Rendben'
            });
          });
      }
    }

    if (mi == "uzem") {

      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_egyezik"].value != true) {
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_nev"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_szul_datum"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_szul_hely"].setValue("0");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_cim_mo"].setValue("I");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_irsz"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telepules"].setValue("0");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_utcanev"].setValue("0");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus"].setValue("0");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_uttipus_van"].setValue(false);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_hazszam"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_emelet"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_ajto"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_hrsz"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_orszag"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_orszag_nev"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_kulf_irsz"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_kulf_cim"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_tipus"].setValue("M");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_elohivo"].setValue("0");
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_telefon_szam"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_email"].setValue(null);
        this.karbejelentoGepjarmuSzemelyForm.controls["f_uzem_fax"].setValue(null);
      }
    }

    if (mi == "vezeto") {
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_modozat"].value == "KGFB") {
        if (this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_egyezik"].value != true) {
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_nev"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_szul_datum"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_szul_hely"].setValue("0");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_cim_mo"].setValue("I");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].setValue("0");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].setValue("0");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus"].setValue("0");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus_van"].setValue(false);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hazszam"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_emelet"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_ajto"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hrsz"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag_nev"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_irsz"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_cim"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_tipus"].setValue("M");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_elohivo"].setValue("0");
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_szam"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_email"].setValue(null);
          this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_fax"].setValue(null);
        }
      }
      if (this.karbejelentoGepjarmuSzemelyForm.controls["f_modozat"].value == "CASCO") {

        let model = {
          f_azonosito: this.karbejelentoGepjarmuSzemelyForm.controls["f_azonosito"].value
        }

        this.karbejelento.bejelento(model).subscribe(
          data => {
            if (data != null) {
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_nev"].setValue(data["f_nev"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_szul_datum"].setValue(data["f_szuldat"].split(".").join("-"));
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_szul_hely"].setValue(data["f_szulhely"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_cim_mo"].setValue(data["f_cim_mo"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_irsz"].setValue(data["f_cim_irszam"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telepules"].setValue(data["f_cim_telepules"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_utcanev"].setValue(data["f_cim_utcanev"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus"].setValue(data["f_cim_uttipus"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_uttipus_van"].setValue(data["f_cim_uttipus"] != "" && data["f_cim_uttipus"] != "");
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hazszam"].setValue(data["f_cim_hazszam"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_emelet"].setValue(data["f_cim_emelet"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_ajto"].setValue(data["f_cim_ajto"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_hrsz"].setValue(data["f_cim_hrsz"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag"].setValue(data["f_kulf_orszag"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_orszag_nev"].setValue(data["f_kulf_orszag_nev"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_irsz"].setValue(data["f_kulf_irszam"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_kulf_cim"].setValue(data["f_kulf_cim"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_tipus"].setValue(data["f_telefon_tipus"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_elohivo"].setValue(data["f_telefon_elohivo"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_telefon_szam"].setValue(data["f_telefon"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_email"].setValue(data["f_email"]);
              this.karbejelentoGepjarmuSzemelyForm.controls["f_vezeto_fax"].setValue(data["f_fax"]);
            }
          },
          () => {
            this.utilityService.dialogMegnyitasa({
              error: true,
              title: 'Hiba',
              message: "Hiba a bejelentő adatainak lekérdezése közben!",
              confirmButtonText: 'Rendben'
            });
          });
      }
    }
  }

}

export class AdatValidator extends Validators {


  static isDate(control_neve: string) {
    let validator = (formGroup: FormGroup) => {
      const control = formGroup.controls[control_neve];
      if (control.value != null) {
        let isDate = false;
        isDate = control.value && !isNaN(Date.parse(control.value));
        if (!isDate) {
          control.setErrors({ isDate: true });
        }
        else {
          control.setErrors(null);
        }
      }
    }
    return validator;
  }

  static keremValasszon(control1_neve: string, control2_neve: string, control3_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];

      const vezetoEgyezik = formGroup.controls["f_vezeto_egyezik"];

      if (control3_neve == "f_modozat" && control3.value == "KGFB") {
        const uzemEgyezik = formGroup.controls["f_uzem_egyezik"];

        const control_tulaj_mo = formGroup.controls["f_tulaj_cim_mo"];
        if (control_tulaj_mo.value == "I") {
          if ((control2_neve == "" || (control2_neve == "f_tulaj_uttipus_van" && control2.value == true)) &&
            (control1_neve == "f_tulaj_telepules" ||
              control1_neve == "f_tulaj_utcanev" ||
              control1_neve == "f_tulaj_uttipus") && control1.value == "0") {
            hasError = true;
          }
        }

        const control_uzem_mo = formGroup.controls["f_uzem_cim_mo"];
        if (uzemEgyezik.value == false) {
          if (control_uzem_mo.value == "I") {
            if ((control2_neve == "" || (control2_neve == "f_uzem_uttipus_van" && control2.value == true)) &&
              (control1_neve == "f_uzem_telepules" ||
                control1_neve == "f_uzem_utcanev" ||
                control1_neve == "f_uzem_uttipus") && control1.value == "0") {
              hasError = true;
            }
          }
        }

      }

      const control_vezeto_mo = formGroup.controls["f_vezeto_cim_mo"];
      if (vezetoEgyezik.value == false) {
        if (control_vezeto_mo.value == "I") {
          if ((control2_neve == "" || (control2_neve == "f_vezeto_uttipus_van" && control2.value == true)) &&
            (control1_neve == "f_vezeto_telepules" ||
              control1_neve == "f_vezeto_utcanev" ||
              control1_neve == "f_vezeto_uttipus") && control1.value == "0") {
            hasError = true;
          }
        }
      }

      if (hasError) {
        control1.setErrors({ keremValasszon: true });
      }
      else {
        control1.setErrors(null);
      }
    }
    return validator;
  }

  static fuggosegiKotelezettseg(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string) {

    let validator = (formGroup: FormGroup) => {
      let hasError = false;
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      const vezetoEgyezik = formGroup.controls["f_vezeto_egyezik"];
      control1.setErrors(null);

      if (control3_neve == "f_modozat" && control3.value == "KGFB") {

        const uzemEgyezik = formGroup.controls["f_uzem_egyezik"];

        if (control1_neve == "f_tulaj_nev" && (control1.value == null || control1.value == '')) {
          hasError = true;
        }

        if (control2_neve == "f_tulaj_cim_mo" && control2.value != "I" && (control1_neve == "f_tulaj_kulf_irsz" || control1_neve == "f_tulaj_kulf_cim") && (control1.value == null || control1.value == '')) {
          hasError = true;
        }

        if (control2_neve == "f_tulaj_cim_mo" && control2.value == "I" && control1_neve == "f_tulaj_irsz" && (control1.value == null || control1.value == '')) {
          hasError = true;
        }

        if (control2_neve == "f_tulaj_cim_mo" && control2.value == "I" && control1_neve == "f_tulaj_hazszam" && control4_neve == "f_tulaj_hrsz" && ((control1.value == null || control1.value == '') && (control4.value == null || control4.value == ''))) {
          hasError = true;
        }

        if (control2_neve == "f_tulaj_cim_mo" && control2.value == "I" && control1_neve == "f_tulaj_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
          control1.setErrors({ irszamHossz: true });
        }

        if (control2_neve == "f_tulaj_telefon_tipus" && control2.value != "K" && (control1.value == null || control1.value == '0')) {
          hasError = true;
        }

        if (control1_neve == "f_tulaj_telefon_elohivo" && (control1.value == null || control1.value == '0')) {
          hasError = true;
        }

        if (uzemEgyezik.value == false) {

          if (control2_neve == "f_uzem_cim_mo" && control2.value != "I" && (control1_neve == "f_uzem_kulf_irsz" || control1_neve == "f_uzem_kulf_cim") && (control1.value == null || control1.value == '')) {
            hasError = true;
          }

          if (control2_neve == "f_uzem_cim_mo" && control2.value == "I" && control1_neve == "f_uzem_irsz" && (control1.value == null || control1.value == '')) {
            hasError = true;
          }

          if (control2_neve == "f_uzem_cim_mo" && control2.value == "I" && control1_neve == "f_uzem_hazszam" && control4_neve == "f_uzem_hrsz" && ((control1.value == null || control1.value == '') && (control4.value == null || control4.value == ''))) {
            hasError = true;
          }

          if (control2_neve == "f_uzem_cim_mo" && control2.value == "I" && control1_neve == "f_uzem_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
            control1.setErrors({ irszamHossz: true });
          }

          if (control2_neve == "f_uzem_telefon_tipus" && (control1.value == null || control1.value == '0')) {
            hasError = true;
          }

          if (control1_neve == "f_uzem_telefon_elohivo" && (control1.value == null || control1.value == '0')) {
            hasError = true;
          }

          if (control1_neve == "f_uzem_nev" && (control1.value == null || control1.value == '')) {
            hasError = true;
          }
        }
      }

      if (vezetoEgyezik.value == false) {

        if (control2_neve == "f_vezeto_cim_mo" && control2.value != "I" && (control1_neve == "f_vezeto_kulf_irsz" || control1_neve == "f_vezeto_kulf_cim") && (control1.value == null || control1.value == '')) {
          hasError = true;
        }

        if (control2_neve == "f_vezeto_cim_mo" && control2.value == "I" && control1_neve == "f_vezeto_irsz" && (control1.value == null || control1.value == '')) {
          hasError = true;
        }

        if (control2_neve == "f_vezeto_cim_mo" && control2.value == "I" && control1_neve == "f_vezeto_hazszam" && control4_neve == "f_vezeto_hrsz" && ((control1.value == null || control1.value == '') && (control4.value == null || control4.value == ''))) {
          hasError = true;
        }

        if (control2_neve == "f_vezeto_cim_mo" && control2.value == "I" && control1_neve == "f_vezeto_irsz" && control1.value != null && control1.value.split("_").join("").length != 4) {
          control1.setErrors({ irszamHossz: true });
        }

        if (control2_neve == "f_vezeto_telefon_tipus" && (control1.value == null || control1.value == '0')) {
          hasError = true;
        }

        if (control1_neve == "f_vezeto_telefon_elohivo" && (control1.value == null || control1.value == '0')) {
          hasError = true;
        }

        if (control1_neve == "f_vezeto_nev" && (control1.value == null || control1.value == '')) {
          hasError = true;
        }
      }

      if (hasError) {
        control1.setErrors({ fuggosegiKotelezettseg: true });
      }

    }
    return validator;
  }

  static telefonszam(control1_neve: string, control2_neve: string, control3_neve: string, control4_neve: string) {

    let validator = (formGroup: FormGroup) => {
      const control1 = formGroup.controls[control1_neve];
      const control2 = formGroup.controls[control2_neve];
      const control3 = formGroup.controls[control3_neve];
      const control4 = formGroup.controls[control4_neve];
      const vezetoEgyezik = formGroup.controls["f_vezeto_egyezik"];
      control1.setErrors(null);

      if (control4_neve == "f_modozat" && control4.value == "KGFB") {

        const uzemEgyezik = formGroup.controls["f_uzem_egyezik"];

        if (control1_neve == "f_tulaj_fax" && control1.value) {

          if (control1.value.split("_").join("").length < 15) {
            control1.setErrors({ fax: true });
          }

        }

        if (control1_neve == "f_tulaj_telefon_szam") {
          if (control1.value == null || control1.value == "") {
            control1.setErrors({ telefonszamUres: true });
          }
          else {                        
              if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
                if (control1.value.split("_").join("").split("-").join("").length != 7) {
                  control1.setErrors({ telefonszam: true });
                }
              }

              if (control2.value == "V" && control3.value != "01") {
                if (control1.value.split("_").join("").split("-").join("").length != 6) {
                  control1.setErrors({ telefonszam: true });
                }
              }            
          }
        }


        if (control1_neve == "f_uzem_fax" && control1.value) {

          if (control1.value.split("_").join("").length < 15) {
            control1.setErrors({ fax: true });
          }

        }

        if (uzemEgyezik.value == false && control1_neve == "f_uzem_telefon_szam") {
          if (control1.value == null || control1.value == "") {
            control1.setErrors({ telefonszamUres: true });
          }
          else {            
              if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
                if (control1.value.split("_").join("").split("-").join("").length != 7) {
                  control1.setErrors({ telefonszam: true });
                }
              }

              if (control2.value == "V" && control3.value != "01") {
                if (control1.value.split("_").join("").split("-").join("").length != 6) {
                  control1.setErrors({ telefonszam: true });
                }
              }
          }
        }
      }

      if (vezetoEgyezik.value == false && control1_neve == "f_vezeto_telefon_szam") {
        if (control1.value == null || control1.value == "") {
          control1.setErrors({ telefonszamUres: true });
        }
        else {
            if (control2.value == "M" || (control2.value == "V" && control3.value == "01")) {
              if (control1.value.split("_").join("").split("-").join("").length != 7) {
                control1.setErrors({ telefonszam: true });
              }
            }

            if (control2.value == "V" && control3.value != "01") {
              if (control1.value.split("_").join("").split("-").join("").length != 6) {
                control1.setErrors({ telefonszam: true });
              }
            }
        }
      }

    }
    return validator;
  }
}
