import { Component, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RendezvenyService {
  private url: string = "/api/rendezveny";

  constructor(
    private httpClient: HttpClient  ) { }


  // Rendezvény alap adatok lekérdezése
  rendezvenyAlapAdatok() {
    return this.httpClient.post<any[]>(this.url + "/RendezvenyAlapAdatok", null).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  // Aktuális rendezvényen resztvevok listája
  rendezvenyResztvevok(model: any) {
    return this.httpClient.post<any[]>(this.url + "/RendezvenyResztvevok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Aktuális rendezvényhez adatok mentése
  rendezvenyAdatokMentes(model: any) {
    return this.httpClient.post<any[]>(this.url + "/RendezvenyAdatokMentes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  
}

