<div class="contain-lg">
  <form [formGroup]="karbejelentoSzemleForm" class="form">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="mt-4">
          <div class="row mt-3">
            <div class="col-lg-2">
              <mat-label class="control-label">Szemle helyszíne:</mat-label>
              <mat-icon class="ml-3 info-icon" matTooltip={{tooltipLabel}} *ngIf="tooltipLabel">info</mat-icon>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-4" *ngIf="karbejelentoSzemleForm.get('f_modozat').value != 'OTTHON' || (karbejelentoSzemleForm.get('f_modozat').value == 'OTTHON' && !(karbejelentoSzemleForm.get('f_kartipus').value == 'E36101' || karbejelentoSzemleForm.get('f_kartipus').value == 'E36001' || karbejelentoSzemleForm.get('f_kartipus').value == 'E31002' || karbejelentoSzemleForm.get('f_kartipus').value == 'E19001'))">
              <mat-checkbox matCheckbox formControlName="f_egyezik" class="mat-checkbox" (change)="egyezikValtozas()">{{egyezikLabel}}</mat-checkbox>
            </div>
          </div>
          <div [class.disableDiv]="karbejelentoSzemleForm.get('f_egyezik').value">
            <div class="row mt-3">
              <div class="col-lg-2" *ngIf="karbejelentoSzemleForm.get('f_modozat').value == 'CMR'">
                <mat-form-field appearance="fill">
                  <mat-select matInput formControlName="f_cim_mo" (selectionChange)="orszagTipusValtozas()">
                    <mat-option value="N">Külföld</mat-option>
                    <mat-option value="I">Magyarország</mat-option>
                  </mat-select>
                </mat-form-field>                
              </div>

              <div class="col-lg-2" *ngIf="(karbejelentoSzemleForm.get('f_modozat').value != 'OTTHON' && karbejelentoSzemleForm.get('f_modozat').value != 'TRAFIK') && karbejelentoSzemleForm.get('f_cim_mo').value == 'I'">
                <mat-form-field appearance="fill">
                  <mat-select matInput formControlName="f_helyszin_tipus">
                    <mat-option value="P">Pontos cím</mat-option>
                    <mat-option value="K">Külterület cím</mat-option>
                  </mat-select>
                </mat-form-field>                
              </div>

            </div>

            <div *ngIf="karbejelentoSzemleForm.get('f_cim_mo').value && karbejelentoSzemleForm.get('f_cim_mo').value == 'I'">
              <div *ngIf="karbejelentoSzemleForm.get('f_helyszin_tipus').value && karbejelentoSzemleForm.get('f_helyszin_tipus').value == 'P'">
                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Irányítószám:  *</mat-label>
                      <input type="text" matInput formControlName="f_irsz" [textMask]="{mask: irszamMask}">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_irsz').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_irsz').errors?.irszamHossz">
                        Hibás adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Település:  *</mat-label>
                      <mat-select matInput formControlName="f_telepules">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let telepules of telepulesLista" [value]="telepules.f_helynev">{{telepules.f_helynev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_telepules').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Utcanév:  *</mat-label>
                      <mat-select matInput formControlName="f_utcanev">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let utcanev of utcanevLista" [value]="utcanev.f_utcanev">{{utcanev.f_utcanev}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_utcanev').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Úttípus:  *</mat-label>
                      <mat-select matInput formControlName="f_uttipus" [disabled]="!karbejelentoSzemleForm.get('f_uttipus_van').value">
                        <mat-option value="0">Kérem válasszon..</mat-option>
                        <mat-option *ngFor="let uttipus of uttipusLista" [value]="uttipus.f_uttipus">{{uttipus.f_uttipus}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_uttipus').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>
                </div>


                <div class="row mt-3">
                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Házszám  *</mat-label>
                      <input matInput formControlName="f_hazszam" maxlength="5">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_hazszam').errors?.fuggosegiKotelezettseg">
                        Házszám vagy Helyrajzi szám kötelező!
                      </mat-error>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Emelet:</mat-label>
                      <input matInput formControlName="f_emelet">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Ajtó:</mat-label>
                      <input matInput formControlName="f_ajto">
                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">
                    <mat-form-field appearance="fill">
                      <mat-label>Hrsz:</mat-label>
                      <input matInput formControlName="f_hrsz">
                    </mat-form-field>
                  </div>

                </div>

              </div>

              <div *ngIf="karbejelentoSzemleForm.get('f_helyszin_tipus').value && karbejelentoSzemleForm.get('f_helyszin_tipus').value == 'K'">
                <div class="row mt-3">
                  <div class="col-lg-4">
                    <mat-form-field appearance="fill">
                      <mat-label>Külterület helyszíne:  *</mat-label>
                      <input matInput formControlName="f_kulterulet">
                    </mat-form-field>
                    <div class="pt-2">
                      <mat-error *ngIf="karbejelentoSzemleForm.get('f_kulterulet').errors?.fuggosegiKotelezettseg">
                        Hiányzó adat!
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="karbejelentoSzemleForm.get('f_cim_mo').value && karbejelentoSzemleForm.get('f_cim_mo').value == 'N'">
              <div class="row mt-3">
                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Ország:  *</mat-label>
                    <mat-select matInput formControlName="f_orszag" (selectionChange)="orszagValtozas($event)">
                      <mat-option value="0">Kérem válasszon..</mat-option>
                      <mat-option *ngFor="let orszag of orszagLista" [value]="orszag.f_orsz_kod2">{{orszag.f_orsz_nev}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoSzemleForm.get('f_orszag').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-2">
                  <mat-form-field appearance="fill">
                    <mat-label>Irányítószám:  *</mat-label>
                    <input matInput formControlName="f_kulf_irsz">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoSzemleForm.get('f_kulf_irsz').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>

                <div class="col-lg-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Cím:  *</mat-label>
                    <input matInput formControlName="f_kulf_cim">
                  </mat-form-field>
                  <div class="pt-2">
                    <mat-error *ngIf="karbejelentoSzemleForm.get('f_kulf_cim').errors?.fuggosegiKotelezettseg">
                      Hiányzó adat!
                    </mat-error>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="karbejelentoSzemleForm.get('f_modozat').value == 'CMR'">
            <div class="row mt-3">
              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Kapcsolattartó neve:</mat-label>
                  <input matInput formControlName="f_kapcsolattarto_neve">
                </mat-form-field>
              </div>

              <div class="col-lg-2">
                <mat-form-field appearance="fill">
                  <mat-label>Kapcsolattartó elérhetősége:</mat-label>
                  <input matInput formControlName="f_kapcsolattarto_elerhetosege">
                </mat-form-field>
                <div class="pt-2">
                  <mat-error *ngIf="karbejelentoSzemleForm.get('f_kapcsolattarto_elerhetosege').errors?.elerhetosegHossz">
                    Hibás adat! (Minimum 6 és maximum 20 karakterből állhat)
                  </mat-error>
                  <mat-error *ngIf="karbejelentoSzemleForm.get('f_kapcsolattarto_elerhetosege').errors?.elerhetoseg">
                    Hibás adat! (Megengedett karakterek: 0-9, /, +, -)
                  </mat-error>
                </div>
              </div>

              <div class="col-lg-4">
                <mat-form-field appearance="fill">
                  <mat-label>Megjegyzés:</mat-label>
                  <textarea matInput cdkTextareaAutosize formControlName="f_megjegyzes"
                            #notificationTextInput="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10" [maxlength]="maxCharMegjegyzes"></textarea>
                  <mat-hint align="end">{{karbejelentoSzemleForm.get('f_megjegyzes').value?.length || 0}}/{{maxCharMegjegyzes}}</mat-hint>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>
