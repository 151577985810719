import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdopontUsziService {
  private url: string = "/api/idopontUszi";

  constructor(
    private httpClient: HttpClient) { }


  /* Dátum lista */
  datumLista() {
    return this.httpClient.get<any[]>(this.url + "/DatumLista").pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Időpont lista */
  idopontLista(model: any) {

    return this.httpClient.post<any[]>(this.url + "/IdopontLista", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Időpont foglalása */
  idopontFoglalas(model: any) {

    return this.httpClient.post(this.url + "/IdopontFoglalas", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Időpont lista */
  idopontFoglalasAdatok(model: any) {

    return this.httpClient.post<any>(this.url + "/IdopontFoglalasAdatok", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  /* Időpont foglalás törlése */
  idopontFoglalasTorles(model: any) {

    return this.httpClient.post(this.url + "/IdopontFoglalasTorles", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /* Ügyfélportál felhasználóhoz tartozó email cím lekérdezése */
  felhasznaloEmail(model: any) {

    return this.httpClient.post<any[]>(this.url + "/FelhasznaloEmail", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
