<div class="container-fluid">
  <div class="section">
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="formGroup" class="form">
          <div>
            <div class="row mt-5 mb-3">
              <div class="info">
                <p><b>Tisztelt Ügyfelünk!</b></p>
                <p>Ezen az oldalon az Ön részére megküldött elektronikus levél csatolmányát tudja megtekinteni.</p>
                <div runat="server" *ngIf="formGroup.controls['f_tipus'].value == 'normal'">
                  <p>Az Ön biztonsága érdekében, a dokumentum megtekintéséhez kérjük adja meg az alábbi adatokat.</p>
                </div>
                <div id="kar" runat="server" *ngIf="formGroup.controls['f_tipus'].value == 'kar'">
                  <p>Az Ön biztonsága érdekében kérjük adja meg a megküldött levélben található kárszámát.</p>
                </div>
                <div id="flotta" runat="server" *ngIf="formGroup.controls['f_tipus'].value == 'flotta'">
                  <p>Az Ön biztonsága érdekében, a dokumentum megtekintéséhez kérjük adja meg az alábbi adatokat.</p>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="homokora">
            <div class="row">
              <div class="col-lg-12 bg-white">
                <ngx-skeleton-loader count="1" [theme]="{'border-radius': '0', 'height': '50px', 'margin': '0 0 2.34375em 0' }">
                </ngx-skeleton-loader>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!homokora">

            <div *ngIf="!urlHiba">

              <div class="row mb-3 mt-5" *ngIf="formGroup.controls['f_tipus'].value == 'normal'">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Szerződés azonosító:  *</mat-label>
                    <input matInput type="text"formControlName="f_szerz_azon">
                    <mat-error *ngIf="formGroup.get('f_szerz_azon').errors?.feltetelesenKotelezo">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="formGroup.get('f_szerz_azon').errors?.szerzAzonHiba">
                      A szerződés azonosítónak 8 számból kell állnia, nem kezdődhet 0-val
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row mb-3 mt-5" *ngIf="formGroup.controls['f_tipus'].value == 'kar'">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Kárszám:  *</mat-label>
                    <input matInput type="text" formControlName="f_karszam">
                    <mat-error *ngIf="formGroup.get('f_karszam').errors?.feltetelesenKotelezo">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="formGroup.get('f_karszam').errors?.karszamHiba">
                      A kárszám csak számokat tartalmazhat, nem kezdődhet 0-val
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row mb-3 mt-5" *ngIf="formGroup.controls['f_tipus'].value == 'flotta'">
                <div class="col-lg-4 pl-5">
                  <mat-form-field appearance="fill">
                    <mat-label>Flotta azonosító:  *</mat-label>
                    <input matInput type="text" formControlName="f_flotta">
                    <mat-error *ngIf="formGroup.get('f_flotta').errors?.feltetelesenKotelezo">
                      Hiányzó adat!
                    </mat-error>
                    <mat-error *ngIf="formGroup.get('f_flotta').errors?.flottaAzonHiba">
                      A flotta azonosító csak számokat tartalmazhat, nem kezdődhet 0-val
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="formGroup.controls['f_tipus'].value == 'normal' || formGroup.controls['f_tipus'].value == 'flotta'">
                <div class="row mb-3 mt-3">
                  <div class="col-lg-4 pl-5">
                    <mat-form-field appearance="fill">
                      <mat-label>Szerződő neme:  *</mat-label>
                      <mat-select formControlName="f_neme" (selectionChange)="nemeValtozas()">
                        <mat-option value="0">Kérem válasszon!</mat-option>
                        <mat-option value="N">Nő</mat-option>
                        <mat-option value="F">Férfi</mat-option>
                        <mat-option value="J">Jogi</mat-option>
                      </mat-select>
                      <mat-error *ngIf="formGroup.get('f_neme').errors?.nemeHiba">
                        Hiányzó adat!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-3 mt-3" *ngIf="formGroup.controls['f_neme'].value == 'N' || formGroup.controls['f_neme'].value == 'F'">
                  <div class="col-lg-4 pl-5">
                    <mat-form-field appearance="fill">
                      <mat-label>Születési idő:  *</mat-label>

                      <input matInput [matDatepicker]="szulDatumPicker" formControlName="f_szulido">
                      <mat-datepicker-toggle matSuffix [for]="szulDatumPicker"></mat-datepicker-toggle>
                      <mat-datepicker #szulDatumPicker></mat-datepicker>
                      <mat-error *ngIf="formGroup.get('f_szulido').errors?.feltetelesenKotelezo">
                        Hiányzó adat!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-3 mt-3" *ngIf="formGroup.controls['f_neme'].value == 'J'">
                  <div class="col-lg-4 pl-5">
                    <mat-form-field appearance="fill">
                      <mat-label>Adószám:  *</mat-label>
                      <input matInput type="text" formControlName="f_adoszam">
                      <mat-error *ngIf="formGroup.get('f_adoszam').errors?.feltetelesenKotelezo">
                        Hiányzó adat!
                      </mat-error>
                      <mat-error *ngIf="formGroup.get('f_adoszam').errors?.adoszamHiba">
                        Az adószámnak 11 számból kell állnia, nem kezdődhet 0-val
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-4 pl-5">
                  <button type="button" mat-raised-button color="accent" [disabled]="!formGroup.valid" (click)="letoltes()">Letöltés</button>
                </div>                
              </div>

            </div>
          </ng-container>

        </form>
      </div>
    </div>
  </div>
</div>
