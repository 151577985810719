<div class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xxl-8 col-xl-10 col-lg-12">
      <div class="mt-5 ml-5">
        <mat-nav-list>
          <mat-list-item *ngFor="let item of listItems;" class="mb-3">
            <a [routerLink]="item.link"
               routerLinkActive="active"
               matLine>
              {{item.linkTitle}}
            </a>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
  </div>
</div>
