<form [formGroup]="alapAdatokFormGroup" class="form-group">
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Kockázatviselés kezdete</mat-label>
        <input matInput formControlName="f_kockazatviseles_kezdete" [matDatepicker]="kockazatviselesKezdetePicker">
        <mat-datepicker-toggle matSuffix [for]="kockazatviselesKezdetePicker"></mat-datepicker-toggle>
        <mat-datepicker #kockazatviselesKezdetePicker></mat-datepicker>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_kockazatviseles_kezdete').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a kockázatviselés kezdetét!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Biztosítás típusa</mat-label>
        <mat-select formControlName="f_biztositas_tipusa" [compareWith]="objectComparisonFunction">
          <mat-option *ngFor="let item of biztositasTipusokLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_biztositas_tipusa').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a biztosítás típusát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Biztosítandó ingatlan típusa</mat-label>
        <mat-select formControlName="f_biztositando_ingatlan_tipusa" [compareWith]="objectComparisonFunction">
          <mat-option *ngFor="let item of ingatlanJellegekLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_biztositando_ingatlan_tipusa').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a biztosítandó ingatlan típusát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Tulajdonviszony</mat-label>
        <mat-select formControlName="f_tulajdonviszony" [compareWith]="objectComparisonFunction">
          <mat-option *ngFor="let item of tulajdonviszonyokLista" [value]="item">
            {{item.f_nev}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_tulajdonviszony').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a tulajdonviszonyt!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-checkbox formControlName="f_ingatlan_allandoan_lakott">Az ingatlan állandóan lakott</mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-checkbox formControlName="f_szerzodo_maganszemely">A szerződő magánszemély</mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-checkbox formControlName="f_ingatlan_epites_alatt_all">Az ingatlan építés alatt áll</mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-checkbox formControlName="f_ingatlan_kulteruleten_helyezkedik_el">Az ingatlan külterületen helyezkedik el</mat-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Szerződő születési dátuma</mat-label>
        <input matInput formControlName="f_szerzodo_szuletesi_datuma" [matDatepicker]="szerzodoSzuletesiDatumaPicker">
        <mat-datepicker-toggle matSuffix [for]="szerzodoSzuletesiDatumaPicker"></mat-datepicker-toggle>
        <mat-datepicker #szerzodoSzuletesiDatumaPicker></mat-datepicker>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_szerzodo_szuletesi_datuma').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a szerződő születési dátumát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Irányítószám</mat-label>
        <input matInput formControlName="f_iranyitoszam">
        <mat-error *ngIf="alapAdatokFormGroup.get('f_iranyitoszam').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg az irányítószámot!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Település</mat-label>
        <input matInput formControlName="f_telepules">
        <mat-error *ngIf="alapAdatokFormGroup.get('f_telepules').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a települést!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Főépület építési éve</mat-label>
        <input matInput formControlName="f_foepulet_epitesi_eve">
        <mat-error *ngIf="alapAdatokFormGroup.get('f_foepulet_epitesi_eve').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a főépület építési évét!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Biztosítandó melléképületek száma</mat-label>
        <mat-select formControlName="f_biztositando_mellekepuletek_szama">
          <mat-option value="0">0</mat-option>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
        </mat-select>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_biztositando_mellekepuletek_szama').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg a biztosítandó melléképületek számát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <mat-form-field appearance="outline">
        <mat-label>Életvitelszerűen ott lakók száma</mat-label>
        <mat-select formControlName="f_eletvitelszeruen_ott_lakok_szama">
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
          <mat-option value="8">8</mat-option>
          <mat-option value="9">9</mat-option>
          <mat-option value="10">10</mat-option>
        </mat-select>
        <mat-error *ngIf="alapAdatokFormGroup.get('f_eletvitelszeruen_ott_lakok_szama').errors?.required">
          <mat-icon>error</mat-icon>
          Adja meg az életvitelszerűen ott lakók számát!
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
