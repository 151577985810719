import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Form } from '@angular/forms';
import { KalkulatorService } from '../../../../services/kalkulator.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mfo-alap-adatok',
  templateUrl: './mfo-alap-adatok.component.html',
  styleUrls: ['./mfo-alap-adatok.component.scss']
})
export class MfoAlapAdatokComponent implements OnInit {

  constructor(
    private kalkulatorService: KalkulatorService) { }

  @Input()
  get formGroup(): FormGroup { return this._formGroup; }
  set formGroup(value: FormGroup) {
    this._formGroup = value;
    this.mfoTarifalasFormGroup = value;
    this.alapAdatokFormGroup = value.controls['alapAdatokFormGroup'] as FormGroup;
  }
  private _formGroup: FormGroup;

  mfoTarifalasFormGroup: FormGroup;
  alapAdatokFormGroup: FormGroup;

  biztositasTipusokLista: any[];
  ingatlanJellegekLista: any[];
  tulajdonviszonyokLista: any[];
  objectComparisonFunction = function (option, value): boolean {
    return value ? option.f_id === value.f_id : false;
  }

  ngOnInit(): void {
    this.formListakFeltoltese();  
    this.azonositoBeallitas();      
  }

  // Form listák feltöltése adatokkal
  formListakFeltoltese() {
    forkJoin(this.kalkulatorService.mfoKodtablaBiztositasTipusokLekerese(), this.kalkulatorService.mfoKodtablaIngatlanJellegekLekerese(), this.kalkulatorService.mfoKodtablaTulajdonviszonyokLekerese()).subscribe(
      data => {
        this.biztositasTipusokLista = data[0];
        this.ingatlanJellegekLista = data[1];
        this.tulajdonviszonyokLista = data[2];
      },
      errorResult => {
        console.log(errorResult);
      }
    );   
  }

  // Azonosító beállítás
  azonositoBeallitas() {
    this.mfoTarifalasFormGroup.controls['f_azonosito'].valueChanges.subscribe(
      value => {
        this.alapAdatokFormGroup.controls['f_azonosito'].setValue(value);
      },
    );
  }
}
