import { Component, OnInit, ChangeDetectorRef, ViewChild, ɵSWITCH_CHANGE_DETECTOR_REF_FACTORY__POST_R3__, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { KarbejelentoService } from '../../services/karbejelento.service';
import { UtilityService } from '@granit/common-library';
import { MatStepper } from '@angular/material/stepper';
import { saveAs } from 'file-saver';
import { WebAppsService } from '../../services/webApps.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { base64StringToBlob } from 'blob-util';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KarbejelentoAlapadatokComponent } from './aloldalak/karbejelento-alapadatok/karbejelento-alapadatok.component';


@Component({
  selector: 'karbejelento',
  templateUrl: './karbejelento.component.html',
  styleUrls: ['./karbejelento.component.scss']
})


export class KarbejelentoComponent implements OnInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('alapAdatok', { static: true }) alapAdatok: KarbejelentoAlapadatokComponent;

  alapadatokFormGroup: FormGroup;
  karesemenyFormGroup: FormGroup;
  bejelentoFormGroup: FormGroup;
  szemleFormGroup: FormGroup;
  gepjarmuSzemelyFormGroup: FormGroup;
  karosultFormGroup: FormGroup;
  karosultOtthonFormGroup: FormGroup;
  karosultVagyontargyFormGroup: FormGroup;
  fajlFeltoltesFormGroup: FormGroup;
  eredmenyFormGroup: FormGroup;
  stepFormGroup: FormGroup;
  pdfFormGroup: FormGroup;

  azonosito: string = "0";
  mentes_uzenet: string = " ";
  isKarosultGepjarmu: boolean = true;
  isKarosultSzemely: boolean = true;
  isKarosultSzemelyOtthon: boolean = true;
  isKgfbVagyon: boolean = true;
  modozat: string;
  karosultLabel: string = "Károsult adatai";
  karbejelentesVege: boolean = false;
  ugyfelportal: boolean = false;
  url: string = "";
  teszt: boolean = false;
  toltoKereses: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private karbejelento: KarbejelentoService,
    private formBuilder: FormBuilder,
    private webApps: WebAppsService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router  ) {

    this.url = window.location.href;
    this.route.queryParams.subscribe((params: Params) => {
      const _params = this.toLower(params);
      if (_params['karbejazon'] && _params['mac']) {
        this.urlEllenorzes(this.url, _params['karbejazon']);
      }
    });


    /*this.route.queryParams.subscribe(params => {
      if (params.length > 0) {
        // Ha az karbejazon querystringnek van értéke
        if (params['karbejazon']) {
          this.azonosito = (params['karbejazon'] as string);
          this.ugyfelportal = true;
        }
        else {
          this.azonosito = "0";
        }
      }
    });*/
  }

  urlEllenorzes(url: string, azonosito: string) {
    this.utilityService.isValidUrl(url).subscribe(
      adat => {
        if (adat) {
          this.azonosito = azonosito;
          this.ugyfelportal = true;
          this.ngAfterViewInit();
        }
        else {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hibás link!",
            confirmButtonText: 'Rendben'
          });
        }
      },
      errorResult => {
        this.utilityService.dialogMegnyitasa({
          error: true,
          title: 'Hiba',
          message: errorResult,
          confirmButtonText: 'Rendben'
        });
      });
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }

  ngOnInit() {
    this.webApps.changeMessage("Online Kárbejelentő");
  }
 

  async ngAfterViewInit() {

    let model = {
      key: "Karbejelento_Hiba"
    }
    let hiba = await this.utilityService.appsettingsKeyErtekLekerese(model);
        
    if (hiba == "N") {


      this.stepFormGroup = this.alapadatokFormGroup;

      if (this.azonosito != "0") {

        this.azonositoAtadas();
        this.cdr.detectChanges();

        await this.adatFeltoltes("");

        let validForm: boolean = true;
        while (validForm) {
          validForm = this.stepFormGroup.valid;

          if (validForm) {
            let elozoFomGroup: FormGroup = this.stepFormGroup;
            this.stepper.next();
            this.stepFormGroup = this.stepper.selected.stepControl as FormGroup;

            validForm = elozoFomGroup != this.stepFormGroup && this.stepFormGroup.valid;
          }
        }
      }

      if (this.alapadatokFormGroup != null) {

        this.alapadatokFormGroup.controls['f_modozat'].valueChanges.subscribe(value => {
          this.modozat = this.alapadatokFormGroup.controls['f_modozat'].value;
          this.stepsLathatosag()
        });

        this.alapadatokFormGroup.controls['f_kartipus_gfb'].valueChanges.subscribe(value => {
          this.stepsLathatosag()
        });

        this.alapadatokFormGroup.controls['f_kartipus'].valueChanges.subscribe(value => {
          this.stepsLathatosag()
        });
      }

      this.cdr.detectChanges();
    }
    else {
      this.router.navigate(['karbejelento-hiba-oldal']);
    }

  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  azonositoAtadas() {
    this.alapadatokFormGroup.controls["f_azonosito"].setValue(this.azonosito);
    this.szemleFormGroup.controls["f_azonosito"].setValue(this.azonosito);

    if (this.gepjarmuSzemelyFormGroup) {
      this.gepjarmuSzemelyFormGroup.controls["f_azonosito"].setValue(this.azonosito);
    }

    if (this.karosultFormGroup) {
      this.karosultFormGroup.controls["f_azonosito"].setValue(this.azonosito);
    }
    if (this.karosultOtthonFormGroup) {
      this.karosultOtthonFormGroup.controls["f_azonosito"].setValue(this.azonosito);
    }

    this.fajlFeltoltesFormGroup.controls["f_azonosito"].setValue(this.azonosito);
  }

  stepsLathatosag() {

    this.isKarosultGepjarmu = false;
    this.isKarosultSzemely = false;
    this.isKarosultSzemelyOtthon = false;
    this.isKgfbVagyon = false;

    switch (this.modozat) {
      case "KGFB":
        if (this.alapadatokFormGroup.controls["f_kartipus_gfb"].value) {
          if (this.alapadatokFormGroup.controls["f_kartipus_gfb"].value.indexOf("E56101") > -1) {
            this.isKarosultGepjarmu = true;
          }
          if (this.alapadatokFormGroup.controls["f_kartipus_gfb"].value.indexOf("E56201") > -1) {
            this.isKarosultSzemely = true;
          }
          if (this.alapadatokFormGroup.controls["f_kartipus_gfb"].value.indexOf("E56102") > -1) {
            this.isKgfbVagyon = true;
          }
        }
        break;
      case "CASCO":
        this.isKarosultGepjarmu = true;
        break;
      case "CMR":
        this.isKarosultSzemely = true;
        this.isKgfbVagyon = true;
        break;
      case "OTTHON":
        if (this.alapadatokFormGroup.controls["f_kartipus"].value == "E36001" ||
          this.alapadatokFormGroup.controls["f_kartipus"].value == "E36101") {
          this.isKarosultSzemely = true;
          this.isKgfbVagyon = true;
        }
        if (this.alapadatokFormGroup.controls["f_kartipus"].value == "E31001" ||
          this.alapadatokFormGroup.controls["f_kartipus"].value == "E31101" ||
          this.alapadatokFormGroup.controls["f_kartipus"].value == "E31102" ||
          this.alapadatokFormGroup.controls["f_kartipus"].value == "E31002") {
          this.isKarosultSzemelyOtthon = true;
        }
        if (!this.isKarosultSzemely && !this.isKarosultSzemelyOtthon) {
          this.isKgfbVagyon = true;
        }
        break;
      case "TRAFIK":
        if (this.alapadatokFormGroup.controls["f_kartipus"].value.indexOf("TEBFF") > 0 ||
          this.alapadatokFormGroup.controls["f_kartipus"].value.indexOf("TEMFF") > 0 ||
          this.alapadatokFormGroup.controls["f_kartipus"].value.indexOf("TESFF") > 0 ||
          this.alapadatokFormGroup.controls["f_kartipus"].value.indexOf("TETFF") > 0) {
          this.isKarosultSzemely = true;
        }
        this.isKgfbVagyon = true;
        break;

    }

    this.cdr.detectChanges();

    if (this.isKarosultGepjarmu && this.gepjarmuSzemelyFormGroup) {
      this.gepjarmuSzemelyFormGroup.controls["f_azonosito"].setValue(this.azonosito);
      this.gepjarmuSzemelyFormGroup.controls["f_modozat"].setValue(this.modozat);
      this.gepjarmuSzemelyFormGroup.controls["f_bejelento_tipusa"].setValue(this.bejelentoFormGroup.controls["f_bejelento_tipusa"].value);
    }

    if (this.isKarosultSzemely) {
      if (this.alapadatokFormGroup.controls["f_modozat"].value == "CMR") {
        this.karosultLabel = "Károsult adatai"
      }
      else {
        this.karosultLabel = "Károsult személy adatai"
      }

      if (this.karosultFormGroup) {
        this.karosultFormGroup.controls["f_azonosito"].setValue(this.azonosito);
        this.karosultFormGroup.controls["f_modozat"].setValue(this.modozat);
        this.karosultFormGroup.controls["f_bejelento_tipusa"].setValue(this.bejelentoFormGroup.controls["f_bejelento_tipusa"].value);
        this.karosultFormGroup.controls["f_karosult_tipusa"].setValue(this.alapadatokFormGroup.controls["f_karosult_tipus"].value);
        this.karosultFormGroup.controls["f_nev"].setValue(this.alapadatokFormGroup.controls["f_karosult_nev"].value);
      }
    }

    if (this.isKarosultSzemelyOtthon && this.karosultOtthonFormGroup) {
      this.karosultOtthonFormGroup.controls["f_azonosito"].setValue(this.azonosito);
      this.karosultOtthonFormGroup.controls["f_szerzazon"].setValue(this.alapadatokFormGroup.controls["f_szerzazon"].value);
    }

    if (this.isKgfbVagyon && this.karosultVagyontargyFormGroup) {
      this.karosultVagyontargyFormGroup.controls["f_modozat"].setValue(this.alapadatokFormGroup.controls["f_modozat"].value);
      this.karosultVagyontargyFormGroup.controls["f_kartipus"].setValue(this.alapadatokFormGroup.controls["f_modozat"].value == "KGFB" ? this.alapadatokFormGroup.controls["f_kartipus_gfb"].value : this.alapadatokFormGroup.controls["f_kartipus"].value);
      this.karosultVagyontargyFormGroup.controls["f_karido"].setValue(this.alapadatokFormGroup.controls["f_karido"].value);
      this.karosultVagyontargyFormGroup.controls["f_szerzazon"].setValue(this.alapadatokFormGroup.controls["f_szerzazon"].value);
      this.karosultVagyontargyFormGroup.controls["f_trafik_kockhely_ssz"].setValue(this.alapadatokFormGroup.controls["f_trafik_kockhely_ssz"].value);
    }

    if (this.bejelentoFormGroup) {
      this.bejelentoFormGroup.controls["f_szerzazon"].setValue(this.alapadatokFormGroup.controls["f_szerzazon"].value);
    }

    this.fajlFeltoltesFormGroup.controls["f_azonosito"].setValue(this.azonosito);
    this.fajlFeltoltesFormGroup.controls["f_modozat"].setValue(this.alapadatokFormGroup.controls["f_modozat"].value);
    this.fajlFeltoltesFormGroup.controls["f_szemely_serultes"].setValue(this.modozat != "CMR" && (this.isKarosultSzemely || this.isKarosultSzemelyOtthon));

  }

  adatFeltoltes(mit: string): Promise<any> {

    return new Promise<any>(async (resolve) => {

      let model = {
        f_azonosito: this.azonosito
      }

      this.karbejelento.adatFeltoltes(model).toPromise().then(
        data => {

          if ((mit == "" || mit == "karalap") && data["karalap"] != null) {
            this.alapadatokFormGroup.controls["f_adatkezelesi"].setValue((this.ugyfelportal == true || (this.ugyfelportal == false && this.azonosito != "0")) ? true : false);
            this.alapadatokFormGroup.controls["f_modozat"].setValue(data["karalap"].f_modozat);
            this.modozat = data["karalap"].f_modozat;
            this.alapadatokFormGroup.controls["f_modkod"].setValue(data["karalap"].f_modkod);
            this.alapadatokFormGroup.controls["f_szerzazon"].setValue(data["karalap"].f_szerzazon);
            if (data["karalap"].f_karido != null) {
              this.alapadatokFormGroup.controls["f_karido"].setValue(data["karalap"].f_karido.split(".").join("-"));
            }
            else {
              this.alapadatokFormGroup.controls["f_karido"].setValue(null);
            }
            this.alapadatokFormGroup.controls["f_azon_tip"].setValue((data["karalap"].f_modozat == "KGFB" || data["karalap"].f_modozat == "CASCO") ? data["karalap"].f_kereses_mezo : null);
            this.alapadatokFormGroup.controls["f_azon_adat"].setValue((data["karalap"].f_modozat == "KGFB" || data["karalap"].f_modozat == "CASCO") ? data["karalap"].f_kereses_mezo == "0" ? data["karalap"].f_rendszam : data["karalap"].f_szerzazon : null);
            this.alapadatokFormGroup.controls["f_rendszam"].setValue((data["karalap"].f_modozat == "KGFB" || data["karalap"].f_modozat == "CASCO") ? data["karalap"].f_kereses_mezo == "0" ? data["karalap"].f_rendszam : null : null);
            this.alapadatokFormGroup.controls["f_trafik_kockhely_ssz"].setValue(data["karalap"].f_trafik_kockhely_ssz);
            this.alapadatokFormGroup.controls["f_trafik_kockhely"].setValue(data["karalap"].f_trafik_kockhely);
            this.alapadatokFormGroup.controls["f_trafik_reszmodozat"].setValue(data["karalap"].f_trafik_reszmodozat);
          }
          if ((mit == "" || mit == "karalap") && data["kartipus_gfb"] != null) {
            let tipusok: string[] = [];
            if (data["kartipus_gfb"].f_kar_tipus_gjm == "I") {
              tipusok.push("E56101");
            }
            if (data["kartipus_gfb"].f_kar_tipus_szemely == "I") {
              tipusok.push("E56201");
            }
            if (data["kartipus_gfb"].f_kar_tipus_vagyon == "I") {
              tipusok.push("E56102");
            }
            this.alapadatokFormGroup.controls["f_kartipus_gfb"].setValue(tipusok);
          }
          if ((mit == "" || mit == "karalap") && data["kartipus_tobbi"] != null) {
            this.alapadatokFormGroup.controls["f_kartipus"].setValue(data["kartipus_tobbi"].f_kar_tipus_tobbi);
          }

          if ((mit == "" || mit == "karalap") && data["gepjarmu"] != null) {
            this.alapadatokFormGroup.controls["f_gjm_jelleg"].setValue(data["gepjarmu"].f_jelleg);
            this.alapadatokFormGroup.controls["f_gjm_jellegnev"].setValue(data["gepjarmu"].f_jelleg_nev);
            this.alapadatokFormGroup.controls["f_karosult_rendszam"].setValue(data["gepjarmu"].f_rendszam);
            this.alapadatokFormGroup.controls["f_rendszam_tipus"].setValue(data["gepjarmu"].f_rendszam_tipus);
            this.alapadatokFormGroup.controls["f_vontato_rendszam"].setValue(data["gepjarmu"].f_vontato_rendszam);
            this.alapadatokFormGroup.controls["f_potkocsi_tipus"].setValue(data["gepjarmu"].f_potkocsi_tipus);
            this.alapadatokFormGroup.controls["f_potkocsi_rendszam"].setValue(data["gepjarmu"].f_potkocsi_rendszam);
          }
          if ((mit == "" || mit == "karalap") && data["karosult"] != null) {
            this.alapadatokFormGroup.controls["f_karosult_tipus"].setValue(data["karosult"].f_tipus);
            this.alapadatokFormGroup.controls["f_karosult_nev"].setValue(data["karosult"].f_nev);
          }
          if ((mit == "" || mit == "karalap") && data["mfo_kiegeszites"] != null) {
            this.alapadatokFormGroup.controls["f_becsult_osszeg"].setValue(data["mfo_kiegeszites"].f_becsult_osszeg_tipus.toString());
          }

          if ((mit == "" || mit == "karesemeny") && data["karesemeny"] != null) {
            this.karesemenyFormGroup.controls["f_modozat"].setValue(data["karalap"].f_modozat);
            this.karesemenyFormGroup.controls["f_leiras"].setValue(data["karesemeny"].f_leiras);
            this.karesemenyFormGroup.controls["f_cim_mo"].setValue(data["karesemeny"].f_cim_mo);
            this.karesemenyFormGroup.controls["f_helyszin_tipus"].setValue(data["karesemeny"].f_helyszin_tipus);
            this.karesemenyFormGroup.controls["f_irsz"].setValue(data["karesemeny"].f_helyszin_irszam);
            this.karesemenyFormGroup.controls["f_telepules"].setValue(data["karesemeny"].f_helyszin_telepules);
            this.karesemenyFormGroup.controls["f_utcanev"].setValue(data["karesemeny"].f_helyszin_utcanev);
            this.karesemenyFormGroup.controls["f_uttipus"].setValue(data["karesemeny"].f_helyszin_uttipus == "" ? "0" : data["karesemeny"].f_helyszin_uttipus)
            this.karesemenyFormGroup.controls["f_uttipus_van"].setValue(data["karesemeny"].f_helyszin_uttipus != "");
            this.karesemenyFormGroup.controls["f_hazszam"].setValue(data["karesemeny"].f_helyszin_hazszam);
            this.karesemenyFormGroup.controls["f_emelet"].setValue(data["karesemeny"].f_helyszin_emelet);
            this.karesemenyFormGroup.controls["f_ajto"].setValue(data["karesemeny"].f_helyszin_ajto);
            this.karesemenyFormGroup.controls["f_hrsz"].setValue(data["karesemeny"].f_helyszin_hrsz);
            this.karesemenyFormGroup.controls["f_kulterulet"].setValue(data["karesemeny"].f_helyszin_kulterulet);
            this.karesemenyFormGroup.controls["f_orszag"].setValue(data["karesemeny"].f_kulf_orszag);
            this.karesemenyFormGroup.controls["f_orszag_nev"].setValue(data["karesemeny"].f_kulf_orszag_nev);
            this.karesemenyFormGroup.controls["f_kulf_irsz"].setValue(data["karesemeny"].f_kulf_irszam);
            this.karesemenyFormGroup.controls["f_kulf_cim"].setValue(data["karesemeny"].f_kulf_cim);
            this.karesemenyFormGroup.controls["f_megjegyzes"].setValue(data["karesemeny"].f_megjegyzes);
            this.karesemenyFormGroup.controls["f_adatok_megadas"].setValue(data["karesemeny"].f_adatok_megad);
            this.karesemenyFormGroup.controls["f_hatosag_nev"].setValue(data["karesemeny"].f_hatosag_nev);
            this.karesemenyFormGroup.controls["f_hatosag_elerhetoseg"].setValue(data["karesemeny"].f_hatosag_elerh);
            this.karesemenyFormGroup.controls["f_hivatkozasi_szam"].setValue(data["karesemeny"].f_hivat_szam);
          }

          if ((mit == "" || mit == "bejelento") && data["bejelento"] != null) {
            this.bejelentoFormGroup.controls["f_modozat"].setValue(data["karalap"].f_modozat);
            this.bejelentoFormGroup.controls["f_szerzazon"].setValue(data["karalap"].f_szerzazon);
            this.bejelentoFormGroup.controls["f_bejelento_tipusa"].setValue(data["bejelento"].f_tipus);
            this.bejelentoFormGroup.controls["f_nev"].setValue(data["bejelento"].f_nev);
            this.bejelentoFormGroup.controls["f_szul_datum"].setValue(data["bejelento"].f_szuldat.split(".").join("-"));
            this.bejelentoFormGroup.controls["f_szul_hely"].setValue(data["bejelento"].f_szulhely);
            this.bejelentoFormGroup.controls["f_cim_mo"].setValue(data["bejelento"].f_cim_mo);
            this.bejelentoFormGroup.controls["f_irsz"].setValue(data["bejelento"].f_cim_irszam);
            this.bejelentoFormGroup.controls["f_telepules"].setValue(data["bejelento"].f_cim_telepules);
            this.bejelentoFormGroup.controls["f_utcanev"].setValue(data["bejelento"].f_cim_utcanev);
            this.bejelentoFormGroup.controls["f_uttipus"].setValue(data["bejelento"].f_cim_uttipus == "" ? "0" : data["bejelento"].f_cim_uttipus);
            this.bejelentoFormGroup.controls["f_uttipus_van"].setValue(data["bejelento"].f_cim_uttipus != "");
            this.bejelentoFormGroup.controls["f_hazszam"].setValue(data["bejelento"].f_cim_hazszam);
            this.bejelentoFormGroup.controls["f_emelet"].setValue(data["bejelento"].f_cim_emelet);
            this.bejelentoFormGroup.controls["f_ajto"].setValue(data["bejelento"].f_cim_ajto);
            this.bejelentoFormGroup.controls["f_hrsz"].setValue(data["bejelento"].f_cim_hrsz);
            this.bejelentoFormGroup.controls["f_orszag"].setValue(data["bejelento"].f_kulf_orszag);
            this.bejelentoFormGroup.controls["f_orszag_nev"].setValue(data["bejelento"].f_kulf_orszag_nev);
            this.bejelentoFormGroup.controls["f_kulf_irsz"].setValue(data["bejelento"].f_kulf_irszam);
            this.bejelentoFormGroup.controls["f_kulf_cim"].setValue(data["bejelento"].f_kulf_cim);
            this.bejelentoFormGroup.controls["f_telefon_tipus"].setValue(data["bejelento"].f_telefon_tipus);
            this.bejelentoFormGroup.controls["f_telefon_elohivo"].setValue(data["bejelento"].f_telefon_elohivo);
            this.bejelentoFormGroup.controls["f_telefon_szam"].setValue(data["bejelento"].f_telefon);
            this.bejelentoFormGroup.controls["f_email"].setValue(data["bejelento"].f_email);
            this.bejelentoFormGroup.controls["f_fax"].setValue(data["bejelento"].f_fax);
            this.bejelentoFormGroup.controls["f_ugyfelportal"].setValue(this.ugyfelportal);

            this.bejelentoFormGroup.controls["f_kedv_adat_tipus"].setValue(data["bejelento"].f_kedvezmenyezett_adat_tipus);

            if (data["bejelento"].f_kedvezmenyezett_adat_tipus) {
              let kedvTelElo: string = "";
              if (data["bejelento"].f_kedvezmenyezett_adat_tipus == "tel") {
                kedvTelElo = data["bejelento"].f_masodlagos_azonosito.substring(0, 2);

                if (kedvTelElo == "20" || kedvTelElo == "30" || kedvTelElo == "70" || kedvTelElo == "50") {
                  this.bejelentoFormGroup.controls["f_kedv_telefon_tipus"].setValue("M");
                }
                else {
                  this.bejelentoFormGroup.controls["f_kedv_telefon_tipus"].setValue("V");
                }

                this.bejelentoFormGroup.controls["f_kedv_telefon_elohivo"].setValue(kedvTelElo);
                this.bejelentoFormGroup.controls["f_kedv_telefon_szam"].setValue(data["bejelento"].f_masodlagos_azonosito.substring(2));
              }
              else {
                if (data["bejelento"].f_kedvezmenyezett_adat_tipus.toString() == "szla") {
                  this.bejelentoFormGroup.controls["f_kedv_adat"].setValue(data["bejelento"].f_szamlaszam);
                }
                else {
                  this.bejelentoFormGroup.controls["f_kedv_adat"].setValue(data["bejelento"].f_masodlagos_azonosito);
                }
              }

              this.bejelentoFormGroup.controls["f_kedv_telefon_tipus_ujra"].setValue(this.bejelentoFormGroup.controls["f_kedv_telefon_tipus"].value);
              this.bejelentoFormGroup.controls["f_kedv_telefon_elohivo_ujra"].setValue(this.bejelentoFormGroup.controls["f_kedv_telefon_elohivo"].value);
              this.bejelentoFormGroup.controls["f_kedv_telefon_szam_ujra"].setValue(this.bejelentoFormGroup.controls["f_kedv_telefon_szam"].value);
              this.bejelentoFormGroup.controls["f_kedv_adat_ujra"].setValue(this.bejelentoFormGroup.controls["f_kedv_adat"].value);
            }

            this.bejelentoFormGroup.controls["f_nev_kapcsolattarto"].setValue(data["bejelento"].f_nev_kapcsolattarto);
            this.bejelentoFormGroup.controls["f_telefon_tipus_kapcsolattarto"].setValue(data["bejelento"].f_telefon_tipus_kapcsolattarto);
            this.bejelentoFormGroup.controls["f_telefon_elohivo_kapcsolattarto"].setValue(data["bejelento"].f_telefon_elohivo_kapcsolattarto);
            this.bejelentoFormGroup.controls["f_telefon_szam_kapcsolattarto"].setValue(data["bejelento"].f_telefon_kapcsolattarto);
            this.bejelentoFormGroup.controls["f_email_kapcsolattarto"].setValue(data["bejelento"].f_email_kapcsolattarto);
            this.bejelentoFormGroup.controls["f_fax_kapcsolattarto"].setValue(data["bejelento"].f_fax_kapcsolattarto);
          }

          this.stepsLathatosag();

          if ((mit == "" || mit == "szemle") && data["szemle"] != null) {

            this.szemleFormGroup.controls["f_kartipus"].setValue(this.modozat == "KGFB" ? this.alapadatokFormGroup.controls["f_kartipus_gfb"].value : this.alapadatokFormGroup.controls["f_kartipus"].value);
            this.szemleFormGroup.controls["f_egyezik"].setValue(data["szemle"].f_egyez == "I" ? true : false);
            this.szemleFormGroup.controls["f_cim_mo"].setValue(data["szemle"].f_cim_mo);
            this.szemleFormGroup.controls["f_helyszin_tipus"].setValue(data["szemle"].f_cim_tipus);
            this.szemleFormGroup.controls["f_irsz"].setValue(data["szemle"].f_szemle_irszam);
            this.szemleFormGroup.controls["f_telepules"].setValue(data["szemle"].f_szemle_telepules);
            this.szemleFormGroup.controls["f_utcanev"].setValue(data["szemle"].f_szemle_utcanev);
            this.szemleFormGroup.controls["f_uttipus"].setValue(data["szemle"].f_szemle_uttipus == "" ? "0" : data["szemle"].f_szemle_uttipus);
            this.szemleFormGroup.controls["f_uttipus_van"].setValue(data["szemle"].f_szemle_uttipus != "");
            this.szemleFormGroup.controls["f_hazszam"].setValue(data["szemle"].f_szemle_hazszam);
            this.szemleFormGroup.controls["f_emelet"].setValue(data["szemle"].f_szemle_emelet);
            this.szemleFormGroup.controls["f_ajto"].setValue(data["szemle"].f_szemle_ajto);
            this.szemleFormGroup.controls["f_hrsz"].setValue(data["szemle"].f_szemle_hrsz);
            this.szemleFormGroup.controls["f_kulterulet"].setValue(data["szemle"].f_szemle_kulterulet);
            this.szemleFormGroup.controls["f_orszag"].setValue(data["szemle"].f_kulf_orszag);
            this.szemleFormGroup.controls["f_orszag_nev"].setValue(data["szemle"].f_kulf_orszag_nev);
            this.szemleFormGroup.controls["f_kulf_irsz"].setValue(data["szemle"].f_kulf_irszam);
            this.szemleFormGroup.controls["f_kulf_cim"].setValue(data["szemle"].f_kulf_cim);
            this.szemleFormGroup.controls["f_kapcsolattarto_neve"].setValue(data["szemle"].f_kapcsolattarto_nev);
            this.szemleFormGroup.controls["f_kapcsolattarto_elerhetosege"].setValue(data["szemle"].f_kapcsolattarto_elerhet);
            this.szemleFormGroup.controls["f_megjegyzes"].setValue(data["szemle"].f_megjegyzes);
          }
          else {
            if (data["szemle"] == null && mit == "szemle") {
              this.szemleFormGroup.controls["f_modozat"].setValue(data["karalap"].f_modozat);
              this.szemleFormGroup.controls["f_egyezik"].setValue(false);
              this.szemleFormGroup.controls["f_cim_mo"].setValue("I");
              this.szemleFormGroup.controls["f_helyszin_tipus"].setValue("P");
              this.szemleFormGroup.controls["f_irsz"].setValue(null);
              this.szemleFormGroup.controls["f_telepules"].setValue("0");
              this.szemleFormGroup.controls["f_utcanev"].setValue("0");
              this.szemleFormGroup.controls["f_uttipus"].setValue("0");
              this.szemleFormGroup.controls["f_uttipus_van"].setValue(false);
              this.szemleFormGroup.controls["f_hazszam"].setValue(null);
              this.szemleFormGroup.controls["f_emelet"].setValue(null);
              this.szemleFormGroup.controls["f_ajto"].setValue(null);
              this.szemleFormGroup.controls["f_hrsz"].setValue(null);
              this.szemleFormGroup.controls["f_kulterulet"].setValue(null);
              this.szemleFormGroup.controls["f_orszag"].setValue(null);
              this.szemleFormGroup.controls["f_orszag_nev"].setValue(null);
              this.szemleFormGroup.controls["f_kulf_irsz"].setValue(null);
              this.szemleFormGroup.controls["f_kulf_cim"].setValue(null);
              this.szemleFormGroup.controls["f_kapcsolattarto_neve"].setValue(null);
              this.szemleFormGroup.controls["f_kapcsolattarto_elerhetosege"].setValue(null);
              this.szemleFormGroup.controls["f_megjegyzes"].setValue(null)
            }
          }

          if (this.gepjarmuSzemelyFormGroup) {

            if ((mit == "" || mit == "gepjarmuTulaj") && data["gepjarmuTulaj"] != null) {
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_egyezik"].setValue(data["gepjarmuTulaj"].f_egyez == "I" ? true : false);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_nev"].setValue(data["gepjarmuTulaj"].f_nev);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_szul_datum"].setValue(data["gepjarmuTulaj"].f_szuldat.split(".").join("-"));
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_szul_hely"].setValue(data["gepjarmuTulaj"].f_szulhely);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_cim_mo"].setValue(data["gepjarmuTulaj"].f_cim_mo);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_irsz"].setValue(data["gepjarmuTulaj"].f_cim_irszam);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_telepules"].setValue(data["gepjarmuTulaj"].f_cim_telepules);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_utcanev"].setValue(data["gepjarmuTulaj"].f_cim_utcanev);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_uttipus"].setValue(data["gepjarmuTulaj"].f_cim_uttipus == "" ? "0" : data["gepjarmuTulaj"].f_cim_uttipus);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_uttipus_van"].setValue(data["gepjarmuTulaj"].f_cim_uttipus != "");
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_hazszam"].setValue(data["gepjarmuTulaj"].f_cim_hazszam);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_emelet"].setValue(data["gepjarmuTulaj"].f_cim_emelet);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_ajto"].setValue(data["gepjarmuTulaj"].f_cim_ajto);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_hrsz"].setValue(data["gepjarmuTulaj"].f_cim_hrsz);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_orszag"].setValue(data["gepjarmuTulaj"].f_kulf_orszag);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_orszag_nev"].setValue(data["gepjarmuTulaj"].f_kulf_orszag_nev);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_kulf_irsz"].setValue(data["gepjarmuTulaj"].f_kulf_irszam);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_kulf_cim"].setValue(data["gepjarmuTulaj"].f_kulf_cim);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_telefon_tipus"].setValue(data["gepjarmuTulaj"].f_telefon_tipus);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_telefon_elohivo"].setValue(data["gepjarmuTulaj"].f_telefon_elohivo);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_telefon_szam"].setValue(data["gepjarmuTulaj"].f_telefon);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_email"].setValue(data["gepjarmuTulaj"].f_email);
              this.gepjarmuSzemelyFormGroup.controls["f_tulaj_fax"].setValue(data["gepjarmuTulaj"].f_fax);
            }
            if ((mit == "" || mit == "gepjarmuUzem") && data["gepjarmuUzem"] != null) {
              this.gepjarmuSzemelyFormGroup.controls["f_uzem_egyezik"].setValue(data["gepjarmuUzem"].f_egyez == "I" ? true : false);
              if (data["gepjarmuUzem"].f_egyez != "I") {
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_nev"].setValue(data["gepjarmuUzem"].f_nev);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_szul_datum"].setValue(data["gepjarmuUzem"].f_szuldat.split(".").join("-"));
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_szul_hely"].setValue(data["gepjarmuUzem"].f_szulhely);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_cim_mo"].setValue(data["gepjarmuUzem"].f_cim_mo);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_irsz"].setValue(data["gepjarmuUzem"].f_cim_irszam);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_telepules"].setValue(data["gepjarmuUzem"].f_cim_telepules);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_utcanev"].setValue(data["gepjarmuUzem"].f_cim_utcanev);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_uttipus"].setValue(data["gepjarmuUzem"].f_cim_uttipus == "" ? "0" : data["gepjarmuUzem"].f_cim_uttipus);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_uttipus_van"].setValue(data["gepjarmuUzem"].f_cim_uttipus != "");
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_hazszam"].setValue(data["gepjarmuUzem"].f_cim_hazszam);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_emelet"].setValue(data["gepjarmuUzem"].f_cim_emelet);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_ajto"].setValue(data["gepjarmuUzem"].f_cim_ajto);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_hrsz"].setValue(data["gepjarmuUzem"].f_cim_hrsz);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_orszag"].setValue(data["gepjarmuUzem"].f_kulf_orszag);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_orszag_nev"].setValue(data["gepjarmuUzem"].f_kulf_orszag_nev);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_kulf_irsz"].setValue(data["gepjarmuUzem"].f_kulf_irszam);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_kulf_cim"].setValue(data["gepjarmuUzem"].f_kulf_cim);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_telefon_tipus"].setValue(data["gepjarmuUzem"].f_telefon_tipus);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_telefon_elohivo"].setValue(data["gepjarmuUzem"].f_telefon_elohivo);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_telefon_szam"].setValue(data["gepjarmuUzem"].f_telefon);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_email"].setValue(data["gepjarmuUzem"].f_email);
                this.gepjarmuSzemelyFormGroup.controls["f_uzem_fax"].setValue(data["gepjarmuUzem"].f_fax);
              }
            }
            if ((mit == "" || mit == "gepjarmuVezeto") && data["gepjarmuVezeto"] != null) {
              this.gepjarmuSzemelyFormGroup.controls["f_vezeto_egyezik"].setValue(data["gepjarmuVezeto"].f_egyez == "I" ? true : false);
              if (this.modozat == "CASCO" || (this.modozat == "KGFB" && data["gepjarmuVezeto"].f_egyez != "I")) {
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_nev"].setValue(data["gepjarmuVezeto"].f_nev);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_szul_datum"].setValue(data["gepjarmuVezeto"].f_szuldat.split(".").join("-"));
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_szul_hely"].setValue(data["gepjarmuVezeto"].f_szulhely);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_cim_mo"].setValue(data["gepjarmuVezeto"].f_cim_mo);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_irsz"].setValue(data["gepjarmuVezeto"].f_cim_irszam);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_telepules"].setValue(data["gepjarmuVezeto"].f_cim_telepules);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_utcanev"].setValue(data["gepjarmuVezeto"].f_cim_utcanev);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_uttipus"].setValue(data["gepjarmuVezeto"].f_cim_uttipus == "" ? "0" : data["gepjarmuVezeto"].f_cim_uttipus);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_uttipus_van"].setValue(data["gepjarmuVezeto"].f_cim_uttipus != "");
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_hazszam"].setValue(data["gepjarmuVezeto"].f_cim_hazszam);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_emelet"].setValue(data["gepjarmuVezeto"].f_cim_emelet);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_ajto"].setValue(data["gepjarmuVezeto"].f_cim_ajto);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_hrsz"].setValue(data["gepjarmuVezeto"].f_cim_hrsz);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_orszag"].setValue(data["gepjarmuVezeto"].f_kulf_orszag);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_orszag_nev"].setValue(data["gepjarmuVezeto"].f_kulf_orszag_nev);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_kulf_irsz"].setValue(data["gepjarmuVezeto"].f_kulf_irszam);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_kulf_cim"].setValue(data["gepjarmuVezeto"].f_kulf_cim);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_telefon_tipus"].setValue(data["gepjarmuVezeto"].f_telefon_tipus);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_telefon_elohivo"].setValue(data["gepjarmuVezeto"].f_telefon_elohivo);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_telefon_szam"].setValue(data["gepjarmuVezeto"].f_telefon);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_email"].setValue(data["gepjarmuVezeto"].f_email);
                this.gepjarmuSzemelyFormGroup.controls["f_vezeto_fax"].setValue(data["gepjarmuVezeto"].f_fax);
              }
            }
          }

          if (this.karosultFormGroup) {
            if ((mit == "" || mit == "karosult") && data["karosult"] != null) {
              this.karosultFormGroup.controls["f_egyezik"].setValue(data["karosult"].f_egyez == "I" ? true : false);
              this.karosultFormGroup.controls["f_karosult_tipusa"].setValue(data["karosult"].f_tipus);
              this.karosultFormGroup.controls["f_nev"].setValue(data["karosult"].f_nev);
              this.karosultFormGroup.controls["f_szul_datum"].setValue(data["karosult"].f_szuldat.split(".").join("-"));
              this.karosultFormGroup.controls["f_szul_hely"].setValue(data["karosult"].f_szulhely);
              this.karosultFormGroup.controls["f_cim_mo"].setValue(data["karosult"].f_cim_mo);
              this.karosultFormGroup.controls["f_irsz"].setValue(data["karosult"].f_cim_irszam);
              this.karosultFormGroup.controls["f_telepules"].setValue(data["karosult"].f_cim_telepules == "" ? "0" : data["karosult"].f_cim_telepules);
              this.karosultFormGroup.controls["f_utcanev"].setValue(data["karosult"].f_cim_utcanev == "" ? "0" : data["karosult"].f_cim_utcanev);
              this.karosultFormGroup.controls["f_uttipus"].setValue(data["karosult"].f_cim_uttipus == "" ? "0" : data["karosult"].f_cim_uttipus);
              this.karosultFormGroup.controls["f_uttipus_van"].setValue(data["karosult"].f_cim_uttipus != "");
              this.karosultFormGroup.controls["f_hazszam"].setValue(data["karosult"].f_cim_hazszam);
              this.karosultFormGroup.controls["f_emelet"].setValue(data["karosult"].f_cim_emelet);
              this.karosultFormGroup.controls["f_ajto"].setValue(data["karosult"].f_cim_ajto);
              this.karosultFormGroup.controls["f_hrsz"].setValue(data["karosult"].f_cim_hrsz);
              this.karosultFormGroup.controls["f_orszag"].setValue(data["karosult"].f_kulf_orszag);
              this.karosultFormGroup.controls["f_orszag_nev"].setValue(data["karosult"].f_kulf_orszag_nev);
              this.karosultFormGroup.controls["f_kulf_irsz"].setValue(data["karosult"].f_kulf_irszam);
              this.karosultFormGroup.controls["f_kulf_cim"].setValue(data["karosult"].f_kulf_cim);
              this.karosultFormGroup.controls["f_telefon_tipus"].setValue(data["karosult"].f_telefon_tipus);
              this.karosultFormGroup.controls["f_telefon_elohivo"].setValue(data["karosult"].f_telefon_elohivo == "" ? "0" : data["karosult"].f_telefon_elohivo);
              this.karosultFormGroup.controls["f_telefon_szam"].setValue(data["karosult"].f_telefon);
              this.karosultFormGroup.controls["f_email"].setValue(data["karosult"].f_email);
              this.karosultFormGroup.controls["f_fax"].setValue(data["karosult"].f_fax);
            }
          }

          if (this.karosultVagyontargyFormGroup) {
            if ((mit == "" || mit == "vagyon") && data["vagyon"] != null) {
              this.karosultVagyontargyFormGroup.controls["f_vagyontargy_leiras"].setValue(data["vagyon"].f_leiras);
              this.karosultVagyontargyFormGroup.controls["f_uveg_tipus"].setValue(data["vagyon"].f_tipus_kod);
              this.karosultVagyontargyFormGroup.controls["f_uveg_tipus_megnev"].setValue(data["vagyon"].f_tipus);
              this.karosultVagyontargyFormGroup.controls["f_uveg_meret1"].setValue(data["vagyon"].f_meret_1);
              this.karosultVagyontargyFormGroup.controls["f_uveg_meret2"].setValue(data["vagyon"].f_meret_2);
              this.karosultVagyontargyFormGroup.controls["f_idegen_vagyontargy"].setValue(data["vagyon"].f_idegen_vagyontargy == "I");
            }

            if ((mit == "" || mit == "vagyon") && data["mfo_kiegeszites"] != null) {
              this.karosultVagyontargyFormGroup.controls["f_vagyontargy_tipus"].setValue(data["mfo_kiegeszites"].f_karosodas);
              this.karosultVagyontargyFormGroup.controls["f_becsult_osszeg"].setValue(data["mfo_kiegeszites"].f_becsult_osszeg);
              this.karosultVagyontargyFormGroup.controls["f_becsult_osszeg_tipus"].setValue(data["mfo_kiegeszites"].f_becsult_osszeg_tipus);
            }
          }

          if (this.fajlFeltoltesFormGroup) {
            if ((mit == "" || mit == "filefeltoltes")) {
              this.fajlFeltoltesFormGroup.controls["f_modozat"].setValue(data["karalap"].f_modozat);
              if (data["bejelento"] != null) {
                this.fajlFeltoltesFormGroup.controls["f_bejelento"].setValue(data["bejelento"].f_bejelento_tipusa);
              }

              if (data["okozo"] != null) {
                this.fajlFeltoltesFormGroup.controls["f_okozo_felelos"].setValue(data["okozo"].f_felelos.toString());
                this.fajlFeltoltesFormGroup.controls["f_okozo_felelos_egyeb"].setValue(data["okozo"].f_felelos_egyeb);
                this.fajlFeltoltesFormGroup.controls["f_okozo_vezeto"].setValue(data["okozo"].f_vezeto.toString());
                this.fajlFeltoltesFormGroup.controls["f_okozo_vezeto_egyeb"].setValue(data["okozo"].f_vezeto_egyeb);
              }

              if (data["biztosito"] != null) {
                this.fajlFeltoltesFormGroup.controls["f_mas_biztositonal_bejelentes"].setValue(data["biztosito"].f_mas_biztosito.toString() == "-" ? "-1" : data["biztosito"].f_mas_biztosito.toString());
                this.fajlFeltoltesFormGroup.controls["f_mas_biztosito"].setValue(data["biztosito"].f_biztosito);
                this.fajlFeltoltesFormGroup.controls["f_mas_biztosito_kod"].setValue(data["biztosito"].f_biztosito_kod);
              }

            }
          }

          resolve("");

        },
        () => {
          this.utilityService.dialogMegnyitasa({
            error: true,
            title: 'Hiba',
            message: "Hiba a mentett adatok lekérdezése közben!",
            confirmButtonText: 'Rendben'
          });
        });
    });
  }

  duplaEllenorzes(): Promise<any> {

    return new Promise<any>(async (resolve) => {

      if (/*this.alapadatokFormGroup.controls["f_azonosito"].value == "0" &&*/
        this.alapadatokFormGroup.controls["f_szerzazon"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_karido"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_vontato_rendszam"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_rendszam"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_karosult_tipus"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_karosult_nev"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_modozat"].status == "VALID" &&
        this.alapadatokFormGroup.controls["f_modkod"].status == "VALID" &&
        ((this.alapadatokFormGroup.controls["f_modozat"].value != "KGFB" && this.alapadatokFormGroup.controls["f_kartipus"].status == "VALID") ||
          (this.alapadatokFormGroup.controls["f_modozat"].value == "KGFB" && this.alapadatokFormGroup.controls["f_kartipus_gfb"].status == "VALID"))) {


        this.alapadatokFormGroup.controls["f_dupla"].setValue(false);

        // Recaptcha kliens validálás
        this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').toPromise().then(
          token => {
            let model = { encodedResponse: token }
            // Recaptcha szerver validálás
            this.utilityService.recaptchaValidalas(model).subscribe(result => {

              this.karbejelento.karbejelentoDupla(this.alapadatokFormGroup.value).subscribe(
                async data => {
                  if (data[3] != "") {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba duplikált bejelentés ellenőrzése közben! - " + data[3],
                      confirmButtonText: 'Rendben'
                    });
                    this.alapadatokFormGroup.controls["f_dupla"].setValue(false);
                  }
                  else {
                    if (data[0] != "N" || data[1] != "N" || data[2] != "N") {

                      const result = await this.karbejelentoDuplaDialog();
                      this.alapadatokFormGroup.controls["f_dupla"].setValue(result);


                    }
                    else {
                      if (data[0] == "N" && data[1] == "N" && data[2] === "N") {
                        this.alapadatokFormGroup.controls["f_dupla"].setValue(true);
                      }
                    }
                  }
                  resolve("");
                },
                () => {
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hiba',
                    message: "Hiba duplikált bejelentés ellenőrzése közben!",
                    confirmButtonText: 'Rendben'
                  });
                  this.alapadatokFormGroup.controls["f_dupla"].setValue(false);
                  resolve("");
                });
            });
          });

        //await this.karbejelentoDupla();
      }
      else {
        this.alapadatokFormGroup.controls["f_dupla"].setValue(true);
      }

    });
  }

  async karbejelentoDuplaDialog(): Promise<number> {
    const dialogRef = this.dialog.open(KarbejelentoDuplaDialog, {
      data: {}
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      return Promise.resolve(result);
    });
  }

  public onStepChange(event: any) {

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_karbejelento_onStepChange').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(async result => {

          let hiba = false;
          /*while (!hiba) {*/
          switch (event.previouslySelectedStep.stepControl) {
            case this.alapadatokFormGroup:

              if (this.alapadatokFormGroup.status == "VALID") {


                await this.duplaEllenorzes();

                if (this.alapadatokFormGroup.controls["f_dupla"].value) {

                  let model = {
                    formGroup: this.alapadatokFormGroup.value,
                    f_azonosito: this.azonosito
                  }

                  this.karbejelento.alapadatokMentes(model).subscribe(
                    data => {
                      this.mentes_uzenet = data["f_uzenet"];
                      if (this.mentes_uzenet == "") {
                        this.azonosito = data["f_azonosito"].toString();

                        //this.azonositoAtadas();

                        this.alapadatokFormGroup.controls["f_azonosito"].setValue(this.azonosito);

                        this.karesemenyFormGroup.controls["f_modozat"].setValue(this.modozat);

                        this.bejelentoFormGroup.controls["f_modozat"].setValue(this.modozat);
                        this.bejelentoFormGroup.controls["f_szerzazon"].setValue(this.alapadatokFormGroup.controls["f_szerzazon"].value);

                        this.szemleFormGroup.controls["f_azonosito"].setValue(this.azonosito);
                        this.szemleFormGroup.controls["f_modozat"].setValue(this.modozat);
                        this.szemleFormGroup.controls["f_kartipus"].setValue(this.modozat == "KGFB" ? this.alapadatokFormGroup.controls["f_kartipus_gfb"].value : this.alapadatokFormGroup.controls["f_kartipus"].value);

                        this.fajlFeltoltesFormGroup.controls["f_azonosito"].setValue(this.azonosito);
                        this.fajlFeltoltesFormGroup.controls["f_modozat"].setValue(this.modozat);

                        this.karosultVagyontargyFormGroup.controls["f_szerzazon"].setValue(this.alapadatokFormGroup.controls["f_szerzazon"].value);
                        this.karosultVagyontargyFormGroup.controls["f_karido"].setValue(this.alapadatokFormGroup.controls["f_karido"].value);
                        this.karosultVagyontargyFormGroup.controls["f_kartipus"].setValue(this.alapadatokFormGroup.controls["f_modozat"].value == "KGFB" ? this.alapadatokFormGroup.controls["f_kartipus_gfb"].value : this.alapadatokFormGroup.controls["f_kartipus"].value);
                        this.karosultVagyontargyFormGroup.controls["f_trafik_kockhely_ssz"].setValue(this.alapadatokFormGroup.controls["f_trafik_kockhely_ssz"].value);

                        this.stepsLathatosag();

                      }
                      else {
                        hiba = true;
                      }
                    },
                    () => {
                      this.utilityService.dialogMegnyitasa({
                        error: true,
                        title: 'Hiba',
                        message: "Hiba az alapadatok mentése közben!",
                        confirmButtonText: 'Rendben'
                      });
                      hiba = true;
                    });
                }
                else {
                  this.alapAdatok.formGroupNew();
                  this.stepper.previous();
                }
              }
              else {
                hiba = true;
              }
              break;
            case this.karesemenyFormGroup:

              if (this.karesemenyFormGroup.status == "VALID") {

                let model = {
                  formGroup: this.karesemenyFormGroup.value,
                  f_azonosito: this.azonosito
                }

                this.karbejelento.karesemenyMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba a káresemény mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              else {
                hiba = true;
              }
              break;
            case this.bejelentoFormGroup:

              if (this.bejelentoFormGroup.status == "VALID") {

                let model = {
                  formGroup: this.bejelentoFormGroup.value,
                  f_azonosito: this.azonosito
                }

                this.karbejelento.bejelentoMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                    else {
                      this.adatFeltoltes("szemle");
                      if (this.karosultFormGroup) {
                        this.karosultFormGroup.controls["f_bejelento_tipusa"].setValue(this.bejelentoFormGroup.controls["f_bejelento_tipusa"].value);
                      }

                      if (this.gepjarmuSzemelyFormGroup) {
                        this.gepjarmuSzemelyFormGroup.controls["f_bejelento_tipusa"].setValue(this.bejelentoFormGroup.controls["f_bejelento_tipusa"].value);
                      }

                      this.fajlFeltoltesFormGroup.controls["f_bejelento"].setValue(this.bejelentoFormGroup.controls["f_bejelento_tipusa"].value);
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba a bejelentő mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              /*else {
                hiba = true;
              }*/

              break;
            case this.karosultFormGroup:

              if (this.karosultFormGroup && this.karosultFormGroup.status == "VALID") {

                let model = {
                  formGroup: this.karosultFormGroup.value,
                  f_azonosito: this.azonosito
                }

                this.karbejelento.karosultMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba az károsult mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              else {
                hiba = true;
              }

              break;
            case this.karosultOtthonFormGroup:
              if (this.karosultOtthonFormGroup && this.karosultOtthonFormGroup.status != "VALID") {
                hiba = true;
              }
              break;
            case this.szemleFormGroup:

              if (this.szemleFormGroup.status == "VALID") {

                let model = {
                  formGroup: this.szemleFormGroup.value,
                  f_azonosito: this.azonosito
                }

                this.karbejelento.szemleMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba a szemle mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              else {
                hiba = true;
              }

              break;
            case this.gepjarmuSzemelyFormGroup:

              if (this.gepjarmuSzemelyFormGroup && this.gepjarmuSzemelyFormGroup.status == "VALID") {

                let model = {
                  formGroup: this.gepjarmuSzemelyFormGroup.value,
                  f_azonosito: this.azonosito
                }

                this.karbejelento.gepjarmuSzemelyMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba a gépjármű személyek mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              else {
                hiba = true;
              }

              break;
            case this.karosultVagyontargyFormGroup:
              if (this.karosultVagyontargyFormGroup && this.karosultVagyontargyFormGroup.status == "VALID") {

                let model = {
                  formGroup: this.karosultVagyontargyFormGroup.value,
                  f_azonosito: this.azonosito
                }

                this.karbejelento.karosultVagyontargyMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba a károsult vagyontárgy mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              else {
                hiba = true;
              }

              break;
            case this.fajlFeltoltesFormGroup:
              if (this.fajlFeltoltesFormGroup && this.fajlFeltoltesFormGroup.status == "VALID") {

                let model = {
                  f_azonosito: this.azonosito,
                  f_modozat: this.fajlFeltoltesFormGroup.controls["f_modozat"].value,
                  f_okozo_felelos: this.fajlFeltoltesFormGroup.controls["f_okozo_felelos"].value,
                  f_okozo_felelos_egyeb: this.fajlFeltoltesFormGroup.controls["f_okozo_felelos_egyeb"].value,
                  f_okozo_vezeto: this.fajlFeltoltesFormGroup.controls["f_okozo_vezeto"].value,
                  f_okozo_vezeto_egyeb: this.fajlFeltoltesFormGroup.controls["f_okozo_vezeto_egyeb"].value,
                  f_mas_biztositonal_bejelentes: this.fajlFeltoltesFormGroup.controls["f_mas_biztositonal_bejelentes"].value,
                  f_mas_biztosito: this.fajlFeltoltesFormGroup.controls["f_mas_biztosito"].value,
                  f_mas_biztosito_kod: this.fajlFeltoltesFormGroup.controls["f_mas_biztosito_kod"].value
                }

                this.karbejelento.okozoMasBiztositoMentes(model).subscribe(
                  data => {
                    this.mentes_uzenet = data["f_uzenet"];
                    if (this.mentes_uzenet != "") {
                      hiba = true;
                    }
                  },
                  () => {
                    this.utilityService.dialogMegnyitasa({
                      error: true,
                      title: 'Hiba',
                      message: "Hiba más biztosító mentése közben!",
                      confirmButtonText: 'Rendben'
                    });
                    hiba = true;
                  });
              }
              else {
                hiba = true;
              }
              break;
          }

          if (!hiba) {
            this.stepFormGroup = event.selectedStep.stepControl;
          }
          /* }*/
        })
      });


  }

  adatokBekuldese() {

    this.toltoKereses = true;

    // Recaptcha kliens validálás
    this.recaptchaV3Service.execute('webapps_karbejelento_adatokBekuldese').subscribe(
      token => {
        let model = { encodedResponse: token }
        // Recaptcha szerver validálás
        this.utilityService.recaptchaValidalas(model).subscribe(result => {
          this.pdfFormGroup = new FormGroup({});

          this.pdfFormGroup.addControl("alapadatok", this.alapadatokFormGroup);
          this.pdfFormGroup.addControl("karesemeny", this.karesemenyFormGroup);
          this.pdfFormGroup.addControl("bejelento", this.bejelentoFormGroup);
          this.pdfFormGroup.addControl("szemle", this.szemleFormGroup);
          this.pdfFormGroup.addControl("gepjarmuSzemely", this.gepjarmuSzemelyFormGroup);
          this.pdfFormGroup.addControl("karosult", this.karosultFormGroup);
          this.pdfFormGroup.addControl("karosultOtthon", this.karosultOtthonFormGroup);
          this.pdfFormGroup.addControl("vagyon", this.karosultVagyontargyFormGroup);
          this.fajlFeltoltesFormGroup.controls["f_dokumentum"].setValue(null);
          this.pdfFormGroup.addControl("fajlFeltolt", this.fajlFeltoltesFormGroup);


          this.utilityService.environmentName().subscribe(
            data => {
              this.teszt = data != "Production";

              let model = {
                pdfFormGroup: this.pdfFormGroup.value,
                teszt: this.teszt
              }

              this.karbejelento.adatokBekuldese(model).subscribe(
                adatKar => {
                  /*let karszam: string[] = [];
                  let hiba: string[] = [];
                  for (var i = 0; i < adatKar.length; i++) {
                    karszam.push(adatKar[i].f_eredmeny)
                    if (adatKar[i].f_hibalista != "" && adatKar[i].f_hibalista != null) {
                      hiba.push(adatKar[i].f_hibalista);
                    }
                  }*/

                  let karEredemeny: any[] = [];

                  let hiba: string[] = [];
                  for (var i = 0; i < adatKar["karbeFeltoltOssz"].length; i++) {
                    karEredemeny.push({ 'karszam': adatKar["karbeFeltoltOssz"][i].f_eredmeny, 'vonalkod': adatKar["karbeFeltoltOssz"][i].f_vonalkod })
                    if (adatKar["karbeFeltoltOssz"][i].f_hibalista != "" && adatKar["karbeFeltoltOssz"][i].f_hibalista != null) {
                      hiba.push(adatKar["karbeFeltoltOssz"][i].f_hibalista);
                    }
                  }

                  var uzenet: string = ""

                  if (hiba.length > 0) {
                    let hibauzenet = "<ul>";
                    for (var i = 0; i < hiba.length; i++) {
                      hibauzenet = hibauzenet + "<li> " + (i + 1).toString() + ". " + hiba[i] + "</li>"
                    }
                    hibauzenet = hibauzenet + "</ul>";
                    let uzi: string = "<div><spanHiba: " + hibauzenet + "</span></div>"
                    this.eredmenyFormGroup.controls["f_uzenet"].setValue(uzi);
                  }
                  else {
                    this.pdfFormGroup.addControl("karEredemeny", this.formBuilder.array(karEredemeny));


                    this.karbejelentesVege = true;
                    /*let fajlnev: string = "";
                    switch (this.alapadatokFormGroup.controls["f_modozat"].value) {
                        case "KGFB":
                            fajlnev = "_kgfb_karigenybejelento.pdf";
                            break;
                        case "CASCO":
                            fajlnev = "_casco_karigenybejelento.pdf";
                            break;
                        case "CMR":
                            fajlnev = "_cmr_karigenybejelento.pdf";
                            break;
                        case "OTTHON":
                            fajlnev = "_otthon_karigenybejelento.pdf";
                            break;
                    }*/
                    
                    if (this.alapadatokFormGroup.controls["f_modozat"].value != "CMR" && this.alapadatokFormGroup.controls["f_modkod"].value != "22104") {
                      if (this.bejelentoFormGroup.controls["f_bejelento_tipusa"].value == "E") {
                        uzenet = "<strong>Tisztelt Ügyfelünk!</strong><br/><br/>Bejelentését a(z) " + karEredemeny[0].karszam + " nyilvántartási számon rögzítettük." +
                          "<br/>Munkatársunk 3 munkanapon belül felveszi Önnel telefonon a kapcsolatot. A kapcsolat felvételéig szíves türelmét kérjük. Amennyiben további kérdésre, információra van szüksége vagy folyamatban lévő kárüggyel kapcsolatosan szeretne tájékozódni, <strong>kérjük hívja call-centerünket a +36-1/6666-200-as telefonszámon.</strong><br/><br/>" +
                          "<strong>Üdvözlettel,<br/><br/>Gránit Biztosító Zrt.</strong>";
                      }
                      else {
                        uzenet = "<strong>Tisztelt Ügyfelünk!</strong><br/><br/>Bejelentését rögzítettük." +
                          "<br/>Amennyiben további kérdésre, információra van szüksége vagy folyamatban lévő kárüggyel kapcsolatosan szeretne tájékozódni, <strong>kérjük hívja call-centerünket a +36-1-6666-200-as telefonszámon.</strong><br/><br/>" +
                          "<strong>Üdvözlettel,<br/><br/>Gránit Biztosító Zrt.</strong>";
                      }
                    }
                    if (this.alapadatokFormGroup.controls["f_modozat"].value == "CMR") {
                      uzenet = "<strong>Tisztelt Ügyfelünk!</strong><br/><br/>Bejelentését a(z) " + karEredemeny[0].karszam + " nyilvántartási számon rögzítettük." +
                        "<br/>Munkatársunk hamarosan felveszi Önnel a kapcsolatot. A kapcsolat felvételéig szíves türelmét kérjük. Amennyiben további kérdésre, információra van szüksége vagy folyamatban lévő kárüggyel kapcsolatosan szeretne tájékozódni, <strong>kérjük hívja call-centerünket a +36-1-6666-200-as telefonszámon.</strong><br/><br/>" +
                        "<strong>Üdvözlettel,<br/><br/>Gránit Biztosító Zrt.</strong>";
                    }

                    if (this.alapadatokFormGroup.controls["f_modkod"].value == "22104") {
                      uzenet = "<strong>Tisztelt Ügyfelünk!</strong><br/><br/>Bejelentését a(z) " + karEredemeny[0].karszam + " nyilvántartási számon rögzítettük." +
                        "<br/><br/>Ha az Ön által megadott információ nem elégséges ahhoz, hogy bejelentésével érdemben foglalkozzunk akkor munkatársaink az adatok pontosítása érdekében felveszik a kapcsolatot az Ön által megadott elérhetőségen.<br/><br/>Amennyiben helyszíni szemle szükséges, kárszakértőnk a bejelentést követő 2 munkanapon belül telefonon felveszi Önnel a kapcsolatot a szemle időpontjának egyeztetése céljából.<br/><br/>Szükséges iratok beérkezését (és a helyszíni szemlét) követően gondoskodunk a szerződésben megfelelően vállalt kötelezettségünk teljesítéséről.<br/><br/>A kapcsolat felvételéig szíves türelmét kérjük. Amennyiben további kérdésre, információra van szüksége vagy folyamatban lévő kárüggyel kapcsolatosan szeretne tájékozódni, kérjük hívja call-centerünket a +36-1/6666-200-as telefonszámon.<br/><br/>Ha valamelyik vagyontárgycsoportra nem kötötték meg a szerződést, attól még befogadjuk a kárbejelentést, de a hiányosságot szövegesen jelezzük az ügyfélnek.</strong><br/><br/>" +
                        "<strong>Üdvözlettel,<br/><br/>Gránit Biztosító Zrt.</strong>";
                    }                                       

                  }
                  this.toltoKereses = false;
                  this.cdr.detectChanges();

                  if (hiba.length == 0) {

                    this.eredmenyFormGroup.controls["f_uzenet"].setValue(uzenet);
                    let contentType = 'application/pdf';
                    let blob = base64StringToBlob(adatKar.pdf, contentType);
                    saveAs(blob, adatKar.fajlNev);
                  }

                },
                () => {
                  this.utilityService.dialogMegnyitasa({
                    error: true,
                    title: 'Hiba',
                    message: "Hiba az adatok beküldése közben!",
                    confirmButtonText: 'Rendben'
                  });
                  this.toltoKereses = false;
                });
            })
        });
      });
  }

}

@Component({
  selector: 'karbejelento-dupla-dialog',
  templateUrl: './karbejelento-dupla-dialog.html'
})
export class KarbejelentoDuplaDialog {
  constructor(
    public dialogRef: MatDialogRef<KarbejelentoDuplaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onCancel() {
    this.dialogRef.close();
  }
}
