import { Component, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DokumentumService {
  private url: string = "/api/dokumentum";

  constructor(
    private httpClient: HttpClient  ) { }

  // Dokumentum igénylés - szerződés keresés
  szerzodesKereses(model: any) {
    return this.httpClient.post<any[]>(this.url + "/SzerzodesKereses", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Dokumentum igénylés - igény küldése
  igenyKuldes(model: any) {
    return this.httpClient.post<any[]>(this.url + "/IgenyKuldes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  // Dokumentum letöltés
  dokumentumLetoltes(model: any) {
    return this.httpClient.post<any[]>(this.url + "/DokumentumLetoltes", model).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  
}

